import { ZRole } from "@shared/types/User";
import { z } from "zod";

const ZExistingUser = z.object({
  _id: z.string(),
  type: z.literal("existing"),
  name: z.string(),
  email: z.string(),
  isAlreadyInvited: z.boolean(),
});

const ZNewUser = z.object({
  _id: z.literal("__new__"),
  type: z.literal("new"),
  name: z.string(),
  email: z.string(),
  isAlreadyInvited: z.boolean(),
});

const ZInvitedUser = z.discriminatedUnion("type", [ZExistingUser, ZNewUser]);

export const ZInviteUsersBody = z.object({
  users: z.array(ZInvitedUser),
  permissionGroups: z.array(z.string()),
  message: z.string(),
  context: z
    .object({
      type: z.literal("workspace"),
    })
    .or(
      z.object({
        type: z.literal("folder"),
        folder: z.object({
          _id: z.string(),
          name: z.string(),
        }),
      })
    )
    .or(
      z.object({
        type: z.literal("project"),
        project: z.object({
          id: z.string(),
          name: z.string(),
        }),
        folder: z
          .object({
            _id: z.string(),
            name: z.string(),
          })
          .nullable()
          .optional(),
      })
    ),
});

export const ZInviteUsersRequest = {
  body: ZInviteUsersBody.or(
    z
      .object({
        emails: z.array(z.string()),
        type: z.enum(["project", "folder", "workspace"]),
        docID: z.string().optional(),
        docName: z.string().optional(),
        message: z.string().optional(),
        role: ZRole,
        permissionGroups: z.array(z.string()),
      })
      .nonstrict()
  ),
};
export const ZInviteUsersResponse = z.unknown();
