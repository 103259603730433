import {
  componentFoldersAtom,
  componentIdsForLinkingAtom,
  linkComponentActionAtom,
  searchQueryAtom,
  selectedComponentAtom,
  selectedComponentIdAtom,
  selectedFolderAtom,
  selectedLibraryFolderIdAtom,
} from "@/stores/ComponentLinkingFlow";
import { componentFamilyAtom } from "@/stores/Components";
import { derivedSelectedTextItemsAtom } from "@/stores/ProjectSelection";
import { usersByIdAtom } from "@/stores/Workspace";
import CompactLibraryComponentList from "@ds/organisms/CompactLibraryComponentList";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";

export default function LinkComponent() {
  const history = useHistory();
  const componentIds = useAtomValue(componentIdsForLinkingAtom);
  const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);
  const folders = useAtomValue(componentFoldersAtom);
  const selectedTextItems = useAtomValue(derivedSelectedTextItemsAtom);
  const [selectedFolderId, setSelectedFolderId] = useAtom(selectedLibraryFolderIdAtom);
  const selectedFolder = useAtomValue(selectedFolderAtom);
  const [selectedComponentId, setSelectedComponentId] = useAtom(selectedComponentIdAtom);
  const selectedComponent = useAtomValue(selectedComponentAtom);
  const linkComponent = useSetAtom(linkComponentActionAtom);

  function handleSearchQueryChange(query: string) {
    setSearchQuery(query);
  }

  function handleFolderClick(folder: ILibraryComponentFolder) {
    setSelectedFolderId(folder._id);
    setSelectedComponentId(null);
  }

  function handleBackFolderClick() {
    setSelectedFolderId(null);
  }

  function handleComponentClick(component: ILibraryComponent) {
    setSelectedComponentId(component._id);
  }

  function handleComponentLinkClick(component: ILibraryComponent) {
    linkComponent();
  }

  function handleCancelClick() {
    setSelectedComponentId(null);
  }

  function handleLibraryLinkClick() {
    // TODO
    // history.push("/library");
  }

  return (
    <div className={style.linkComponentContainer}>
      <CompactLibraryComponentList
        componentIds={componentIds}
        componentFamilyAtom={componentFamilyAtom}
        usersByIdAtom={usersByIdAtom}
        folders={folders}
        currentFolder={selectedFolder}
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onLibraryLinkClick={handleLibraryLinkClick}
        onComponentClick={handleComponentClick}
        onFolderClick={handleFolderClick}
        onBackFolderClick={handleBackFolderClick}
        onComponentLinkClick={handleComponentLinkClick}
        onComponentCancelClick={handleCancelClick}
        selectedComponent={selectedComponent}
        originalText={selectedTextItems.length === 1 ? selectedTextItems[0].text : undefined}
      />
    </div>
  );
}

export function LinkComponentFallback() {
  return (
    <div className={style.linkComponentContainer}>
      <CompactLibraryComponentList.Fallback />
    </div>
  );
}

LinkComponent.Fallback = LinkComponentFallback;
