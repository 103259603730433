import { IRole } from "@shared/types/User";
import { useEffect, useMemo, useState } from "react";
import http, { API } from "../../../http";
import { User, UserInvitation } from "../InviteUsersInput/types";

interface InviteFormProps {
  title?: string;
  folderId: string;
  currentUser: {
    _id: string;
    role: IRole;
  };
  invitedEmails: {
    [email: string]: boolean;
  };
  handleSendInvite: () => void;
}

const useInviteForm = (props: InviteFormProps) => {
  const { folderId, currentUser, invitedEmails, handleSendInvite } = props;

  const [invites, setInvites] = useState<UserInvitation[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [workspaceUsers, setWorkspaceUsers] = useState<User[]>([]);
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const canSave = useMemo(() => {
    if (isSaving) return false;
    const hasInvalidInvites = invites.some((invite) => !invite.email || invite.isAlreadyInvited);
    return !hasInvalidInvites && invites.length > 0;
  }, [invites, isSaving]);

  const showExternalInviteMsg = useMemo(() => {
    const hasExternalInvite = invites.some(({ type }) => {
      return type === "new";
    });
    const hasInvalidInvites = invites.some((invite) => !invite.email || invite.isAlreadyInvited);
    return hasExternalInvite && !hasInvalidInvites;
  }, [invites]);

  const userList = useMemo(() => {
    return workspaceUsers.filter(({ email }) => !invitedEmails[email]);
  }, [workspaceUsers, invitedEmails]);

  const getWorkspaceUsers = async () => {
    try {
      const { url } = API.workspace.get.users;
      const { data } = await http.get(url);
      const formattedUserList = data
        .map((user) => ({
          _id: user._id,
          name: user.name,
          email: user.email,
          role: user.role,
        }))
        .filter((user) => user._id !== currentUser._id);
      setWorkspaceUsers(formattedUserList);
    } catch (error) {
      console.error("Error fetching workspace users");
      console.error(error);
    }
  };

  const isEmailInUse = async (email: string) => {
    try {
      const { url, body } = API.user.post.checkExists;
      const { data: userExists } = await http.post(url, body({ email }));
      return userExists.exists && !userExists.sameWorkspace;
    } catch (error) {
      console.error("Error checking if email exists", error);
      return false;
    }
  };
  const onUserInvited = async (userInvite: UserInvitation) => {
    if (!userInvite.email && invitedEmails[userInvite.name]) return;
    const isAlreadyInvited = userInvite.type !== "new" ? false : await isEmailInUse(userInvite.email);
    setInvites((prev) => {
      if (userInvite?.email && prev.find((invite) => invite?.email === userInvite?.email)) return prev;
      const update = prev.concat({ ...userInvite, isAlreadyInvited });
      return update;
    });
  };
  const onUserRemoved = (index: number) => {
    setInvites((prev) => {
      let temp = prev;
      temp.splice(index, 1);
      return [...temp];
    });
  };

  const showInviteSuccessMsg = (numInvites: number) => {
    setSuccessMsg(`${numInvites} invite${numInvites > 1 ? "s" : ""} sent successfully.`);
    setTimeout(() => {
      setSuccessMsg("");
    }, 5000);
  };
  const showInviteFailureMsg = () => {
    setErrorMsg("There was an error sending the invites. Please try again.");
    setTimeout(() => {
      setErrorMsg("");
    }, 7000);
  };
  const onSendInvite = async () => {
    try {
      setIsSaving(true);
      setErrorMsg("");
      const { url, body } = API.invite.post.folder;
      await http.post(
        url(folderId),
        body({
          invitations: invites,
        })
      );
      handleSendInvite();
      showInviteSuccessMsg(invites.length);
      setInvites([]);
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      showInviteFailureMsg();
      console.error("Error inviting users to folder");
      console.error(error);
    }
  };

  useEffect(() => {
    getWorkspaceUsers();
  }, []);

  return {
    isSaving,
    canSave,
    invitedUserList: invites,
    userList,
    errorMsg,
    successMsg,
    showExternalInviteMsg,
    onUserInvited,
    onUserRemoved,
    onSendInvite,
  };
};

export { InviteFormProps, useInviteForm };
