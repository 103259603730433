import { CollapseState } from "@ds/organisms/FilterBar";
import { extractVariableMetadataFromRichText } from "@shared/frontend/richText/serializer";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { getPriorSelections } from "@shared/frontend/stores/selection";
import { IObjectId } from "@shared/types/lib";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ITipTapRichText } from "@shared/types/TextItem";
import { isNonEmptyArray } from "@shared/utils/array";
import logger from "@shared/utils/logger";
import { Virtualizer } from "@tanstack/react-virtual";
import ObjectID from "bson-objectid";
import { atom, SetStateAction, WritableAtom } from "jotai";
import { derive, soon, soonAll } from "jotai-derive";
import { atomFamily, atomWithDefault } from "jotai/utils";
import { client } from "../http/lib/dittoClient";
import { componentFolderFamilyAtom } from "./ComponentFolders";
import { componentFamilyAtom, updateComponentActionAtom } from "./Components";
import { locationAtom } from "./Location";

const DETAILS_PANEL_PARAM = "detailsPanel";

export const sidebarCollapseStateAtom = atom<CollapseState>("unset");

/**
 * Represents the id of the currently selected library folder.
 * This is used to load the library list in context of the selected folder.
 */
export const selectedLibraryFolderIdAtom = atom<IObjectId | undefined>(undefined);

/**
 * Composed of the items to render in the library list. This is the structure of the items in the library.
 * Note that this is loaded in the context of the currently selected folder id.
 */
export const libraryListAtomFamily = atomFamily((folderId?: IObjectId) => {
  const { valueAtom: familyAtom } = asyncMutableDerivedAtom({
    loadData: async () => {
      try {
        const result = await client.library.getLibraryStructure({ folderId });
        return result;
      } catch (e) {
        logger.error("Error loading library list", { context: { folderId } }, e);
        // TODO: Render an error component here.
        return [];
      }
    },
  });

  familyAtom.debugLabel = `LibraryList Atom Family (${folderId})`;

  return familyAtom;
});

/**
 * Represents the virtualizer for the library list items. Provides a way to programmatically
 * interact with the virtualized list.
 */
export const libraryItemsVirtualizerAtom = atom<Record<string, Virtualizer<Element, Element>>>({});

/**
 * Represents the items to render in the library list. This is the structure of the items in the library.
 */
export const libraryItemsAtom = atom((get) => {
  const folderId = get(selectedLibraryFolderIdAtom);

  return soon(get(libraryListAtomFamily(folderId)), (libraryItems) => {
    return libraryItems.filter((item) => item.type === "component");
  });
});

/**
 * The count of the items in the library list.
 */
export const libraryItemsCountAtom = derive([libraryItemsAtom], (libraryItems) => libraryItems.length);
libraryItemsCountAtom.debugLabel = "LibraryItemsCountAtom";

/**
 * Represents the virtualizer for the library nav items. Provides a way to programmatically
 * interact with the virtualized list.
 */
export const libraryNavItemsVirtualizerAtom = atom<Record<string, Virtualizer<Element, Element>>>({});

/**
 * Represents the items to render in the library nav. This is the structure of the items in the library including components and folders.
 */
export const libraryNavItemsAtom = atom((get) => {
  const folderId = get(selectedLibraryFolderIdAtom);

  return soon(get(libraryListAtomFamily(folderId)), (libraryItems) => {
    return libraryItems;
  });
});

/**
 * Represents the name of the currently selected library folder.
 */
export const libraryCurrentFolderNameAtom = atom((get) => {
  const folderId = get(selectedLibraryFolderIdAtom);
  if (!folderId) return null;
  return soon(get(componentFolderFamilyAtom(folderId)), (folder) => folder.name);
});

/**
 * Represents the parent id of the currently selected library folder.
 */
export const libraryCurrentFolderParentIdAtom = atom((get) => {
  const folderId = get(selectedLibraryFolderIdAtom);
  if (!folderId) return null;
  return soon(get(componentFolderFamilyAtom(folderId)), (folder) => folder.parentId);
});

/**
 * Controls whether the library create component modal is currently showing
 */
export const libraryCreateComponentModalIsOpenAtom = atom(false);

/**
 * Controls whether the library create folder modal is currently showing
 */
export const libraryCreateFolderModalIsOpenAtom = atom(false);

// MARK: - Selection

export type LibrarySelection =
  | {
      type: "none";
    }
  | {
      type: "component";
      ids: string[];
    };

export const componentIsSelectedAtomFamily = atomFamily((id: string) =>
  atomWithDefault((get) =>
    soon(get(selectedComponentIdsAtom), (selectedComponentIds) => selectedComponentIds.includes(id))
  )
);

/**
 * Special atom type for use only in this file.
 * Stores the current selection state for the project.
 * These selection states are mutually exclusive -- we can have text items selected, or blocks selected, but not both.
 * Any other kind of selection logic should be enforced here as well.
 */
function librarySelectionAtom(): WritableAtom<
  LibrarySelection | Promise<LibrarySelection>,
  [SetStateAction<LibrarySelection>],
  void
> {
  const componentIdsAtom = atomWithURLStorage("selectedLibraryComponentIds", locationAtom);
  componentIdsAtom.debugLabel = "componentIdsAtom (librarySelectionAtom)";
  // atomWithURLStorage will return an array of strings if the URL param is present, and null if it's not
  // we should probably allow it to just store a string, but for now the code in this atom takes
  // care of string <> array conversion

  const selectionAtom = atom(
    (get) => {
      return soon(get(libraryNavItemsAtom), (libraryNavItems) => {
        const componentIds = get(componentIdsAtom);
        /**
         * Check that the items actually exist in the current folder
         */
        const filteredComponentIds = componentIds?.filter((id) => libraryNavItems.some((item) => item._id === id));

        // It's possible for the URL to have an empty value for the selection URL keys. This means the atom returns a value
        // of an empty array. As of the time of this comment, we treat this case as if the selection is empty. If this ever
        // changes, we should make sure that every consumer of `selectedLibraryComponentIdsAtom` knows they might
        // receive an empty array.
        if (filteredComponentIds && filteredComponentIds.length > 0) {
          return { type: "component", ids: filteredComponentIds } as LibrarySelection;
        } else {
          return { type: "none" } as LibrarySelection;
        }
      });
    },
    async (get, set, newSelection: LibrarySelection) => {
      // Detect if there is actually a change in selection
      async function hasSelectionChanged() {
        const currentSelection = await get(selectionAtom);
        if (currentSelection.type !== newSelection.type) {
          return true;
        }

        switch (newSelection.type) {
          case "none":
            return false;
          case "component":
            const currentSelectedItems = "ids" in currentSelection ? currentSelection.ids : []; // Even though we already know both selection types are "component", TS doesn't
            if (newSelection.ids.length !== currentSelectedItems.length) {
              return true;
            }

            return newSelection.ids.toSorted().join(",") !== currentSelectedItems.toSorted().join(",");
        }
      }

      if (!(await hasSelectionChanged())) {
        return;
      }

      const clearSelectedComponents = () =>
        get(componentIdsAtom)?.forEach((id) => set(componentIsSelectedAtomFamily(id), false));
      // const clearSelectedBlocks = () => get(blockIdsAtom)?.forEach((id) => set(blockIsSelectedAtom(id), false));

      function _handleSelectionChange() {
        if (newSelection.type === "none") {
          clearSelectedComponents();

          set(componentIdsAtom, null);
          // set(blockIdsAtom, null);
          // set(_selectedCommentAtom, null);
        } else if (newSelection.type === "component") {
          const newSelectionIds = new Set(newSelection.ids);

          get(componentIdsAtom)?.forEach((id) => {
            if (!newSelectionIds.has(id)) {
              set(componentIsSelectedAtomFamily(id), false);
            }
          });
          newSelectionIds.forEach((id) => set(componentIsSelectedAtomFamily(id), true));
          // clearSelectedBlocks();

          set(componentIdsAtom, newSelection.ids);
          // set(blockIdsAtom, null);
          // set(_selectedCommentAtom, null);
        }
      }

      _handleSelectionChange();

      // // Either immediately change selection or show a discard changes confirmation modal
      // set(discardChangesModalActionAtom, {
      //   onConfirm: _handleSelectionChange,
      //   ignoreInlineChanges: false,
      //   activeElement: document.activeElement,
      // });
    }
  );

  return selectionAtom;
}
export const librarySelectedItemsAtom = librarySelectionAtom();

export const selectedComponentIdsAtom = atom((get) => {
  return soon(get(librarySelectedItemsAtom), (selection) => {
    if (selection.type === "component") {
      return selection.ids;
    } else {
      return [];
    }
  });
});

const _selectedComponentsAtom = atom((get) => {
  return soon(get(selectedComponentIdsAtom), (selectedComponentIds) => {
    const valuesArray = selectedComponentIds.map((id) => get(componentFamilyAtom(id)));
    if (!isNonEmptyArray(valuesArray)) return [];
    const components = soonAll(valuesArray);
    return components;
  });
});

export const derivedSelectedComponentsAtom = derive([_selectedComponentsAtom], (items) => items);
derivedSelectedComponentsAtom.debugLabel = "derivedSelectedComponentsAtom";

/**
 * If exactly one component is selected, returns the full data for that component.
 * Otherwise, returns null.
 */
export const derivedOnlySelectedComponentAtom = derive([_selectedComponentsAtom], (selectedItems) => {
  if (selectedItems.length !== 1) return null;
  return selectedItems[0];
});
derivedOnlySelectedComponentAtom.debugLabel = "derivedOnlySelectedComponentAtom";

export const handleComponentClickActionAtom = atom(
  null,
  async (
    get,
    set,
    props: {
      event: React.MouseEvent<HTMLElement, MouseEvent>;
      componentId: IObjectId;
      skipInlineEditing: boolean;
    }
  ) => {
    const isSelected = get(componentIsSelectedAtomFamily(props.componentId));
    // TODO: handle inline editing
    const isInlineEditing = false;
    const selectedComponentIds = await get(selectedComponentIdsAtom);
    if ("shiftKey" in props.event && props.event.shiftKey) {
      // We need the ordered list of all text items to determine the range of elements to select.
      const components = await get(libraryItemsAtom);
      // If we press SHIFT+click, select the range of elements between the most recently selected element and the current one.
      const mostRecentlySelectedIndex = components.findIndex(
        (item) => item._id === selectedComponentIds[selectedComponentIds.length - 1]
      );
      // If the last selected element is not found (should never happen), select the current element.
      if (mostRecentlySelectedIndex === -1) {
        // TODO: handle inline editing
        set(librarySelectedItemsAtom, { type: "component", ids: [props.componentId] });
        return;
      }
      const currentIndex = components.findIndex((item) => item._id === props.componentId);
      const startIndex = Math.min(mostRecentlySelectedIndex, currentIndex);
      const endIndex = Math.max(mostRecentlySelectedIndex, currentIndex);
      const alreadyPresentSelections = getPriorSelections(
        selectedComponentIds,
        components,
        currentIndex,
        mostRecentlySelectedIndex,
        startIndex,
        endIndex
      );
      // Create the new selections array and ensure the most recently selected element is preserved at the end.
      const newSelections = [
        ...alreadyPresentSelections,
        ...components.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
        ...components
          .slice(mostRecentlySelectedIndex, endIndex + 1)
          .map((item) => item._id)
          .reverse(),
      ];
      // TODO: handle inline editing
      set(librarySelectedItemsAtom, { type: "component", ids: newSelections });
    } else if ("metaKey" in props.event && props.event.metaKey) {
      // If we press CMD+click, toggle selection of the element, without affecting other selections.
      // TODO: Handle inline editing. See the project for an example of this.
      if (isSelected) {
        set(librarySelectedItemsAtom, {
          type: "component",
          ids: selectedComponentIds.filter((id) => id !== props.componentId),
        });
      } else {
        // Place the text item twice in the selections array to properly handle both pivot and pointer state (relevant for arrow-shift selection)
        set(librarySelectedItemsAtom, {
          type: "component",
          ids: [props.componentId, ...selectedComponentIds, props.componentId],
        });
      }
    } else {
      // If we plain-select a selected element, turn on inline editing and remove other selections.
      if (isSelected) {
        if (selectedComponentIds.length > 1) {
          // other things were selected, so we should just select this one
          set(librarySelectedItemsAtom, { type: "component", ids: [props.componentId] });
        } else {
          // nothing else was selected, so we should just inline edit unless we are skipping inline editing
          if (props.skipInlineEditing) {
            set(librarySelectedItemsAtom, { type: "none" });
          } else {
            // TODO; set inline editing
          }
        }
      } else if (!isSelected) {
        if (isInlineEditing) {
          // TODO: Handle inline editing. See the project for an example of this.
        } else {
          set(librarySelectedItemsAtom, { type: "component", ids: [props.componentId] });
        }
        return;
      }
    }
  }
);
/**
 * Creates a new library component from the library page.
 */
export const createLibraryComponentActionAtom = atom(
  null,
  async (
    get,
    set,
    props: { folderId: IObjectId | null; name: string; richText: ITipTapRichText; text: string; workspaceId: IObjectId }
  ) => {
    const variables = extractVariableMetadataFromRichText(props.richText);
    const optimisticComponent: ILibraryComponent = {
      _id: ObjectID().toHexString(),
      name: props.name,
      rich_text: props.richText,
      text: props.text,
      folderId: props.folderId || null,
      status: "NONE",
      instances: [],
      commentThreads: [],
      variables,
      variants: [],
      plurals: [],
      tags: [],
      assignee: null,
      notes: null,
      apiId: crypto.randomUUID(),
      sortKey: "ZZZZZZZZZZZZZZZZZZZZZZZZ",
      workspaceId: props.workspaceId,
    };

    // Optimistically update the component family atom

    componentFamilyAtom(optimisticComponent._id, optimisticComponent);
    const libraryStructureNode = libraryListAtomFamily(optimisticComponent.folderId || undefined);
    set(libraryStructureNode, [
      ...(await get(libraryStructureNode)),
      { type: "component", _id: optimisticComponent._id, sortKey: optimisticComponent.sortKey },
    ]);

    try {
      const result = await client.libraryComponent.createLibraryComponent({
        _id: optimisticComponent._id,
        name: optimisticComponent.name,
        richText: optimisticComponent.rich_text,
        folderId: optimisticComponent.folderId || null,
      });
      set(updateComponentActionAtom, { _id: optimisticComponent._id, update: result.newComponent });
    } catch (error) {
      logger.error("Failed to create library component", { context: { optimisticComponent } }, error);
      componentFamilyAtom.remove(optimisticComponent._id);
      set(
        libraryStructureNode,
        (await get(libraryStructureNode)).filter((item) => item._id !== optimisticComponent._id)
      );
    }
  }
);

// MARK: - Details Panel

const LibraryDetailsPanelContexts = ["GENERAL", "EDIT"] as const;
export type LibraryDetailsPanelContext = (typeof LibraryDetailsPanelContexts)[number];

export const libraryDetailsPanelContextAtom = atom((get) => {
  return soon(get(selectedComponentIdsAtom), (selectedComponentIds) => {
    if (selectedComponentIds.length > 0) {
      return "EDIT";
    }

    return "GENERAL";
  });
});

const LibraryDetailsGeneralPanelTabs = ["ACTIVITY", "COMMENTS"] as const;
export type LibraryDetailsGeneralPanelTab = (typeof LibraryDetailsGeneralPanelTabs)[number];

export const libraryDetailsGeneralPanelSelectedTabAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as LibraryDetailsGeneralPanelTab;

    if (state === null || !LibraryDetailsGeneralPanelTabs.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: LibraryDetailsGeneralPanelTab) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }

      set(locationAtom, {
        ...location,
        searchParams: newSearchParams,
      });
    }

    handleChangePanel();
    // TODO; HAndle discard changes modal

    // set(discardChangesModalActionAtom, {
    //   onConfirm: handleChangePanel,
    //   ignoreInlineChanges: true,
    //   activeElement: document.activeElement,
    // });
  }
);

const LibraryDetailsEditPanelTabs = ["EDIT", "ACTIVITY", "COMMENTS", "VARIANTS"] as const;
export type LibraryDetailsEditPanelTab = (typeof LibraryDetailsEditPanelTabs)[number];

export const LibraryDetailsEditPanelTabAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as LibraryDetailsEditPanelTab;

    if (state === null || !LibraryDetailsEditPanelTabs.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: LibraryDetailsEditPanelTab) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }

      set(locationAtom, {
        ...location,
        searchParams: newSearchParams,
      });
    }

    handleChangePanel();
    // TODO; HAndle discard changes modal

    // set(discardChangesModalActionAtom, {
    //   onConfirm: handleChangePanel,
    //   ignoreInlineChanges: true,
    //   activeElement: document.activeElement,
    // });
  }
);
