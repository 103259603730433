import { IUserWithPermissionsFigmaAuthenticated } from "@shared/types/User";
import { Ditto } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import PermissionTags from "../../permissionTags";
import style from "./style.module.css";

export interface Props {
  user: IUserWithPermissionsFigmaAuthenticated;
  permissionGroups: { _id: string; name: string }[];
}

export default function UserPanel(props: Props) {
  return (
    <Panel titleDittoComponentId="user">
      <div className={style.container}>
        <table className={style.table}>
          <tbody>
            <tr className={style.divider}>
              <td className={style.title}>
                <Ditto componentId="name" />
              </td>
              <td className={style.value} data-testid="user-name">
                {props.user.name}
              </td>
            </tr>
            <tr className={style.divider}>
              <td className={style.title}>
                <Ditto componentId="email-label" />
              </td>
              <td className={style.value} data-testid="user-email">
                {props.user.email}
              </td>
            </tr>
            <tr className={style.divider}>
              <td className={style.title}>
                <Ditto componentId="permissions" />
              </td>
              <td className={style.value}>
                <PermissionTags
                  permissions={props.user.permissionGroups?.groups
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.map((p) => p._id)}
                  permissionGroups={props.permissionGroups}
                />
              </td>
            </tr>
            <tr>
              <td className={style.title}>
                <Ditto componentId="role-label" />
              </td>
              <td className={style.value} data-testid="user-job">
                {props.user.job.slice(0, 1).toUpperCase() + props.user.job.slice(1).toLowerCase()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Panel>
  );
}
