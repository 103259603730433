import React from "react";

export default function BlockIcon(props: { style?: React.CSSProperties; className?: string }) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="4"
        width="10"
        height="3"
        rx="1.25"
        fill="none"
        strokeWidth="0.8"
        stroke="var(--icon-secondary, #A3A3A3)"
      />
      <rect
        x="3"
        y="8.5"
        width="7"
        height="3"
        rx="1.25"
        fill="none"
        strokeWidth="0.8"
        stroke="var(--icon-secondary, #A3A3A3)"
      />
    </svg>
  );
}
