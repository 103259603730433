import { IPopulatedVariableFolder } from "@/../shared/types/VariableFolder";
import PermissionRequiredFolder from "@/components/permissions/PermissionRequired/PermissionRequiredFolder";
import { WebappPermissionProvider as UserPermissionProvider } from "@/store/webappPermissionContext";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { IVariable } from "@shared/types/Variable";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import AddVariableModal from "../../components/AddVariableModal";
import NewComponentFolderModal from "../../components/NewComponentFolderModal";
import ExportModal from "../../components/export-modal/export-modal";
import Notification from "../../components/notification-toast";
import DeleteVariableFolderModal from "./components/DeleteVariableFolderModal";
import MoveVariableModal from "./components/MoveVariableModal";
import VariableDetails from "./components/VariableDetails";
import VariableList from "./components/VariableList";
import VariableNav from "./components/VariableNav";
import VariableTitleBar from "./components/VariableTitleBar";
import style from "./style.module.css";
import { useVariables } from "./useVariables";

const Variables = () => {
  const {
    query,
    setQuery,
    handleQueryChange,
    typeFilter,
    chooseType,
    variables,
    variablesByFolder,
    loadingVariables,
    handleInsertNewVariable,
    handleDeleteVariable,
    handleDeleteVariables,
    selectedVariable,
    handleSelectVariable,
    handleSelectVariableId,
    activityFilter,
    setActivityFilter,
    handleUpdateVariable,
    isSaving,
    allHistoryFetched,
    loadingHistory,
    variableHistory,
    fetchVariableHistoryNewPage,
    instanceData,
    showCreateVariableModal,
    openCreateVariableModal,
    closeCreateVariableModal,
    openExportModal,
    closeExportModal,
    showExportModal,
    variableFolders,
    handleCreateNewVariableFolder,
    handleRenameVariableFolder,
    handleDeleteVariableFolder,
    handleMoveVariables,
    selectedFolderId,
    foldersAllowed,
    variablesToMove,
    setVariablesToMove,
    notification,
    selectVariableFolder,
    setSelectedVariable,
    handleRouteChange,
  } = useVariables();

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showMoveVariableModal, setMoveVariableModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);

  const onCreateNewFolder = async (name: string) => {
    handleCreateNewVariableFolder(name);
    setShowCreateFolderModal(false);
  };

  const onMoveVariables = async (folder: IPopulatedVariableFolder) => {
    let moveToFolder = folder;
    if (folder._id === "__new__") {
      moveToFolder = await handleCreateNewVariableFolder(folder.name);
    }
    handleMoveVariables(
      moveToFolder._id,
      variablesToMove.map((v) => v._id)
    );
    setMoveVariableModal(false);
  };

  const toggleMultiSelected = (variable: IVariable) => {
    if (variablesToMove.find((v) => v._id === variable._id)) {
      setVariablesToMove(variablesToMove.filter((v) => v._id !== variable._id));
    } else {
      setVariablesToMove([...variablesToMove, variable]);
    }
  };

  const selectedFolder = useMemo(() => {
    return variableFolders.find((folder) => folder._id === selectedFolderId);
  }, [selectedFolderId, variableFolders]);

  useEffect(function mountEscapeKeyListener() {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.key !== "Escape") {
        return;
      }
      handleRouteChange();
      setSelectedVariable(null);
      setVariablesToMove([]);
    }
    document.addEventListener("keydown", handleEscapeKey);
    return function unMountEscapeKeyListener() {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const canAccessFolder = userHasResourcePermission("variable_folder:comment");

  if (selectedFolder && !selectedFolder.isSample && !canAccessFolder) {
    return <PermissionRequiredFolder />;
  }

  return (
    <div className={style.variablesPage}>
      <UserPermissionProvider resourceId={selectedFolderId} resourceType="variable_folder">
        <VariableTitleBar
          openExportModal={openExportModal}
          openCreateVariableModal={openCreateVariableModal}
          selectedFolder={selectedFolder}
          handleFolderClick={selectVariableFolder}
        />
        <div className={style.container}>
          <VariableNav
            loadingVariables={loadingVariables}
            variablesByFolder={variablesByFolder}
            selectedVariableId={selectedVariable ? selectedVariable._id : null}
            handleSelectVariableId={handleSelectVariableId}
            variableFolders={variableFolders}
            selectedFolderId={selectedFolderId}
            foldersAllowed={foldersAllowed}
            handleFolderClick={selectVariableFolder}
            isSearching={!!query || typeFilter !== "Any"}
          />
          <VariableList
            isLoading={loadingVariables}
            variables={variables}
            variablesByFolder={variablesByFolder}
            query={query}
            setQuery={setQuery}
            typeFilter={typeFilter}
            chooseType={chooseType}
            handleQueryChange={handleQueryChange}
            selectedVariable={selectedVariable}
            handleSelectVariable={handleSelectVariable}
            foldersAllowed={foldersAllowed}
            variableFolders={variableFolders}
            openCreateVariableFolderModal={async () => setShowCreateFolderModal(true)}
            handleFolderClick={selectVariableFolder}
            selectedFolderId={selectedFolderId}
            handleRenameVariableFolder={handleRenameVariableFolder}
            handleDeleteVariableFolder={() => setShowDeleteFolderModal(true)}
            toggleMultiSelected={toggleMultiSelected}
            multiSelectedVariables={variablesToMove}
            isSearching={!!query || typeFilter !== "Any"}
          />
          <VariableDetails
            selectedVariable={selectedVariable}
            handleSelectVariableId={handleSelectVariableId}
            activityFilter={activityFilter}
            setActivityFilter={setActivityFilter}
            handleUpdateVariable={handleUpdateVariable}
            handleDeleteVariable={handleDeleteVariable}
            handleMoveVariable={(variable: IVariable) => {
              setVariablesToMove([variable]);
              setMoveVariableModal(true);
            }}
            isSaving={isSaving}
            allHistoryFetched={allHistoryFetched}
            loadingHistory={loadingHistory}
            variableHistory={variableHistory}
            fetchVariableHistoryNewPage={fetchVariableHistoryNewPage}
            instanceData={instanceData}
          />
        </div>
        {variablesToMove.length > 0 && (
          <MultiSelectBanner
            foldersEnabled={foldersAllowed}
            selectedVariables={variablesToMove}
            handleDeleteVariables={handleDeleteVariables}
            handleMoveToFolder={() => {
              setMoveVariableModal(true);
            }}
            inSampleFolder={selectedFolder?.isSample}
          />
        )}
        {showCreateVariableModal && (
          <AddVariableModal
            type="new"
            onInsertVariable={handleInsertNewVariable}
            onHide={closeCreateVariableModal}
            defaultExample={undefined}
            selectedFolderId={selectedFolderId}
          />
        )}
        {showExportModal && (
          <ExportModal
            doc_ID={"variable_library"}
            doc_name={"variable_library"}
            onHide={closeExportModal}
            developerModeEnabled
            componentFolder={selectedFolder}
          />
        )}
        {showCreateFolderModal && (
          <NewComponentFolderModal
            onHide={() => setShowCreateFolderModal(false)}
            folderNames={variableFolders.map((f) => f.name)}
            onCreateNewFolder={onCreateNewFolder}
          />
        )}
        {showMoveVariableModal && (
          <MoveVariableModal
            variables={variablesToMove}
            variableFolders={variableFolders.filter((v) => !v.isSample)}
            onHide={() => setMoveVariableModal(false)}
            onSubmit={onMoveVariables}
            selectedFolderId={selectedFolderId}
          />
        )}
        {showDeleteFolderModal && selectedFolder && (
          <DeleteVariableFolderModal
            onHide={() => setShowDeleteFolderModal(false)}
            onSubmit={() => {
              handleDeleteVariableFolder(selectedFolder._id);
              setShowDeleteFolderModal(false);
            }}
            folderName={selectedFolder.name}
            variableCount={selectedFolder.variableCount}
          />
        )}
        {notification && <Notification notification={notification} noPadding={false} />}
      </UserPermissionProvider>
    </div>
  );
};

const MultiSelectBanner = ({
  foldersEnabled,
  selectedVariables,
  handleDeleteVariables,
  handleMoveToFolder,
  inSampleFolder,
}) => {
  const text = `${selectedVariables.length} selected`;
  const canDelete = selectedVariables.every((v: IVariable) => v.instances.length === 0);

  return (
    <div className={style.multiSelectBanner}>
      <div className={style.text}>{text}</div>
      <div className={style.buttons}>
        <button
          onClick={() => {
            if (inSampleFolder) return;
            !foldersEnabled ? undefined : handleMoveToFolder(selectedVariables);
          }}
          className={classNames({
            [style.disabled]: !foldersEnabled || inSampleFolder,
          })}
          data-tip
          data-for="variable-folders-tooltip"
        >
          Move to folder
        </button>
        {(!foldersEnabled || inSampleFolder) && (
          <ReactTooltip
            id="variable-folders-tooltip"
            place="top"
            effect="solid"
            className={style.tooltip}
            delayUpdate={inSampleFolder ? 0 : 500}
            delayHide={inSampleFolder ? 0 : 500}
          >
            {!inSampleFolder && (
              <span className={style.tooltipBody}>
                <a target="_blank" href="/account/workspace">
                  Upgrade
                </a>{" "}
                to Growth to create variable folders
              </span>
            )}
            {inSampleFolder && <span className={style.tooltipBody}>Sample data cannot be moved to other folders.</span>}
          </ReactTooltip>
        )}
        {canDelete && (
          <button
            data-tip
            data-for="delete-variables-tooltip"
            className={classNames({
              [style.disabled]: inSampleFolder,
            })}
            onClick={() => {
              if (inSampleFolder) return;
              handleDeleteVariables(selectedVariables.map((v: IVariable) => v._id));
            }}
          >
            Delete
          </button>
        )}
        {canDelete && inSampleFolder && (
          <ReactTooltip id="delete-variables-tooltip" place="top" effect="solid" className={style.tooltip}>
            <span className={style.tooltipBody}>Deleting is disabled for sample data.</span>
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};
export default Variables;
