import { z } from "zod";
import { ZAction } from "../figmaSync";
import { ZFigmaTextNodeSerialized } from "../FigmaTextNode";

export const ZFigmaSyncRequestBody = z.object({
  fileId: z.string(),
  branchId: z.string().nullable(),
  projectId: z.string(),
});

export type IFigmaSyncRequestBody = z.infer<typeof ZFigmaSyncRequestBody>;

const ZFigmaSelectedTextRange = z
  .object({
    node: z.object({
      id: z.string(),
    }),
    start: z.number(),
    end: z.number(),
  })
  .nullable();

export type IFigmaSelectedTextRange = z.infer<typeof ZFigmaSelectedTextRange>;

export const ZFigmaSelection = z.object({
  textNodes: z.array(ZFigmaTextNodeSerialized),
  pageId: z.string(),
  figmaSelectedTextRange: ZFigmaSelectedTextRange,
  unparsedNodeIds: z.array(z.string()),
});

export type IFigmaSelection = z.infer<typeof ZFigmaSelection>;

const ZSyncedTextObject = z.object({
  textItemId: z.string().nullable(),
});

export type ISyncedTextObject = z.infer<typeof ZSyncedTextObject>;

export const ZInstaSyncResponse = z.object({
  syncedTextNodesByNodeId: z.record(ZSyncedTextObject),
  actions: z.array(ZAction),
});

export type IInstaSyncResponse = z.infer<typeof ZInstaSyncResponse>;
