import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export function NavItemNestedIndicator(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.NavItemNestedIndicatorWrapper, props.className)}
      data-testid="nav-item-nested-indicator"
    ></div>
  );
}

export default NavItemNestedIndicator;
