import { ZActualComponentStatus, ZTextItemVariant } from "@shared/types/TextItem";
import { z } from "zod";

export const ZAddVariantsToFrameRequest = {
  body: z.object({
    projectId: z.string(),
    variants: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    ),
    groupIds: z.array(z.string()),
    folderId: z.string().nullable(),
    applyToAllGroups: z.boolean().optional(),
    status: ZActualComponentStatus.optional(),
  }),
};
export const ZAddVariantsToFrameResponse = z.unknown();

export const ZUpdateVariantDescriptionRequest = {
  params: z.object({
    variantId: z.string(),
  }),
  body: z.object({
    description: z.string(),
  }),
};
export const ZUpdateVariantDescriptionResponse = z.unknown();

export const ZDeleteVariantRequest = {
  params: z.object({
    variantId: z.string(),
  }),
};
export const ZDeleteVariantResponse = z.unknown();

export const ZUpdateVariantTextInstancesControllerRequest = {
  body: z.object({
    data: z.object({
      projectId: z.string(),
      allTextItemsToUpdate: z.array(
        z.object({
          textItemId: z.string(),
          componentId: z.string().optional(),
          variantId: z.string(),
        })
      ),
      updates: ZTextItemVariant.partial(),
    }),
  }),
};
export const ZUpdateVariantTextInstancesControllerResponse = z.unknown();
