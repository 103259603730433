import { z } from "zod";
import { ZObjectId } from "./lib";

const ZLibraryComponentItem = z.object({
  _id: ZObjectId,
  type: z.literal("component"),
  sortKey: z.string(),
});

export type ILibraryComponentItem = z.infer<typeof ZLibraryComponentItem>;

const ZLibraryComponentFolderItem = z.object({
  _id: ZObjectId,
  type: z.literal("componentFolder"),
});

export type ILibraryComponentFolderItem = z.infer<typeof ZLibraryComponentFolderItem>;

export const ZLibraryItem = z.discriminatedUnion("type", [ZLibraryComponentItem, ZLibraryComponentFolderItem]);

export type ILibraryItem = z.infer<typeof ZLibraryItem>;
