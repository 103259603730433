import { libraryDetailsPanelContextAtom } from "@/stores/Library";
import { useAtomValue } from "jotai";
import LibraryEditDetailsPanel from "../LibraryEditDetailsPanel";
import LibraryGeneralDetailsPanel from "../LibraryGeneralDetailsPanel";
import style from "./style.module.css";

function LibraryDetailsPanel() {
  const libraryDetailsPanelContext = useAtomValue(libraryDetailsPanelContextAtom);
  return (
    <div className={style.libraryDetailsPanelContainer}>
      <div className={style.libraryDetailsPanelFloatingBox}>
        {libraryDetailsPanelContext === "GENERAL" && <LibraryGeneralDetailsPanel />}
        {libraryDetailsPanelContext === "EDIT" && <LibraryEditDetailsPanel />}
      </div>
    </div>
  );
}

export default LibraryDetailsPanel;
