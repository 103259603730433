import TextItemTextInputs from "@/components/TextItemTextInputs/TextItemTextInputs";
import { updateTextItems } from "@/http/dittoProject";
import { editableHasChangesAtom, editableTextItemVariantChangesAtom } from "@/stores/Editing";
import {
  allTagsInProject as allTagsInProjectAtom,
  projectIdAtom,
  removeVariantFromTextItemsActionAtom,
  updateTextItemsActionAtom,
} from "@/stores/Project";
import {
  derivedOnlySelectedTextItemAtom,
  derivedSelectedTextItemsAtom,
  detailsPanelPropsAtom,
  detailsPanelSelectionStateAtom,
  selectedTextItemIdsAtom,
} from "@/stores/ProjectSelection";
import { blockSelectedVariantIdFamilyAtom, updateTextItemVariantActionAtom } from "@/stores/Variants";
import { createNewTagActionAtom, unwrappedAllTagsAtom, usersByIdAtom, variantNameFamilyAtom } from "@/stores/Workspace";
import { TextItemMetaData } from "@ds/organisms/TextItemMetadata";
import { isDiffRichText } from "@shared/lib/text";
import { ZTextItemsUpdate } from "@shared/types/DittoProject";
import { CharacterLimit, RichTextInputProps, RichTextInputRef } from "@shared/types/RichText";
import {
  ITextItem,
  ITextItemStatus,
  ITextItemVariant,
  ITextItemVariantUpdate,
  ITipTapRichText,
  ZTextItemPluralType,
} from "@shared/types/TextItem";
import { IUser } from "@shared/types/User";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import { Editor } from "@tiptap/react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { uniq } from "lodash";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import style from "./style.module.css";

interface SkinnyPlural {
  rich_text: ITipTapRichText;
  form: z.infer<typeof ZTextItemPluralType>;
}

/**
 * If all statuses are the same, return that status. Otherwise, return "MIXED".
 *
 * @param textItems The text items to check.
 * @returns The merged status.
 */
function getMergedStatus(textItems: ITextItem[]): ITextItemStatus | "MIXED" {
  const statuses = textItems.map((textItem) => textItem.status);

  let status: ITextItemStatus | "MIXED" = "MIXED";

  if (statuses.every((status) => status === statuses[0])) {
    status = statuses[0];
  }

  return status;
}

/**
 * If all assignees are the same, return that assignee. Otherwise, return "MIXED".
 *
 * @param textItems The text items to check.
 * @returns The merged assignee.
 */
function getMergedAssignee(textItems: ITextItem[], usersById: Record<string, IUser>) {
  const assignees = textItems.map((textItem) => textItem.assignee);

  if (assignees.every((assignee) => assignee === assignees[0])) {
    // If the common assignee does not exist in the workspace, return null.
    if (!assignees[0] || !usersById[assignees[0]]) return null;

    return { value: assignees[0], label: usersById[assignees[0]].name };
  }

  return { value: "MIXED", label: "Mixed Assignees" };
}

/**
 * If all sets of tags are the same, return that set of tags. Otherwise, return a 'mixed tags' object.
 */
function getMergedTags(textItems: ITextItem[]): string[] | { mixed: true } {
  if (textItems.length === 0) {
    return [];
  }

  const referenceTags = [...textItems[0].tags].sort();

  // Check if every item's tags matches the reference
  for (let i = 1; i < textItems.length; i++) {
    const currentSorted = [...textItems[i].tags].sort();

    if (currentSorted.length !== referenceTags.length) {
      return { mixed: true };
    }

    for (let j = 0; j < referenceTags.length; j++) {
      if (currentSorted[j] !== referenceTags[j]) {
        return { mixed: true };
      }
    }
  }

  return referenceTags;
}

/**
 * Returns a list of tags to write and list of tags to delete based on diff between original and current tags.
 *
 * @param baseTags The original base tags in selection.
 * @param tags The current tags in state.
 * @returns The lists of tags to write and delete.
 */
function getTagsChanges(baseTags: string[][], tags: string[]) {
  const baseTagsSet = new Set(baseTags.flat());
  const tagsSet = new Set(tags);

  /**
   * The new behavior is to set all selected text items to the tags currently
   * selected in the tag list. This requires that the set of tags to create is
   * exactly the selected tags, while the set of tags to delete is all other tags
   * which are not selected.
   */
  const tagsToDelete = [...baseTagsSet].filter((baseTag) => !tagsSet.has(baseTag));

  return {
    tagsToWrite: tags,
    tagsToDelete,
  };
}

/**
 * If all texts are the same, return that text. Otherwise, return null.
 */
function getMergedText(textItems: ITextItem[]) {
  const baseTexts = textItems.map((textItem) => textItem.rich_text);

  if (baseTexts.every((text) => text === baseTexts[0])) {
    return baseTexts[0];
  }

  return null;
}

/**
 * If there is only one text item, return its notes. Otherwise, return a mixed notes object.
 */
function getMergedNotes(textItems: ITextItem[]) {
  const allNotesSame = textItems.every((t) => t.notes === textItems[0].notes);
  return allNotesSame ? textItems[0].notes : ({ mixed: true } as const);
}

/**
 * The merged character limit is the minimum of all character limits.
 */
function getMergedCharacterLimit(textItems: ITextItem[]) {
  const characterLimits = textItems.map((textItem) => textItem.characterLimit ?? Infinity);

  const minimum = Math.min(...characterLimits);

  return minimum === Infinity ? null : minimum;
}

/**
 * The merged plurals only exist if only one item is selected and it has plurals.
 */
function getMergedPlurals(textItems: ITextItem[]) {
  return textItems.length === 1 ? textItems[0].plurals : null;
}

function MetadataPanel() {
  const projectId = useAtomValue(projectIdAtom);
  const usersById = useAtomValue(usersByIdAtom);
  const allTags = useAtomValue(unwrappedAllTagsAtom);
  const onCreateNewTag = useSetAtom(createNewTagActionAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const updateTextItemsAction = useSetAtom(updateTextItemsActionAtom);
  const selectedTextItems = useAtomValue(derivedSelectedTextItemsAtom);
  const onlySelectedTextItem = useAtomValue(derivedOnlySelectedTextItemAtom);
  const [editableHasChanges, setEditableHasChanges] = useAtom(editableHasChangesAtom);
  const setEditableTextItemVariantChanges = useSetAtom(editableTextItemVariantChangesAtom);
  const [allTagsInProject, setAllTagsInProject] = useAtom(allTagsInProjectAtom);

  // Variant atoms
  const activeVariantId = useAtomValue(blockSelectedVariantIdFamilyAtom(onlySelectedTextItem?.blockId ?? null));
  const activeVariantName = useAtomValue(variantNameFamilyAtom(activeVariantId));
  const setUpdateTextItemVariantAction = useSetAtom(updateTextItemVariantActionAtom);
  const removeVariantFromTextItemsAction = useSetAtom(removeVariantFromTextItemsActionAtom);

  // Details panel atom state
  const setDetailsPanelEditState = useSetAtom(detailsPanelSelectionStateAtom);
  const [detailsPanelProps, setDetailsPanelProps] = useAtom(detailsPanelPropsAtom);

  const [baseText, setBaseText] = useState(() => getMergedText(selectedTextItems));
  const [status, setStatus] = useState(() => getMergedStatus(selectedTextItems));
  const [assignee, setAssignee] = useState(() => getMergedAssignee(selectedTextItems, usersById));
  const [tags, setTags] = useState(() => getMergedTags(selectedTextItems));
  const [notes, setNotes] = useState(() => getMergedNotes(selectedTextItems));
  const [characterLimit, setCharacterLimit] = useState<CharacterLimit>(() =>
    getMergedCharacterLimit(selectedTextItems)
  );

  const setBaseTextCallback = useCallback((richText: ITipTapRichText) => setBaseText(richText), [setBaseText]);

  // Ref for rich text input so we can reset editor state
  const richTextInputRef = useRef<RichTextInputRef>(null);

  const resetState = useCallback(() => {
    setBaseText(getMergedText(selectedTextItems));
    setStatus(getMergedStatus(selectedTextItems));
    setAssignee(getMergedAssignee(selectedTextItems, usersById));
    setTags(getMergedTags(selectedTextItems));
    setNotes(getMergedNotes(selectedTextItems));
    setCharacterLimit(getMergedCharacterLimit(selectedTextItems));
  }, [selectedTextItems, usersById]);

  useLayoutEffect(resetState, [selectedTextItemIds, resetState]);

  const [plurals, setPlurals] = useState<SkinnyPlural[] | null>(getMergedPlurals(selectedTextItems));

  // TextItemMetaData takes a lot of props, so make sure we are memoizing everything we can!
  const setAssigneeProp = useCallback(
    (value: { id: string; name: string }) => setAssignee(value ? { value: value.id, label: value.name } : null),
    [setAssignee]
  );
  const usersProp = useMemo(() => usersById, [usersById]);
  const assigneeProp = useMemo(() => (assignee ? { id: assignee.value, name: assignee.label } : null), [assignee]);
  const allTagsProp = useMemo(() => allTags.map((tag) => ({ value: tag._id, label: tag._id })), [allTags]);

  // Show variant metadata only if there is a single selection and the active variant on the block is not the base variant
  // (If the text item is non-block, its active variant id is always "BASE_VARIANT_ID")
  const showVariantMetadata = useMemo(
    () => !!onlySelectedTextItem?._id && activeVariantId !== BASE_VARIANT_ID,
    [onlySelectedTextItem?._id, activeVariantId]
  );
  const activeVariantProp = useMemo(
    () => ({ id: activeVariantId, name: activeVariantName ?? "" }),
    [activeVariantId, activeVariantName]
  );
  const textItemVariantProp: (ITextItemVariant & { name: string; textItemId: string }) | undefined = useMemo(() => {
    if (!onlySelectedTextItem?._id) return undefined;
    // Find the variant on the text item that matches the active variant on the block
    const variant = onlySelectedTextItem.variants.find((v) => v.variantId === activeVariantId);
    return variant ? { ...variant, name: activeVariantName ?? "", textItemId: onlySelectedTextItem._id } : undefined;
  }, [onlySelectedTextItem?._id, onlySelectedTextItem?.variants, activeVariantName, activeVariantId]);

  const calculateDiffFromState = useCallback(
    (textItem: ITextItem) => {
      const mergedTags = getMergedTags(selectedTextItems);
      /**
       * Tags are considered to have changed if the tags were set in the input, and either the original
       * value was a merged set, or the new set of tags is different than the user input value.
       */
      const tagsChanged =
        Array.isArray(tags) &&
        (!Array.isArray(mergedTags) ||
          JSON.stringify(tags.map((t) => t.toLowerCase())) !== JSON.stringify(mergedTags.map((t) => t.toLowerCase())));

      return {
        baseTextChanged: Boolean(baseText) && isDiffRichText(baseText, textItem.rich_text),
        statusChanged: status !== "MIXED" && status !== textItem.status,
        assigneeChanged: assignee?.value !== "MIXED" && (assignee?.value || null) !== textItem.assignee,
        tagsChanged,
        notesChanged: notes !== null && notes !== (textItem.notes ?? "") && typeof notes === "string",
        characterLimitChanged: characterLimit !== (textItem.characterLimit ?? null),
        pluralsChanged:
          plurals !== null &&
          JSON.stringify(plurals) !==
            JSON.stringify(
              textItem.plurals?.map((p) => ({
                rich_text: p.rich_text,
                form: p.form,
              })) || []
            ),
      };
    },
    [selectedTextItems, tags, status, baseText, assignee?.value, notes, characterLimit, plurals]
  );

  // Check if changes have been made.
  useEffect(
    function checkForChanges() {
      const anyTextItemChanged = Object.values(selectedTextItems).some((textItem) =>
        Object.values(calculateDiffFromState(textItem)).some((value) => value)
      );

      setEditableHasChanges(anyTextItemChanged);
    },
    [selectedTextItems, calculateDiffFromState, setEditableHasChanges]
  );

  const resetFormState = useCallback(() => {
    richTextInputRef.current?.reset();
    resetState();
  }, [resetState]);

  useEffect(() => {
    if (detailsPanelProps?.resetFormState) {
      resetFormState();
      setDetailsPanelProps((prevProps) => ({
        ...prevProps,
        resetFormState: false,
      }));
    }
  }, [detailsPanelProps?.resetFormState, setDetailsPanelProps, resetFormState]);

  const onDeleteVariant = useCallback(
    function _onDeleteVariant() {
      removeVariantFromTextItemsAction({ textItemIds: selectedTextItemIds, variantId: activeVariantId });
    },
    [activeVariantId, removeVariantFromTextItemsAction, selectedTextItemIds]
  );

  if (!projectId) {
    return <div className={style.wrapper}>No project selected</div>;
  }

  const onSave = async () => {
    // If any of the selected text items have been changed, that attribute will be set to true.
    const changes = Object.values(selectedTextItems)
      .map((textItem) => calculateDiffFromState(textItem))
      .reduce((acc, change) => {
        Object.keys(change).forEach((key) => {
          acc[key] = acc[key] || change[key];
        });
        return acc;
      }, {} as ReturnType<typeof calculateDiffFromState>);

    const { tagsToWrite, tagsToDelete } = Array.isArray(tags)
      ? getTagsChanges(
          selectedTextItems.map((textItem) => textItem.tags),
          tags
        )
      : { tagsToWrite: [], tagsToDelete: [] };

    const [request] = updateTextItems({
      projectId,
      updates: selectedTextItems.map((textItem) => {
        const update: z.infer<typeof ZTextItemsUpdate> = {
          textItemIds: [textItem._id],
          ...(changes.baseTextChanged ? { richText: baseText! } : {}),
          ...(changes.statusChanged ? { status: status as ITextItemStatus } : {}),
          ...(changes.assigneeChanged ? { assignee: assignee?.value || null } : {}),
          ...(changes.tagsChanged && tagsToWrite.length ? { tags: tagsToWrite } : {}),
          ...(changes.tagsChanged && tagsToDelete.length ? { tagsToDelete } : {}),
          ...(changes.notesChanged && typeof notes === "string" ? { notes } : {}),
          ...(characterLimit !== null && changes.characterLimitChanged
            ? { characterLimit: characterLimit as number }
            : {}),
          ...(changes.pluralsChanged ? { plurals } : {}),
        };

        return update;
      }),
    });

    const result = await request;
    const updatedTextItems = selectedTextItems.map((textItem) => result.data[textItem._id.toString()]);

    // Update text item to changed values
    updateTextItemsAction(updatedTextItems);

    // Update all project tags if new one is added
    if (changes.tagsChanged && tagsToWrite.length) {
      const newAllTagsInProject: string[] = uniq([...allTagsInProject, ...tagsToWrite]);

      if (JSON.stringify(allTagsInProject) !== JSON.stringify(newAllTagsInProject)) {
        setAllTagsInProject(newAllTagsInProject);
      }
    }
  };

  // Update handler for text item's variant in Edit panel
  function onUpdateVariant(update: ITextItemVariantUpdate) {
    if (!onlySelectedTextItem?._id) return;
    setUpdateTextItemVariantAction({ textItemId: onlySelectedTextItem._id, update });
  }

  // When the user clicks "Add variant" in the Edit panel, we switch the to the Variants tab with the addVariantsForm open
  // and the selected option as the active variant
  function onAddVariant() {
    setDetailsPanelEditState("VARIANTS");
    setDetailsPanelProps({
      variants: {
        showAddForm: true,
        defaultVariant: {
          id: activeVariantId,
          name: activeVariantName ?? "",
        },
      },
    });
  }

  function onVariantFormHasChanges(hasChanges: boolean) {
    setEditableTextItemVariantChanges({ [activeVariantId]: hasChanges });
  }

  return (
    <div className={style.wrapper}>
      <TextItemMetaData
        status={status}
        setStatus={setStatus}
        users={usersProp}
        assignee={assigneeProp}
        setAssignee={setAssigneeProp}
        allTags={allTagsProp}
        tags={tags}
        setTags={setTags}
        onCreateNewTag={onCreateNewTag}
        notes={notes}
        setNotes={setNotes}
        onCancel={resetFormState}
        onSave={onSave}
        onInsertVariable={() => {}}
        showCTAButtons={editableHasChanges}
        onDeleteVariant={onDeleteVariant}
        // Only allow text editing if we have a single text item selected and it has no plurals
        richTextInput={
          !!onlySelectedTextItem &&
          !onlySelectedTextItem.plurals.length && (
            <RichTextInput
              ref={richTextInputRef}
              initialVariableRichValue={onlySelectedTextItem}
              setBaseText={setBaseTextCallback}
              setPlurals={setPlurals}
              setCharacterLimit={setCharacterLimit}
              characterLimit={characterLimit}
              // Disable text item rich text inputs (including plurals, variables, character limit) if variant is selected
              richTextInputDisabled={showVariantMetadata}
              pluralsDisabled={showVariantMetadata}
              variablesDisabled={showVariantMetadata}
              characterLimitDisabled={showVariantMetadata}
            />
          )
        }
        RichTextInputWithProps={RichTextInput}
        showVariantMetadata={showVariantMetadata}
        activeVariant={activeVariantProp}
        textItemVariant={textItemVariantProp}
        onUpdateVariant={onUpdateVariant}
        onAddVariant={onAddVariant}
        variantPlaceholderText={onlySelectedTextItem?.text}
        onVariantFormHasChanges={onVariantFormHasChanges}
      />
    </div>
  );
}

/**
 * Wrapper for our legacy TextItemTextInput component that wraps TipTap's rich text editor.
 * @param props Wrapper props:
 * - initialVariableRichValue: The value to pass down to the legacy TextItemTextInput component.
 *
 * @important
 * The initialVariableRichValue prop gets passed down to our legacy TextItemTextInput component, and passes down many layers of prop drilling
 * before being used to initialize the TipTap editor. It's important that the value here does *not* change on edit --
 * it's used for initializing editor state, and thus should only change when we're re-initializing the selected page
 * (e.g. changing our selection).
 */
export const RichTextInput = memo(
  forwardRef<RichTextInputRef, RichTextInputProps>(function RichTextInput(props: RichTextInputProps, ref) {
    const { initialVariableRichValue, setBaseText, setPlurals, setCharacterLimit, characterLimit } = props;

    // Ref for the TipTap editor instance
    const editorRef = useRef<Editor | null>(null);

    const handleCharacterLimitChange = useCallback(
      (newCharacterLimit: number | null) => {
        setCharacterLimit(newCharacterLimit);
      },
      [setCharacterLimit]
    );

    const legacyHandleTextChange = useCallback(
      function _legacyHandleTextChange(
        fieldStates: {
          label: string | undefined;
          form: string | undefined;
          value: { text: string; richText: ITipTapRichText; variables: ITextItem["variables"] };
        }[]
      ): void {
        const base = fieldStates.find((fieldState) => fieldState.form === undefined);
        const nonBases = fieldStates.filter((fieldState) => fieldState.form !== undefined);

        if (nonBases.length === 0 && base) {
          setBaseText(base.value.richText);
        } else if (nonBases[0]) {
          setBaseText(nonBases[0].value.richText);
        }

        setPlurals(
          nonBases.map((fieldState) => ({
            rich_text: fieldState.value.richText,
            form: fieldState.form as z.infer<typeof ZTextItemPluralType>,
          }))
        );
      },
      [setBaseText, setPlurals]
    );

    /**
     * Callback for resetting the TipTap editor with initial values
     * TODO: Handle resetting plurals
     */
    const reset = useCallback(() => {
      if (editorRef.current) {
        editorRef.current.commands.setContent(initialVariableRichValue.rich_text);
      }
    }, [initialVariableRichValue.rich_text]);

    // Expose reset function through ref
    useImperativeHandle(
      ref,
      () => ({
        reset,
      }),
      [reset]
    );

    const accessEditorInstance = useCallback((editor: Editor) => {
      editorRef.current = editor;
    }, []);

    const textLabelLeft = useMemo(() => <div></div>, []);

    return (
      <TextItemTextInputs
        overrideClassname={style.richTextInput}
        hideTopLabels={true}
        useNSLabels
        textItem={initialVariableRichValue}
        legacyHandleTextChange={legacyHandleTextChange}
        readonly={false}
        isBaseText={true}
        isVariant={false}
        shouldShowRichText={true}
        handleCharacterLimitChange={handleCharacterLimitChange}
        characterLimit={characterLimit}
        textLabelLeft={textLabelLeft}
        disabled={props.richTextInputDisabled}
        pluralInputsDisabled={props.pluralsDisabled}
        variablesDisabled={props.variablesDisabled}
        characterLimitDisabled={props.characterLimitDisabled}
        placeholder={props.placeholder}
        emptyEditorClass={props.emptyEditorClass}
        accessEditorInstance={accessEditorInstance}
      />
    );
  })
);

export default MetadataPanel;
