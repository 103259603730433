import PanelEmptyState from "@ds/molecules/PanelEmptyState";
import React from "react";

// TODO: Implement this with correct functionality
function LibraryEditVariantsPanel() {
  return (
    <PanelEmptyState data-testid="variants-panel" header="No variants" description="Variants are not supported yet" />
  );
}

export default LibraryEditVariantsPanel;
