import { atom, Atom, useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import React, { useMemo, useRef } from "react";
import BaseCombobox from "../BaseCombobox";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;

  selectedFolder: { _id: string; name: string } | null;
  onChange: (folder: { _id: string; name: string } | null) => void;
  foldersAtom: Atom<{ _id: string; name: string }[] | Promise<{ _id: string; name: string }[]>>;
}

export const ALL_COMPONENTS = { value: "__ALL_COMPONENTS__", label: "All Components" };

export function LibraryComponentFolderFilterDropdown(props: IProps) {
  const selectedFolderId = props.selectedFolder?._id ?? null;

  const { current: optionsAtom } = useRef(
    atom(async (get) => {
      const folders = await get(props.foldersAtom);
      return [ALL_COMPONENTS].concat(
        folders.map((folder) => ({
          value: folder._id,
          label: folder.name,
        }))
      );
    })
  );

  const optionSelectedAtom = useMemo(() => {
    return unwrap(
      atom(async (get) => {
        if (!selectedFolderId) return null;
        const options = await get(optionsAtom);
        return options.find((option) => option.value === selectedFolderId) ?? null;
      }),
      () => null
    );
  }, [optionsAtom, selectedFolderId]);

  const optionSelected = useAtomValue(optionSelectedAtom);

  return (
    <BaseCombobox
      hideDownArrow
      optionsAtom={optionsAtom}
      selectedItem={optionSelected}
      setSelectedItem={(option) => props.onChange(option ? { _id: option.value, name: option.label } : null)}
      placeholder="Choose a destination..."
    />
  );
}

Object.assign(LibraryComponentFolderFilterDropdown, {
  isAllComponentsOption: (folderId: string) => folderId === ALL_COMPONENTS.value,
});

export default LibraryComponentFolderFilterDropdown as typeof LibraryComponentFolderFilterDropdown & {
  isAllComponentsOption: (folderId: string) => boolean;
};
