import { routes } from "@/defs";
import { getUnreadNotifications, markNotificationAsRead } from "@/http/user";
import { NotificationContext } from "@/store/notificationContext";
import { ALL_PAGE_ID } from "@/views/Project/state/usePageState";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import { IPopulatedNotification } from "@shared/types/Notification";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import {
  HOMEPAGE_DISCUSSION_MARKED_READ,
  HOMEPAGE_DISCUSSION_REPLY_CLICKED,
} from "../../../../../shared/segment-event-names";
import useSegment from "../../../../hooks/useSegment";

export default function useDiscussionPanel() {
  const [loading, setLoading] = useState(true);
  const [allNotifications, setAllNotifications] = useState<IPopulatedNotification[]>([]);
  const [notifications, setNotifications] = useState<IPopulatedNotification[]>([]);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const segment = useSegment();
  const { fetchNotifications: refreshNotifications } = useContext(NotificationContext);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const { lastMessage } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (!lastMessage) return;
    const data = JSON.parse(lastMessage.data);

    if (data.messageType === WEBSOCKET_EVENTS.NOTIFICATION) {
      fetchNotifications();
    }
  }, [lastMessage]);

  const fetchNotifications = async () => {
    const [request] = getUnreadNotifications();
    const { data } = await request;
    const discussions = data.filter((n) => n.commentThread && n.action !== "resolved your comment");
    setAllNotifications(discussions);
    paginateNotifications(discussions, page, pageSize);
    setTotal(discussions.length);
    setLoading(false);
  };

  const paginateNotifications = (array: any[], page: number, pageSize: number) => {
    const startIndex = (page - 1) * pageSize;
    setNotifications(array.slice(startIndex, startIndex + pageSize));
  };

  const onPaginateBack = () => {
    const newPage = page - 1;
    setPage(newPage);
    paginateNotifications(allNotifications, newPage, pageSize);
  };

  const onPaginateForward = () => {
    const newPage = page + 1;
    setPage(newPage);
    paginateNotifications(allNotifications, newPage, pageSize);
  };

  const onMarkAsRead = async (notification: IPopulatedNotification) => {
    const [request] = markNotificationAsRead({ notificationId: notification._id });
    await request;
    const updatedNotifications = allNotifications.filter((n) => n._id !== notification._id);
    setTotal(updatedNotifications.length);
    setAllNotifications(updatedNotifications);
    paginateNotifications(updatedNotifications, page, pageSize);
    refreshNotifications();

    segment.track({
      event: HOMEPAGE_DISCUSSION_MARKED_READ,
      properties: { notification: notification },
    });
  };

  const onReply = (notification: IPopulatedNotification) => {
    segment.track({
      event: HOMEPAGE_DISCUSSION_REPLY_CLICKED,
      properties: {
        notification: notification,
        projectId: notification.docId,
        componentId: notification.wsCompId,
      },
    });
    if (notification.docId && !notification.isNorthStar) {
      history.push(
        `${routes.nonNavRoutes.project.getPath(notification.docId)}/page/${ALL_PAGE_ID}/${notification.compId}`
      );
    } else if (notification.docId && notification.isNorthStar) {
      history.push(
        `${routes.nonNavRoutes.projectBeta.getPath(notification.docId)}?selectedTextItemIds=${
          notification.compId
        }&detailsPanel=COMMENTS&selectedCommentThreadId=${notification.commentThreadId}`
      );
    } else {
      history.push({
        pathname: `/components/${notification.wsCompId}`,
        state: { commentThreadId: notification.commentThreadId, wsCompId: notification.wsCompId },
      });
    }
  };

  return {
    loading,
    notifications,
    page,
    pageSize,
    total,
    onPaginateBack,
    onPaginateForward,
    onMarkAsRead,
    onReply,
  };
}
