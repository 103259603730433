import { z } from "zod";

export const ZFigmaGetBranchInfoJobData = z.object({
  projectId: z.string(),
  figmaToken: z.string(),
  validateFigma: z.boolean(),
  workspaceId: z.string(),
});

export type IFigmaGetBranchInfoJobData = z.infer<typeof ZFigmaGetBranchInfoJobData>;
