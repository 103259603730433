import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { IVariable } from "@shared/types/Variable";
import { IPopulatedVariableFolder } from "@shared/types/VariableFolder";
import VariableItem from "../VariableItem";
import style from "./style.module.css";

interface VariableFolderGroupProps {
  folder: IPopulatedVariableFolder;
  variables: IVariable[];
  handleSelectVariable: (variable: IVariable) => void;
  isSelected: (variable: IVariable) => boolean;
  isMultiSelected: (variable: IVariable) => boolean;
  toggleSelected: (variable: IVariable) => void;
}

export default function VariableFolderGroup({
  folder,
  variables,
  handleSelectVariable,
  isSelected,
  isMultiSelected,
  toggleSelected,
}: VariableFolderGroupProps) {
  return (
    <div className={style.variableGroup}>
      <div className={style.groupName}>
        <div className={style.folderIconWrapper}>
          <FolderOpenOutlinedIcon className={style.icon} />
        </div>
        <div className={style.frameName}>{folder.name}</div>
        <div className={style.count}>{variables.length}</div>
      </div>
      <div className={style.groupVariables}>
        {variables &&
          variables.map((variable, index) => (
            <VariableItem
              key={index}
              variable={variable}
              isSelected={isSelected(variable)}
              handleSelectVariable={() => handleSelectVariable(variable)}
              toggleSelected={() => toggleSelected(variable)}
              isMultiSelected={isMultiSelected(variable)}
            />
          ))}
      </div>
    </div>
  );
}
