import ComponentCard from "@/components/ComponentCard/ComponentCard";
import ProjectCard from "@/components/ProjectCard/ProjectCard";
import SkeletonLoader from "@/components/SkeletonLoader/SkeletonLoader";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ScheduleIcon from "@mui/icons-material/ScheduleOutlined";
import { IRecentCard, IRecentComponentCard } from "@shared/types/http/Homepage";
import classNames from "classnames";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import ProjectFolderCard from "../../../../components/ProjectFolderCard/ProjectFolderCard";
import Panel from "../panel";
import style from "./RecentsPanel.module.css";

export interface RecentsPanelProps {
  loading: boolean;
  cards: IRecentCard[];
  onClickProjectCard: (projectId: string) => void;
  onClickComponentCard: (component: IRecentComponentCard) => void;
  onClickProjectFolderCard: (folderId: string) => void;
}

const CAROUSEL_GAP = 24;

const RecentsPanel = (props: RecentsPanelProps) => {
  // Carousel pagination assumes that the distance between the start of one card and the next is constant, i.e.:
  //  1. All cards are the same width
  //  2. All cards have the same gap between them
  //
  // It works by:
  //  1. Taking the width + margin of the first card in the array -- cardShiftDistance
  //  2. Dividing that by the width of the panel to determine how many cards make up a page -- numCardsThatFitInPanel
  //  3. Modifying the cardIndex by a multiple of numCardsThatFitInPanel
  //  4. Multiplying cardIndex with cardShiftDistance to determine the total shift distance
  //
  // This approach is adaptive to the width of the panel! If you resize, you'll be able to move a
  // different number of cards at a time, but you're still moving in a whole-number multiple of a
  // the width of a card.

  const [cardIndex, setCardIndex] = useState<number>(0); // the index of the card that is currently showing at the far left of the panel
  const [cardShiftDistance, setCardShiftDistance] = useState(0);
  const cardToMeasureRef = useRef<HTMLDivElement>(null);

  const panelRef = useRef<HTMLDivElement>(null);
  const [panelWidth, setPanelWidth] = useState(0);
  const numCardsThatFitInPanel = Math.floor(panelWidth / cardShiftDistance);

  const totalShiftDistance = cardShiftDistance * cardIndex;
  const transformStyle = `translateX(-${totalShiftDistance}px)`;

  const cardIncludesFolder = useMemo(
    () =>
      props.cards.some(
        (card) =>
          (card.type === "project" && !!card.project.folder) || (card.type === "component" && !!card.component.folder)
      ),
    [props.cards]
  );

  // We don't want the user to be able to go so far forwards that they see more than one card's worth of empty space.
  // This maxIndex means at that farthest index "forward", the max number of cards should be showing.
  //
  // E.g. if the panel is 700px wide, and each card is 200px wide, a user can see three cards
  // at a time; we shouldn't advance so far forward that they only see 1 or 2 cards.
  const maxIndex = props.cards.length - numCardsThatFitInPanel;

  // We want to move one "page" at a time, or if we can't move a full page, move to the start/end.
  function onForwardClick() {
    const newIndex = cardIndex + numCardsThatFitInPanel;
    if (newIndex <= maxIndex) setCardIndex(newIndex);
    else setCardIndex(maxIndex);
  }

  function onBackwardClick() {
    const newIndex = cardIndex - numCardsThatFitInPanel;
    if (newIndex >= 0) setCardIndex(newIndex);
    else setCardIndex(0);
  }

  useLayoutEffect(
    function measureFirstCardWidth() {
      function handleResize() {
        setCardShiftDistance((cardToMeasureRef.current?.clientWidth || 0) + CAROUSEL_GAP);
        setPanelWidth(panelRef.current?.clientWidth || 0);
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    },
    [props.cards]
  );

  if (!props.loading && props.cards.length === 0) {
    return <></>;
  }

  const shouldUseMultiStatusMarginAdjustment = props.cards
    .filter((card): card is IRecentComponentCard => card.type === "component")
    .some((card) => !!card.component.multiEditedIds?.length);

  return (
    <Panel
      titleDittoComponentId="home.headers.recents"
      icon={ScheduleIcon}
      containerClassName={style.container}
      contentClassName={style.content}
      headerClassName={style.header}
    >
      <div className={style.recentsPanel} ref={panelRef}>
        {cardIndex > 0 && (
          <div className={classNames(style.arrow, style.back)} onClick={onBackwardClick}>
            <ChevronLeft />
          </div>
        )}

        <div className={style.carousel}>
          <div
            className={classNames(style.carouselShiftWrapper, {
              [style.noFoldersInCards]: !cardIncludesFolder && !props.loading,
            })}
            style={{ transform: transformStyle, gap: CAROUSEL_GAP }}
          >
            <div></div>
            {props.loading && (
              <>
                <SkeletonLoader format="recentsCard" />
                <SkeletonLoader format="recentsCard" />
                <SkeletonLoader format="recentsCard" />
                <SkeletonLoader format="recentsCard" />
                <SkeletonLoader format="recentsCard" />
                <SkeletonLoader format="recentsCard" />
                <SkeletonLoader format="recentsCard" />
              </>
            )}

            {props.cards.map((card, index) => {
              const possibleRef = index === 0 ? { ref: cardToMeasureRef } : {};
              return (
                <div className={style.cardWrapper} key={index} {...possibleRef}>
                  {card.type === "project" && (
                    <ProjectCard
                      project={card.project}
                      classNameCard={classNames(style.projectCard)}
                      onClick={() => props.onClickProjectCard(card.project._id)}
                      className={classNames({
                        [style.multiStatusMarginAdjustment]: shouldUseMultiStatusMarginAdjustment,
                      })}
                    />
                  )}
                  {card.type === "component" && (
                    <ComponentCard
                      component={card.component}
                      onClick={() => props.onClickComponentCard(card)}
                      className={classNames({
                        [style.multiStatusMarginAdjustment]: shouldUseMultiStatusMarginAdjustment,
                      })}
                    />
                  )}
                  {card.type === "project-folder" && (
                    <ProjectFolderCard
                      folder={card.folder}
                      className={classNames({
                        [style.projectFolder]: true,
                        [style.multiStatusMarginAdjustmentFolder]: shouldUseMultiStatusMarginAdjustment,
                      })}
                      onClick={() => props.onClickProjectFolderCard(card.folder._id)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {cardIndex < maxIndex && (
          <div className={classNames(style.arrow, style.forward)} onClick={onForwardClick}>
            <ChevronRight />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default RecentsPanel;
