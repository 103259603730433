import { ZActualComponentStatus } from "@shared/types/TextItem";
import { z } from "zod";

export const ZEditCompRequest = {
  body: z.object({
    ws_comp_id: z.string(),
    doc_id: z.string().nullable().optional(),
    newCompInfo: z.any(),
    from: z.string().optional(),
  }),
};
export const ZEditCompResponse = z.unknown();

export const ZUpdateComponentsRequest = {
  body: z.object({
    ids: z.array(z.string()),
    assignee: z.string().nullable().optional(),
    tags_added: z.array(z.string()).optional(),
    tags_deleted: z.array(z.string()).optional(),
    status: ZActualComponentStatus.optional(),
    docId: z.string().optional(),
    from: z.string().optional(),
    characterLimit: z.number().nullable().optional(),
  }),
};
export const ZUpdateComponentsResponse = z.unknown();

export const ZRenameBlocksRequest = {
  body: z.object({
    // this is the shape of calling Object.entries on a Map<string, { name: string; apiID: string; sortKey: string }>
    componentEntries: z.array(
      z.tuple([
        z.string(),
        z.object({ name: z.string(), apiID: z.string().optional(), sortKey: z.string().optional() }),
      ])
    ),
  }),
};
export const ZRenameBlocksResponse = z.unknown();

export const ZRenameMultiCompRequest = {
  body: z.object({
    renamePairs: z.array(
      z.object({
        name: z.string(),
        oldName: z.string(),
      })
    ),
  }),
};
export const ZRenameMultiCompResponse = z.unknown();

export const ZGetImportComponentsCreateActionRequest = {
  body: z.object({ backgroundJobEntryId: z.string() }),
};
export const ZGetImportComponentsCreateActionResponse = z.unknown();

export const ZGetImportComponentsVariantActionRequest = {
  body: z.object({ backgroundJobEntryId: z.string() }),
};
export const ZGetImportComponentsVariantActionResponse = z.unknown();

export const ZGetImportComponentsUpdateActionRequest = {
  body: z.object({ backgroundJobEntryId: z.string() }),
};
export const ZGetImportComponentsUpdateActionResponse = z.unknown();

export const ZInitializeCSVImportRequest = {
  body: z.array(z.array(z.string())),
};
export const ZInitializeCSVImportResponse = z.unknown();

export const ZIgnoreComponentMergeSuggestionRequest = {
  params: z.object({ key: z.string() }),
  body: z.object({ value: z.boolean() }),
};
export const ZIgnoreComponentMergeSuggestionResponse = z.unknown();

export const ZBulkIgnoreComponentMergeSuggestionsRequest = {
  body: z.object({
    bulkSuggestionKeys: z.array(z.string()),
  }),
};
export const ZBulkIgnoreComponentMergeSuggestionsResponse = z.unknown();

export const ZBulkAcceptComponentMergeSuggestionsRequest = {
  body: z.object({
    bulkSuggestions: z.array(
      z.object({
        suggestionKey: z.string(),
        targetComponentId: z.string(),
      })
    ),
  }),
};
export const ZBulkAcceptComponentMergeSuggestionsResponse = z.unknown();

export const ZAcceptComponentMergeSuggestionRequest = {
  params: z.object({ key: z.string() }),
  body: z.object({
    targetComponentId: z.string(),
  }),
};
export const ZAcceptComponentMergeSuggestionResponse = z.unknown();

export const ZBulkAttachMultiCompRequest = {
  body: z.object({
    attach_data: z.array(
      z.object({
        suggestionId: z.string().optional(),
        ws_comp_id: z.string(),
        actual_comp_ids: z.array(z.string()),
      })
    ),
  }),
};
export const ZBulkAttachMultiCompResponse = z.unknown();

export const ZBulkCreateCompsRequest = {
  body: z.object({
    compCreationData: z.array(
      z.object({
        suggestionId: z.string().optional(),
        componentFields: z.object({
          name: z.string(),
          folder_id: z.string().nullable().optional(),
        }),
        comp_ids: z.array(z.string()),
      })
    ),
    projectId: z.string(),
  }),
};
export const ZBulkCreateCompsResponse = z.unknown();

export const ZIgnoreMultiSuggestionsRequest = {
  body: z.object({
    suggestionIds: z.array(z.string()),
    ignore: z.boolean(),
  }),
};
export const ZIgnoreMultiSuggestionsResponse = z.unknown();

export const ZGetPopulatedInstancesRequest = {
  params: z.object({
    id: z.string(),
  }),
  query: z.object({
    projectId: z.string().optional(),
  }),
};
export const ZGetPopulatedInstancesResponse = z.unknown();

export const ZGetPopulatedInstancesMultiRequest = {
  query: z.object({
    projectId: z.string().optional(),
    componentIds: z.array(z.string()),
    omitDraftInstances: z.string().optional(),
  }),
};
export const ZGetPopulatedInstancesMultiResponse = z.unknown();

export const ZGetPageForGroupRequest = {
  params: z.object({
    groupName: z.string(),
  }),
  query: z.object({
    folderId: z.string().optional(),
  }),
};
export const ZGetPageForGroupResponse = z.unknown();

export const ZGetPageForGroupBlockRequest = {
  params: z.object({
    groupName: z.string(),
    blockName: z.string(),
  }),
  query: z.object({
    folderId: z.string().optional(),
  }),
};
export const ZGetPageForGroupBlockResponse = z.unknown();

export const ZAddVariantRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZAddVariantResponse = z.unknown();

export const ZCreateComponentFromTextItemRequest = {
  body: z.object({
    name: z.string(),
    comp_id: z.string(),
    folder_id: z.string().nullable().optional(),
  }),
};
export const ZCreateComponentFromTextItemResponse = z.unknown();

export const ZAcceptComponentSuggestionRequest = {
  params: z.object({
    suggestionId: z.string(),
  }),
};
export const ZAcceptComponentSuggestionResponse = z.unknown();

export const ZUndoAutoAttachRequest = {
  body: z.object({
    changeItemId: z.string(),
    projectId: z.string(),
  }),
};
export const ZUndoAutoAttachResponse = z.unknown();

export const ZValidateNameExistsRequest = {
  body: z.object({
    name: z.string(),
  }),
};
export const ZValidateNameExistsResponse = z.unknown();

export const ZGetComponentsByGroupAndBlockNameRequest = {
  query: z.object({
    folderId: z.string().optional(),
  }),
  body: z.object({
    groupNames: z.array(z.string()),
    groupBlockNames: z.array(z.string()),
  }),
};
export const ZGetComponentsByGroupAndBlockNameResponse = z.unknown();

export const ZGetCompNamesByIdsRequest = {
  query: z.object({
    componentIds: z.array(z.string()),
  }),
};
export const ZGetCompNamesByIdsResponse = z.unknown();

export const ZMergeComponentsRequest = {
  body: z.object({
    componentIds: z.array(z.string()),
    targetId: z.string(),
  }),
};
export const ZMergeComponentsResponse = z.unknown();

export const ZLoadUpdatedComponentsRequest = {
  query: z.object({
    componentIds: z.array(z.string()),
  }),
};
export const ZLoadUpdatedComponentsResponse = z.unknown();
