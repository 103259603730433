import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import classNames from "classnames";
import { Atom, PrimitiveAtom, useAtom, useAtomValue, useSetAtom, WritableAtom } from "jotai";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import * as SegmentEvents from "../../../../shared/segment-event-names";
import { IHiddenField } from "../../../../shared/types/TextItem";
import Button from "../../atoms/Button";
import ThumbnailBarIcon from "../../atoms/Icon/icons/ThumbnailBarIcon";
import SeparatorLine from "../../atoms/SeparatorLine";
import Text from "../../atoms/Text";
import Toggle from "../../atoms/Toggle";
import HideFieldsDropdown from "../../molecules/HideFieldsDropdown";
import FilterBarSearch from "../FilterBarSearch";
import style from "./index.module.css";

interface IProps {
  filterContextId: string;
  designPreviewToggledAtom: PrimitiveAtom<boolean>;
  searchAtom: WritableAtom<string | null, [newSearch: string | null], void>;
  sidebarCollapseStateAtom: PrimitiveAtom<CollapseState>;
  hiddenFieldsListAtom: Atom<IHiddenField[]>;
  resetHiddenFieldsActionAtom: WritableAtom<null, [id: string], void>;
  updateHiddenFieldsActionAtom: WritableAtom<
    null,
    [id: string, updatedFields: { value: string; label: string; isHidden: boolean }[]],
    void
  >;
  useSegment: () => {
    track: (args: unknown) => void;
    debouncedTrack: (args: unknown, debounceTime: number) => void;
  };

  filters: Record<string, { value: string; label: string }>;
  className?: string;
  style?: React.CSSProperties;
}

export type CollapseState = "open" | "closed" | "unset";

export function FilterBar(props: IProps) {
  return (
    <div style={props.style} className={classNames(style.FilterBarContainer, props.className)} data-testid="filter-bar">
      <div className={style.leftSection}>
        <SidebarToggle sidebarCollapseStateAtom={props.sidebarCollapseStateAtom} />
        <HiddenFields
          hiddenFieldsListAtom={props.hiddenFieldsListAtom}
          resetHiddenFieldsActionAtom={props.resetHiddenFieldsActionAtom}
          updateHiddenFieldsActionAtom={props.updateHiddenFieldsActionAtom}
          useSegment={props.useSegment}
          filterContextId={props.filterContextId}
        />

        {/* TODO: implement in https://linear.app/dittowords/issue/DIT-7814/project-level-variant-preview */}
        {/* <NavMenuItem Icon={<CallSplitIcon className={style.rotateRight} />} label="Variant" /> */}

        {/* <AddFiltersDropdown
          selectedOptions={selectedFilterOptions}
          setSelectedOptions={onSetSelectedFilters}
          options={PROJECT_FILTER_OPTIONS}
        /> */}
      </div>
      <div className={style.rightSection}>
        <FilterBarSearch searchAtom={props.searchAtom} />
        <SeparatorLine color="var(--border-secondary)" height={20} />
        <DesignPreviews designPreviewToggledAtom={props.designPreviewToggledAtom} />
      </div>
    </div>
  );
}

function SidebarToggle(props: { sidebarCollapseStateAtom: PrimitiveAtom<CollapseState> }) {
  const [sidebarCollapseState, setSidebarCollapseState] = useAtom(props.sidebarCollapseStateAtom);
  const thumbnailAreaRef = useRef<HTMLDivElement>(null);
  const thumbnailWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(
    function calculateThumbnailAreaWidth() {
      if (thumbnailAreaRef.current && thumbnailWrapperRef.current) {
        const width = thumbnailAreaRef.current.getBoundingClientRect().width;
        thumbnailWrapperRef.current.style.setProperty("--thumbnail-area-width", `${width}px`);
      }
    },
    [sidebarCollapseState]
  );

  const getThumbnailState = useCallback(function thumbnailState(sidebarState: CollapseState): CollapseState {
    if (sidebarState === "closed") return "open";
    else if (sidebarState === "open") return "closed";
    else return "unset";
  }, []);

  const toggleSidebarCollapse = useCallback(
    function toggleSidebarCollapse() {
      if (sidebarCollapseState === "closed") {
        setSidebarCollapseState("open");
      } else {
        setSidebarCollapseState("closed");
      }
    },
    [setSidebarCollapseState, sidebarCollapseState]
  );

  return (
    <div ref={thumbnailWrapperRef} data-state={getThumbnailState(sidebarCollapseState)}>
      <div ref={thumbnailAreaRef} className={style.thumbnailButtonArea}>
        <Button type="icon" level="subtle" onClick={toggleSidebarCollapse}>
          <ThumbnailBarIcon />
        </Button>
        <SeparatorLine color="var(--border-secondary)" height={20} />
      </div>
    </div>
  );
}

interface IHiddenFieldsProps {
  filterContextId: string;
  useSegment: () => {
    track: (args: unknown) => void;
    debouncedTrack: (args: unknown, debounceTime: number) => void;
  };
  hiddenFieldsListAtom: Atom<IHiddenField[]>;
  resetHiddenFieldsActionAtom: WritableAtom<null, [id: string], void>;
  updateHiddenFieldsActionAtom: WritableAtom<
    null,
    [id: string, updatedFields: { value: string; label: string; isHidden: boolean }[]],
    void
  >;
}

function HiddenFields(props: IHiddenFieldsProps) {
  const { debouncedTrack } = props.useSegment();
  const hiddenFieldsList = useAtomValue(props.hiddenFieldsListAtom);
  const resetHiddenFields = useSetAtom(props.resetHiddenFieldsActionAtom);
  const updateHiddenFields = useSetAtom(props.updateHiddenFieldsActionAtom);

  function setHiddenFields(newSelectedOptions: { value: string; label: string }[]) {
    const selectedValuesSet = new Set(newSelectedOptions.map((option) => option.value));

    const newHiddenFields =
      hiddenFieldsList?.map((field) => {
        if (selectedValuesSet.has(field.value)) {
          return {
            ...field,
            isHidden: true,
          };
        } else {
          return {
            ...field,
            isHidden: false,
          };
        }
      }) ?? [];

    updateHiddenFields(props.filterContextId, newHiddenFields);

    if (newHiddenFields.length > 0) {
      debouncedTrack(
        {
          event: SegmentEvents.FIELDS_HIDDEN,
          properties: {
            fields: newHiddenFields.filter((f) => f.isHidden).map((f) => f.label),
            contextId: props.filterContextId,
          },
        },
        5000
      );
    }
  }

  const onResetHiddenFields = useCallback(
    function onResetHiddenFields() {
      resetHiddenFields(props.filterContextId);
    },
    [props.filterContextId, resetHiddenFields]
  );

  return (
    <HideFieldsDropdown
      hiddenFieldsList={hiddenFieldsList}
      setSelectedOptions={setHiddenFields}
      removeFilter={onResetHiddenFields}
    />
  );
}

interface DesignPreviewsProps {
  designPreviewToggledAtom: PrimitiveAtom<boolean>;
}

function DesignPreviews(props: DesignPreviewsProps) {
  const [designPreviewToggled, setDesignPreviewToggled] = useAtom(props.designPreviewToggledAtom);

  return (
    <div className={style.designPreviewToggle}>
      <Toggle
        pressed={designPreviewToggled}
        onPressedChange={setDesignPreviewToggled}
        leadingIcon={<BrokenImageOutlinedIcon />}
        iconSize="xs"
      >
        <Text size="small" color="primary">
          Design preview
        </Text>
      </Toggle>
    </div>
  );
}

export default FilterBar;
