import DittoComponents from "@/ditto/components__root__base.json";
import useInviteModal from "@/hooks/useInviteModal";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { NotificationContext } from "@/store/notificationContext";
import { useWorkspace } from "@/store/workspaceContext";
import HelpOutline from "@mui/icons-material/HelpOutline";
import PersonAdd from "@mui/icons-material/PersonAdd";
import { NS_BETA_URL_PATH_NAME } from "@shared/common/constants";
import * as SegmentEvents from "@shared/segment-event-names";
import logger from "@shared/utils/logger";
import classNames from "classnames";
import DittoProvider, { DittoComponent } from "ditto-react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logoHand from "../../assets/ditto-logo-hand.png";
import logoText from "../../assets/ditto-logo-text.png";
import dittoSource from "../../ditto";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import { BillingContext } from "../../store/billingContext";
import { getRemainingDaysInTrial, getRemainingWorkspaceTrialDays } from "../../utils/trialDateMath";
import { HelpCenter } from "../HelpCenter/HelpCenter_NEW";
import InviteCollaboratorsModal from "../InviteCollaboratorsModal";
import OverlayToast from "../OverlayToast";
import { useOverlayToast } from "../OverlayToast/useOverlayToast";
import ChartDataIcon from "../icons/ChartDataIcon";
import ReminderIcon from "../icons/ReminderIcon";
import InviteModal from "../invitemodal/invitemodal";
import NavLink from "./NavLink";
import style from "./Navbar.module.css";
import NotificationsArea from "./NotificationsArea";
import PlanBadge from "./PlanBadge";
import SimpleLink from "./SimpleLink";
import UserSection from "./UserSection";
import { navRoutes } from "./navRoutes_NEW";
import { useHelpCenter } from "./useHelpCenter";
import { useNotificationsPanel } from "./useNotificationsPanel";

interface NavbarProps {}

const DAYS_IN_MS = 1000 * 60 * 60 * 24;
const ONBOARDING_CHECKLIST_PROJECT_ID = "project_630d31fb22f3d9e524d780b9";

// When canCollapse is false, the Navbar will always be expanded to 244px.
// When canCollapse is true, the Navbar's *relatively positioned container* will always be 50px wide,
// and the Navbar can switch between 50px and 244px wide, expanding *over* any content to its right.
const COLLAPSED_WIDTH = 50;
const EXPANDED_WIDTH = 244;

const Navbar = (props: NavbarProps) => {
  const { showBillingModal } = useContext(BillingContext);
  const { workspaceInfo } = useWorkspace();
  const { unreadNotifs } = useContext(NotificationContext);
  const segment = useSegment();
  const helpCenter = useHelpCenter();
  const history = useHistory();
  const { toggleInviteModal, openInviteModal, isInviteModalOpen } = useInviteModal();
  const notificationsPanel = useNotificationsPanel();

  const [canCollapse, setCanCollapse] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [notificationsAreaOpen, setNotificationsAreaOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState(false);
  const remainingDaysInTrial = getRemainingDaysInTrial(workspaceInfo);
  const displayUpgradeCTA =
    workspaceInfo?.plan === "free" || workspaceInfo?.plan === "trial" || remainingDaysInTrial >= 0;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const userHasNotifications = unreadNotifs?.length > 0;

  useEffect(
    function addHoverListeners() {
      const handleMouseEnter = () => {
        setIsHovering(true);
        setCollapsed(false);
      };

      const handleMouseLeave = () => {
        setIsHovering(false);
        if (canCollapse) setCollapsed(true);
      };

      const node = containerRef.current;

      if (node) {
        node.addEventListener("mouseenter", handleMouseEnter);
        node.addEventListener("mouseleave", handleMouseLeave);
      }

      return function removeHoverListener() {
        if (node) {
          node.removeEventListener("mouseenter", handleMouseEnter);
          node.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    },
    [canCollapse]
  );

  const notificationButtonRef = React.useRef<HTMLDivElement | null>(null);
  const notificationsAreaRef = React.useRef<HTMLDivElement | null>(null);

  useEffect(function addClickOutsideListener() {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;

      if (
        notificationsAreaRef.current &&
        !notificationsAreaRef.current.contains(target) &&
        notificationButtonRef.current &&
        !notificationButtonRef.current.contains(target)
      ) {
        setNotificationsAreaOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  // The navbar can only collapse on certain pages -- this effect checks if the current path
  // is one of those pages and sets the canCollapse state accordingly.
  //
  // In addition, when we switch to a page where canCollapse=true:
  //   - if the user is not hovering over the navbar, we collapse it
  //   - if the user is hovering over the navbar, we keep it expanded and let the hover listeners take care of it
  useEffect(
    function possiblyCollapseOnPathChange() {
      const path = history.location.pathname;

      const matchesCollapsePath = Boolean(
        path.match(/^\/projects\/[a-zA-Z0-9]/) || // collapse on /projects/:projectId, but not /projects
          path.startsWith(`/${NS_BETA_URL_PATH_NAME}`) ||
          path.startsWith("/components") ||
          path.startsWith("/variants") ||
          path.startsWith("/variables") ||
          path.startsWith("/library")
      );

      setCanCollapse(matchesCollapsePath);
      if (matchesCollapsePath) {
        setCollapsed(true);
      }
      if (!matchesCollapsePath) {
        setCollapsed(false);
      }
    },
    [history.location.pathname]
  );

  function trialType() {
    if (workspaceInfo?.ws_trial && getRemainingWorkspaceTrialDays(workspaceInfo) >= 0) {
      return "workspace";
    } else {
      return "devTools";
    }
  }

  function handleUpgradePlanClick() {
    segment.track({
      event: SegmentEvents.UPGRADE_PLAN_LINK_CLICKED,
      properties: { location: "navbar", application: "web_app" },
    });
    showBillingModal();
  }

  function handleInviteTeammateClick() {
    segment.track({ event: SegmentEvents.INVITE_TEAMMATE_CTA });
    openInviteModal();
  }

  function onLogoClick() {
    history.push("/");
  }

  function toggleNotificationsAreaOpen() {
    setNotificationsAreaOpen((p) => !p);
  }

  function toggleHelpCenterOpen() {
    helpCenter.setIsOpen((p) => !p);
  }

  const { users: workspaceUsers } = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const { showToast, overlayToastProps } = useOverlayToast();
  const [isInviteSending, setIsInviteSending] = useState(false);
  const isPermissionGroupsEnabled = workspaceInfo?.plan === "enterprise";

  const toggleInviteOpen = () => {
    let didOpen = toggleInviteModal();
    if (didOpen) {
      segment.track({ event: "Invite Modal Opened" });
    }
  };

  const handleAddInvite = async ({ emails, role, type, message }) => {
    try {
      setIsInviteSending(true);
      const { url, body } = API.invite.post.create;
      await http.post(
        url,
        body({
          emails,
          permissionGroups: [role],
          message,
          role,
          type,
          docID: undefined,
          docName: undefined,
        })
      );
      toggleInviteOpen();
      showToast(emails.length === 1 ? "Invite sent! 🎉" : "Invites sent! 🎉");
    } catch (error) {
      logger.error(
        "Error Inviting Users",
        {
          context: {
            emails,
            permissionGroups: [role],
            message,
            role,
            type,
            docID: null,
            docName: null,
          },
        },
        error
      );
      toggleInviteOpen();
      showToast("Error sending invites. Please try again later.", 5000);
    }
    setIsInviteSending(false);
  };

  return (
    <div
      ref={containerRef}
      className={style.navBarRelativeContainer}
      style={{ flexBasis: `${canCollapse ? COLLAPSED_WIDTH : EXPANDED_WIDTH}px` }}
    >
      <div
        className={classNames(style.navBar, {
          [style.collapsed]: collapsed,
          [style.dropShadow]: canCollapse && !collapsed,
        })}
      >
        <div className={style.top}>
          <div className={style.logoSection}>
            {/* For future reference -- this section is divided unintuitively to make the collapse/expand
                work much more nicely. Be careful with the <div> groupings here! */}
            <div
              className={classNames(style.clickOverlay, {
                [style.collapsed]: collapsed,
              })}
              onClick={onLogoClick}
            />

            <img
              src={logoHand}
              alt="Ditto logo"
              className={classNames(style.logoHand, {
                [style.collapsed]: collapsed,
              })}
            />

            <div
              className={classNames(style.collapseSection, {
                [style.collapsed]: collapsed,
              })}
            >
              <img src={logoText} className={style.logoText} />
              {workspaceInfo && <PlanBadge planType={workspaceInfo?.plan} />}
            </div>
          </div>

          <div className={classNames(style.linkSection, style.sectionOne)}>
            {Object.keys(navRoutes)
              .slice(0, 3)
              .map((key) => (
                <NavLink key={key} to={key} collapsed={collapsed} shortcut={navRoutes[key].shortcut} />
              ))}
          </div>

          <div className={style.linkSection}>
            {Object.keys(navRoutes)
              .slice(3, 7)
              .map((key) => (
                <NavLink key={key} to={key} collapsed={collapsed} />
              ))}
          </div>
        </div>

        <div className={classNames(style.bottom, style.linkSection)}>
          {remainingDaysInTrial >= 0 && (
            <div
              className={classNames(style.remainingDaysInTrial, {
                [style.collapsed]: collapsed,
              })}
            >
              <ReminderIcon className={style.icon} />
              <div className={style.text}>
                <DittoComponent
                  componentId="trial-time-left"
                  count={remainingDaysInTrial}
                  variables={{ trialType: trialType(), numDays: remainingDaysInTrial }}
                />
              </div>
            </div>
          )}
          {displayUpgradeCTA && (
            <SimpleLink
              Icon={ChartDataIcon}
              text={DittoComponents["upgrade-plan"].text}
              onClick={handleUpgradePlanClick}
              className={style.upgradeCTA}
              collapsed={collapsed}
            />
          )}
          <SimpleLink
            Icon={PersonAdd}
            text={DittoComponents["invite"].text}
            onClick={handleInviteTeammateClick}
            collapsed={collapsed}
          />
          <NavLink to="integrations" collapsed={collapsed} />
          <SimpleLink
            Icon={HelpOutline}
            text={DittoComponents["help"].text}
            onClick={toggleHelpCenterOpen}
            collapsed={collapsed}
            active={helpCenter.isOpen}
            ref={helpCenter.buttonRef}
          />
          <UserSection
            ref={notificationButtonRef}
            toggleNotificationsAreaOpen={toggleNotificationsAreaOpen}
            notificationsAreaOpen={notificationsAreaOpen}
            collapsed={collapsed}
            hasNotifications={userHasNotifications}
          />
        </div>

        {notificationsAreaOpen && (
          <NotificationsArea
            {...notificationsPanel}
            ref={notificationsAreaRef}
            closeNotificationsArea={() => setNotificationsAreaOpen(false)}
          />
        )}

        {helpCenter.isOpen && (
          <DittoProvider source={dittoSource} projectId={ONBOARDING_CHECKLIST_PROJECT_ID} variant={"base"}>
            {/* TODO */}
            {/* Need to extract logic from old HelpCenterWrapper into a hook during Navbar integration */}
            {/* and use it to provide correct data here. */}
            <HelpCenter
              checklistState={helpCenter.checklistState}
              className={style.helpCenter}
              setHelpCenterOpen={helpCenter.setIsOpen}
              ref={helpCenter.ref}
            />
          </DittoProvider>
        )}

        {isPermissionGroupsEnabled && isInviteModalOpen && workspaceInfo && workspaceUsers && (
          <InviteCollaboratorsModal
            inviteContext={{
              type: "workspace",
            }}
            currentUser={user}
            onHide={toggleInviteOpen}
            setNotification={({ message, time }) => showToast(message, time)}
          />
        )}
        {!isPermissionGroupsEnabled && isInviteModalOpen && workspaceInfo && workspaceUsers && (
          <InviteModal
            title="Invite Teammates"
            inviteType="workspace"
            showCustomMessage={true}
            onHide={toggleInviteOpen}
            isInviteSending={isInviteSending}
            handleAddInvite={handleAddInvite}
            workspaceInfo={workspaceInfo}
            workspaceUsers={workspaceUsers}
            docID={null}
            docName={null}
          />
        )}
        {overlayToastProps && <OverlayToast {...overlayToastProps} />}
      </div>
    </div>
  );
};

export default Navbar;
