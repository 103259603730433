import { ZCreateWebhookRequestBody, ZUpdateWebhookRequestBody } from "@shared/lib/webhook";
import { IUser } from "@shared/types/User";
import { IWebhookLog } from "@shared/types/WebhookLog";
import { SearchResult } from "@shared/types/http/search";
import {
  UpdateConnectionsWaitlistRequestBody,
  UpdateConnectionsWaitlistResponse,
  UpdateWorkspaceConfigRequestBody,
  UpdateWorkspaceConfigResponse,
} from "@shared/types/http/workspace";
import axios, { AxiosResponse } from "axios";
import z from "zod";
import http from "./lib/client";
import { createHttpRequest } from "./lib/createHttpRequest";

const url = (path: string) => `/workspace${path}`;

export interface OnboardingTask {
  key: string;
  completed_at?: Date;
}

export interface OnboardingTaskGroup {
  name: string;
  tasks: OnboardingTask[];
}

export interface OnboardingChecklistResponse {
  workspace_id: string;
  task_groups: OnboardingTaskGroup[];
  created_at: Date;
  updated_at: Date;
}

export const fetchOnboardingChecklist = (): [Promise<AxiosResponse<OnboardingChecklistResponse>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.get<OnboardingChecklistResponse>(url("/onboarding_checklist"), {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

export const updateWorkspaceConfig = async (
  updateData: UpdateWorkspaceConfigRequestBody["config"],
  updateExistingIds?: UpdateWorkspaceConfigRequestBody["updateExistingIds"]
) => {
  const { data } = await http.put(
    url("/config"),
    UpdateWorkspaceConfigRequestBody.parse({
      config: updateData,
      updateExistingIds,
    })
  );

  return UpdateWorkspaceConfigResponse.parse(data);
};

export const updateConnectionsWaitlist = async (waitlist: UpdateConnectionsWaitlistRequestBody["waitlist"]) => {
  const { data } = await http.put(
    url("/updateConnectionsWaitlist"),
    UpdateConnectionsWaitlistRequestBody.parse({
      waitlist,
    })
  );

  return UpdateConnectionsWaitlistResponse.parse(data);
};

export const updateWebhookSigningKey = createHttpRequest<string, { signingKeyPreview: string }>({
  url: url("/webhooks/signing-key"),
  method: "put",
  getConfig: (signingKey) => ({
    data: { signingKey },
  }),
});

export const sendWebhookTestRequest = createHttpRequest<{ webhookId: string; url: string }, IWebhookLog>({
  method: "post",
  url: url("/webhooks/send-test-request"),
  getConfig: (data) => ({ data }),
});

export const validateWebhookUrl = createHttpRequest<{ url: string; webhookId: string }, boolean>({
  method: "get",
  url: url("/webhooks/validate-url"),
  getConfig: (c) => ({
    params: {
      url: c.url,
      webhookId: c.webhookId,
    },
  }),
});

export const createWebhookEndpoint = createHttpRequest<z.infer<typeof ZCreateWebhookRequestBody>, void>({
  method: "post",
  url: url("/webhooks/endpoints"),
  getConfig: (data) => ({ data }),
});

export const updateWebhookEndpoint = createHttpRequest<z.infer<typeof ZUpdateWebhookRequestBody>, void>({
  method: "put",
  getUrl: (data) => url(`/webhooks/endpoints/${data._id}`),
  getConfig: (data) => ({ data }),
});

export const deleteWebhookEndpoint = createHttpRequest<{ webhookId: string }, void>({
  method: "delete",
  getUrl: (data) => url(`/webhooks/endpoints/${data.webhookId}`),
});

export const getTags = createHttpRequest<
  { excludeWsComps: boolean; sortAlpha: boolean; excludeSampleData: boolean },
  { _id: string; total: number }[]
>({
  method: "get",
  getUrl: ({ excludeWsComps, sortAlpha, excludeSampleData }) =>
    `/comp/alltags?excludeWsComps=${excludeWsComps}&sortAlpha=${sortAlpha}&excludeSampleData=${excludeSampleData}`,
});

export const editTag = createHttpRequest<{ currentTagName: string; newTagName: string }, void>({
  method: "post",
  url: url("/tags/edit"),
  getConfig: ({ currentTagName, newTagName }) => ({
    data: {
      tag: currentTagName,
      new_tag: newTagName,
    },
  }),
});

export const deleteTag = createHttpRequest<{ tagName: string }, void>({
  method: "post",
  url: url("/tags/delete"),
  getConfig: ({ tagName }) => ({
    data: {
      tag: tagName,
    },
  }),
});

export const getResourceCounts = createHttpRequest<
  void,
  { components: number; projects: number; variants: number; variables: number }
>({
  method: "get",
  url: url("/resource-counts"),
});

export const searchRequest = createHttpRequest<{ query: string }, SearchResult[]>({
  method: "get",
  getUrl: ({ query }) => `/seach?query=${query}`,
});

export const getUsers = createHttpRequest<void, IUser[]>({
  method: "get",
  url: url("/users"),
});

export const getVariants = createHttpRequest<void, { _id: string; name: string }[]>({
  method: "get",
  url: url("/variants"),
});
