import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { BASE_VARIANT_ID } from "../../../../shared/types/Variant";
import getBoldedMatchingText from "../../../helpers/getBoldedMatchingText";
import Icon from "../../atoms/Icon";
import StarIcon from "../../atoms/Icon/icons/StarIcon";
import Label from "../../atoms/Label";
import Text from "../../atoms/Text";
import Toggle from "../../atoms/Toggle";
import DropdownCombobox from "../DropdownCombobox";
import style from "./index.module.css";

export interface IVariantTab {
  id: string;
  name: string;
}

interface IVariantTabsProps {
  variantTabs: IVariantTab[];
  activeVariant: IVariantTab;

  /**
   * Handler for when a tab in the group is clicked.
   * @param tab The clicked variant tab.
   * @returns
   */
  onTabClick: (tab: IVariantTab) => void;

  className?: string;
}

const MAX_TABS_TO_SHOW = 7;

export function VariantTabs(props: IVariantTabsProps) {
  const { variantTabs, activeVariant, className, onTabClick } = props;

  const [variantsRenderData, setVariantsRenderData] = useState<{
    numTabsToShow: number;
    showOtherVariantsToggle: boolean;
  }>({ numTabsToShow: 0, showOtherVariantsToggle: false });
  const containerRef = useRef<HTMLDivElement>(null);
  const toggleTriggerRef = useRef<HTMLButtonElement>(null);
  const [toggleTriggerWidth, setToggleTriggerWidth] = useState(0);
  const hiddenTabContainerRefs = useRef<HTMLDivElement[]>([]);

  // We calculate the number of tabs to show and whether to show the "Other Variants" toggle based on the width of the container
  // and the width of the toggle trigger.
  // We use a ResizeObserver to recalculate the dimensions when the size of either the container or toggle trigger changes.
  // We calculate how many tabs to show by:
  //   - measuring the width of all the tabs with an invisible set of matching tabs overlaid on top of the container
  //   - if the width of all the tabs is less than the container width, we show all the tabs
  //   - otherwise, we calculate how many tabs to show by summing the widths of the tabs until we reach the container width
  //     minus the size of the "Other Variants" toggle (which we also dynamically measure)
  const calculateVariantTabsToShow = useCallback((): { numTabsToShow: number; showOtherVariantsToggle: boolean } => {
    const containerWidth = containerRef.current?.offsetWidth;
    if (!containerWidth) return { numTabsToShow: 0, showOtherVariantsToggle: false };

    // This measurement includes the width of the "Base" tab, since that's included in the variantTabs array
    const variantTabsTotalWidth = hiddenTabContainerRefs.current.reduce((acc, curr) => acc + curr.offsetWidth, 0);

    if (variantTabsTotalWidth <= containerWidth && variantTabs.length <= MAX_TABS_TO_SHOW) {
      return { numTabsToShow: variantTabs.length, showOtherVariantsToggle: false };
    } else {
      // We know we're gonna have to show the "Other Variants" toggle -- subtract that from our usable width
      const usableWidth = containerWidth - toggleTriggerWidth;

      let sumWidth = 0;
      let numTabsToShow = 0;
      for (let i = 0; i < variantTabs.length; i++) {
        if (i > MAX_TABS_TO_SHOW - 1) {
          return { numTabsToShow, showOtherVariantsToggle: true };
        }

        const tabWidth = hiddenTabContainerRefs.current[i]?.offsetWidth;
        if (sumWidth + tabWidth > usableWidth) {
          return { numTabsToShow, showOtherVariantsToggle: true };
        }

        numTabsToShow++;
        sumWidth += tabWidth;
      }

      // We shouldn't actually get here; this case should be handled by the first if statement.
      // But fallback just in case.
      return { numTabsToShow: variantTabs.length, showOtherVariantsToggle: false };
    }
  }, [variantTabs, toggleTriggerWidth]);

  const measureVariantsRenderDataCb = useCallback(
    function measureVariantsRenderData() {
      const data = calculateVariantTabsToShow();
      setVariantsRenderData(data);
    },
    [calculateVariantTabsToShow, setVariantsRenderData]
  );

  const toggleContainer = toggleTriggerRef.current?.parentElement;
  const measureToggleTriggerWidthCb = useCallback(
    function measureToggleTriggerWidth() {
      if (toggleContainer) {
        setToggleTriggerWidth(toggleContainer.offsetWidth);
      }
    },
    // Important to include the container element as a dependency, since it will sometimes change!
    [toggleContainer]
  );

  // Calculate all dimensions on mount and on resize
  useEffect(
    function calculateDimensionsOnMountAndResize() {
      measureVariantsRenderDataCb();
      measureToggleTriggerWidthCb();

      const containerResizeObserver = new ResizeObserver(measureVariantsRenderDataCb);
      if (containerRef.current) {
        containerResizeObserver.observe(containerRef.current);
      }

      const toggleResizeObserver = new ResizeObserver(measureToggleTriggerWidthCb);
      if (toggleTriggerRef.current) {
        toggleResizeObserver.observe(toggleTriggerRef.current);
      }

      return () => {
        containerResizeObserver.disconnect();
        toggleResizeObserver.disconnect();
      };
    },
    [measureVariantsRenderDataCb, measureToggleTriggerWidthCb]
  );

  const { numTabsToShow, showOtherVariantsToggle } = variantsRenderData;
  const tabsToShow = numTabsToShow === variantTabs.length ? variantTabs : variantTabs.slice(0, numTabsToShow);
  const otherVariants = variantTabs.slice(numTabsToShow);
  const activeVariantFromOtherVariants = otherVariants.find((variant) => variant.id === activeVariant.id);

  return (
    <div className={style.VariantTabsWrapper} ref={containerRef}>
      <div className={classNames(style.VariantTabsList, className)}>
        <div className={style.hiddenWidthContainer}>
          {variantTabs.map((variantTab, idx) => (
            <div
              key={variantTab.id}
              className={style.measureToggleWidth}
              style={{
                padding: "var(--spacing-sm) var(--spacing-lg)",
              }}
              ref={(el) => {
                if (el) {
                  hiddenTabContainerRefs.current[idx] = el;
                }
              }}
            >
              <Label size="small" color="secondary" className={style.VariantTabsLabel}>
                {variantTab.name}
              </Label>
            </div>
          ))}
        </div>

        {tabsToShow.map((variantTab, idx) => {
          const isLast = idx === tabsToShow.length - 1 && !showOtherVariantsToggle;
          const isActive = variantTab.id === activeVariant.id;
          const isPrecedingActiveTab = idx + 1 <= tabsToShow.length - 1 && tabsToShow[idx + 1].id === activeVariant.id;
          const isPrecedingActiveOtherVariants =
            idx === tabsToShow.length - 1 && Boolean(activeVariantFromOtherVariants);

          const showDivider = !isLast && !isActive && !isPrecedingActiveTab && !isPrecedingActiveOtherVariants;

          return (
            <React.Fragment key={variantTab.id}>
              <Toggle
                pressed={variantTab.id === activeVariant.id}
                onPressedChange={() => onTabClick(variantTab)}
                className={style.VariantTabsTrigger}
              >
                {variantTab.id === BASE_VARIANT_ID && <Icon Icon={<StarIcon />} size="micro" />}
                <Label size="small" color="secondary" className={style.VariantTabsLabel}>
                  {variantTab.name}
                </Label>
              </Toggle>
              <div className={style.VariantTabsDivider} style={{ opacity: showDivider ? 1 : 0 }} />
            </React.Fragment>
          );
        })}

        {showOtherVariantsToggle && (
          <OtherVariantsToggle
            numTabsToShow={numTabsToShow}
            variantTabs={variantTabs}
            activeVariant={activeVariant}
            onTabClick={onTabClick}
            ref={toggleTriggerRef}
          />
        )}
      </div>
    </div>
  );
}

interface IOtherVariantsToggleProps {
  numTabsToShow: number;
  variantTabs: IVariantTab[];
  activeVariant: IVariantTab;
  onTabClick: (tab: IVariantTab) => void;
}

const OtherVariantsToggle = forwardRef<HTMLButtonElement, IOtherVariantsToggleProps>(function OtherVariantsToggle(
  props,
  forwardedRef
) {
  const otherVariants = props.variantTabs.slice(props.numTabsToShow);
  const otherVariantsOptions = otherVariants.map((variant) => ({
    value: variant.id,
    label: variant.name,
  }));

  const activeVariantFromOtherVariants = otherVariants.find((variant) => variant.id === props.activeVariant.id);

  // If the active variant is in the other variants, we don't want to show it in the count
  const numOtherVariants = Boolean(activeVariantFromOtherVariants) ? otherVariants.length - 1 : otherVariants.length;

  function onSelect(value: { value: string; label: string }[]) {
    const tab = {
      id: value[0].value,
      name: value[0].label,
    };
    props.onTabClick(tab);
  }

  const selectedOption = activeVariantFromOtherVariants
    ? { value: activeVariantFromOtherVariants.id, label: activeVariantFromOtherVariants.name }
    : undefined;

  return (
    <div className={style.VariantTabsOtherVariantsToggle}>
      <DropdownCombobox
        ref={forwardedRef}
        options={otherVariantsOptions}
        selectedOptions={selectedOption ? [selectedOption] : []}
        onChange={onSelect}
        asToggle
        toggleClassName={classNames(style.otherVariantsToggle, {
          [style.active]: Boolean(activeVariantFromOtherVariants),
        })}
        TriggerComponent={(options) => (
          <OtherVariantsToggleTrigger
            activeVariant={activeVariantFromOtherVariants}
            numOtherVariants={numOtherVariants}
          />
        )}
        OptionComponent={OptionComponent}
        className={style.VariantTabsOtherVariantsDropdown}
      />
    </div>
  );
});

function OtherVariantsToggleTrigger(props: { numOtherVariants: number; activeVariant?: IVariantTab }) {
  return (
    <div className={style.otherVariantsToggleTrigger}>
      {props.activeVariant && (
        <>
          <Label size="small" className={style.VariantTabsLabel}>
            {props.activeVariant.name}
          </Label>

          <div className={style.VariantTabsDivider} />
        </>
      )}

      <Label size="small" color="secondary" className={style.VariantTabsLabel}>
        {`${props.numOtherVariants} other variant${props.numOtherVariants > 1 ? "s" : ""}`}
      </Label>

      <Icon className={style.arrowIcon} Icon={<KeyboardArrowDown />} size="xs" color="secondary" />
    </div>
  );
}

interface IOptionProps {
  option: { value: string; label: string };
  query: string;
  selected: boolean;
}

function OptionComponent(props: IOptionProps) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <span>{getBoldedMatchingText(props.option.label, props.query)}</span>
    </Text>
  );
}

export default VariantTabs;
