import z from "zod";
import { ZObjectId } from "./lib";

const ZVariantGroup = z.object({
  _id: ZObjectId,
  variants: z.array(ZObjectId),
  name: z.string(),
  workspaceId: ZObjectId,
  integrations: z.object({
    launchDarkly: z
      .object({
        flagId: z.string(),
        variations: z.array(z.object({ variantId: ZObjectId, variationId: z.string() })),
      })
      .optional(),
    split: z
      .object({
        flagId: z.string(),
        treatmentId: z.string(),
        treatments: z.array(
          z.object({
            variantId: ZObjectId,
            treatmentId: z.string(),
          })
        ),
      })
      .optional(),
  }),
});
export type IVariantGroup = z.infer<typeof ZVariantGroup>;

export const ZABPlatformIntegrationModalItem = z.object({
  flagId: z.string(),
  flagName: z.string(),
  syncedVariants: z.array(ZObjectId),
  lockedVariants: z.array(z.object({ _id: ZObjectId, reason: z.string() })),
});

export const ZABPlatformIntegrationModalItemFrontend = z.object({
  flagId: z.string(),
  flagName: z.string(),
  syncedVariants: z.set(z.string()),
  // variantId -> reason it's locked
  lockedVariants: z.map(z.string(), z.string()),
});
export type IABPlatformIntegrationModalItemFrontend = z.infer<typeof ZABPlatformIntegrationModalItemFrontend>;

export const ZABPlatformIntegrationModalData = z.array(ZABPlatformIntegrationModalItem);
export type IABPlatformIntegrationModalData = z.infer<typeof ZABPlatformIntegrationModalData>;
