import { z } from "zod";

export const ZFigmaGetPagesJobData = z.object({
  figmaToken: z.string(),
  workspaceId: z.string(),
  figmaFileId: z.string(),
  figmaBranchId: z.string().nullable().optional(),
  onImport: z.boolean(),
});

export type IFigmaGetPagesJobData = z.infer<typeof ZFigmaGetPagesJobData>;
