import { IWorkspacePlan } from "@shared/types/Workspace";

export const INVITE_ONLY_FOLDERS_ENABLED_FOR_PLANS: IWorkspacePlan[] = ["growth", "enterprise"];

export const getInviteOnlyFoldersEnabled = (
  workspace: {
    plan: IWorkspacePlan;
  } | null
) => {
  if (!workspace) {
    return false;
  }

  return INVITE_ONLY_FOLDERS_ENABLED_FOR_PLANS.includes(workspace.plan);
};

export const INVITE_ONLY_UPSELL_TEXT = "Growth or Enterprise Plan";
