import CallSplitIcon from "@mui/icons-material/CallSplit";
import NotesIcon from "@mui/icons-material/Notes";
import useControlledState from "@shared/frontend/lib/useControlledState";
import { CharacterLimit } from "@shared/types/RichText";
import { ITextItem, ITextItemVariableRichValue, ITipTapRichText } from "@shared/types/TextItem";
import { Editor } from "@tiptap/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useEventHandler from "../../utils/useEventHandler";
import RichTextInfoPopup, { RichTextInfoPopupMarkType } from "../RichTextInfoPopup";
import VariableRichTextArea from "../VariableTextArea/VariableRichTextArea";
import { labelComponents } from "./components";
import style from "./style.module.css";

type InputTypes = "base" | "one" | "two" | "zero" | "few" | "many" | "other";

const inputLabels: Record<InputTypes, string> = {
  base: "",
  one: "One (1)",
  two: "Two (2)",
  zero: "Zero (0)",
  few: "Few",
  many: "Many",
  other: "Other",
};

interface InputValue {
  type: InputTypes;
  text: string;
  rich_text: ITipTapRichText;
  variables: ITextItem["variables"];
}

interface TextItemTextInputsProps {
  textItem: ITextItemVariableRichValue;
  // editwscomp requires this format and I don't like it so I prepended "legacy"
  // Please don't use it unless necessary
  legacyHandleTextChange: (
    fieldStates: {
      // label and form are undefined when plurals are disabled
      label: string | undefined;
      form: string | undefined;
      value: {
        text: string;
        richText: ITipTapRichText;
        variables: ITextItem["variables"];
      };
    }[]
  ) => void;
  readonly: boolean;
  isBaseText: boolean;
  isVariant: boolean;
  shouldShowRichText: boolean;
  textLabelLeft?: React.ReactNode;
  shouldShowPlurals?: React.StatePair<boolean>;
  // Only used when rich text is not on in a project
  setRichTextModalOpen?: (val: boolean) => void;
  textInputClassName?: string;
  pluralInputsDisabled?: boolean;
  highlightBrackets?: boolean;
  origCharacterLimit?: number | null;
  handleCharacterLimitChange?: (val: number | null) => void;
  handleCharacterLimitSubmit?: (val: number | null) => void;
  characterLimit: CharacterLimit;
  inSampleProject?: boolean;
  overrideClassname?: string;
  hideTopLabels?: boolean;
  useNSLabels?: boolean;
  disabled?: boolean;
  characterLimitDisabled?: boolean;
  variablesDisabled?: boolean;
  placeholder?: string;
  emptyEditorClass?: string;
  /**
   * Optional prop callback for getting the tiptap editor instance
   * Used for resetting the TipTap editor
   * @param editor - The TipTap  editor instance
   */
  accessEditorInstance?: (editor: Editor) => void;
}

const generateBaseRichTextObject = (text: string): ITipTapRichText => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text,
        },
      ],
    },
  ],
});

const RCallSplitIcon = () => <CallSplitIcon className={style.rCallSplitIcon} />;

export default function TextItemTextInputs(props: TextItemTextInputsProps) {
  const {
    textItem,
    readonly,
    legacyHandleTextChange,
    isBaseText,
    isVariant,
    shouldShowRichText,
    textLabelLeft,
    shouldShowPlurals = undefined,
    setRichTextModalOpen,
    textInputClassName,
    highlightBrackets,
    handleCharacterLimitChange,
    handleCharacterLimitSubmit,
    inSampleProject,
    overrideClassname,
    hideTopLabels,
    characterLimit,
    useNSLabels = false,
    disabled = false,
    characterLimitDisabled = false,
    variablesDisabled = false,
    pluralInputsDisabled = false,
    emptyEditorClass,
    accessEditorInstance,
  } = props;

  const pluralsReadonlyOrExplicitlyDisabled = readonly || props.pluralInputsDisabled;

  const [pluralsEnabled, setPluralsEnabled] = useControlledState<boolean>(shouldShowPlurals, false);
  // Used for initial rendering of the text item's plurals
  // Will get update when we add or remove plurals too
  const [initialInputs, setInitialInputs] = useState<InputValue[]>([]);
  // Used for keeping track of data but not for rendering
  // We update these values when text/rich text changes in the text input but
  // there is no need to pass that change back to the text inputs (RichTextVariableInput)
  // Otherwise causes https://linear.app/dittowords/issue/DIT-2606/when-edit-a-plural-text-edit-or-bold-underline-italicize-cursor-jumps
  const [currentInputs, setCurrentInputs] = useState<InputValue[]>([]);

  const [showRichTextInfoPopup, setShowRichTextInfoPopup] = useState(false);

  const unusedPluralOptions = useMemo(
    () =>
      Object.keys(inputLabels)
        // Filter for plural options that aren't used
        .filter((pluralOption: InputTypes) => !currentInputs.map((i) => i.type).includes(pluralOption))
        // Remove "base" since it isn't technically a plural
        .filter((pluralOption: InputTypes) => pluralOption !== "base")
        .map((pluralOption: InputTypes) => ({
          value: pluralOption,
          label: inputLabels[pluralOption],
        })),
    [currentInputs]
  );

  useEffect(
    function extractPropInputValues() {
      if (textItem?.plurals?.length > 0) {
        const variableMap = textItem.variables.reduce((acc, curr) => {
          acc[curr.variable_id.toString()] = curr;
          return acc;
        }, {});

        const newCurrInputs: InputValue[] = textItem.plurals.map((plural) => ({
          type: plural.form,
          text: plural.text,
          rich_text: plural.rich_text || generateBaseRichTextObject(plural.text),
          variables: plural.variables?.map((v) => variableMap[v.toString()]) || [],
        }));

        setPluralsEnabled(true);
        setCurrentInputs(newCurrInputs);
        setInitialInputs(newCurrInputs);
      } else {
        const baseOnlyInput: InputValue[] = [
          {
            type: "base",
            text: textItem.text,
            rich_text: textItem.rich_text,
            variables: textItem.variables,
          },
        ];
        setCurrentInputs(baseOnlyInput);
        setInitialInputs(baseOnlyInput);
        setPluralsEnabled(false);
      }
    },
    [setPluralsEnabled, textItem.plurals, textItem.rich_text, textItem.text, textItem.variables]
  );

  useEffect(
    function notifyParentOfInputsChange() {
      legacyHandleTextChange(
        currentInputs.map((input) => ({
          label: input.type === "base" ? undefined : inputLabels[input.type],
          form: input.type === "base" ? undefined : input.type,
          value: {
            text: input.text,
            richText: input.rich_text,
            variables: input.variables,
          },
        }))
      );
    },
    [currentInputs, legacyHandleTextChange]
  );

  // this effct allows us to achieve the effect of the onEnablePlurals function
  // when we are enabling plurals from a parent component
  useEffect(
    function createInitialPluralOnEnable() {
      if (pluralsEnabled && currentInputs.length === 1 && currentInputs[0].type === "base") {
        const newInputs: InputValue[] = [
          {
            type: "other",
            text: currentInputs[0].text,
            rich_text: currentInputs[0].rich_text,
            variables: currentInputs[0].variables,
          },
        ];
        setCurrentInputs(newInputs);
        setInitialInputs(newInputs);
      }
    },
    [pluralsEnabled, currentInputs]
  );

  const onEnablePlurals = useCallback(() => {
    const newInputs: InputValue[] = [
      {
        type: "other",
        text: currentInputs[0].text,
        rich_text: currentInputs[0].rich_text,
        variables: currentInputs[0].variables,
      },
    ];
    setCurrentInputs(newInputs);
    setInitialInputs(newInputs);
    setPluralsEnabled(true);
  }, [currentInputs, setPluralsEnabled]);

  const onSelectPluralForm = useCallback(
    (type: { value: InputTypes; label: string }, fieldIndex: number) => {
      const newCurrentInputs = [...currentInputs];
      newCurrentInputs[fieldIndex].type = type.value;

      setCurrentInputs(newCurrentInputs);
      setInitialInputs(newCurrentInputs);
    },
    [currentInputs]
  );

  const onTextChange = useCallback(
    (
      newInputValue: { text: string; variables: ITextItem["variables"] },
      inputIndex: number,
      richText: ITipTapRichText
    ) => {
      setCurrentInputs((previousInputs) => {
        const newCurrentInputs = [...previousInputs];
        newCurrentInputs[inputIndex] = {
          ...newCurrentInputs[inputIndex],
          ...newInputValue,
          rich_text: richText,
        };
        return newCurrentInputs;
      });
    },
    []
  );

  const onRemovePlural = useCallback(
    (fieldIndex: number) => {
      const newCurrentInputs = [...currentInputs];
      newCurrentInputs.splice(fieldIndex, 1);
      setCurrentInputs(newCurrentInputs);
      setInitialInputs(newCurrentInputs);
    },
    [currentInputs]
  );

  const onDisablePlurals = useCallback(() => {
    const baseOnlyInput: InputValue[] = [
      {
        type: "base",
        text: currentInputs[0].text,
        rich_text: currentInputs[0].rich_text,
        variables: currentInputs[0].variables,
      },
    ];
    setPluralsEnabled(false);
    // Allow for plurals to be disabled before updating inputs
    setTimeout(() => {
      setInitialInputs(baseOnlyInput);
      setCurrentInputs(baseOnlyInput);
    }, 0);
  }, [currentInputs, setPluralsEnabled]);

  const onAddPlural = () => {
    const withNewPlural: InputValue[] = [
      ...currentInputs,
      {
        type: unusedPluralOptions[0].value,
        text: currentInputs[0].text,
        rich_text: currentInputs[0].rich_text,
        variables: currentInputs[0].variables,
      },
    ];

    setCurrentInputs(withNewPlural);
    setInitialInputs(withNewPlural);
  };

  /*******************************************************************************/
  /* Listening for rich text keyboard commands.                                  */
  /* Used to show rich text info popup when rich text is not enabled in project. */
  /*******************************************************************************/
  const isInProject = Boolean(setRichTextModalOpen);
  const richTextInfoPopupMark = useRef<RichTextInfoPopupMarkType>("bold");
  const cntrlKeyPressed = useRef(false);
  const cmdKeyPressed = useRef(false);

  useEventHandler("keyup", ({ key }) => {
    if (shouldShowRichText || !isInProject) return;

    if (String(key) === "Control") {
      cntrlKeyPressed.current = false;
    } else if (String(key) === "Meta") {
      cmdKeyPressed.current = false;
    }
  });

  useEventHandler("keydown", ({ key: rawKey }) => {
    if (shouldShowRichText || !isInProject) return;

    const key = String(rawKey);
    const isCntrlOrCmdPressed = cntrlKeyPressed.current || cmdKeyPressed.current;

    if (key === "Control") {
      cntrlKeyPressed.current = true;
    } else if (key === "Meta") {
      cmdKeyPressed.current = true;
    } else if (key === "b") {
      if (isCntrlOrCmdPressed) {
        richTextInfoPopupMark.current = "bold";
        setShowRichTextInfoPopup(true);
      }
    } else if (key === "i") {
      if (isCntrlOrCmdPressed) {
        richTextInfoPopupMark.current = "italicize";
        setShowRichTextInfoPopup(true);
      }
    } else if (key === "u") {
      if (isCntrlOrCmdPressed) {
        richTextInfoPopupMark.current = "underline";
        setShowRichTextInfoPopup(true);
      }
    }
  });

  const handleTextChange = useCallback(
    function _handleTextChange(newInputValue, richText) {
      onTextChange(newInputValue, 0, richText);
    },
    [onTextChange]
  );

  const memoizedComponents = useMemo(
    () =>
      labelComponents({
        onEnablePlurals,
        showLabel: true,
        index: 0,
        form: currentInputs[0]?.type,
        options: unusedPluralOptions,
        numPlurals: Object.keys(currentInputs).length,
        isDisabled: readonly,
        onSelectPluralForm,
        pluralsEnabled,
        pluralsDropdownDisabled: pluralsReadonlyOrExplicitlyDisabled,
        onRemovePlural,
        onDisablePlurals,
        textLabelLeft,
        CompactLabelIcon: !isBaseText && isVariant ? RCallSplitIcon : NotesIcon,
      }),
    [
      currentInputs,
      unusedPluralOptions,
      onEnablePlurals,
      onSelectPluralForm,
      onRemovePlural,
      onDisablePlurals,
      textLabelLeft,
      isBaseText,
      isVariant,
      readonly,
      pluralsEnabled,
      pluralsReadonlyOrExplicitlyDisabled,
    ]
  );

  // currentInputs have not been initialized yet
  if (currentInputs.length === 0) {
    return <></>;
  }

  return (
    <div className={style.wrapper} data-testid="text-item-text-inputs-wrapper">
      <div className={style.baseInputWrapper}>
        <VariableRichTextArea
          hideLabels={hideTopLabels}
          useNSLabels={useNSLabels}
          highlightBrackets={highlightBrackets}
          disableRichText={!shouldShowRichText}
          placeholder={props.placeholder ?? "No text."}
          emptyEditorClass={emptyEditorClass}
          isBaseText={isBaseText}
          isPlural={false}
          isVariant={isVariant}
          isDisabled={readonly || disabled}
          showContentLength={true}
          value={textItem}
          handleTextChange={handleTextChange}
          components={memoizedComponents}
          textInputClassName={textInputClassName}
          handleCharacterLimitChange={handleCharacterLimitChange}
          handleCharacterLimitSubmit={handleCharacterLimitSubmit}
          disableCharacterLimit={isVariant || characterLimitDisabled}
          inSampleProject={inSampleProject}
          onEnablePlurals={onEnablePlurals}
          characterLimit={characterLimit}
          variablesDisabled={variablesDisabled}
          pluralsDisabled={pluralInputsDisabled}
          accessEditorInstance={accessEditorInstance}
        />
      </div>
      <div className={style.pluralsContainer}>
        {/*Logic from old TextInput component*/}
        {/*When plurals are enabled,*/}
        {/*the first one is used as the base and rendered above*/}
        {/*The rest are*/}
        {/*rendered plurals*/}
        {initialInputs.slice(1).map((input, index) => (
          <div key={index} className={style.variableInputWrapper} data-testid={`plurals-${index}`}>
            <VariableRichTextArea
              highlightBrackets={highlightBrackets}
              disableRichText={!shouldShowRichText}
              placeholder={isVariant ? "No variant text." : "No text."}
              emptyEditorClass={emptyEditorClass}
              isBaseText={isBaseText}
              isVariant={isVariant}
              isPlural={true}
              isDisabled={pluralsReadonlyOrExplicitlyDisabled}
              showContentLengthOnFocusOnly={true}
              hideCharacterLimit={true}
              value={{ ...input, characterLimit: textItem.characterLimit }}
              handleTextChange={(newInputValue, richText) => onTextChange(newInputValue, index + 1, richText)}
              handleCharacterLimitChange={handleCharacterLimitChange}
              handleCharacterLimitSubmit={handleCharacterLimitSubmit}
              components={labelComponents({
                onEnablePlurals,
                showLabel: false,
                index: index + 1,
                form: input.type,
                options: unusedPluralOptions,
                numPlurals: Object.keys(currentInputs).length,
                isDisabled: pluralsReadonlyOrExplicitlyDisabled,
                onSelectPluralForm,
                pluralsEnabled,
                onRemovePlural,
                onDisablePlurals,
              })}
              inSampleProject={inSampleProject}
              onEnablePlurals={onEnablePlurals}
            />
          </div>
        ))}
      </div>

      {pluralsEnabled && !pluralsReadonlyOrExplicitlyDisabled && (
        <div className={style.pluralBtnWrapper}>
          {Object.keys(currentInputs).length !== Object.keys(inputLabels).length - 1 && (
            <div className={style.addPluralBtn} onClick={() => !disabled && onAddPlural()}>
              + Add Plural
            </div>
          )}
        </div>
      )}
      {showRichTextInfoPopup && (
        <div className={style.richTextInfoPopupContainer}>
          <RichTextInfoPopup
            onClose={() => {
              setShowRichTextInfoPopup(false);
            }}
            onShowMe={() => {
              if (setRichTextModalOpen) {
                setRichTextModalOpen(true);
              }
            }}
            markType={richTextInfoPopupMark.current}
          />
        </div>
      )}
    </div>
  );
}
