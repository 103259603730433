import {
  DetailsPanelSelectionStates,
  detailsPanelSelectionStateAtom,
  selectedTextItemsCountAtom,
  selectionTypeAtom,
} from "@/stores/ProjectSelection";
import Tabs from "@ds/molecules/Tabs";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import style from "./style.module.css";

type Tab = { id: DetailsPanelSelectionStates; label: string };

const EditTab: Tab = { id: "EDIT", label: "Edit" };
const ActivityTab: Tab = { id: "ACTIVITY", label: "Activity" };
const CommentsTab: Tab = { id: "COMMENTS", label: "Comments" };
const VariantsTab: Tab = { id: "VARIANTS", label: "Variants" };

function EditTabBar() {
  const [selectedTab, setSelectedTab] = useAtom(detailsPanelSelectionStateAtom);
  const selectedTextItemsCount = useAtomValue(selectedTextItemsCountAtom);
  const selectionType = useAtomValue(selectionTypeAtom);

  const tabs = useMemo(
    function generateTabs() {
      if (selectionType === "block") {
        return [EditTab];
        // TODO: Add Activity Tab https://linear.app/dittowords/issue/DIT-7924/implement-block-change-history

        // Variants and Comments not supported for block selection
      }
      if (selectedTextItemsCount !== 1) {
        return [EditTab];
        // TODO: Add support for multiselection with variants: https://linear.app/dittowords/issue/DIT-8361/support-multiselection-with-variants
      }

      return [EditTab, ActivityTab, CommentsTab, VariantsTab];
    },
    [selectedTextItemsCount, selectionType]
  );

  useEffect(
    function ensureSelectedTabExists() {
      const matchingTab = tabs.find((tab) => tab.id === selectedTab);
      const validTabValue = matchingTab ? matchingTab.id : "EDIT";

      if (validTabValue !== selectedTab) {
        setSelectedTab(validTabValue);
      }
    },
    [selectedTab, setSelectedTab, tabs]
  );

  return (
    <div className={style.editTabBarContainer}>
      <Tabs
        value={selectedTab}
        onValueChange={setSelectedTab}
        tabs={tabs}
        size="small"
        className={style.tabBar}
        listClassName={style.tabList}
        triggerClassName={style.tab}
      />
      <div className={style.spacer} />
    </div>
  );
}

export default EditTabBar;
