import { z } from "zod";
import { ZGetFigmaDataSuccessResponse } from "../FigmaData";
import { ZFigmaVariablesResponse } from "../FigmaVariables";

export const ZFigmaSyncNewFramesJobData = z.object({
  docId: z.string(),
  figmaData: ZGetFigmaDataSuccessResponse.omit({ isError: true }),
  newFrames: z.array(z.string()),
  workspaceId: z.string(),
  userId: z.string(),
  overrides: ZFigmaVariablesResponse.optional(),
  autoImport: z.boolean().optional(),
});

export type IFigmaSyncNewFramesJobData = z.infer<typeof ZFigmaSyncNewFramesJobData>;
