import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import * as SegmentEvents from "@shared/segment-event-names";
import React, { useState } from "react";
import { IConnectionWaitlistInfo } from "../../../shared/types/Workspace";
import SlackLogo from "../../assets/slack.png";
import useSegment from "../../hooks/useSegment";
import { updateConnectionsWaitlist } from "../../http/workspaceNew";
import AcrolinxLogo from "./assets/acrolinx.jpeg";
import AEMLogo from "./assets/aem.avif";
import ContentfulLogo from "./assets/contentful.png";
import CrowdinLogo from "./assets/crowdin.png";
import JiraLogo from "./assets/jira.png";
import LaunchDarklyLogo from "./assets/launchdarkly.png";
import LokaliseLogo from "./assets/lokalise.png";
import SplitLogo from "./assets/split.png";
import VercelLogo from "./assets/vercel.svg";
import WriterLogo from "./assets/writer.png";
import ConnectionModal from "./components/ConnectionModal";
import {
  Connected as CrowdinConnected,
  NotConnected as CrowdinNotConnected,
} from "./components/ConnectionModal/Crowdin";
import {
  Connected as LaunchDarklyConnected,
  NotConnected as LaunchDarklyNotConnected,
} from "./components/ConnectionModal/LaunchDarkly";
import {
  Connected as LokaliseConnected,
  NotConnected as LokaliseNotConnected,
} from "./components/ConnectionModal/Lokalise";
import { Connected as SlackConnected, NotConnected as SlackNotConnected } from "./components/ConnectionModal/Slack";
import { Connected as SplitConnected, NotConnected as SplitNotConnected } from "./components/ConnectionModal/Split";
import ConnectionTile from "./components/ConnectionTile";
import { useCrowdinConnection } from "./store/CrowdinConnectionContext";
import { useLokaliseConnection } from "./store/LokaliseConnectionContext";
import styles from "./styles.module.css";

function Connections() {
  const segment = useSegment();
  const workspace = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const { workspaceInfo, updateWorkspaceInfo } = workspace;
  const [showLokaliseModal, setShowLokaliseModal] = useState(false);
  const [showCrowdinModal, setShowCrowdinModal] = useState(false);
  const [showSlackModal, setShowSlackModal] = useState(false);
  const [showDittoAiModal, setShowDittoAiModal] = useState(false);
  const [showLaunchDarklyModal, setShowLaunchDarklyModal] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const lokaliseConnection = useLokaliseConnection();
  const crowdinConnection = useCrowdinConnection();

  const splitIsConnected = Boolean(workspaceInfo.integrations.split?.tokenPreview);
  const launchDarklyIsConnected = Boolean(workspaceInfo.integrations.launchDarkly?.tokenPreview);

  // if the user hasn't yet asked to be notified, set the proper waitlist flag on their workspace
  const waitlistConnection = (connectionName: keyof IConnectionWaitlistInfo) => async () => {
    const isWaitlisted = workspaceInfo.connectionWaitlist[connectionName];
    if (isWaitlisted) return;

    const newConnectionWaitlist = {
      ...workspaceInfo.connectionWaitlist,
      [connectionName]: true,
    };

    // update frontend
    updateWorkspaceInfo({
      ...workspaceInfo,
      connectionWaitlist: newConnectionWaitlist,
    });

    // update backend
    await updateConnectionsWaitlist(newConnectionWaitlist);

    // emit segment event
    segment.track({
      event: SegmentEvents.CONNECTION_WAITLISTED,
      properties: {
        userEmail: user.email,
        workspaceId: workspaceInfo._id,
        workspaceName: workspaceInfo.name,
        connectionName,
      },
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>Connections</div>
          <div className={styles.sectionDescription}>
            Extend the functionality of Ditto by integrating with your existing tools.
          </div>

          <div className={styles.tilesArea}>
            <ConnectionTile
              name="Slack"
              description="Get updates about changes to your projects and components."
              logo={SlackLogo}
              isConnected={!!workspaceInfo?.integrations.slack.authenticated}
              showModal={() => setShowSlackModal(true)}
            />

            <ConnectionTile
              name="Lokalise"
              description="Localization Made Easy - Manage. Translate. Automate."
              logo={LokaliseLogo}
              isConnected={lokaliseConnection.lokaliseTokenUsed}
              showModal={() => setShowLokaliseModal(true)}
            />

            <ConnectionTile
              name="Crowdin"
              description="Localization Management Platform for agile teams."
              logo={CrowdinLogo}
              isConnected={crowdinConnection.crowdinTokenUsed}
              showModal={() => setShowCrowdinModal(true)}
            />
            <ConnectionTile
              name="LaunchDarkly"
              description="Sync your Ditto variants with feature flag variations in LaunchDarkly."
              logo={LaunchDarklyLogo}
              isConnected={launchDarklyIsConnected}
              showModal={() => setShowLaunchDarklyModal(true)}
            />
            <ConnectionTile
              name="Split"
              description="Sync your Ditto variants with feature flag treatments in Split."
              logo={SplitLogo}
              isConnected={splitIsConnected}
              showModal={() => setShowSplitModal(true)}
            />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>Coming Soon</div>
          <div className={styles.sectionDescription}>
            These integrations are currently in development. To be the first to get updates, click "Notify Me" below and
            we'll keep you posted on progress!
          </div>

          <div className={styles.tilesArea}>
            <ConnectionTile
              name="Contentful"
              description="Surface text in Ditto directly in the Contentful CMS."
              logo={ContentfulLogo}
              isWaitlisted={workspaceInfo.connectionWaitlist["contentful"]}
              toggleWaitlist={waitlistConnection("contentful")}
            />
            <ConnectionTile
              name="Acrolinx"
              description="Check writing in Ditto against guidelines stored in Acrolinx. "
              logo={AcrolinxLogo}
              isWaitlisted={workspaceInfo.connectionWaitlist["acrolinx"]}
              toggleWaitlist={waitlistConnection("acrolinx")}
            />
            <ConnectionTile
              name="Adobe Experience Manager"
              description="Surface text in Ditto directly in the AEM CMS."
              logo={AEMLogo}
              isWaitlisted={workspaceInfo.connectionWaitlist["aem"]}
              toggleWaitlist={waitlistConnection("aem")}
            />
            <ConnectionTile
              name="Writer.com"
              description="Check writing in Ditto against guidelines stored in Writer."
              logo={WriterLogo}
              isWaitlisted={workspaceInfo.connectionWaitlist["writer"]}
              toggleWaitlist={waitlistConnection("writer")}
            />
            <ConnectionTile
              name="Jira"
              description="Update tickets in Jira with activity in Ditto."
              logo={JiraLogo}
              isWaitlisted={workspaceInfo.connectionWaitlist["jira"]}
              toggleWaitlist={waitlistConnection("jira")}
            />
            <ConnectionTile
              name="Vercel"
              description="Automatically deploy updated text with Vercel."
              logo={VercelLogo}
              isWaitlisted={workspaceInfo.connectionWaitlist["vercel"]}
              toggleWaitlist={waitlistConnection("vercel")}
            />
          </div>
        </div>
      </div>

      {showSlackModal && (
        <ConnectionModal
          connectionName="Slack"
          connectionDescription="Get updates about changes to your projects and components."
          hasConnection={!!workspaceInfo?.integrations.slack.authenticated}
          learnMoreLink="http://www.dittowords.com/docs/slack-integration"
          logoSrc={SlackLogo}
          Connected={SlackConnected}
          NotConnected={SlackNotConnected}
          onHide={() => {
            setShowSlackModal(false);
          }}
        />
      )}
      {showLokaliseModal && (
        <ConnectionModal
          connectionName="Lokalise"
          connectionDescription="Localization Made Easy - Manage. Translate. Automate."
          hasConnection={Boolean(lokaliseConnection.currentLokaliseConnection)}
          logoSrc={LokaliseLogo}
          learnMoreLink="https://docs.lokalise.com/en/articles/7549522-ditto"
          Connected={LokaliseConnected}
          NotConnected={LokaliseNotConnected}
          onHide={() => {
            setShowLokaliseModal(false);
          }}
        />
      )}
      {showSplitModal && (
        <ConnectionModal
          connectionName="Split"
          connectionDescription="Manage your AB tests by syncing variants in Ditto to feature flag treatments in Split."
          hasConnection={splitIsConnected}
          logoSrc={SplitLogo}
          // learnMoreLink="https://store.crowdin.com/dittowords"
          Connected={SplitConnected}
          NotConnected={SplitNotConnected}
          onHide={() => setShowSplitModal(false)}
        />
      )}
      {showLaunchDarklyModal && (
        <ConnectionModal
          connectionName="LaunchDarkly"
          connectionDescription="Manage your AB tests by syncing variants in Ditto to feature flag variations in LaunchDarkly."
          hasConnection={launchDarklyIsConnected}
          logoSrc={LaunchDarklyLogo}
          // learnMoreLink="https://store.crowdin.com/dittowords"
          Connected={LaunchDarklyConnected}
          NotConnected={LaunchDarklyNotConnected}
          onHide={() => setShowLaunchDarklyModal(false)}
        />
      )}
      {showCrowdinModal && (
        <ConnectionModal
          connectionName="Crowdin"
          connectionDescription="Localization Management Platform for agile teams."
          hasConnection={Boolean(crowdinConnection.currentCrowdinConnection)}
          logoSrc={CrowdinLogo}
          learnMoreLink="https://store.crowdin.com/dittowords"
          Connected={CrowdinConnected}
          NotConnected={CrowdinNotConnected}
          onHide={() => {
            setShowCrowdinModal(false);
          }}
        />
      )}
      {/*
      showDittoAiModal && (
        <ConnectionModal
          connectionName="Ditto AI (Beta)"
          connectionDescription="Generates AI edits to improve, rephrase, or augment highlighted text in our text editor. "
          hasConnection={Boolean(workspaceInfo.feature_flags.dittoAi)}
          logoSrc={DittoAiLogo}
          Connected={DittoAiConnected}
          NotConnected={DittoAiNotConnected}
          connectedText="Enabled"
          onHide={() => {
            setShowDittoAiModal(false);
          }
          
        />
      )
        */}
    </>
  );
}

export default Connections;
