import EditDiff from "@/components/editdiff/editdiff";
import PromptSuggestionIcon from "@/components/icons/PromptSuggestionIcon";
import CheckSharp from "@mui/icons-material/CheckSharp";
import { IPopulatedNotification } from "@shared/types/Notification";
import React from "react";
import TimeAgo from "react-timeago";
import style from "./DiscussionRow.module.css";

export interface IDiscussionRowProps {
  notification: IPopulatedNotification;
  onMarkAsRead?: () => void;
  onReply?: () => void;
}

export default function DiscussionRow(props: IDiscussionRowProps) {
  const relevantComments =
    props.notification.commentThread?.comments.filter(
      (comment) => comment.user_id !== props.notification.receiverUserId
    ) || [];
  const lastComment = relevantComments[relevantComments.length - 1];

  const markAsRead = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (props.onMarkAsRead) {
      props.onMarkAsRead();
    }
  };

  const reply = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (props.onReply) {
      props.onReply();
    }
  };

  return (
    <div className={style.container} onClick={reply} data-testid="discussion-row">
      <div className={style.title}>
        <div>
          <span className={style.actor}>{props.notification.actorUserName}</span> {props.notification.action} in
          <span className={style.location}> {props.notification.docName || "Component Library"}</span>
        </div>
        <div className={style.actions}>
          <div className={style.action} onClick={markAsRead}>
            <CheckSharp className={style.icon} />
            Mark as read
          </div>
          <div className={style.action} onClick={reply}>
            <PromptSuggestionIcon className={style.icon} />
            Reply
          </div>
          <div className={style.time}>
            <TimeAgo
              date={props.notification.dateTime}
              minPeriod={60}
              key={props.notification.dateTime?.toString()}
              formatter={(a, b, c) => `${a}${b[0]} ${c}`}
            />
          </div>
        </div>
      </div>
      {props.notification.commentThread && props.notification.commentThread.type !== "suggestion" && (
        <div className={style.comment}>{lastComment?.text}</div>
      )}
      {props.notification.commentThread && props.notification.commentThread.type === "suggestion" && (
        <div className={style.comment}>
          <EditDiff
            text_before={props.notification.commentThread.suggestion?.text_before}
            text_after={props.notification.commentThread.suggestion?.text_after}
            rich_text_before={props.notification.commentThread.suggestion?.rich_text_before}
            rich_text_after={props.notification.commentThread.suggestion?.rich_text_after}
            key={props.notification._id}
            showRichText={true}
            isApiID={false}
          />
        </div>
      )}
    </div>
  );
}
