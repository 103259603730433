import { ToastHandlerData } from "@/views/Components/lib";
import { CollisionDetection, MouseSensor, pointerWithin, useSensor } from "@dnd-kit/core";
import * as SegmentEvents from "@shared/segment-event-names";
import { useState } from "react";
import { ComponentPopulatedInterface, IComponent } from "../../../shared/types/Component";
import useSegment from "../../hooks/useSegment";
import http from "../../http";
import { DraggableData, DropTypePriority, DroppableData, DroppableTypes } from "./lib";
import { RenamePair, computeRenames } from "./util";

interface Props {
  updateComponentsInMemory: (updatedComps: Partial<IComponent>[]) => void;
  refreshLibraryHistory: () => Promise<void>;
  moveComponentsToFolder: (folderId: string, compIds: string[]) => Promise<void>;
  showToast: (data: ToastHandlerData) => void;
  comps: ComponentPopulatedInterface<string>[];
  selectComp: (
    compId: string,
    options?: {
      handleRouteChange?: boolean;
      disableQuickReplyComments?: boolean;
      preventDeselection?: boolean;
      disableScrolling?: boolean;
    }
  ) => void;
  selectedComps: IComponent[];
}

// there is a DragStartEvent type in the dnd-kit library, but it doesn't
// provide a template type for the data, so we define our own instead
type DragStartEvent = {
  active: { data: { current: DraggableData } };
};

type DragEndEvent = {
  active: { data: { current: DraggableData } };
  over?: { data: { current: DroppableData } };
};

export const useCompLibraryDragAndDrop = ({
  updateComponentsInMemory,
  refreshLibraryHistory,
  moveComponentsToFolder,
  showToast,
  comps,
  selectComp,
  selectedComps,
}: Props) => {
  const segment = useSegment();
  const [activeDragData, setActiveDragData] = useState<DraggableData | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const selectedFolderId = comps[0]?.folder_id;

  const handleDragStart = (event: DragStartEvent) => {
    setIsDragging(true);

    // handle the selection state when starting a drag
    const selectedCompIds = selectedComps.map((c) => c._id);
    const dragCompId = event.active.data.current.dragCompId;

    let dragComps = selectedComps.map((c) => ({ _id: c._id, name: c.name }));

    // if we're dragging a single component, and it's not already selected, select it
    if (!selectedCompIds.includes(dragCompId)) {
      selectComp(dragCompId, { disableScrolling: true });
      dragComps = [
        {
          _id: dragCompId,
          name: comps.find((c) => c._id === dragCompId)?.name || "",
        },
      ];
    }

    // send modified selection data to the drag state
    setActiveDragData({
      ...event.active.data.current,
      selectedComps: dragComps,
    });
  };

  const handleRenameMultiComp = async (renamePairs: RenamePair[]) => {
    try {
      await http.post("/ws_comp/multiRename", { renamePairs });
    } catch (error) {
      console.error("Error renaming components", error);
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    setActiveDragData(null);
    setIsDragging(false);

    const draggingData = event.active.data.current;
    const droppingData = event.over?.data.current;
    if (!droppingData) return;

    if (droppingData.type === DroppableTypes.COMPONENT_FOLDER && droppingData.isSample) {
      return showToast({
        text: "Components cannot be moved to a sample folder. Please select a different folder.",
        visibleFor: 3000,
      });
    }

    const { selectedComps } = draggingData;

    if (droppingData.type === DroppableTypes.COMPONENT_FOLDER) {
      segment.track({
        event: SegmentEvents.DND_MOVE_TO_FOLDER,
        properties: {
          folderId: droppingData.folderId,
          isMulti: selectedComps.length > 1,
        },
      });
      const folderId = droppingData.folderId;
      const componentUpdates = selectedComps.map((c) => ({
        ...c,
        folder_id: folderId,
      }));
      updateComponentsInMemory(componentUpdates);
      await moveComponentsToFolder(
        folderId,
        selectedComps.map((c) => c._id)
      );
      return;
    }

    let renamePairs: { name: string; sortKey: string; oldName: string }[] = [];
    switch (droppingData.type) {
      case DroppableTypes.COMPONENT_GROUP:
        segment.track({
          event: "DnD -- Move to group",
          properties: {
            groupName: droppingData.groupName,
            isMulti: selectedComps.length > 1,
          },
        });
        renamePairs = computeRenames(selectedComps, selectedFolderId, droppingData.groupName, "");
        break;

      case DroppableTypes.COMPONENT_BLOCK:
        segment.track({
          event: "DnD -- Move to block",
          properties: {
            blockName: droppingData.blockName,
            isMulti: selectedComps.length > 1,
          },
        });
        renamePairs = computeRenames(selectedComps, selectedFolderId, droppingData.groupName, droppingData.blockName);
        break;

      case DroppableTypes.COMPONENT_NOT_IN_GROUP:
        segment.track({
          event: "DnD -- Move out of group",
          properties: {
            isMulti: selectedComps.length > 1,
          },
        });
        renamePairs = computeRenames(selectedComps, selectedFolderId, "", "");
        break;

      default:
        console.error("Tried to drag and drop onto an unsupported drop target");
        break;
    }

    if (renamePairs.length === 0) return;

    const componentUpdates = renamePairs.map((pair) => ({
      _id: selectedComps.find((c) => c.name === pair.oldName)?._id || "",
      name: pair.name,
      sortKey: pair.sortKey,
    }));
    updateComponentsInMemory(componentUpdates);

    await Promise.all([handleRenameMultiComp(renamePairs), refreshLibraryHistory()]);
  };

  /**
   * We use a built-in pointer-based collision algorithm to detect if the user is
   * dragging over something droppable, but since we have nested drop targets, we
   * need to do a little sorting to make sure we're always returning the desired
   * drop target.
   */
  const customCollisionDetection: CollisionDetection = (args) => {
    const pointerCollisions = pointerWithin(args);

    // dnd-kit will use the first collision in the array
    const sortedCollisions = pointerCollisions.sort((a, b) => {
      const aType = a.data?.droppableContainer.data.current.type;
      const bType = b.data?.droppableContainer.data.current.type;

      return DropTypePriority[aType] - DropTypePriority[bType];
    });

    return sortedCollisions;
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  return {
    customCollisionDetection,
    activeDragData,
    isDragging,
    handleDragStart,
    handleDragEnd,
    mouseSensor,
  };
};
