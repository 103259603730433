import ReorderIcon from "@mui/icons-material/Reorder";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { IVariable } from "@shared/types/Variable";
import classnames from "classnames";
import React from "react";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import RoundedCount from "../../../../components/rounded-count/rounded-count";
import { VariableActivityFilter, VariableHistoryItem, VariableInstanceData } from "../../types";
import EditVariable from "../EditVariable";
import VariableActivity from "../VariableActivity";
import VariableHistory from "../VariableHistory";
import VariableInstances from "../VariableInstances";
import style from "./style.module.css";

export interface VariableDetailsProps {
  selectedVariable: IVariable | null;
  activityFilter: VariableActivityFilter;
  setActivityFilter: (input: VariableActivityFilter) => void;
  handleUpdateVariable: (variable: IVariable) => void;
  handleDeleteVariable: (variable: IVariable) => void;
  handleMoveVariable: (variable: IVariable) => void;
  handleSelectVariableId: (variableId: string) => void;
  isSaving: boolean;
  loadingHistory: boolean;
  variableHistory: VariableHistoryItem[];
  allHistoryFetched: boolean;
  fetchVariableHistoryNewPage: () => void;
  instanceData: VariableInstanceData;
}
const VariableDetails = ({
  selectedVariable,
  activityFilter,
  setActivityFilter,
  handleUpdateVariable,
  handleDeleteVariable,
  handleMoveVariable,
  handleSelectVariableId,
  isSaving,
  loadingHistory,
  variableHistory,
  allHistoryFetched,
  fetchVariableHistoryNewPage,
  instanceData,
}: VariableDetailsProps) => {
  const instanceCount = instanceData.projects.length + instanceData.wsComps.length;
  const canEdit = userHasResourcePermission("variable_folder:edit");

  return (
    <div className={style.sidebar}>
      <div>
        {!selectedVariable && (
          <div className={style.titleSection}>
            <div className={classnames(style.option, style.selected)}>
              <ReorderIcon className={style.activityIcon} />
              Activity
            </div>
          </div>
        )}
        {selectedVariable && (
          <div className={style.titleSection}>
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: activityFilter === "Edit",
              })}
              onClick={() => setActivityFilter("Edit")}
            >
              Edit
            </div>
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: activityFilter === "Instances",
              })}
              onClick={() => setActivityFilter("Instances")}
            >
              Instances
              {instanceCount > 0 && <RoundedCount className={style.count} count={instanceCount} />}
            </div>
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: activityFilter === "Activity",
              })}
              onClick={() => setActivityFilter("Activity")}
            >
              Activity
            </div>
          </div>
        )}
      </div>
      <div className={style.detailPane}>
        {!selectedVariable && (
          <VariableHistory
            allHistoryFetched={allHistoryFetched}
            loadingHistory={loadingHistory}
            variableHistory={variableHistory}
            fetchVariableHistoryNewPage={fetchVariableHistoryNewPage}
            handleSelectVariableId={handleSelectVariableId}
          />
        )}
        {activityFilter === "Edit" && selectedVariable && (
          <ErrorBoundary componentName="EditVariable" type="Selection">
            <EditVariable
              variable={selectedVariable}
              canEdit={canEdit}
              isSaving={isSaving}
              handleUpdateVariable={handleUpdateVariable}
              handleDeleteVariable={handleDeleteVariable}
              handleMoveVariable={handleMoveVariable}
              instanceData={instanceData}
            />
          </ErrorBoundary>
        )}
        {activityFilter === "Instances" && selectedVariable && <VariableInstances instanceData={instanceData} />}
        {activityFilter === "Activity" && selectedVariable && (
          <VariableActivity
            loadingHistory={loadingHistory}
            variableHistory={variableHistory}
            handleSelectVariableId={() => {}}
          />
        )}
      </div>
    </div>
  );
};
export default VariableDetails;
