import { z } from "zod";
import { ITipTapRichText, ZTipTapRichText } from "./TextItem";

export interface IComponentConflict {
  id?: string;
  figma_node_id: string;
  comp_id?: string;
  comp_name?: string;
  textBefore?: string;
  textAfter: string;
  richTextAfter: ITipTapRichText;
  richTextBefore?: ITipTapRichText;
  reversed?: boolean;
  appliedVariantId?: string;
  appliedVariantName?: string;
  figmaVariableId?: string;
}

export interface ITextItemConflict {
  id: string;
  figma_node_id: string;
  options: [string, string];
  rich_text_options: [ITipTapRichText, ITipTapRichText];
  resolved?: boolean;
  appliedVariantId?: string;
  appliedVariantName?: string;
  variableConflict: boolean;
}

export function isComponentConflict(conflict: ITextItemConflict | IComponentConflict): conflict is IComponentConflict {
  if (!conflict) return false;
  return "comp_name" in conflict;
}

export function isTextItemConflict(conflict: ITextItemConflict | IComponentConflict): conflict is ITextItemConflict {
  if (!conflict) return false;
  return "options" in conflict;
}

export const ZInstaSyncEdit = z.object({
  textItemId: z.string(),
  figmaNodeId: z.string(),
  appliedVariantId: z.string().optional().nullable(),
  textBefore: z.string().optional(),
  textAfter: z.string(),
  richTextBefore: ZTipTapRichText.optional(),
  richTextAfter: ZTipTapRichText,
  lastSync: z.string(),
  lastSyncRichText: ZTipTapRichText,
  resetVariables: z.boolean().optional(),
  syncedFigmaVariableId: z.string().optional(),
  syncedFigmaVariableCollectionId: z.string().optional(),
  attachComponentId: z.string().optional(),
});

export type IInstaSyncEdit = z.infer<typeof ZInstaSyncEdit>;
