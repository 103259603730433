import { IWorkspace, IWorkspaceSlackAuthenticated } from "@shared/types/Workspace";
import { Types } from "mongoose";

const DAYS_IN_MS = 1000 * 60 * 60 * 24;

export function getSegmentTraitsForUser(
  user: {
    name: string;
    email: string;
    workspaceId: string | Types.ObjectId;
    isFigmaEditor: boolean;
    job: string;
    userId: string;
    createdAt: Date;
    metrics: {
      firstWeekLogins: number;
      firstMonthLogins: number;
    };
    billingRole: "admin" | "editor" | "commenter";
  },
  workspace: IWorkspaceSlackAuthenticated | IWorkspace,
  additionalTraits: Record<string, any> = {}
) {
  return {
    displayName: user.name,
    name: user.name,
    email: user.email,
    workspaceId: user.workspaceId.toString(),
    figmaEditor: user.isFigmaEditor,
    job: user.job,
    workspacePlan: workspace.plan,
    isWorkspaceCreator: workspace.creatorId === user.userId,
    inFirstWeek: user.createdAt > new Date(Date.now() - 7 * DAYS_IN_MS),
    inFirstMonth: user.createdAt > new Date(Date.now() - 30 * DAYS_IN_MS),
    firstWeekLogins: user.metrics.firstWeekLogins,
    firstMonthLogins: user.metrics.firstMonthLogins,
    createdAt: user.createdAt,
    /* Deprecated Fields */
    workspaceID: user.workspaceId.toString(),
    figma_editor: user.isFigmaEditor,
    role: user.billingRole,
    ...additionalTraits,
  };
}
