import { ZObjectId } from "@shared/types/lib";
import { z } from "zod";

export const ZLibraryComponentFolder = z.object({
  _id: ZObjectId,
  name: z.string(),
  apiId: z.string(),
  parentId: ZObjectId.nullable(),
  workspaceId: ZObjectId,
});

export type ILibraryComponentFolder = z.infer<typeof ZLibraryComponentFolder>;
