import { ZInstaSyncEdit } from "@shared/types/InstaSync";
import { z } from "zod";

export const ZUpdateMultiSyncFigmaVariablesRequest = {
  body: z.object({
    textItemToComponentIds: z.record(z.string()),
    projectId: z.string(),
  }),
};
export const ZUpdateMultiSyncFigmaVariablesResponse = z.unknown();
export const UpdateMultiSyncFigmaVariablesMethod = "POST";
export const UpdateMultiSyncFigmaVariablesPath = "comp/multi/sync-figma-variables";

export const ZUpdateMultiSyncRequest = {
  body: z.object({
    edits: z.array(ZInstaSyncEdit),
    projectId: z.string(),
  }),
};
export const ZUpdateMultiSyncResponse = z.unknown();
export const UpdateMultiSyncMethod = "POST";
export const UpdateMultiSyncPath = "comp/multi/sync";

export const ZGetMultiTextItemsRequest = {
  query: z.object({ textItemIds: z.array(z.string()) }),
};
export const ZGetMultiTextItemsResponse = z.unknown();
export const GetMultiTextItemsMethod = "GET";
export const GetMultiTextItemsPath = "comp/multi/info";
