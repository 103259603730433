import React from "react";

export default function LoadingIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <circle cx="6" cy="6" r="5" stroke="url(#paint0_linear_6601_3410)" strokeWidth="2" />
      <defs>
        <linearGradient id="paint0_linear_6601_3410" x1="0" y1="0" x2="12" y2="12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9E9E9F" />
          <stop offset="1" stopColor="#9E9E9F" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
