import { libraryItemsAtom, libraryItemsVirtualizerAtom } from "@/stores/Library";
import VirtualizedList from "@ds/atoms/VirtualizedList";
import logger from "@shared/utils/logger";
import { useAtomValue } from "jotai";
import { Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import LibraryComponentListItem from "../LibraryComponentListItem";
import LibraryItemsHeader from "../LibraryItemsHeader";
import style from "./style.module.css";

function LibraryItemsList() {
  return (
    <div className={style.libraryItemsListContainer}>
      <LibraryItemsHeader />
      <Suspense fallback={<LibraryItemsListFallback />}>
        <LibraryItemsListContent />
      </Suspense>
    </div>
  );
}

function LibraryItemsListFallback() {
  return (
    <div className={style.libraryListFallback}>
      <Skeleton count={20} height={80} borderRadius={8} />
    </div>
  );
}

function LibraryItemsListContent() {
  const libraryItems = useAtomValue(libraryItemsAtom);

  return (
    <VirtualizedList
      className={style.libraryVirtualList}
      id={"LIBRARY_CONTENT"}
      items={libraryItems}
      virtualizerAtom={libraryItemsVirtualizerAtom}
      virtualizeOptions={{
        estimateSize: () => 300,
        overscan: 10,
        paddingEnd: 24,
        getItemKey: (index) => libraryItems[index]._id,
      }}
    >
      {(item, options, atom) => {
        switch (item.type) {
          case "component":
            return (
              <Suspense fallback={<LibraryComponentListItem.Fallback />}>
                <LibraryComponentListItem item={item} />
              </Suspense>
            );
          default:
            logger.error("Unknown library item type", { context: { item } }, new Error("Unknown library item type"));
            return null;
        }
      }}
    </VirtualizedList>
  );
}

export default LibraryItemsList;
