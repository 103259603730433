import classnames from "classnames";
import React from "react";

import SampleBadge from "@/components/SampleBadge";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { userHasBillingRole, userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { IPopulatedVariableFolder } from "@shared/types/VariableFolder";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ViewOnlyBadge from "../../../../components/ViewOnlyBadge";
import style from "./style.module.css";

type ExportFlaggedFeatureResponse = {
  workspacePlan: string;
  isEnabled: boolean;
  wsTrial: string | null;
};
interface Props {
  openExportModal: () => void;
  openCreateVariableModal: () => void;
  selectedFolder?: IPopulatedVariableFolder | null;
  handleFolderClick: (folderId?: string) => void;
}

const VariableTitleBar = ({ openExportModal, openCreateVariableModal, selectedFolder, handleFolderClick }: Props) => {
  const canExport = userHasBillingRole("editor");
  const history = useHistory();
  const isEditEnabled = userHasResourcePermission("variable_folder:edit");

  return (
    <Navbar className={style.navbar}>
      <div className={style.alignLeft}>
        <div className={classnames([style.title], { [style.alignLeft]: true })}>Variables</div>
        {selectedFolder && selectedFolder.name && (
          <div className={style.breadcrumbNav}>
            <div className={classNames(style.breadcrumb, style.clickable)} onClick={() => handleFolderClick()}>
              Home
            </div>
            <span className={style.breadcrumbDivider}>/</span>
            <div className={style.breadcrumb}>
              <FolderOpenOutlined />
              {selectedFolder.name}
            </div>
            {selectedFolder.isSample && <SampleBadge>Sample</SampleBadge>}
          </div>
        )}
        {!isEditEnabled && (
          <div className={style.viewOnlyContainer}>
            <ViewOnlyBadge tooltipText="You have use-only access to this variable folder. This means you can view variables and use them in projects or components, but not edit them. To get full edit rights, ask an admin." />
          </div>
        )}
      </div>
      <div>
        {canExport && (
          <>
            <SaveAltIcon onClick={openExportModal} className={style.icon} data-tip data-for="export" />
            <ReactTooltip id="export" place="bottom" effect="solid">
              Export Variables
            </ReactTooltip>
          </>
        )}
        {isEditEnabled && (
          <span>
            <Button
              data-tip
              data-for="create-variable"
              disabled={selectedFolder?.isSample}
              className={style.barButton}
              variant="primary"
              onClick={openCreateVariableModal}
            >
              + New Variable
            </Button>
            {selectedFolder?.isSample && (
              <ReactTooltip id="create-variable" place="bottom" effect="solid">
                Creating new variables is disabled in this sample folder.
              </ReactTooltip>
            )}
          </span>
        )}
      </div>
    </Navbar>
  );
};
export default VariableTitleBar;
