import { searchAtom } from "@/stores/Location";
import { projectHiddenResultsBlocksCountAtom, projectHiddenResultsTextItemsCountAtom } from "@/stores/Project";
import { selectedFiltersListAtom } from "@/stores/ProjectFiltering";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";
import style from "./style.module.css";

function NoSearchResults() {
  const [search, setSearch] = useAtom(searchAtom);
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersListAtom);
  const projectHiddenResultsTextItemsCount = useAtomValue(projectHiddenResultsTextItemsCountAtom);
  const projectHiddenResultsBlocksCount = useAtomValue(projectHiddenResultsBlocksCountAtom);

  function clearSearch() {
    setSearch("");
  }

  function clearFilters() {
    setSelectedFilters([]);
  }

  function handleKeyboardEvent(event: React.KeyboardEvent) {
    if (event.key === "Escape") {
      clearSearch();
    }
  }

  const message = useMemo(() => {
    let blockCount = `${projectHiddenResultsBlocksCount} block${projectHiddenResultsBlocksCount === 1 ? "" : "s"}`;
    let textItemCount = `${projectHiddenResultsTextItemsCount} text item${
      projectHiddenResultsTextItemsCount === 1 ? "" : "s"
    }`;

    const message = `There are ${blockCount} and ${textItemCount} in this project`;

    if (!projectHiddenResultsBlocksCount && !projectHiddenResultsTextItemsCount) {
      return `${message}.`;
    } else {
      return `${message}, but none of them match your ${search ? "search" : "filters"}.`;
    }
  }, [projectHiddenResultsBlocksCount, projectHiddenResultsTextItemsCount, search]);

  return (
    <div className={style.noSearchResultsContainer} onKeyDown={handleKeyboardEvent}>
      <div className={style.noSearchResults}>
        <Text>{search ? `Nothing found for "${search}"` : "Nothing found"}</Text>
        <Text color="secondary">{message}</Text>
        <div className={style.clearSearchContainer}>
          {!!search && (
            <Button onClick={clearSearch} className={style.buttonWithNoPadding} size="micro" level="secondary">
              Clear search
            </Button>
          )}
          {selectedFilters.length > 0 && (
            <Button onClick={clearFilters} size="micro" level="secondary">
              Clear filters
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NoSearchResults;
