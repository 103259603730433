import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IInstanceCountProps {
  className?: string;
  content: string | React.ReactNode;
  actionText: string;
  onActionClick: () => void;
}

export function InstanceCount(props: IInstanceCountProps) {
  return (
    <div className={classNames(style.InstanceCountWrapper, props.className)}>
      {typeof props.content === "string" ? (
        <Text color="secondary" weight="medium" size="small">
          {props.content}
        </Text>
      ) : (
        props.content
      )}
      <Button level="secondary" onClick={props.onActionClick} size="micro">
        <Text color="action" size="micro">
          {props.actionText}
        </Text>
      </Button>
    </div>
  );
}

export default InstanceCount;
