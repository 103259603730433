import { generateHTML } from "@tiptap/react";
import classnames from "classnames";
import parse from "html-react-parser";
import React, { useEffect, useMemo, useRef } from "react";
import prepareRichTextNodes from "../../../../shared/common/richText/prepareRichTextNodes";
import getExtensions from "../../../../shared/frontend/richText/templates";
import { ITipTapRichText } from "../../../../shared/types/TextItem";

import style from "./index.module.css";

interface IProps {
  richText: ITipTapRichText;
  renderProps?: React.HTMLProps<HTMLDivElement>;
  highlightBrackets?: boolean;
  truncate?: boolean | number;
  size?: "base" | "small" | "micro";
  className?: string;
}

function getLineHeight(el: HTMLElement) {
  var temp = document.createElement(el.nodeName),
    ret;
  temp.setAttribute(
    "style",
    "margin:0; padding:0; " +
      "font-family:" +
      (el.style.fontFamily || "inherit") +
      "; " +
      "font-size:" +
      (el.style.fontSize || "inherit")
  );
  temp.innerHTML = "A";

  el.parentNode!.appendChild(temp);
  ret = temp.clientHeight;
  temp.parentNode!.removeChild(temp);

  return ret;
}

export const RichTextRender = (props: IProps) => {
  const { richText, renderProps, className } = props;
  const divRef = useRef<HTMLDivElement>(null);

  const size = props.size ?? "base";

  const extensions = useMemo(() => getExtensions({}), []);

  const richTextHTML = useMemo(() => {
    try {
      return parse(
        generateHTML(
          prepareRichTextNodes(richText, {
            highlightBrackets: props.highlightBrackets,
          }),
          extensions
        )
      );
    } catch (e) {
      console.error("Failed to parse " + JSON.stringify(richText), e);
      return <></>;
    }
  }, [richText, props.highlightBrackets, extensions]);

  useEffect(
    function calculateTruncation() {
      if (!props.truncate || !divRef.current) {
        return;
      }

      // If props.truncate is a number, treat the value as the height which should be truncated against.
      // Supporting this is necessary for <RichTextRender /> usage in parents where a height cannot
      // be implicitly calculated.
      const divHeight = typeof props.truncate === "number" ? props.truncate : divRef.current.clientHeight;

      const paragraphs = divRef.current.querySelectorAll("p");

      let totalHeight = 0;
      let overflowed = false;

      // Calculate the total height of paragraphs until it exceeds the div's height
      for (const paragraph of paragraphs) {
        paragraph.style.visibility = "unset";
        if (overflowed) {
          paragraph.style.display = "none";
          continue;
        }

        let newTotalHeight = totalHeight + paragraph.clientHeight;
        if (newTotalHeight < divHeight) {
          totalHeight = newTotalHeight;
          continue;
        }

        overflowed = true;
        const paragraphLineHeight = getLineHeight(paragraph);
        const lines = Math.floor((divHeight - totalHeight) / paragraphLineHeight);
        paragraph.style.webkitLineClamp = lines.toString();

        // Remove the excess paragraphs
      }
    },
    [props.truncate, richTextHTML]
  );

  return (
    <div
      ref={divRef}
      {...renderProps}
      className={classnames(style.RichTextRenderWrapper, renderProps?.className, className, style[size])}
    >
      {richTextHTML}
    </div>
  );
};
