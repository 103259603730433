import InviteCollaboratorsModal from "@/components/InviteCollaboratorsModal";
import ManagePermissionsModal from "@/components/ManagePermissionsModal";
import UpsellModal from "@/components/UpsellModal";
import InviteModal from "@/components/invitemodal/invitemodal";
import { ToastNotification } from "@/components/notification-toast";
import RemoveUserModal from "@/components/remove-user-modal/remove-user-modal";
import useSegment from "@/hooks/useSegment";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { BillingContext } from "@/store/billingContext";
import { useWorkspace } from "@/store/workspaceContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { IUser } from "@shared/types/User";
import { Ditto } from "ditto-react";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import sharedStyles from "../style.module.css";
import Toast from "../toast";
import AIFunctionalityPanel from "./AIFunctionalityPanel";
import useAIFunctionalityPanel from "./AIFunctionalityPanel/useAIFunctionalityPanel";
import GeneralPanel from "./GeneralPanel";
import LogoutPanel from "./LogoutPanel";
import ManageTagsModal from "./ManageTagsModal";
import ResourcesPanel from "./ResourcesPanel";
import useResourcesPanel from "./ResourcesPanel/useResourcesPanel";
import TagsPanel from "./TagsPanel";
import useTagsPanel from "./TagsPanel/useTagsPanel";
import TeamMembersPanel from "./TeamMembersPanel";
import useTeamMembersPanel from "./TeamMembersPanel/useTeamMembersPanel";
import style from "./style.module.css";

interface WorkspaceTabProps {
  permissionGroups: { _id: string; name: string }[];
}
export default function WorkspaceTab({ permissionGroups }: WorkspaceTabProps) {
  const { workspaceInfo, fetchWorkspaceUsers } = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const { showBillingModal } = useContext(BillingContext);
  const segment = useSegment();
  const history = useHistory();
  if (window.location.pathname === "/account") {
    history.push("/account/workspace");
  }

  const isSelfServe = workspaceInfo?.plan !== "enterprise";
  const isAdmin = user.permissionGroups.groups.map((group) => group._id).includes("admin");

  const queryParams = new URLSearchParams(window.location.search);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(queryParams.get("openInviteModal") ?? false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [showManagePermissionsModal, setShowManagePermissionsModal] = useState(false);
  const [showSamlUpsellModal, setShowSamlUpsellModal] = useState(false);
  const [notification, setNotification] = useState<ToastNotification | null>(null);

  const {
    activeUserCount,
    canEditInvites,
    canEditUser,
    canManagePermissions,
    canRemoveUser,
    inviteUser,
    invites,
    isInviteSending,
    permissionGroupsEnabled,
    permissionOptions,
    removeUser,
    resendInvite,
    revokeInvite,
    setUserToManagePermissions,
    setUserToRemove,
    updateInvites,
    updateUserRole,
    userToManagePermissions,
    userToRemove,
    users,
  } = useTeamMembersPanel(setNotification);
  const { resources } = useResourcesPanel();
  const { tags, canEditTags, editTag, deleteTag } = useTagsPanel();
  const {
    isAiEditorEnabled,
    isAutomatedComponentNamingEnabled,
    toggleIsAiEditorEnabled,
    toggleIsAutomatedComponentNamingEnabled,
    usageData,
  } = useAIFunctionalityPanel();

  return (
    <div>
      {workspaceInfo && (
        <div>
          {notification && <Toast notification={notification} />}
          <div className={sharedStyles.container}>
            <GeneralPanel workspaceName={workspaceInfo.name} workspacePlan={workspaceInfo.plan} />
          </div>
          <div className={sharedStyles.container}>
            <TeamMembersPanel
              activeUserCount={activeUserCount}
              canEditUser={canEditUser}
              canEditInvites={canEditInvites}
              canManagePermissions={canManagePermissions}
              canRemoveUsers={canRemoveUser}
              invites={invites}
              onInviteClick={() => setShowInviteModal(true)}
              onManagePermissions={(user) => {
                setUserToManagePermissions(user);
                setShowManagePermissionsModal(true);
              }}
              onRemoveUser={(user) => {
                segment.track({
                  event: SegmentEvents.REMOVE_USER_MODAL_OPENED,
                  properties: {
                    application: "web_app",
                  },
                });

                setUserToRemove(user);
                setShowRemoveUserModal(true);
              }}
              onResendInviteClick={(invite) => resendInvite(invite.email)}
              onRevokeInviteClick={(invite) => revokeInvite(invite.email)}
              onSelectRole={updateUserRole}
              onUpgradePlanClicked={() => showBillingModal()}
              pendingInviteCount={invites.length}
              permissionGroupsEnabled={permissionGroupsEnabled}
              permissionGroups={permissionGroups}
              userLimitReached={workspaceInfo.plan === "free" && users.length >= 4}
              users={users}
              permissionOptions={permissionOptions}
            />
            {isSelfServe && (
              <div>
                <div className={style.ssoLinkContainer}>
                  <div className={style.ssoLink} onClick={() => setShowSamlUpsellModal(true)}>
                    <Ditto componentId="configure-saml-sso" />
                  </div>
                </div>
                {showSamlUpsellModal && (
                  <UpsellModal
                    actionDittoComponentId="contact-sales"
                    contentDittoComponentId="sso-paywall-content"
                    titleDittoComponentId="sso-paywall-title"
                    onHide={() => setShowSamlUpsellModal(false)}
                  />
                )}
              </div>
            )}
            {permissionGroupsEnabled && showInviteModal && (
              <InviteCollaboratorsModal
                inviteContext={{
                  type: "workspace",
                }}
                currentUser={user}
                onHide={() => setShowInviteModal(false)}
                setNotification={setNotification}
                onUsersInvited={updateInvites}
              />
            )}
            {!permissionGroupsEnabled && showInviteModal && (
              <InviteModal
                title="Invite Teammates"
                docID={undefined}
                docName={undefined}
                inviteType="workspace"
                showCustomMessage={true}
                onHide={() => setShowInviteModal(false)}
                isInviteSending={isInviteSending}
                handleAddInvite={(invite) => {
                  inviteUser(invite);
                  setShowInviteModal(false);
                }}
                workspaceInfo={workspaceInfo}
                workspaceUsers={users}
              />
            )}
            {showRemoveUserModal && (
              <RemoveUserModal
                user={userToRemove}
                onHide={() => setShowRemoveUserModal(false)}
                handleRemoveUser={(user: IUser) => {
                  setShowRemoveUserModal(false);
                  removeUser(user);
                }}
              />
            )}
            {showManagePermissionsModal && userToManagePermissions && (
              <ManagePermissionsModal
                onHide={() => setShowManagePermissionsModal(false)}
                user={userToManagePermissions}
                fetchUsers={fetchWorkspaceUsers}
              />
            )}
          </div>
          {(isAdmin || isSelfServe) && (
            <div className={sharedStyles.container}>
              <ResourcesPanel resources={resources} onUpgradePlanClicked={() => showBillingModal()} />
            </div>
          )}
          <div className={sharedStyles.container}>
            <TagsPanel
              showManageButton={canEditTags}
              tagCount={Object.keys(tags).length}
              onClick={() => setShowTagsModal(true)}
            />
            {showTagsModal && (
              <ManageTagsModal
                onHide={() => setShowTagsModal(false)}
                onEditTag={editTag}
                onDeleteTag={deleteTag}
                tags={tags}
              />
            )}
          </div>
          {isAdmin && usageData && (
            <div className={sharedStyles.container}>
              <AIFunctionalityPanel
                isAiEditorEnabled={isAiEditorEnabled}
                isAutomatedComponentNamingEnabled={isAutomatedComponentNamingEnabled}
                toggleIsAiEditorEnabled={toggleIsAiEditorEnabled}
                toggleIsAutomatedComponentNamingEnabled={toggleIsAutomatedComponentNamingEnabled}
                usageData={usageData}
              />
            </div>
          )}
          <div className={sharedStyles.container}>
            <LogoutPanel />
          </div>
        </div>
      )}
    </div>
  );
}
