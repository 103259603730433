import React from "react";
import Icon, { IProps as IIconProps } from "../Icon";
import LoadingIconSvg from "../Icon/icons/LoadingIcon";
import style from "./index.module.css";

interface IProps extends Omit<IIconProps, "Icon"> {}

export function LoadingIcon(props: IProps) {
  return <Icon {...props} Icon={<LoadingIconSvg className={style.svg} />} />;
}

export default LoadingIcon;
