import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { ALL_PAGE_ID } from "@/views/Project/state/usePageState";
import { HOMEPAGE_ASSIGNED_COMPONENT_CLICKED, HOMEPAGE_ASSIGNED_PROJECT_CLICKED } from "@shared/segment-event-names";
import classnames from "classnames";
import { Ditto, useDittoComponent } from "ditto-react";
import React from "react";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import useSegment from "../../../../hooks/useSegment";
import style from "./assignment.module.css";

export interface Props {
  projectId?: string;
  projectName?: string;
  statusCounts: { count: number; status: string }[];
  lastAssignedAt: Date;
  isLegacy?: boolean;
}

export default function Assignment(props: Props) {
  const { user } = useAuthenticatedAuth();
  const segment = useSegment();
  const sum = props.statusCounts.reduce((a, b) => a + b.count, 0);
  const history = useHistory();

  const onClick = () => {
    if (!props.projectId) {
      segment.track({ event: HOMEPAGE_ASSIGNED_COMPONENT_CLICKED });
      history.push(`/components?assignee=${user._id}`);
    } else if (props.isLegacy) {
      segment.track({
        event: HOMEPAGE_ASSIGNED_PROJECT_CLICKED,
        properties: { projectId: props.projectId, isLegacy: true },
      });
      history.push({
        pathname: `/projects/${props.projectId}/page/${ALL_PAGE_ID}`,
        search: `?assignee=${user._id}`,
      });
    } else {
      segment.track({
        event: HOMEPAGE_ASSIGNED_PROJECT_CLICKED,
        properties: { projectId: props.projectId, isLegacy: false },
      });
      history.push({
        pathname: `/projects-beta/${props.projectId}`,
        search: `?assignee=${user._id}`,
      });
    }
  };

  function getStatusText(status: string) {
    switch (status) {
      case "REVIEW":
        return "In Review";
      case "WIP":
        return "WIP";
      case "FINAL":
        return "Final";
      default:
        return status;
    }
  }

  const textTextItems = useDittoComponent({ componentId: "item.text-item", count: sum }) ?? "";
  const textComponent = useDittoComponent({ componentId: "item.component", count: sum }) ?? "";

  return (
    <div className={style.container} onClick={onClick} data-testid="assignment-row">
      <div className={style.assigment}>
        <div className={style.assigmentText}>
          <span className={style.count}>
            <Ditto
              componentId="assigment-text"
              variables={{
                count: sum,
                items: props.projectName ? textTextItems : textComponent,
              }}
            />
          </span>{" "}
          <span className={style.location}>{props.projectName ? props.projectName : "Component Library"}</span>
        </div>
        {props.statusCounts
          .sort((a, b) => b.status.localeCompare(a.status))
          .filter((status) => status.status !== "NONE")
          .map((status) => (
            <div
              key={status.status}
              className={classnames([
                style.statusTag,
                {
                  [style.review]: status.status === "REVIEW",
                  [style.wip]: status.status === "WIP",
                  [style.final]: status.status === "FINAL",
                },
              ])}
            >
              {status.count} {getStatusText(status.status)}
            </div>
          ))}
      </div>
      <div className={style.time}>
        <TimeAgo
          date={props.lastAssignedAt}
          minPeriod={60}
          key={props.lastAssignedAt.toString()}
          formatter={(a, b, c) => `${a}${b[0]} ${c}`}
        />
      </div>
    </div>
  );
}
