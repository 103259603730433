import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/react";
import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import { Atom, getDefaultStore, useAtomValue } from "jotai";
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Icon from "../../atoms/Icon";
import LoadingIcon from "../../atoms/LoadingIcon";
import Text from "../../atoms/Text";
import { Option } from "../MultiCombobox";
import style from "./index.module.css";

export const NO_ITEMS_VALUE = "__NO_ITEMS__";

export interface ComboboxOption {
  value: string;
  label: string;
  /**
   * Whether the option is a draft item created with the "Create item" combobox functionality.
   */
  isDraftItem?: boolean;
  /**
   * Whether or not the option is in a loading state. Currently only used when the entire Combobox
   * is in a loading state, but could technically be extended to support loading states on a per-option
   * basis.
   */
  isLoading?: boolean;
}

interface IBaseComboboxProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * Message to display when options list is loaded but empty.
   */
  emptyMessage?: string;
  /**
   * The options in the combobox dropdown. Stored in an atom to be able to handle asynchronous loading states internally.
   */
  optionsAtom: Atom<ComboboxOption[] | Promise<ComboboxOption[]>>;
  /**
   * Options which should not be shown in the dropdown and cannot be created.
   */
  exclusions?: string[];
  /**
   * The selected item from the options list.
   */
  selectedItem: ComboboxOption | null;
  /**
   * Callback function to set the selected item.
   */
  setSelectedItem: (item: ComboboxOption | null) => void;
  /**
   * Placeholder text for the combobox input.
   */
  placeholder?: string;
  /**
   * Text to display when creating a new item.
   */
  createNewText?: string;
  /**
   * Callback function that runs when the user creates a new item in the combobox.
   */
  onCreateNew?: (value: string) => void;
  /**
   * Autofocus the combobox input on render.
   */
  autoFocus?: boolean;
  /**
   * Hide the down arrow icon.
   */
  hideDownArrow?: boolean;

  store?: ReturnType<typeof getDefaultStore>;
}

const NEW_ITEM_VALUE = "__NEW__";
const DEFAULT_STORE = getDefaultStore();
const LOADING_OPTION = {
  label: "",
  value: "__LOADING__",
  isLoading: true,
};

interface IInternalStateOptions {
  onFocus?: () => void;
  onBlur?: () => void;
  isLoading?: boolean;
}

function useInternalState(
  props: Omit<IBaseComboboxProps, "options">,
  optionList: ComboboxOption[],
  options?: IInternalStateOptions
) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState(props.selectedItem?.label ?? "");
  const [comboboxWidth, setComboboxWidth] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  const { selectedItem, setSelectedItem, onCreateNew } = props;

  const isLoading = options?.isLoading ?? false;

  useEffect(function calculateWrapperDimensions() {
    if (!wrapperRef.current) return;
    const rect = wrapperRef.current.getBoundingClientRect();
    wrapperRef.current.style.setProperty("--input-wrapper-height", `${rect.height}px`);
    wrapperRef.current.style.setProperty("--input-wrapper-width", `${rect.width}px`);
  });

  useEffect(function recalculateOptionsWidth() {
    if (wrapperRef.current) {
      const integerWidth = Math.ceil(wrapperRef.current.offsetWidth);
      setComboboxWidth(integerWidth);
    }
  }, []);

  const selectedItems = useMemo(
    function selectedItems() {
      return optionList.filter((item) => selectedItem?.value === item.value);
    },
    [optionList, selectedItem?.value]
  );

  const unselectedItems = useMemo(
    () =>
      optionList
        .filter((item) => {
          return (
            (!item.isDraftItem &&
              selectedItem?.value !== item.value &&
              // filter items that don't match the query
              item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())) ||
            item.isLoading
          );
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [optionList, query, selectedItem?.value]
  );

  const showCreateNew = useMemo(
    () =>
      onCreateNew &&
      query !== "" &&
      !optionList.some((item) => item.label === query) &&
      !props.exclusions?.some((value) => value.toLowerCase() === query.toLowerCase()),
    [optionList, props.exclusions, query, onCreateNew]
  );

  const onChangeValue = useCallback(
    function onChangeValue(value: ComboboxOption | null) {
      if (!value) {
        setSelectedItem(null);
        setQuery("");
        return;
      }

      if (value.value === NEW_ITEM_VALUE) {
        if (!onCreateNew) {
          throw new Error("Cannot create a new item without an onCreateNew callback.");
        }

        onCreateNew(query);

        // Set isDraftItem flag to indicate this is an unsaved combobox option
        value = { value: query, label: query, isDraftItem: true };
      }

      if (selectedItem && selectedItem.value === value.value) {
        setSelectedItem(null);
        setQuery("");
      } else {
        setSelectedItem(value);
        setQuery(value?.label || ""); // Immediately set the query to the new value's label
      }

      setIsOpen(false);
      // hacky, but required to avoid getting beaten out by a 3rd party .focus() command
      setTimeout(() => inputRef.current?.blur(), 10);
    },
    [onCreateNew, query, selectedItem, setSelectedItem]
  );

  const handleOnCreateNew = useCallback(
    (value: string) => {
      if (!onCreateNew) {
        throw new Error("Cannot create a new item without an onCreateNew callback.");
      }

      onCreateNew(value);

      // Set isDraftItem flag to indicate this is an unsaved combobox option
      const newValue = { value, label: value, isDraftItem: true };
      onChangeValue(newValue);
    },
    [onCreateNew, onChangeValue]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen(false);
        setQuery(selectedItem?.label || "");
      }

      if (event.key === "Enter") {
        let item: ComboboxOption | null = null;

        // this is anti-React, but the only way to determine which of the options is focused
        // via keyboard input is to inspect the DOM directly since the lib doesn't expose
        // an API to hook into it
        const optionsGrandparentElement = optionsListRef.current;
        if (optionsGrandparentElement) {
          const optionElements = optionsGrandparentElement.children[0].children;
          for (let i = 0; i < (optionElements?.length ?? 0); i++) {
            const optionElement = optionElements[i] as HTMLDivElement;
            if ("focus" in optionElement.dataset && "value" in optionElement.dataset) {
              const value = optionElement.dataset.value;
              item = unselectedItems.find((unselectedItem) => unselectedItem.value === value) ?? null;
              break;
            }
          }
        }

        if (item) {
          event.preventDefault();
          onChangeValue(item);
          return;
        }

        if (onCreateNew) {
          event.preventDefault();
          handleOnCreateNew(query);
          return;
        }

        // by nooping here, we fall back to the default behavior of the combobox
      }
    },
    [selectedItem, onChangeValue, handleOnCreateNew, onCreateNew, query, unselectedItems]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isLoading && selectedItem) {
        setSelectedItem(null);
      }

      setQuery(e.target.value);
      setIsOpen(true);
    },
    [setQuery, setIsOpen, isLoading, selectedItem, setSelectedItem]
  );

  const handleFocus = useCallback(() => {
    // if the combobox is in a loading state, we want to maintain the value of the query
    // when it's focused
    if (!options?.isLoading) {
      setQuery("");
    }

    setIsOpen(true);
    options?.onFocus?.();
  }, [options]);

  const handleBlur = useCallback(() => {
    setIsOpen(false);
    options?.onBlur?.();
  }, [options]);

  const handleInputClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  return {
    query,
    isOpen,
    isLoading,
    selectedItems,
    unselectedItems,
    showCreateNew,
    comboboxWidth,

    wrapperRef,
    inputRef,
    optionsListRef,

    onChangeValue,
    handleKeyDown,
    onInputChange,
    handleFocus,
    handleBlur,
    handleInputClick,
  };
}

export function BaseCombobox(props: IBaseComboboxProps) {
  // Keep track of whether or not the input was focused while the options were still loading.
  // If so, we want to auto-focus the loading input as soon as it mounts after the suspense finishes.
  const [loadingInputIsFocused, setLoadingInputIsFocused] = useState(false);
  const loadingStateOptions = useMemo<IInternalStateOptions>(
    () => ({
      onFocus: () => setLoadingInputIsFocused(true),
      onBlur: () => setLoadingInputIsFocused(false),
      isLoading: true,
    }),
    []
  );

  return (
    <Suspense fallback={<BaseComboboxLoading {...props} stateOptions={loadingStateOptions} />}>
      <BaseComboboxLoaded {...props} autoFocus={props.autoFocus ?? loadingInputIsFocused} />
    </Suspense>
  );
}
function BaseComboboxLoaded(props: IBaseComboboxProps) {
  const { store = DEFAULT_STORE } = props;
  const options = useAtomValue(props.optionsAtom, { store });
  const state = useInternalState(props, options);
  return <BaseComboboxInner rootProps={props} state={state} />;
}
function BaseComboboxLoading(props: IBaseComboboxProps & { stateOptions?: IInternalStateOptions }) {
  const state = useInternalState(props, [LOADING_OPTION], props.stateOptions);
  return <BaseComboboxInner rootProps={props} state={state} />;
}

type BaseComoboxInnerProps = {
  state: ReturnType<typeof useInternalState>;
  rootProps: IBaseComboboxProps;
};
function BaseComboboxInner(props: BaseComoboxInnerProps) {
  const {
    state: {
      query,
      isOpen,
      selectedItems,
      unselectedItems,
      showCreateNew,
      comboboxWidth,

      inputRef,
      wrapperRef,
      optionsListRef,

      onChangeValue,
      handleKeyDown,
      onInputChange,
      handleFocus,
      handleBlur,
      handleInputClick,
    },
    rootProps,
  } = props;

  const emptyMessage = rootProps.emptyMessage ?? "No items available";
  const showEmptyMessage = !(selectedItems.length || unselectedItems.length) && !showCreateNew;

  let inputValue: string;
  // if the option list is open, the input should reflect the value
  // of the current query so the user can modify it to manipulate the list
  if (isOpen) {
    inputValue = query;
  }
  // otherwise, if the option list is closed, the input should reflect either
  // the selected item OR the query (if either is set)
  else {
    inputValue = rootProps.selectedItem?.label ?? "";
  }

  return (
    <Combobox immediate by="value" value={rootProps.selectedItem} onChange={onChangeValue}>
      <div
        className={classNames(style.combobox, {
          [style.open]: isOpen,
        })}
        style={{ width: comboboxWidth ? `${comboboxWidth}px` : undefined }}
        ref={wrapperRef}
      >
        <div className={style.selectedOptions}>
          <ComboboxInput
            autoFocus={rootProps.autoFocus}
            placeholder={rootProps.placeholder}
            onKeyDown={handleKeyDown}
            onChange={onInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleInputClick}
            className={style.input}
            value={inputValue}
            ref={inputRef}
          />
          {!rootProps.hideDownArrow && !isOpen && (
            <Icon Icon={<KeyboardArrowDownIcon />} size="xs" className={style.dropdownIcon} />
          )}
        </div>

        {isOpen && (
          <ComboboxOptions
            // set static to true in order to control combobox open/close behavior
            static={true}
            modal={false}
            anchor={false}
            style={{ width: comboboxWidth }}
            className={style.optionsList}
            ref={optionsListRef}
          >
            <div className={style.filteredOptions}>
              {selectedItems.map((option) => (
                <Option
                  key={`option-$${option.value}`}
                  className={classNames(style.option)}
                  innerClassName={style.optionInner}
                  label={option.label}
                  value={option.value}
                >
                  {({ selected }) => (
                    <>
                      <Icon Icon={<CheckIcon />} size="xxs" className={classNames({ [style.hidden]: !selected })} />
                      <Text>{option.label}</Text>
                    </>
                  )}
                </Option>
              ))}
              {unselectedItems.map((option) => {
                if (option.isLoading) {
                  return (
                    <Option
                      key={`option-$${option.value}`}
                      className={classNames(style.option, style.loading)}
                      innerClassName={style.optionInner}
                      label={option.label}
                      value={option.value}
                    >
                      {/* Ensure this remains a reference instead of an inline function; an inline
                      function causes re-mounting on render which can break the CSS animatino */}
                      {OptionLoadingContents}
                    </Option>
                  );
                }

                return (
                  <Option
                    key={`option-$${option.value}`}
                    className={classNames(style.option)}
                    innerClassName={style.optionInner}
                    label={option.label}
                    value={option.value}
                  >
                    {({ selected }) => (
                      <>
                        <Icon Icon={<CheckIcon />} size="xxs" className={classNames({ [style.hidden]: !selected })} />
                        <Text>{option.label}</Text>
                      </>
                    )}
                  </Option>
                );
              })}
              {showEmptyMessage && (
                <Option
                  className={classNames(style.option, style.empty)}
                  innerClassName={style.optionInner}
                  label={""}
                  value={NO_ITEMS_VALUE}
                >
                  {() => <OptionEmptyContents message={emptyMessage} />}
                </Option>
              )}
              {showCreateNew && (
                <Option
                  key={`option-${NEW_ITEM_VALUE}`}
                  className={classNames(style.option, style.createNewOptionWrapper)}
                  innerClassName={style.optionInner}
                  label={query}
                  value={NEW_ITEM_VALUE}
                >
                  {() => (
                    <>
                      <Icon Icon={<Add />} size="xs" className={style.createNewIcon} />
                      <Text>{"Create: " + query}</Text>
                    </>
                  )}
                </Option>
              )}
            </div>
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}

// Text must be static so that this component can be passed by reference,
// avoiding re-mounting each render, and avoiding interrupting the animation
function OptionLoadingContents() {
  return (
    <>
      <LoadingIcon size="xxs" />
      <Text color="secondary" size="micro">
        Loading...
      </Text>
    </>
  );
}

function OptionEmptyContents(props: { message: string }) {
  return (
    <>
      <Text color="secondary" size="micro">
        {props.message}
      </Text>
    </>
  );
}

export default BaseCombobox;
