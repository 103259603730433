import { IWebhookLog } from "@shared/types/WebhookLog";
import classNames from "classnames";
import React from "react";

import { webhookToggleLabels } from "@shared/lib/webhook";
import style from "./WebhookDeliveryHistory.module.css";

const getEventLabel = (event: string) => {
  return webhookToggleLabels[event] || event || "";
};

interface IProps {
  history: IWebhookLog[];
  loading?: boolean;
}

export const WebhookDeliveryHistory = (props: IProps) => {
  return (
    <div className={style.tableWrapper}>
      <table className={style.table}>
        <tbody>
          <tr>
            <th>EVENT</th>
            <th>SENT AT (UTC)</th>
            <th className={style.responseCell}>RESPONSE</th>
          </tr>
          {!props.loading &&
            props.history.map((webhookLog) => (
              <tr key={webhookLog.requestId}>
                <td>{getEventLabel(webhookLog.data?.event || "")}</td>
                <td>{new Date(webhookLog.attemptedAt).toISOString()}</td>
                <td
                  className={classNames({
                    [style.statusCode]: true,
                    [getColorClassForStatusCode(webhookLog.responseStatusCode)]: true,
                  })}
                >
                  {webhookLog.responseStatusCode || "N/A"}
                </td>
              </tr>
            ))}
          {props.loading && (
            <tr>
              <td colSpan={3} className={style.loadingMessage}>
                Loading delivery history...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

function getColorClassForStatusCode(statusCode: number | null) {
  if (statusCode === null) {
    return "";
  }

  if (statusCode >= 200 && statusCode < 300) {
    return style.success;
  }

  return style.error;
}
