import { unlinkComponentActionAtom } from "@/stores/ComponentLinkingFlow";
import {
  detailsPanelSelectionStateAtom,
  editPanelCloseActionAtom,
  editPanelTitleAtom,
  libraryInteractionViewAtom,
  selectedBlockIdAtom,
  selectionTypeAtom,
  singleSelectedComponentNameAtom,
} from "@/stores/ProjectSelection";
import BlockDetails from "@/views/NS/ProjectNS/components/DetailsPanel/BlockDetails";
import LinkComponent from "@/views/NS/ProjectNS/components/DetailsPanel/LinkComponent";
import TextItemDetails from "@/views/NS/ProjectNS/components/DetailsPanel/TextItemDetails";
import VariantsPanel from "@/views/NS/ProjectNS/components/DetailsPanel/VariantsPanel";
import ComponentActions from "@ds/molecules/ComponentActions";
import Scrollbar from "@ds/molecules/Scrollbar";
import TitleRow from "@ds/organisms/DetailsPanelTitleRow";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import EditTabBar from "../EditTabBar";
import PublishToLibraryWrapper from "../PublishToLibraryWrapper";
import TextItemActivity from "../TextItemActivity";
import TextItemComments from "../TextItemComments";
import style from "./style.module.css";

function EditDetailsPanel() {
  const libraryInteractionView = useAtomValue(libraryInteractionViewAtom);
  const selectedComponentName = useAtomValue(singleSelectedComponentNameAtom);
  const editPanelCloseAction = useSetAtom(editPanelCloseActionAtom);
  const title = useAtomValue(editPanelTitleAtom);

  const unlinkComponent = useSetAtom(unlinkComponentActionAtom);
  function handleUnlinkClick() {
    unlinkComponent();
  }

  const closeAction = useCallback(() => {
    editPanelCloseAction();
  }, [editPanelCloseAction]);
  return (
    <>
      <TitleRow title={title} closeAction={closeAction} />
      {selectedComponentName && (
        <ComponentActions componentName={selectedComponentName} onUnlinkClick={handleUnlinkClick} />
      )}
      {libraryInteractionView === null && <EditTabBar />}
      <Scrollbar className={style.scrollArea} disableScrollX>
        <EditDetailsPanelContent />
      </Scrollbar>
    </>
  );
}

function EditDetailsPanelContent() {
  const selectionType = useAtomValue(selectionTypeAtom);
  const selectedTab = useAtomValue(detailsPanelSelectionStateAtom);
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const libraryInteractionView = useAtomValue(libraryInteractionViewAtom);

  if (libraryInteractionView !== null) {
    return (
      <>
        {libraryInteractionView === "PUBLISH" && <PublishToLibraryWrapper />}
        {libraryInteractionView === "LINK" && (
          <Suspense fallback={<LinkComponent.Fallback />}>
            <LinkComponent />
          </Suspense>
        )}
        {libraryInteractionView === "SWAP" && <div>Swap</div>}
      </>
    );
  }

  return (
    <>
      {selectedTab === "EDIT" && (
        <>
          {selectionType === "text" && <TextItemDetails />}
          {selectionType === "block" && selectedBlockId && <BlockDetails selectedBlockId={selectedBlockId} />}
        </>
      )}
      {selectedTab === "ACTIVITY" && <TextItemActivity />}
      {selectedTab === "COMMENTS" && <TextItemComments />}
      {selectedTab === "VARIANTS" && <VariantsPanel />}
    </>
  );
}

export default EditDetailsPanel;
