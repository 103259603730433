import { IFullComponentMergeSuggestion, IListWithCountComponent } from "@shared/types/Component";
import {
  IFTreeItem,
  IGetComponentBlockParams,
  IGetComponentBlockQuery,
  IGetComponentGroupParams,
  IGetComponentGroupQuery,
  IGetComponentLibraryRootQuery,
} from "@shared/types/http/Component";
import { IObjectId } from "@shared/types/lib";
import { createHttpRequest } from "./lib/createHttpRequest";

const url = (path: string) => `/ws_comp${path}`;

export const importCsvData = createHttpRequest<
  {
    data: string[][];
    params: {
      text: number;
      name: number[];
      notes?: number;
      tags?: number;
      status?: number;
      componentId?: number;
      folderId?: string | null;
    };
  },
  {}
>({
  url: url("/importCsvData"),
  method: "post",
  getConfig: ({ data, params }) => ({
    data,
    params,
  }),
});

export const getComponentMergeSuggestions = createHttpRequest<void, { suggestions: IFullComponentMergeSuggestion[] }>({
  method: "get",
  url: url("/merge_suggestions"),
});

export const ignoreComponentMergeSuggestion = createHttpRequest<{ key: string }, boolean>({
  method: "put",
  getUrl: ({ key }) => url(`/merge_suggestions/ignore/${key}`),
  config: { data: { value: true } },
});

export const unignoreComponentMergeSuggestion = createHttpRequest<{ key: string }, boolean>({
  method: "put",
  getUrl: ({ key }) => url(`/merge_suggestions/ignore/${key}`),
  config: { data: { value: false } },
});

export const acceptComponentMergeSuggestion = createHttpRequest<
  { key: string; targetComponentId: string },
  // [mergedComponent, componentIdsRemoved]
  [IListWithCountComponent, string[]]
>({
  method: "put",
  getUrl: ({ key }) => url(`/merge_suggestions/accept/${key}`),
  getConfig: ({ targetComponentId }) => ({ data: { targetComponentId } }),
});

// TODO: find out best place to actually store this interface and import accordingly
export interface BulkSuggestion {
  suggestionKey: string;
  targetComponentId: string;
}

export const bulkAcceptComponentMergeSuggestion = createHttpRequest<{
  bulkSuggestions: BulkSuggestion[];
}>({
  method: "put",
  url: url(`/bulk_merge_suggestions/accept`),
  getConfig: ({ bulkSuggestions }) => ({ data: { bulkSuggestions } }),
});

export const bulkIgnoreComponentMergeSuggestions = createHttpRequest<{
  bulkSuggestionKeys: string[];
}>({
  method: "put",
  url: url(`/bulk_merge_suggestions/ignore`),
  getConfig: ({ bulkSuggestionKeys }) => ({ data: { bulkSuggestionKeys } }),
});

export const generateNameSuggestion = createHttpRequest<{ text: string }, { name: string }>({
  method: "post",
  url: url(`/suggest-name`),
  getConfig: ({ text }) => ({ data: { text } }),
});

/**
 * Encodes the given params into a URL query string for filtering component
 * queries.
 */
const getComponentFilterUrl = (basePath: string) => (params: object) => {
  const q = new URLSearchParams();
  for (const key in params) {
    if (params[key] === undefined) continue;

    if (Array.isArray(params[key])) {
      params[key].forEach((value) => q.append(key, value));
      continue;
    }

    q.append(key, params[key]);
  }

  return url(`${basePath}?${q.toString()}`);
};

interface IComponentFilters {
  search?: string;
  name?: string;
  folder_id?: string | null;
  folder_ids?: string[];
  status?: string;
  tags?: string[];
  variantId?: string;
  componentIds?: string[];
  componentType?: string;
  isSample?: boolean;
}

export const loadPagedComponents = createHttpRequest<
  IComponentFilters & { page: number; limit: number },
  { components: IListWithCountComponent[]; count: number; page: number; folderId: string | null }
>({
  method: "get",
  getUrl: getComponentFilterUrl("/loadPagedComponents"),
});

export const loadComponentCount = createHttpRequest<IComponentFilters, { count: number }>({
  method: "get",
  getUrl: getComponentFilterUrl("/loadComponentCount"),
});

export const loadComponentTags = createHttpRequest<IComponentFilters, { _id: string; total: number }[]>({
  method: "get",
  getUrl: getComponentFilterUrl("/loadComponentTags"),
});

export const getComponentLibraryRoot = createHttpRequest<IGetComponentLibraryRootQuery, { children: IFTreeItem[] }>({
  method: "get",
  getUrl: ({ folderId }) => url(`/libraryRoot?folderId=${folderId ?? ""}`),
});

export const getComponentGroup = createHttpRequest<
  IGetComponentGroupQuery & IGetComponentGroupParams,
  { children: IFTreeItem[] }
>({
  method: "get",
  getUrl: ({ groupName, folderId }) => url(`/group/${groupName}?folderId=${folderId ?? ""}`),
});

export const getComponentBlock = createHttpRequest<
  IGetComponentBlockQuery & IGetComponentBlockParams,
  { children: IFTreeItem[] }
>({
  method: "get",
  getUrl: ({ groupName, blockName, folderId }) =>
    url(`/group/${groupName}/block/${blockName}?folderId=${folderId ?? ""}`),
});

export const getPageForGroup = createHttpRequest<{ groupName: string; folderId: string }, { page: number }>({
  method: "get",
  getUrl: ({ groupName, folderId }) => url(`/pageForGroup/${groupName}?folderId=${folderId ?? ""}`),
});

export const getPageForGroupBlock = createHttpRequest<
  {
    groupName: string;
    blockName: string;
    folderId: string;
  },
  { page: number }
>({
  method: "get",
  getUrl: ({ groupName, blockName, folderId }) =>
    url(`/pageForGroupBlock/${groupName}/${blockName}?folderId=${folderId ?? ""}`),
});

export const mergeComponents = createHttpRequest<
  {
    componentIds: string[];
    targetId: string;
  },
  void
>({
  method: "post",
  url: url(`/merge`),
  getConfig: ({ componentIds, targetId }) => ({
    data: {
      componentIds,
      targetId,
    },
  }),
});

export const loadUpdatedComponents = createHttpRequest<
  {
    componentIds: IObjectId[];
  },
  {
    components: IListWithCountComponent[];
  }
>({
  method: "get",
  url: url(`/loadUpdatedComponents`),
  getConfig({ componentIds }) {
    return {
      params: { componentIds },
    };
  },
});
