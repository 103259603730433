import { client } from "@/http/lib/dittoClient";
import { componentFamilyAtom, nullableComponentFamilyAtom, updateComponentActionAtom } from "@/stores/Components";
import { DEFAULT_RICH_TEXT } from "@shared/common/constants";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { cacheAtom } from "@shared/frontend/stores/cacheAtom";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { ZTextItemFilterableFields } from "@shared/types/TextItem";
import logger from "@shared/utils/logger";
import ObjectId from "bson-objectid";
import { atom } from "jotai";
import { derive } from "jotai-derive";
import { projectIdAtom, textItemFamilyAtom } from "./Project";
import {
  clearLibraryInteractionViewActionAtom,
  derivedOnlySelectedTextItemAtom,
  detailsPanelSelectionStateAtom,
  selectedTextItemIdsAtom,
} from "./ProjectSelection";
import { showToastActionAtom } from "./Toast";

export const { valueAtom: componentFoldersAtom } = asyncMutableDerivedAtom<ILibraryComponentFolder[]>({
  async loadData(get) {
    const data = await client.libraryComponentFolder.getLibraryComponentFolders({} as never);
    return data.folders;
  },
  debugLabel: "Component Folders For Linking",
});

export const searchQueryAtom = atom("");
export const _componentIdsForLinkingAtom = cacheAtom(
  atom(async (get) => {
    get(selectedTextItemIdsAtom); // we add this just as a dependency to force a refetch

    const folderId = get(selectedLibraryFolderIdAtom);
    const data = await client.libraryComponent.getLibraryComponents({
      folderId: folderId ?? undefined,
    });
    return data.components;
  })
);
export const componentIdsForLinkingAtom = derive(
  [searchQueryAtom, _componentIdsForLinkingAtom],
  (searchQuery, components) => {
    return components.filter((component) => component.name.includes(searchQuery)).map((component) => component._id);
  }
);

export const selectedLibraryFolderIdAtom = atom<string | null>(null);

export const selectedFolderAtom = derive(
  [selectedLibraryFolderIdAtom, componentFoldersAtom],
  async (folderId, folders) => {
    if (!folderId) return null;
    return folders.find((folder) => folder._id === folderId) ?? null;
  }
);

export const selectedComponentIdAtom = atom<string | null>(null);
export const selectedComponentAtom = atom((get) => {
  const componentId = get(selectedComponentIdAtom);
  if (!componentId) return null;
  return get(componentFamilyAtom(componentId));
});

export const linkComponentActionAtom = atom(null, async (get, set) => {
  const componentId = get(selectedComponentIdAtom);
  const selectedTextItemIds = get(selectedTextItemIdsAtom);
  const selectedTextItems = await Promise.all(
    selectedTextItemIds.map((textItemId) => get(textItemFamilyAtom(textItemId)))
  );
  const component = await get(nullableComponentFamilyAtom(componentId));
  const projectId = get(projectIdAtom);
  if (!componentId || !component || !projectId || !selectedTextItemIds.length) return;

  // create deep copes of the text items so we can revert the changes if the backend update fails
  const originalTextItems = selectedTextItems.map((textItem) => ({
    ...textItem,
  }));

  // perform frontend state updates
  selectedTextItems.forEach((textItem) => {
    const updatedTextItem = { ...textItem, ws_comp: componentId };

    // set text and metadata to match component -- this Zod schema determines which fields are always
    // kept synced between text items and components.
    const sharedFields = ZTextItemFilterableFields.shape;
    for (const key in sharedFields) {
      updatedTextItem[key] = component[key];
    }

    set(textItemFamilyAtom(textItem._id), updatedTextItem);
  });

  set(updateComponentActionAtom, {
    _id: componentId,
    update: {
      ...component,
      instances: component.instances.concat(selectedTextItemIds),
    },
  });

  // switch page back to edit
  set(detailsPanelSelectionStateAtom, "EDIT");
  set(clearLibraryInteractionViewActionAtom);
  set(selectedComponentIdAtom, null);

  // backend update
  try {
    await client.libraryComponent.linkTextItems({
      componentId,
      projectId,
      textItemIds: selectedTextItemIds,
    });
    set(showToastActionAtom, { message: "Linked text item to component" });
  } catch (e) {
    // revert frontend state updates
    for (const textItem of originalTextItems) {
      set(textItemFamilyAtom(textItem._id), textItem);
    }
    set(updateComponentActionAtom, {
      _id: componentId,
      update: {
        ...component,
        instances: component.instances.filter((instance) => !selectedTextItemIds.includes(instance)),
      },
    });

    set(showToastActionAtom, { message: "Something went wrong linking this component" });
    logger.error(
      "Error linking component",
      { context: { componentId, projectId, textItemIds: selectedTextItemIds } },
      e
    );
    return;
  }
});

export const unlinkComponentActionAtom = atom(null, async (get, set) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return;
  const textItem = await get(derivedOnlySelectedTextItemAtom);
  if (!textItem) return;
  const component = await get(nullableComponentFamilyAtom(textItem.ws_comp));
  if (!component) return;

  // create deep copy of the text item so we can revert the changes if the backend update fails
  const originalTextItem = { ...textItem };

  // perform frontend state updates
  set(textItemFamilyAtom(textItem._id), {
    ...textItem,
    ws_comp: null,
  });

  set(updateComponentActionAtom, {
    _id: component._id,
    update: {
      ...component,
      instances: component.instances.filter((instance) => instance !== textItem._id),
    },
  });

  // finally, perform the backend update
  try {
    await client.libraryComponent.unlinkTextItems({
      componentId: component._id,
      projectId,
      textItemIds: [textItem._id],
    });
    set(showToastActionAtom, { message: "Component unlinked successfully" });
  } catch (e) {
    // revert frontend state updates
    set(textItemFamilyAtom(textItem._id), originalTextItem);
    set(updateComponentActionAtom, {
      _id: component._id,
      update: {
        ...component,
        instances: component.instances.concat(textItem._id),
      },
    });

    set(showToastActionAtom, { message: "Something went wrong unlinking this component" });
    logger.error(
      "Error unlinking component",
      {
        context: {
          componentId: component._id,
          projectId,
          textItemIds: [textItem._id],
        },
      },
      e
    );
    return;
  }
});

function getNewComponentObject(override: Partial<ILibraryComponent>): ILibraryComponent {
  const id = new ObjectId();
  return {
    _id: id.toString(),
    instances: [],
    workspaceId: "",
    name: "",
    apiId: "",
    commentThreads: [],
    folderId: null,
    assignedAt: null,
    editedAt: null,
    editedBy: null,
    characterLimit: null,
    sortKey: "",
    status: "NONE",
    assignee: null,
    text: "",
    rich_text: DEFAULT_RICH_TEXT,
    notes: null,
    tags: [],
    variables: [],
    plurals: [],
    variants: [],
    ...override,
  };
}

export const publishComponentActionAtom = atom(
  null,
  async (get, set, data: { name: string; textItemId: string; folderId?: string }) => {
    const textItem = await get(textItemFamilyAtom(data.textItemId));
    if (!textItem) {
      throw new Error("Cannot publish component without selected text item");
    }

    // create deep copy of the text item so we can revert the changes if the backend update fails
    const originalTextItem = { ...textItem };

    const newComponentObject = getNewComponentObject({
      name: data.name,
      instances: [data.textItemId],
      folderId: data.folderId ?? null,
      workspaceId: textItem.workspace_ID,
    });

    // frontend updates
    for (const key in ZTextItemFilterableFields.shape) {
      newComponentObject[key] = textItem[key];
    }

    componentFamilyAtom(newComponentObject._id, newComponentObject);

    // we need to do this set *after* the componentFamilyAtom set above, so that the text item in the frontend will
    // properly resolve to the new component object.
    set(textItemFamilyAtom(data.textItemId), {
      ...textItem,
      ws_comp: newComponentObject._id,
    });

    // backend update
    try {
      await client.libraryComponent.publish({
        name: data.name,
        folderId: data.folderId,
        textItemId: data.textItemId,
        newComponentId: newComponentObject._id,
      });
      set(showToastActionAtom, { message: "Published new component to library!" });
    } catch (error) {
      // revert frontend state updates
      set(textItemFamilyAtom(data.textItemId), originalTextItem);

      set(showToastActionAtom, { message: "Something went wrong publishing a new component" });
      logger.error("Error publishing component", { context: { data } }, error);
      return;
    }
  }
);
