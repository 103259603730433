import { IWorkspaceSlackAuthenticated } from "../../../shared/types/Workspace";
import { IRecentCard } from "../../../shared/types/http/Homepage";

interface GettingStartedPanelCardsToShow {
  user: { job: string };
  recentsPanelProps: { loading: boolean; cards: IRecentCard[] };
  projectsCount: { value: number } | undefined;
  componentsCount: { value: number } | undefined;
  workspaceInfo: Pick<IWorkspaceSlackAuthenticated, "metrics">;
  userHasBillingRole: (role: string) => boolean;
}

interface GettingStartedPanelCardsToShowReturn {
  displayImportDesignFile: boolean;
  displayViewAllProjectsButton: boolean;
  displayImportStrings: boolean;
  developerView: boolean;
}

export function gettingStartedPanelCardsToShow(
  args: GettingStartedPanelCardsToShow
): GettingStartedPanelCardsToShowReturn {
  // projectsCount or componentsCount being undefined means that data isn't ready yet
  // so we don't show the panel
  if (!args.projectsCount || !args.componentsCount) {
    return {
      displayImportDesignFile: false,
      displayViewAllProjectsButton: false,
      displayImportStrings: false,
      developerView: false,
    };
  }

  const developerView = args.user.job === "ENGINEER";

  const hasProjects = Boolean(args.projectsCount && args.projectsCount.value > 0);
  const hasComponents = Boolean(args.componentsCount && args.componentsCount.value > 0);
  const hasRecents = !args.recentsPanelProps.loading && args.recentsPanelProps.cards.length > 0;

  const showTwoPanels = (!hasProjects || !hasComponents) && args.userHasBillingRole("editor");

  const wsNeverCLImport = Boolean(args.workspaceInfo?.metrics?.firstComponentFileImport === null);
  const wsLessThanFiftyComponents = Boolean(args.componentsCount && args.componentsCount.value < 50);
  const showOnePanel =
    developerView &&
    (wsNeverCLImport || wsLessThanFiftyComponents) &&
    args.workspaceInfo?.metrics.firstApiFetch === null;

  return {
    displayImportDesignFile: showTwoPanels,
    displayViewAllProjectsButton: args.userHasBillingRole("editor") && !hasRecents && hasProjects,
    displayImportStrings: showTwoPanels || showOnePanel,
    developerView,
  };
}
