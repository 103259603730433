import { z } from "zod";

export const ZCreateFigmaAuthRequest = {
  body: z.object({
    code: z.string(),
    redirectUri: z.string(),
  }),
};
export const ZCreateFigmaAuthResponse = z.unknown();

export const ZUpdateUserProfilePictureRequest = {
  body: z.object({
    picture: z.string().optional().nullable(),
  }),
};
export const ZUpdateUserProfilePictureResponse = z.unknown();

export const ZMarkNotifAsReadRequest = {
  params: z.object({ notificationId: z.string() }),
};
export const ZMarkNotifAsReadResponse = z.unknown();
