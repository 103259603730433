import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import UndoIcon from "@mui/icons-material/Undo";
import { IFullComponentMergeSuggestion } from "@shared/types/Component";
import React, { useMemo, useState } from "react";
import Comp from "../comp";

import { PropagationWrapper } from "@shared/frontend/PropagationWrapper";
import classNames from "classnames";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import style from "./ComponentMergeSuggestion.module.css";

type Props = {
  ignored: boolean;
  suggestion: IFullComponentMergeSuggestion;
  onReviewClick: () => void;
  onMergeClick: (targetComponentId: string) => Promise<void>;
  onIgnoreClick: () => void;
  onUnignoreClick: () => void;
  onSelectComponent?: (componentId: string) => void;
  className?: string;
  isSelected: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  index: number;
  multiSelectedSuggestions: IFullComponentMergeSuggestion[];
  setMultiSelectedSuggestions: (suggestions: IFullComponentMergeSuggestion[]) => void;
  handleSelected: (suggestion: any) => void;
};

const ComponentMergeSuggestion = (props: Props) => {
  const { suggestion } = props;

  // The spec calls for the components with the most instances
  // to be displayed first.
  const sortedComponents = useMemo(
    () => suggestion.components.sort((a, b) => b.instanceCount - a.instanceCount),
    [suggestion.components]
  );

  // The component that is selected when the user clicks "Merge"
  // is the component that all others in the suggestion will merge into.
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(0);

  const selectedComponent = sortedComponents[selectedComponentIndex];
  if (!selectedComponent) {
    throw new Error("Out of bounds");
  }

  const updateTargetComponentId = (newIndex) => {
    const selectedSuggestions = [...props.multiSelectedSuggestions];
    let existingSuggestionIndex = selectedSuggestions.findIndex((sugg) => sugg.key === suggestion.key);
    selectedSuggestions.splice(existingSuggestionIndex, 1);
    const newMultiSuggestion = {
      ...suggestion,
      targetComponentId: sortedComponents[newIndex]._id,
    };
    props.setMultiSelectedSuggestions([...selectedSuggestions, newMultiSuggestion]);
  };

  const nextDisabled = selectedComponentIndex === sortedComponents.length - 1;
  const onNextClick = () => {
    const newIndex = Math.min(selectedComponentIndex + 1, sortedComponents.length - 1);
    setSelectedComponentIndex(newIndex);
    props.onSelectComponent?.(sortedComponents[newIndex]._id);
    updateTargetComponentId(newIndex);
  };

  const previousDisabled = selectedComponentIndex === 0;
  const onPreviousClick = () => {
    const newIndex = Math.max(selectedComponentIndex - 1, 0);
    setSelectedComponentIndex(newIndex);
    props.onSelectComponent?.(sortedComponents[newIndex]._id);
    updateTargetComponentId(newIndex);
  };

  const [hoveringUnignore, setHoveringUnignore] = useState(false);

  const isChecked = props.multiSelectedSuggestions.some((s) => s.key === suggestion.key);

  const renderAsSelected = isChecked || props.isSelected || hoveringUnignore;

  const onReviewClick = () => {
    props.onReviewClick();
    props.onSelectComponent?.(selectedComponent._id);
  };

  const onMergeClick = async () => {
    await props.onMergeClick(selectedComponent._id);
  };

  const onCheckClick = () => {
    props.handleSelected(suggestion);
  };

  const mergeButtonText = props.isLoading ? "Merging..." : "Merge into this component";

  function handleMouseEnter() {
    if (props.ignored) setHoveringUnignore(true);
  }

  function handleMouseLeave() {
    if (props.ignored) setHoveringUnignore(false);
  }

  return (
    <div
      className={classNames(props.className, {
        [style.container]: true,
        [style.isSelected]: renderAsSelected,
        [style.isIgnored]: props.ignored,
        [style.isHoveringUnignore]: props.ignored && hoveringUnignore,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={style.header}>
        <span
          className={classNames({
            [style.identicalComponentsLabel]: true,
            [style.identicalComponentsLabelSelected]: renderAsSelected,
          })}
        >
          <i>{sortedComponents.length}</i> identical components
        </span>
        {renderAsSelected && (
          <div className={style.controls}>
            <ChevronLeftIcon
              onClick={onPreviousClick}
              className={classNames({
                [style.control]: true,
                [style.controlDisabled]: previousDisabled,
              })}
            />
            {selectedComponentIndex + 1} of {sortedComponents.length}
            <ChevronRightIcon
              onClick={onNextClick}
              className={classNames({
                [style.control]: true,
                [style.controlDisabled]: nextDisabled,
              })}
            />
          </div>
        )}

        {!props.ignored ? (
          <PropagationWrapper>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={onCheckClick}
              className={classNames(style.multiSelectCheckbox, {
                [style.checked]: isChecked,
              })}
            />
          </PropagationWrapper>
        ) : null}
      </div>
      <div className={style.content} onClick={onReviewClick}>
        <Comp
          noId
          compType={props.suggestion.type as any}
          hideCompName={!renderAsSelected}
          className={classNames({
            [style.compOverride]: true,
            [style.compOverrideSelected]: renderAsSelected,
          })}
          setPanelState={() => null}
          setCommentState={() => null}
          is_ws_comp
          ws_comp_id={selectedComponent._id}
          compName={selectedComponent.name}
          is_suggested={false}
          displayApiIds={renderAsSelected}
          is_selected={renderAsSelected}
          apiID={selectedComponent.apiID}
          isDraft={false}
          component={{
            _id: selectedComponent._id,
            text: suggestion.text,
            variants: selectedComponent.variants as any,
            comment_threads: [],
            notes: suggestion.notes,
            status: suggestion.status,
            tags: selectedComponent.tags,
            plurals: selectedComponent.plurals,
          }}
          importVariantPreview={null}
          selectComp={() => null}
        />
      </div>
      <div className={style.footer}>
        {!isChecked && !props.ignored && (
          <>
            {!props.isLoading && (
              <ButtonSecondary text="Ignore" onClick={props.onIgnoreClick} className={style.ctaSecondary} />
            )}
            {!isChecked ? (
              props.isSelected ? (
                <ButtonPrimary
                  disabled={props.isDisabled}
                  text={mergeButtonText}
                  onClick={onMergeClick}
                  className={style.ctaPrimary}
                  loading={props.isLoading}
                />
              ) : (
                <ButtonPrimary text="Review" onClick={onReviewClick} className={style.ctaPrimary} />
              )
            ) : null}
          </>
        )}
        {props.ignored && (
          <button className={style.unignoreButton} onClick={props.onUnignoreClick}>
            <UndoIcon /> Unignore
          </button>
        )}
      </div>
    </div>
  );
};

export default ComponentMergeSuggestion;
