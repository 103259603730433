import z from "zod";
import {
  ActualComponentInterface,
  ITextItemFilterableFields,
  ZActualComponentStatus,
  ZTextItemPlural,
  ZTextItemVariant,
} from "./TextItem";
import { ZCreatableId, ZObjectId } from "./lib";

import { CreateableId } from "../../schema/lib";
import { filterableFields as TextItemFilterableFields } from "./TextItem";
import { IVariant } from "./Variant";

export const ZComponent = z.object({
  _id: ZObjectId,
  instances: z.array(ZObjectId),
  workspace_ID: ZCreatableId,
  name: z.string(),
  apiID: z.string(),
  comment_threads: z.array(ZObjectId),
  folder_id: ZObjectId.nullable(),
  isSample: z.boolean(),
  edited_at: z.date().nullable().optional(),
  edited_by: ZObjectId.nullable().optional(),
  type: z.enum(["standard", "template"]),
  componentName: z.string(),
  groupName: z.string(),
  blockName: z.string(),
  ...TextItemFilterableFields,
});
export type IComponent = z.infer<typeof ZComponent>;

export type ComponentType = "standard" | "template";

export type ComponentPopulatedInterface<IdType> = {
  _id: IdType;
  instances: ActualComponentInterface<IdType>[];
  workspace_ID: IdType;
  type: ComponentType;
  name: string;
  apiID: string;
  comment_threads: IdType[];
  folder_id: IdType | null;
  isSample: boolean;
  variants: IVariant[];
} & ITextItemFilterableFields;

/**
 * This is the component merge suggestion type that corresponds to the
 * `ComponentMergeSuggestion` schema in the database. It is streamlined
 * to include only the data that is necessary for ignoring and accepting
 * suggestions.
 *
 * See `ZFullComponentMergeSuggestion` for the type that is used when
 * generating suggestions and when working with them on the front-end.
 */
export const ZComponentMergeSuggestion = z.object({
  _id: ZObjectId,
  workspace_id: ZObjectId,
  user_id: ZObjectId,
  component_ids: z.array(ZObjectId),
  key: z.string(),
  ignored: z.boolean(),
});
export type IComponentMergeSuggestion = z.infer<typeof ZComponentMergeSuggestion>;

export const ZComponentMergeSuggestionCreatable = ZComponentMergeSuggestion.omit({
  _id: true,
});
export type IComponentMergeSuggestionCreatable = z.infer<typeof ZComponentMergeSuggestionCreatable>;

export const ZFullComponentInMergeSuggestion = z.object({
  _id: ZObjectId,
  name: z.string(),
  apiID: z.string().nullable(),
  instanceCount: z.number(),
  folder_id: ZObjectId.nullable().optional(),
  // Aggregation needs to include any data here
  // that needs to be hashed and compared in-memory
  variants: z.array(
    z.object({
      variantId: ZObjectId,
      text: z.string(),
    })
  ),
  plurals: z.array(
    z.object({
      form: z.string(),
      text: z.string(),
    })
  ),
  tags: z.array(z.string()),
});
export type IFullComponentInMergeSuggestion = z.infer<typeof ZFullComponentInMergeSuggestion>;

/**
 * This is the component merge suggestion type that is used in two places:
 * 1. when generating the merge suggestions
 * 2. when working with the merge suggestions on the front-end
 *
 * See `ZComponentMergeSuggestion` for the type of the merge suggsetion
 * that is stored in the database.
 */
export const ZFullComponentMergeSuggestion = z.object({
  key: z.string(),
  text: z.string(),
  ignored: z.boolean(),
  notes: z.string().nullable(),
  status: z.string(),
  assignee: z.any().nullable(),
  type: z.string(),
  components: z.array(ZFullComponentInMergeSuggestion),
  targetComponentId: ZObjectId.nullable().optional(),
  // used for bulk merge on the FE
});
export type IFullComponentMergeSuggestion = z.infer<typeof ZFullComponentMergeSuggestion>;

export const ZListWithCountComponent = z.object({
  _id: ZObjectId,
  type: z.enum(["standard", "template"]),
  workspace_ID: ZObjectId,
  name: z.string(),
  apiID: z.string(),
  comment_threads: z.array(z.object({ _id: ZObjectId })),
  instances: z.array(z.any()).length(1),
  folder_id: ZObjectId.nullable(),
  folder: z.any().nullable(),
  isDraft: z.boolean(),
  isSample: z.boolean(),
  variants: z.array(ZTextItemVariant),
  variables: z.array(z.any()),
  plurals: z.array(z.any()),
  status: z.string(),
});

export type IListWithCountComponent = z.infer<typeof ZListWithCountComponent>;

export const ZUpdatedComponent = ZListWithCountComponent.extend({
  variants: z.array(z.any()),
});

const BaseCreateableComponent = z.object({
  apiID: z.string(),
  name: z.string(),
  workspace_ID: CreateableId,
  notes: z.string().nullable().optional(),
  tags: z.array(z.string()).nullable().optional(),
  status: z.string().nullable().optional(),
  folder_id: z.string().nullable().optional(),
  characterLimit: z.number().nullable().optional(),
  plurals: z.array(ZTextItemPlural).optional().nullable(),
});

export const CreateableComponentWithText = BaseCreateableComponent.extend({
  text: z.string(),
});

export const CreateableComponentWithBaseText = BaseCreateableComponent.extend({
  baseText: z.string(),
  variantText: z.string().optional(),
  variantRichText: z.any(),
  variantVariables: z.array(z.any()).optional().nullable(),
  variantPlurals: z.array(ZTextItemPlural).optional().nullable(),
  variantStatus: ZActualComponentStatus.optional().nullable(),
});

export const CreateableComponent = CreateableComponentWithText.or(CreateableComponentWithBaseText);
export type CreateableComponent = z.infer<typeof CreateableComponent>;
export type CreateableComponentWithText = z.infer<typeof CreateableComponentWithText>;
export type CreateableComponentWithBaseText = z.infer<typeof CreateableComponentWithBaseText>;

export const ZComponentNames = z.object({
  groupName: z.string(),
  blockName: z.string(),
  componentName: z.string(),
});

export type IComponentNames = z.infer<typeof ZComponentNames>;

export const ZComponentNamesMap = z.record(z.string(), ZComponentNames);
export type IComponentNamesMap = z.infer<typeof ZComponentNamesMap>;
