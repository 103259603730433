import { z } from "zod";
import { ZActualComponentVariableSchema, ZTipTapRichText } from "./TextItem";
import { ZObjectId } from "./lib";

export const ZComment = z.object({
  _id: ZObjectId,
  user_name: z.string(),
  user_id: z.string(),
  text: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type IComment = z.infer<typeof ZComment>;

export const ZBaseCommentThread = z.object({
  _id: ZObjectId,
  comp_id: ZObjectId.nullable(),
  ws_comp_id: ZObjectId.nullable(),
  figma_node_id: z.string().nullable(),
  doc_id: ZObjectId.nullable(),
  workspace_id: ZObjectId,
  comments: z.array(ZComment),
  subscribed_users: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
  is_resolved: z.boolean(),
  resolved_by: z
    .object({
      user_id: ZObjectId,
      user_name: z.string(),
    })
    .nullable(),
  isSample: z.boolean(),
});

export const ZCommentType = z.enum(["standard", "suggestion"]);
export type ICommentType = z.infer<typeof ZCommentType>;

const ZCommentThreadStandard = ZBaseCommentThread.extend({
  type: z.literal("standard"),
});

export type ICommentThreadStandard = z.infer<typeof ZCommentThreadStandard>;

export const ZSuggestion = z.object({
  text_before: z.string(),
  text_after: z.string(),
  rich_text_before: ZTipTapRichText,
  rich_text_after: ZTipTapRichText,
  variables: z.array(ZActualComponentVariableSchema),
  accepted: z.boolean().nullable(),
});

export type ISuggestion = z.infer<typeof ZSuggestion>;

const ZCommentThreadSuggestion = ZBaseCommentThread.extend({
  type: z.literal("suggestion"),
  suggestion: ZSuggestion.nullable(),
});

export type ICommentThreadSuggestion = z.infer<typeof ZCommentThreadSuggestion>;

export const ZCommentThread = z.discriminatedUnion("type", [ZCommentThreadStandard, ZCommentThreadSuggestion]);
export type ICommentThread = z.infer<typeof ZCommentThread>;
