import { IComponentsApiIdGenerationConfig, IProjectsApiIdGenerationConfig } from "@shared/types/Workspace";

export const UNDERSCORE_REGEX = /^[_-]+$/;
export const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export type Applicator = (value: string) => string;

export const tryToParseRegex = (str: string, flags?: string): RegExp | null => {
  try {
    return new RegExp(str, flags);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const DEFAULT_COMPONENTS_CONFIG: IComponentsApiIdGenerationConfig = {
  acceptedCharsPattern: "a-zA-Z0-9-_",
  casingAdjustment: "lower",
  slashReplacement: ".",
  spaceReplacement: "-",
};

export const DEFAULT_PROJECTS_CONFIG: IProjectsApiIdGenerationConfig = {
  optOutHumanReadable: false,
  template: "{{groupName}}{{blockName}}{{text}}",
  spaceReplacement: "-",
  acceptedCharsPattern: "a-zA-Z0-9-_",
  casingAdjustment: "lower",
  separator: "-",
  maxLength: 60,
};

/**
 *
 * @param str
 * @param delimiters
 * @param upper determines whether or not this should be "upper camelcase" (aka pascal case); defaults to `false`
 * @returns
 */
export function camelCaseString(str: string, isDelimiter: (char: string, index: number) => boolean, upper = false) {
  const fnKey = upper ? "toUpperCase" : "toLowerCase";

  let result = "";

  for (let i = 0; i < str.length; i++) {
    // if we're at the beginning of the string, adjust the character
    if (i === 0) {
      result += str[i][fnKey]();
      continue;
    }

    // if we're at the delimiter, increment i until we're not,
    // then capitalize the next character
    if (isDelimiter(str[i], i)) {
      while (isDelimiter(str[i], i)) {
        result += str[i];
        i++;
      }
      if (str[i]) result += str[i].toUpperCase();
      continue;
    }

    // default to appending lowercase string
    result += str[i].toLowerCase();
  }

  return result;
}
