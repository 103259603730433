import { routes } from "@/defs";
import { componentFolderFamilyAtom } from "@/stores/ComponentFolders";
import NavItem from "@ds/molecules/NavItem";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import { ILibraryComponentFolderItem } from "@shared/types/Library";
import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";

function LibraryComponentFolderNavItem(props: { item: ILibraryComponentFolderItem; options: any; atom: any }) {
  const history = useHistory();
  const folder = useAtomValue(componentFolderFamilyAtom(props.item._id));

  const handleFolderClick = useCallback(
    function _handleFolderClick() {
      history.push(routes.nonNavRoutes.library.getPath(props.item._id));
    },
    [history, props.item._id]
  );

  return (
    <NavItem
      onClick={handleFolderClick}
      id={props.item._id}
      type="item"
      label={folder.name}
      icon={<FolderIcon className={style.folderIcon} />}
      dragAndDrop={{ isDragDisabled: true }}
    />
  );
}

export default LibraryComponentFolderNavItem;
