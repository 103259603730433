import { ZConnectionWaitlistInfo, ZFeatureFlags, ZWorkspaceWebhookEndpoint } from "@shared/types/Workspace";
import { z } from "zod";

export const ZUpdateConnectionsWaitlistRequest = {
  body: z.object({
    waitlist: ZConnectionWaitlistInfo,
  }),
};
export const ZUpdateConnectionsWaitlistResponse = z.unknown();

export const ZUpdateWebhookSigningKeyRequest = {
  body: z.object({
    signingKey: z.string(),
  }),
};
export const ZUpdateWebhookSigningKeyResponse = z.unknown();

export const ZDeleteWebhookEndpointRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZDeleteWebhookEndpointResponse = z.unknown();

export const ZUpdateWebhookEndpointRequest = {
  params: z.object({
    id: z.string(),
  }),
  body: z
    .object({
      url: ZWorkspaceWebhookEndpoint.shape.url,
      name: ZWorkspaceWebhookEndpoint.shape.name,
      events: ZWorkspaceWebhookEndpoint.shape.events,
      filters: ZWorkspaceWebhookEndpoint.shape.filters,
    })
    .partial(),
};
export const ZUpdateWebhookEndpointResponse = z.unknown();

export const ZSendWebhookTestRequestRequest = {
  body: z.object({
    webhookId: z.string(),
    url: z.string(),
  }),
};
export const ZSendWebhookTestRequestResponse = z.unknown();

export const ZValidateWebhookUrlRequest = {
  query: z.object({
    url: z.string(),
    webhookId: z.string(),
  }),
};
export const ZValidateWebhookUrlResponse = z.unknown();

export const ZCreateSampleRequest = {
  body: z.object({ figmaFileId: z.string().optional() }),
};
export const ZCreateSampleResponse = z.unknown();

export const ZTurnOnFeatureFlagRequest = {
  body: z.object({
    flag: ZFeatureFlags,
  }),
};
export const ZTurnOnFeatureFlagResponse = z.unknown();

export const ZTurnOffFeatureFlagRequest = {
  body: z.object({
    flag: ZFeatureFlags,
  }),
};
export const ZTurnOffFeatureFlagResponse = z.unknown();
