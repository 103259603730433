import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import { getSegmentTraitsForUser } from "@shared/common/segment";
import { useEffect } from "react";

/**
 * Requires to be ran in an authenticated context
 * This will track non-anonymous users
 */
function useIdentify() {
  const authContext = useAuthenticatedAuth();
  const workspaceContext = useWorkspace();

  useEffect(() => {
    window.analytics.identify(
      authContext.user.userId,
      getSegmentTraitsForUser(authContext.user, workspaceContext.workspaceInfo)
    );
  }, [authContext.user, workspaceContext.workspaceInfo]);
}

function useAnonymousIdentify() {
  useEffect(() => {
    window.analytics.identify();
  }, []);
}

export { useAnonymousIdentify, useIdentify };
