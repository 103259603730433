import { NotificationContext } from "@/store/notificationContext";
import { useWorkspace } from "@/store/workspaceContext";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import RestoreIcon from "@mui/icons-material/Restore";
import { AutomatedChangeItem } from "@shared/frontend/AutomatedChangeItem";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { ENTRY_TYPES, getDittoComponentIdByComponentCreationType } from "@shared/types/ActualChange";
import classnames from "classnames";
import { DittoComponent } from "ditto-react";
import isEqual from "lodash.isequal";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import { PANELS } from "../../defs";
import { useHasProjectFeatureFlag } from "../../hooks/useHasProjectFeatureFlag";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { ComponentLabel } from "../activity-column";
import { ResolvedSuggestionActivityHeader } from "../comment-editor/SuggestionResolution";
import CommentEditor from "../comment-editor/comment-editor";
import CommentThread from "../commentthread/commentthread";
import EditDiff from "../editdiff/editdiff";
import { GrayWarning } from "../shared/GrayWarning";
import Spinner from "../spinner/spinner";
import style from "./style.module.css";

const CompHistory = ({
  isWsComp,
  selectedId,
  compHistoryLoading,
  origComp,
  history,
  doc_ID,
  doc_name,
  fetchAllComps,
  selectedFigmaId,
  setCompHistory,
  handleDocUpdate,
  handleHistoryUpdate,
  commentStateThreadId,
  setCommentState,
  fetchCompInfo,
  forceShowToast,
  workspaceUsers,
  refreshLibraryHistory,
  selectedVariant,
  setPanelState,
  updateCompResultComments,
  isDraftHistory = false,
  onResolveSuggestion,
  showSuggestionComponentWarning,
  isLockedProject,
  setSyncSettingsModalVisible,
  hideCommentEditor = false,
}) => {
  const page_history = useHistory();
  const [threadOpen, setThreadOpen] = useState(null);
  const { fetchNotifications } = useContext(NotificationContext);
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const { workspaceInfo } = useWorkspace();
  const canManuallyEditTextApiIds = !workspaceInfo?.config?.projects.apiIdPreventManualEdits;

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const showRichText = useHasProjectFeatureFlag("rich_text");

  const onPostCommentReply = (thread) => {
    handleHistoryUpdate();
    handleDocUpdateParent();
    fetchCompInfo(false);
  };

  const postComment = async (commentText, mentionedUsersInfo, clearInput) => {
    try {
      const { url, body } = API.comments.post.postCompThread;
      let requestBody = body({
        doc_id: doc_ID,
        doc_name: doc_name,
        first_comment: commentText,
        figma_node_id: selectedFigmaId,
        mentionedUserIds: mentionedUsersInfo.map((user) => user.userId),
        from: "web_app",
      });
      requestBody[isWsComp ? "ws_comp_id" : "comp_id"] = selectedId;

      const { data: postedCompThread } = await http.post(url, requestBody);
      // 1. reload Activity items + update project history
      handleHistoryUpdate();
      // 2. update card display with new number
      handleDocUpdateParent();
      // 3. update count next to tab name
      fetchCompInfo(false); // false to stop from updating comp history again
      // 4 in case that it isWsComp, update comps in library
      if (isWsComp) {
        updateCompResultComments(selectedId, postedCompThread._id, "add");
      }
      // 5. clear input
      clearInput();
    } catch (e) {
      if (forceShowToast) {
        forceShowToast({
          title: "⚠️ Error posting comment",
          body: "Sorry! We had an error posting your comment. Please try again.",
          autoHide: true,
        });
      }
      console.log("error is: ", e);
    }
  };

  /**
   * Updates comment thread
   * @param {*} threadObject - entire thread object
   * @param {*} incrementCompResultComments - OPTIONAL: Default: true | Blocks propagation of updateCompResultComments if set to false
   */
  const updateCommentThread = (threadObject, incrementCompResultComments = true) => {
    let newHistory = history;
    newHistory = newHistory.map((historyItem) => {
      if (historyItem._id === threadObject._id) {
        return threadObject;
      } else {
        return historyItem;
      }
    });
    setCompHistory(newHistory);
    setCommentState({ isSelected: true, thread_id: threadObject._id });
    if (!threadObject.is_resolved) {
      setThreadOpen(threadObject._id);
    }
    if (isWsComp && incrementCompResultComments) {
      updateCompResultComments(selectedId, threadObject._id, threadObject.is_resolved ? "remove" : "add");
    }
  };

  const handleDocUpdateParent = () => {
    handleDocUpdate([selectedId]);
  };

  const restoreChanges = async (text_version, variantId, { variablesAfter, rich_text_after }) => {
    try {
      if (!origComp || !selectedId) {
        return;
      }
      if (variantId) {
        const variant = origComp.variants.find((v) => v.variantId === variantId);

        const { url, body } = API.variant.post.restore;
        await http.post(
          url(selectedId, variantId),
          body({
            text_before: variant.text,
            text_after: text_version,
            rich_text_before: variant.rich_text,
            rich_text_after,
            variables_before: variant.varibles,
            variables_after: variablesAfter,
            doc_id: doc_ID,
            doc_name: doc_name,
            figma_node_id: origComp.figma_node_ID,
            from: "web_app",
          })
        );
      } else {
        const { url, body } = API.comp.post.restore;
        await http.post(
          url(selectedId),
          body({
            text_before: origComp.text,
            text_after: text_version,
            rich_text_before: origComp.rich_text,
            rich_text_after,
            variables_before: origComp.variables,
            variables_after: variablesAfter,
            doc_id: doc_ID,
            doc_name,
            figma_node_id: origComp.figma_node_ID,
            from: "web_app",
          })
        );
      }
      fetchCompInfo();
      handleDocUpdateParent();
      handleHistoryUpdate(false, true);
    } catch (e) {
      console.log("Error restoring edit in comphistory.jsx: ", e);
    }
  };

  const restoreApiIdChange = async (newApiId) => {
    const { url, body } = API.comp.post.editApiId;

    await http.post(
      url(origComp._id),
      body({
        doc_ID,
        newApiId: newApiId,
        from: "web_app",
      })
    );
    fetchCompInfo();
    handleDocUpdateParent();
    handleHistoryUpdate(false, true);
  };

  const restoreWsCompChanges = async (
    text_before,
    text_after,
    variables_before,
    variables_after,
    rich_text_before,
    rich_text_after,
    variantId
  ) => {
    try {
      if (!origComp || !origComp._id) {
        return;
      }

      const { url, body } = API.ws_comp.post.restore;
      await http.post(
        url(selectedId),
        body({
          text_before,
          text_after,
          rich_text_before,
          rich_text_after,
          variables_before,
          variables_after,
          variantId,
        })
      );

      handleHistoryUpdate();
      refreshLibraryHistory();
      fetchAllComps();
    } catch (e) {
      console.log("Error restoring edit in comphistory.jsx:", e);
    }
  };

  const getVariantInstance = () => {
    if (selectedVariant && selectedVariant.id !== "__base__" && origComp && origComp.variants) {
      for (var variant of origComp.variants) {
        if (variant.variantId === selectedVariant.id) {
          return variant;
        }
      }
    }
    return null;
  };

  const variantFilteredHistory = (history || [])
    .filter((activityElem) => {
      if (!activityElem) return false;

      const { entry_type, variantId, _id } = activityElem;

      if (!entry_type) return true;
      if (entry_type === "ws-comp-status" && doc_name) return false;
      if (selectedVariant && variantId && _id !== "first-change-item") {
        return variantId === selectedVariant.id;
      }

      if (selectedVariant && selectedVariant.id === "__base__" && !variantId) return true;

      // if on base variant
      if (!selectedVariant && !variantId) return true;

      // if wscomp and is a variant change
      if (!doc_name && variantId && ["ws-comp-edit"].includes(entry_type)) return true;

      if (
        !doc_name &&
        (entry_type === "ws-comp-add-variant" ||
          entry_type === ENTRY_TYPES.WS_COMP_ADD_VARIANT_IN_BULK_IMPORT ||
          entry_type === "ws-comp-delete-variant" ||
          entry_type === "ws-comp-status")
      )
        return true;
      if (!doc_name && entry_type === ENTRY_TYPES.EDIT) return true;

      if (entry_type === ENTRY_TYPES.PLURAL_ADDED || entry_type === ENTRY_TYPES.PLURAL_REMOVED) {
        return !selectedVariant || variantId === selectedVariant.id;
      }

      return false;
    })
    .reduce((acc, activityElem) => {
      // the purpouse of this reducer is to eliminate
      // duplicated ENTRY_TYPES.EDIT and "ws-comp-edit" entry types
      // they share the same date_time, so we use that as a constraint
      const duplicateTypes = [ENTRY_TYPES.EDIT, "ws-comp-edit", "ws-comp-status"];

      if (!duplicateTypes.includes(activityElem.entry_type)) {
        acc.push(activityElem);
        return acc;
      }
      const exists = !!acc.find(
        (element) => element.date_time === activityElem.date_time && duplicateTypes.includes(element.entry_type)
      );

      if (!exists || activityElem._id === "first-change-item") {
        acc.push(activityElem);
      }

      return acc;
    }, []);

  if (compHistoryLoading) {
    return <Spinner size={25} marginTop={40} />;
  }

  return (
    <div className={style.all}>
      {!isLockedProject && !hideCommentEditor && (
        <div className={style.newCommentSection}>
          <CommentEditor
            saveContentCallback={postComment}
            mentions={workspaceUsers}
            placeholderText={"Leave a comment or @mention"}
            shouldAutofocus={!isDraftHistory && !commentStateThreadId}
          />
        </div>
      )}
      {(!variantFilteredHistory || variantFilteredHistory.length == 0) && (
        <div className={style.empty}>No changes.</div>
      )}
      <div className={style.compHistoryScrollArea}>
        {variantFilteredHistory.map((activityElem, index) => {
          if (activityElem.entry_type) {
            //activityElem is a history item
            const {
              _id,
              text_before,
              text_after,
              status,
              entry_type,
              user,
              date_time,
              ws_comp,
              variantId, // this is the populated variant
              data = {},
            } = activityElem;
            const { variables_before, variables_after, rich_text_before, rich_text_after } = data;
            const variablesBefore = variables_before || [];
            const variablesAfter = variables_after || [];

            if (entry_type == ENTRY_TYPES.EDIT || entry_type == ENTRY_TYPES.DUPES_EDIT || _id === "first-change-item") {
              const variantInstance = getVariantInstance();
              const didTextChange = origComp && text_after !== variantInstance?.text && origComp.text !== text_after;
              const didRichTextChange =
                showRichText &&
                !isEqual(variantInstance?.rich_text, rich_text_after) &&
                !isEqual(origComp?.rich_text, rich_text_after);

              return (
                <div className={style.version} key={index}>
                  {origComp && !origComp.ws_comp && (didTextChange || didRichTextChange) && (
                    <div className={style.restoreContainer}>
                      {isEditEnabled && !isDraftHistory && (
                        <div
                          className={style.restore}
                          onClick={() =>
                            restoreChanges(text_after, variantId, {
                              variablesAfter,
                              rich_text_after,
                            })
                          }
                        >
                          <RestoreIcon className={style.icon} />
                          Restore
                        </div>
                      )}
                    </div>
                  )}
                  {user.includes("FIGMA EDIT") ? (
                    <div>
                      <div className={style.meta}>
                        Edited directly in Figma, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                      </div>
                      <div className={style.syncName}>
                        {"->"} Synced by {user.match(/\(([^)]+)\)/)[1]}
                      </div>
                    </div>
                  ) : (
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                  )}
                  <EditDiff
                    text_before={text_before}
                    text_after={text_after}
                    rich_text_before={rich_text_before}
                    rich_text_after={rich_text_after}
                    showRichText={showRichText}
                  />
                  {!doc_name && variantId && (
                    <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
                      <div
                        onClick={() => {
                          checkDetailPanelChanges(() => {
                            setPanelState(PANELS.doc.variants);
                          });
                        }}
                        className={classnames([style.changeVariants, style.variantChangeVariants, style.cursorPointer])}
                      >
                        <span className={style.variantNameVariants}>
                          <CallSplitIcon className={style.iconVariants} />
                          {variantId?.name ? variantId.name : "[DELETED VARIANT]"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            } else if (
              entry_type === ENTRY_TYPES.WS_COMP_EDIT ||
              entry_type === ENTRY_TYPES.WS_COMP_EDIT_BULK ||
              entry_type === ENTRY_TYPES.WS_APIID_EDIT ||
              entry_type === ENTRY_TYPES.WS_APIID_EDIT_BULK
            ) {
              const didTextChange =
                origComp &&
                (origComp.text !== text_after || (showRichText && !isEqual(origComp?.rich_text, rich_text_after)));

              const isNameEdit = entry_type === ENTRY_TYPES.WS_COMP_EDIT;

              return (
                <div className={style.version} key={index}>
                  {didTextChange && (
                    <div className={style.restoreContainer}>
                      {/* API ID edit restoration for ws comps is totally broken and 
                          requires significant refactoring to get it working, so I'm
                          disabling for now */}
                      {isNameEdit && isEditEnabled && !isDraftHistory && (
                        <div
                          className={style.restore}
                          data-testid="restore-button"
                          onClick={() =>
                            restoreWsCompChanges(
                              origComp.text,
                              text_after,
                              origComp.variables,
                              variablesAfter,
                              rich_text_before,
                              rich_text_after,
                              variantId?._id || null
                            )
                          }
                        >
                          <RestoreIcon className={style.icon} />
                          Restore
                        </div>
                      )}
                    </div>
                  )}
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <EditDiff
                    isApiID={entry_type === ENTRY_TYPES.WS_APIID_EDIT || entry_type === ENTRY_TYPES.WS_APIID_EDIT_BULK}
                    text_before={text_before}
                    text_after={text_after}
                    rich_text_before={rich_text_before}
                    rich_text_after={rich_text_after}
                    showRichText={showRichText}
                  />
                </div>
              );
            } else if (
              entry_type === ENTRY_TYPES.WS_COMP_CREATION ||
              entry_type === ENTRY_TYPES.WS_COMP_CREATION_IN_BULK_IMPORT
            ) {
              const componentId = getDittoComponentIdByComponentCreationType(data.creationType);
              const didTextChange = origComp && origComp.text !== text_after;

              return (
                <div className={style.version} key={index}>
                  {didTextChange && (
                    <div className={style.restoreContainer}>
                      {isEditEnabled && !isDraftHistory && (
                        <div
                          className={style.restore}
                          data-testid="restore-button"
                          onClick={() =>
                            restoreWsCompChanges(
                              origComp.text,
                              text_after,
                              origComp.variables,
                              variablesAfter,
                              rich_text_before,
                              rich_text_after,
                              variantId?._id || null
                            )
                          }
                        >
                          <RestoreIcon className={style.icon} />
                          Restore
                        </div>
                      )}
                    </div>
                  )}
                  <div className={style.meta}>
                    <DittoComponent componentId={componentId} variables={{ username: user }} />,{" "}
                    <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <EditDiff
                    isApiID={false}
                    text_before={text_before}
                    text_after={text_after}
                    rich_text_before={rich_text_before}
                    rich_text_after={rich_text_after}
                    showRichText={showRichText}
                  />
                  {!doc_name && variantId && (
                    <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
                      <div className={classnames([style.changeVariants], [style.variantChangeVariants])}>
                        <span className={style.variantNameVariants}>
                          <CallSplitIcon className={style.iconVariants} />
                          {variantId?.name ? variantId.name : "[DELETED VARIANT]"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            } else if (
              (entry_type == ENTRY_TYPES.STATUS ||
                entry_type == ENTRY_TYPES.DUPES_STATUS ||
                entry_type == "ws-comp-status") &&
              status !== null
            ) {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div
                    className={classnames({
                      [style.text]: true,
                      [style.status]: true,
                      [style.statusNone]: status == "NONE",
                      [style.statusWip]: status == "WIP",
                      [style.statusReview]: status == "REVIEW",
                      [style.statusFinal]: status == "FINAL",
                      [style.statusDev]: status == "DEV",
                    })}
                  >
                    Marked {Boolean(ws_comp) ? "component" : ""} as <strong>{status}</strong>
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.VARIANT_STATUS_CHANGED) {
              const { status_after: status } = data;
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div
                    className={classnames({
                      [style.text]: true,
                      [style.status]: true,
                      [style.statusNone]: status == "NONE",
                      [style.statusWip]: status == "WIP",
                      [style.statusReview]: status == "REVIEW",
                      [style.statusFinal]: status == "FINAL",
                    })}
                  >
                    Marked variant as <strong>{status}</strong>
                  </div>
                </div>
              );
            } else if (entry_type === "text-apiID-edit") {
              const didApiIdChange = origComp && origComp.apiID !== text_after;

              const showRestoreButton = didApiIdChange && isEditEnabled && canManuallyEditTextApiIds;

              return (
                <div className={style.version} key={index}>
                  <div className={style.restoreContainer}>
                    {showRestoreButton && (
                      <div className={style.restore} onClick={() => restoreApiIdChange(text_after)}>
                        <RestoreIcon className={style.icon} />
                        Restore
                      </div>
                    )}
                  </div>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.changeAndCount}>
                    <div className={style.change}>
                      <EditDiff
                        text_before={text_before}
                        text_after={text_after}
                        rich_text_before={rich_text_before}
                        rich_text_after={rich_text_after}
                        key={index}
                        isApiID={true}
                        showRichText={showRichText}
                      />
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type == ENTRY_TYPES.PLURAL_ADDED) {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>Plural form added</div>
                  {!doc_name && variantId && (
                    <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
                      <div
                        onClick={() => {
                          checkDetailPanelChanges(() => {
                            setPanelState(PANELS.doc.variants);
                          });
                        }}
                        className={classnames(
                          [style.changeVariants],
                          [style.variantChangeVariants],
                          [style.cursorPointer]
                        )}
                      >
                        <span className={style.variantNameVariants}>
                          <CallSplitIcon className={style.iconVariants} />
                          {variantId?.name ? variantId.name : "[DELETED VARIANT]"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            } else if (entry_type == ENTRY_TYPES.PLURAL_REMOVED) {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>Plural form removed</div>
                  {!doc_name && variantId && (
                    <div className={classnames([style.changeAndCountVariants, style.marginTop])}>
                      <div
                        onClick={() => {
                          checkDetailPanelChanges(() => {
                            setPanelState(PANELS.doc.variants);
                          });
                        }}
                        className={classnames(
                          [style.changeVariants],
                          [style.variantChangeVariants],
                          [style.cursorPointer]
                        )}
                      >
                        <span className={style.variantNameVariants}>
                          <CallSplitIcon className={style.iconVariants} />
                          {variantId?.name ? variantId.name : "[DELETED VARIANT]"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            } else if (entry_type == "pr-comp") {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={classnames(style.status, style.pr, style.text)}>Included in a pull request</div>
                </div>
              );
            } else if (entry_type == "ws-comp-attach" || entry_type == "dupes-ws-comp-attach") {
              const { componentType } = data;

              const message =
                componentType === "template" ? "Attached to a template component" : "Attached to a component";

              return (
                <div
                  className={classnames(style.version, style.compversion)}
                  onClick={() => page_history.push("/components/" + ws_comp)}
                  key={index}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div
                    className={classnames({
                      [style.text]: true,
                      [style.status]: true,
                      [style.component]: true,
                      [style.componentTemplate]: componentType === "template",
                    })}
                  >
                    {message}
                    {text_before !== text_after && (
                      <div className={style.diff}>
                        <EditDiff
                          text_before={text_before}
                          text_after={text_after}
                          rich_text_before={rich_text_before}
                          rich_text_after={rich_text_after}
                          showRichText={showRichText}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            } else if (entry_type == "ws-comp-swap") {
              return (
                <div
                  className={classnames(style.version, style.compversion)}
                  onClick={() => page_history.push("/components/" + ws_comp)}
                  key={index}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div
                    className={classnames({
                      [style.text]: true,
                      [style.status]: true,
                      [style.component]: true,
                    })}
                  >
                    Swapped a component
                    {text_before !== text_after && (
                      <div className={style.diff}>
                        <EditDiff
                          text_before={text_before}
                          text_after={text_after}
                          rich_text_before={rich_text_before}
                          rich_text_after={rich_text_after}
                          showRichText={showRichText}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            } else if (entry_type == "ws-comp-detach") {
              const { componentType } = data;
              const componentText = componentType === "template" ? "template component" : "component";

              return (
                <div
                  className={classnames(style.version, style.compversion)}
                  onClick={() => page_history.push("/components/" + ws_comp)}
                  key={index}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div
                    className={classnames({
                      [style.text]: true,
                      [style.status]: true,
                      [style.component]: true,
                      [style.componentTemplate]: componentType === "template",
                    })}
                  >
                    Detached from a {componentText}
                  </div>
                </div>
              );
            } else if (
              !doc_name &&
              (entry_type == "ws-comp-add-variant" ||
                entry_type == "ws-comp-delete-variant" ||
                entry_type === ENTRY_TYPES.WS_COMP_ADD_VARIANT_IN_BULK_IMPORT)
            ) {
              const isAdd =
                entry_type == "ws-comp-add-variant" || entry_type === ENTRY_TYPES.WS_COMP_ADD_VARIANT_IN_BULK_IMPORT;

              return (
                <div
                  key={index}
                  onClick={() => {
                    checkDetailPanelChanges(() => {
                      setPanelState(PANELS.doc.variants);
                    });
                  }}
                >
                  <div className={classnames({ [style.versionVariants]: true })} key={index}>
                    <div className={style.metaVariants}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div>
                      <EditDiff
                        text_before={text_before}
                        text_after={text_after}
                        rich_text_after={rich_text_after}
                        rich_text_before={rich_text_before}
                        showRichText={showRichText}
                      />
                    </div>
                    <div className={style.changeAndCountVariants}>
                      <div className={classnames([style.changeVariants], [style.variantChangeVariants])}>
                        {isAdd ? "Added" : "Removed"}{" "}
                        <span className={style.variantNameVariants}>
                          <CallSplitIcon className={style.iconVariants} />
                          {variantId?.name ? variantId.name : "[DELETED VARIANT]"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type == "hide-comp" || entry_type == "unhide-comp") {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>{entry_type == "hide-comp" ? "Hid text" : "Unhid text"}</div>
                </div>
              );
            } else if (entry_type == ENTRY_TYPES.WS_COMP_RENAME) {
              const oldName = activityElem.data?.oldName;
              const newName = activityElem.data?.newName;
              return (
                <div
                  className={classnames(style.version, style.compversion)}
                  onClick={() => setPanelState(PANELS.comp_library.edit)}
                  key={index}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    <div>
                      Renamed{" "}
                      <div className={style.componentNameWrapper}>
                        <span className={style.componentName}>{oldName || ""}</span>
                      </div>{" "}
                      to{" "}
                      <div className={style.componentNameWrapper}>
                        <span className={style.componentName}>{newName || ""}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (
              entry_type === "ws-comp-move-to-folder" ||
              entry_type === "ws-comp-move-to-folder-in-bulk-operation"
            ) {
              const folderAfter = activityElem.data?.folder?.after;
              const folderBefore = activityElem.data?.folder?.before;

              const folderName = folderAfter?.name || folderBefore?.name || "";
              const preposition = folderAfter ? "to" : folderBefore ? "out of " : "";

              const user = activityElem.user;

              return (
                <div className={classnames(style.version, style.compversion)} key={index}>
                  {user && (
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                  )}
                  <div className={classnames(style.text, style.folderTextContainer)}>
                    Moved {preposition}{" "}
                    <span className={style.folderText}>
                      <FolderIcon className={style.folderIcon} />
                      {folderName}
                    </span>
                  </div>
                </div>
              );
            } else if (
              entry_type === ENTRY_TYPES.COMP_ASSIGNED ||
              entry_type === "ws-comp-assigned" ||
              entry_type === ENTRY_TYPES.MULTI_COMP_ASSIGNED ||
              entry_type === "multi-ws-comp-assigned"
            ) {
              return (
                <div className={classnames(style.version, style.compversion)} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  {activityElem.data?.assignee ? (
                    <div className={style.text}>
                      Assigned to <span className={style.blue}>@{activityElem.data.assigneeName}</span>
                    </div>
                  ) : (
                    <div className={style.text}>Unassigned text</div>
                  )}
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.COMPONENT_MERGED) {
              const { name } = data.mergedComponent;
              return (
                <div className={classnames(style.version, style.compversion)} key={index}>
                  {user && (
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                  )}
                  <div className={classnames(style.text)}>
                    Merged <ComponentLabel inline name={name} /> into this component
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.WS_COMP_CHARACTER_LIMIT_UPDATE) {
              return (
                <div className={classnames(style.version, style.compversion)} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    <DittoComponent
                      variables={{
                        characterLimit: data.newCharacterLimit,
                      }}
                      componentId="selected-component-character-limit-change"
                    />
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE) {
              return (
                <div className={classnames(style.version, style.compversion)} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    <DittoComponent
                      variables={{
                        characterLimit: data.newCharacterLimit,
                      }}
                      componentId="selected-component-character-limit-change"
                    />
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.COMPONENT_AUTO_ATTACH) {
              if (activityElem.parent?.parent?.data?.undone) {
                return <React.Fragment key={activityElem._id} />;
              }
              return (
                <div className={style.automatedChangeItemWrapper} key={activityElem._id}>
                  <AutomatedChangeItem
                    type={"COMPONENTS"}
                    reverted={!!activityElem.data.undone}
                    components={{ DittoComponent }}
                    headText={<DittoComponent componentId="automatically-attached-to-a-component" />}
                    timeAgo={<TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />}
                    items={[]}
                    truncationLimit={40}
                    inProgress={activityElem.data.inProgress}
                    createdAt={date_time}
                    automatedSettingsOnClick={
                      setSyncSettingsModalVisible === undefined ? () => {} : () => setSyncSettingsModalVisible(true)
                    }
                  />
                </div>
              );
            }
          } else {
            //activityElem is a comment!
            const {
              _id: thread_id,
              is_resolved,
              comments,
              suggestion,
              comp_id,
              ws_comp_id,
              updatedAt,
              resolved_by,
            } = activityElem;
            if (!workspaceUsers) return;

            const user = resolved_by?.user_name || comments?.[0]?.user_name || "";
            const isSuggestion = Boolean(suggestion);
            const isResolvedSuggestion = isSuggestion && is_resolved;

            const isForComponent = !!origComp?.ws_comp;
            const isForTemplateComponent = origComp?.ws_comp?.type === "template";

            const showWarning = isSuggestion && isForComponent && !is_resolved && showSuggestionComponentWarning;

            return (
              <div key={index}>
                {isResolvedSuggestion && <ResolvedSuggestionActivityHeader user={user} date={updatedAt} />}
                {showWarning && (
                  <GrayWarning className={style.componentSuggestionWarning}>
                    {isForTemplateComponent
                      ? "Accepting a suggestion for a template component will detach it."
                      : "Accepting a suggestion for a component will also sync to all of the component's instances."}
                  </GrayWarning>
                )}
                <CommentThread
                  key={index}
                  className={isResolvedSuggestion ? style.resolvedSuggestionContainer : undefined}
                  isDisabled={isLockedProject}
                  comp_id={comp_id}
                  ws_comp_id={ws_comp_id}
                  suggestion={suggestion}
                  onAcceptSuggestion={(...args) => onResolveSuggestion(true, ...args)}
                  onRejectSuggestion={(...args) => onResolveSuggestion(false, ...args)}
                  isActiveComment={commentStateThreadId === thread_id}
                  thread_id={thread_id}
                  is_resolved={is_resolved}
                  comments={comments}
                  updateCommentThread={updateCommentThread}
                  should_autofocus={commentStateThreadId === thread_id}
                  default_replies_open={threadOpen === thread_id}
                  handleHistoryUpdate={handleHistoryUpdate}
                  doc_name={doc_name}
                  handleDocUpdateParent={handleDocUpdateParent}
                  fetchCompInfo={fetchCompInfo}
                  fetchNotifications={fetchNotifications}
                  forceShowToast={forceShowToast}
                  workspaceUsers={workspaceUsers}
                  onPostReply={onPostCommentReply}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default CompHistory;
