import useSegment from "@/hooks/useSegment";
import { COMPONENT_CATEGORY_CLICKED } from "@shared/segment-event-names";
import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";

// Default key value for the component modal react-creatable
const BASE_CONTROL_KEY = "__base__";

const useComponentCategories = ({
  searchValue,
  selectedFolderId,
  newCompName,
  selectedCompId,
}: {
  searchValue: React.StatePair<string>;
  selectedFolderId: string;
  newCompName: string;
  selectedCompId: string | null;
}) => {
  const [controlledKey, setControlledKey] = useState<string>(BASE_CONTROL_KEY);
  const { track } = useSegment();
  const categories = useMemo(() => {
    const result = searchValue[0].split("/");
    return result.slice(0, result.length - 1);
  }, [searchValue]);

  const categoryControlKey = useMemo(() => {
    return [selectedFolderId, ...categories].filter(Boolean).join("/");
  }, [categories, selectedFolderId]);

  const onCategoryClick = (category: string) => {
    const updatedSearchValue = categories.length > 0 ? `${categories.join("/")}/${category}/` : `${category}/`;
    searchValue[1](updatedSearchValue);
    const updatedControlKey = [selectedFolderId, ...categories, category].filter(Boolean).join("/");
    setControlledKey(updatedControlKey);
    track({
      event: COMPONENT_CATEGORY_CLICKED,
      properties: {
        category,
        index: categories.length,
      },
    });
    setTimeout(() => {
      window?.document?.getElementById("select-input")?.focus();
    }, 0);
  };

  // Control unique key of `<CreatableSelecte/>` to trigger option reload
  useEffect(
    function updateCreatableSelectKey() {
      if (newCompName || selectedCompId) return;

      const hasFolderChanged =
        controlledKey !== categoryControlKey && categories.length === 0 && selectedFolderId !== "";
      if (hasFolderChanged) {
        setControlledKey(selectedFolderId);
        return;
      }
      if (searchValue[0] === "" && categoryControlKey === "") {
        setControlledKey(BASE_CONTROL_KEY);
        return;
      }
      debounce(() => {
        setControlledKey(categoryControlKey);
        return;
      }, 100);
    },
    [categories, searchValue, controlledKey, selectedFolderId, newCompName, selectedCompId]
  );

  return {
    categories,
    controlledKey,
    onCategoryClick,
  };
};

export { useComponentCategories };
