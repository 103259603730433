import Add from "@mui/icons-material/Add";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useIsComponentVisible from "../../../../shared/frontend/hooks/useIsComponentVisible";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import BlockIcon from "../../atoms/Icon/icons/BlockIcon";
import Text from "../../atoms/Text";
import InlineEditableName from "../../molecules/InlineEditableName";
import style from "./index.module.css";

// TODO: this type should be derived from a top-level shared type.
interface ITextItem {
  text: string;
}

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  name: string;
  isEmpty?: boolean;
  children?: React.ReactNode;
  state?: "default" | "focus" | "active" | "dragging" | "dropping";
  isEditingName?: boolean;
  /**
   * Flag for whether this block is a new, unsaved block.
   */
  isNewBlock?: boolean;
  disableAddTextItem?: boolean;
  highlightedPhrase?: string | null;
  autofocus?: boolean;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
  showAddTextItemButton?: boolean;

  onClickBlock?: () => void;
  onMoveBlockUp?: () => void;
  onMoveBlockDown?: () => void;
  onAddNewTextItem?: () => void;
  onSaveName?: (name: string) => void;
  onResetName?: () => void;
  onChangeName?: (name: string) => void;
  onBlurName?: () => void;
  onFocusName?: () => void;
}

export function TextItemBlock(props: IProps) {
  const { onSaveName, onClickBlock } = props;
  const [hoveringBlockActions, setHoveringBlockActions] = useState(false);
  const blockRef = useRef<HTMLDivElement>(null);
  const moveButtonsRef = useRef<HTMLDivElement>(null);
  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const { isVisibleRef: isBlockVisibleRef } = useIsComponentVisible(blockRef, 1);
  const state = useMemo(() => props.state ?? "default", [props.state]);
  const moveUpActionDisabled = props.canMoveUp === false;
  const moveDownActionDisabled = props.canMoveDown === false;

  const onClick = useCallback(
    function _onClick(e: React.MouseEvent<HTMLDivElement>) {
      // make sure we're *only* clicking on the block wrapper, not on the header or children
      if (childrenWrapperRef.current?.contains(e.target as Node)) {
        return;
      }

      onClickBlock?.();
    },
    [onClickBlock]
  );

  const handleSaveName = useCallback(
    function _handleSaveName(name: string) {
      onSaveName?.(name);
    },
    [onSaveName]
  );

  useEffect(function handleHover() {
    function handleMouseEnter() {
      setHoveringBlockActions(true);
    }

    function handleMouseLeave() {
      setHoveringBlockActions(false);
    }

    const moveButtonsEl = moveButtonsRef.current;
    moveButtonsEl?.addEventListener("mouseenter", handleMouseEnter);
    moveButtonsEl?.addEventListener("mouseleave", handleMouseLeave);

    return function cleanup() {
      moveButtonsEl?.removeEventListener("mouseenter", handleMouseEnter);
      moveButtonsEl?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    // When the block becomes focused, scroll it into view if it's not already visible.
    if (state === "focus" && !isBlockVisibleRef.current && blockRef.current) {
      blockRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [props.state, isBlockVisibleRef, state]);

  return (
    <div
      style={props.style}
      className={classNames(style.TextItemBlockWrapper, props.className, {
        [style[`state-${state}`]]: props.state,
      })}
      data-testid="text-item-block"
      onClick={onClick}
      ref={blockRef}
    >
      <div className={style.header}>
        <div className={style.details}>
          <Icon Icon={<BlockIcon />} size="xs" />
          <InlineEditableName
            onSave={handleSaveName}
            onReset={props.onResetName}
            onChange={props.onChangeName}
            onBlur={props.onBlurName}
            onFocus={props.onFocusName}
            name={props.name}
            // allow new blocks with empty name to default to `Block ${numBlocks}`
            emptyNameAllowed={props.isNewBlock}
            highlightedPhrase={props.highlightedPhrase}
            textStyleClass={style.name}
            placeholder="Give this block a name..."
            autofocus={props.autofocus}
          />
        </div>
        {(props.canMoveUp || props.canMoveDown) && (
          <div
            className={style.actions}
            ref={moveButtonsRef}
            onMouseOver={() => setHoveringBlockActions(true)}
            onMouseLeave={() => setHoveringBlockActions(false)}
          >
            <Button
              className={classNames(style.iconButton, {
                [style.hovering]: hoveringBlockActions,
              })}
              disabled={moveUpActionDisabled}
              type="icon"
              level="subtle"
              iconColor={moveUpActionDisabled ? "minimal" : "secondary"}
              onClick={props.onMoveBlockUp}
            >
              <ArrowUpward />
            </Button>

            <Button
              className={classNames(style.iconButton, {
                [style.hovering]: hoveringBlockActions,
              })}
              disabled={moveDownActionDisabled}
              type="icon"
              level="subtle"
              iconColor={moveDownActionDisabled ? "minimal" : "secondary"}
              onClick={props.onMoveBlockDown}
            >
              <ArrowDownward />
            </Button>
          </div>
        )}
      </div>

      <div className={style.childrenWrapper} ref={childrenWrapperRef}>
        {props.children}

        {props.isEmpty && (
          <Button
            level="subtle"
            expansion="block"
            alignment="start"
            size="small"
            leadingIcon={<Add />}
            iconColor="secondary"
            // in order to compute adding a new text item before saving a new block on blur, use onMouseDown
            onMouseDown={props.onAddNewTextItem}
            disabled={props.disableAddTextItem}
            className={style.addFirstTextItemButton}
          >
            <Text color="secondary">Add text item</Text>
          </Button>
        )}
      </div>

      <div className={style.footer}>
        {!props.isEmpty && (props.showAddTextItemButton ?? true) && (
          <Button
            disabled={props.isEditingName || props.disableAddTextItem}
            leadingIcon={<Add />}
            level="subtle"
            size="micro"
            onClick={props.onAddNewTextItem}
          >
            Add text item
          </Button>
        )}
      </div>
    </div>
  );
}

export default TextItemBlock;
