import { modalAtom } from "@/stores/Modals";
import DialogueModal from "@ds/molecules/DialogueModal";
import { useAtomValue } from "jotai";

function DialogueModalContainer() {
  const modal = useAtomValue(modalAtom);

  if (!modal) return <></>;

  return (
    <DialogueModal
      open={!!modal}
      onOpenChange={modal.onOpenChange}
      onCancel={modal.onCancel}
      onAction={modal.onAction}
      headline={modal.headline}
      content={modal.content}
      actionText={modal.actionText}
      type={modal.type}
    />
  );
}
export default DialogueModalContainer;
