import { ZABPlatformSyncData } from "@shared/types/ABPlatformSyncData";
import { z } from "zod";

export const ZLaunchDarklySyncControllerRequest = {
  body: z.object({ data: ZABPlatformSyncData }),
};
export const ZLaunchDarklySyncControllerResponse = z.unknown();
export const LaunchDarklySyncControllerMethod = "POST";
export const LaunchDarklySyncControllerPath = "/connections/launchDarkly/sync";

export const ZSplitSyncControllerRequest = {
  body: z.object({ data: ZABPlatformSyncData }),
};
export const ZSplitSyncControllerResponse = z.unknown();
export const SplitSyncControllerMethod = "POST";
export const SplitSyncControllerPath = "/connections/split/sync";
