import { IRole } from "@shared/types/User";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { INVITE_ONLY_UPSELL_TEXT } from "../../../util/folder";
import http, { API } from "../../http";
import { UserWithAccess } from "../permissions/FolderAccessList";

interface Folder {
  _id: string;
  name: string;
  invite_only: boolean;
}

interface ShareFolderModalProps {
  folder: Folder;
  currentUser: {
    _id: string;
    name: string;
    email: string;
    role: IRole;
  };
  inviteOnlyFoldersEnabled: boolean;
  onHide: () => void;
  handleFolderMadeInviteOnly: (folder: { _id: string }) => void;
  handleUserRemovedSelf: (folderId: string) => void;
}

const useShareFolder = (props: ShareFolderModalProps) => {
  const { folder, currentUser, inviteOnlyFoldersEnabled, handleFolderMadeInviteOnly, handleUserRemovedSelf } = props;

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [usersWithAccess, setUsersWithAccess] = useState<UserWithAccess[]>([]);
  const [showConfirmInviteOnly, setShowConfirmInviteOnly] = useState(false);

  const history = useHistory();

  const invitedEmails = useMemo(() => {
    const temp = usersWithAccess.reduce((acc, curr) => {
      acc[curr.email] = true;
      return acc;
    }, {});
    temp[currentUser.email] = true;
    return temp;
  }, [usersWithAccess, currentUser]);

  const onInviteOnlyToggleClick = async ({ askForConfirmation }: { askForConfirmation: boolean }) => {
    if (askForConfirmation) {
      return setShowConfirmInviteOnly(true);
    }

    setShowConfirmInviteOnly(false);

    try {
      const { url } = API.folder.put.inviteOnly;

      setIsSaving(true);
      const { data } = await http.put<Folder>(url(folder._id));
      setIsSaving(false);

      handleFolderMadeInviteOnly(data);
      fetchFolderInvitedUsers();
    } catch (error) {
      setIsSaving(false);
      console.error("Error clicking invite toggle");
      console.error(error);
    }
  };

  const tag = useMemo(
    () =>
      inviteOnlyFoldersEnabled
        ? undefined
        : {
            label: INVITE_ONLY_UPSELL_TEXT,
            url: "https://dittowords.com/pricing",
          },
    [inviteOnlyFoldersEnabled]
  );

  const fetchFolderInvitedUsers = async () => {
    try {
      const { url } = API.invite.get.folder;
      const { data } = await http.get(url(folder._id));
      setUsersWithAccess(data);
    } catch (error) {
      console.error("Error fetching invited folder users");
      console.error(error);
    }
  };

  const handleSendInvite = () => {
    fetchFolderInvitedUsers();
  };

  const onShareFolder = async () => {};

  const handleRemoveUser = async (type: "existing" | "invited", user: { email: string; _id: string | null }) => {
    try {
      const { url, body } = API.invite.post.removeFolderUser;
      await http.post(
        url(folder._id),
        body({
          type,
          user,
        })
      );

      await fetchFolderInvitedUsers();
    } catch (error) {
      console.error("Error removing");
    }
  };

  const onUserRemoved = async (email: string) => {
    const userToRemove = usersWithAccess.find((user) => user.email === email);

    if (userToRemove) {
      await handleRemoveUser(userToRemove.type, {
        _id: userToRemove._id,
        email: userToRemove.email,
      });

      setUsersWithAccess((prev) => {
        let temp = prev;
        temp = temp.filter((t) => t.email !== email);
        return [...temp];
      });

      if (userToRemove._id == currentUser._id) {
        handleUserRemovedSelf(folder._id);
      }
    }
  };

  useEffect(() => {
    fetchFolderInvitedUsers();
  }, []);

  return {
    isSaving,
    onInviteOnlyToggleClick,
    tag,
    invitedEmails,
    handleSendInvite,
    onShareFolder,
    onUserRemoved,
    usersWithAccess,
    showConfirmInviteOnly,
    setShowConfirmInviteOnly,
  };
};

export { ShareFolderModalProps, useShareFolder };
