import { getTags, getUsers, getVariants } from "@/http/workspaceNew";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { IUser } from "@shared/types/User";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import { atom } from "jotai";
import { derive } from "jotai-derive";
import { atomFamily, atomWithRefresh, unwrap } from "jotai/utils";

export const usersAtom = atomWithRefresh(() => getUsers()[0].then((r) => r.data));
export const deferredUsersAtom = derive([usersAtom], (users) => users);
deferredUsersAtom.debugLabel = "deferredUsersAtom";

/**
 * user referenced by their _id (ObjectId)
 */
export const usersByIdAtom = derive([usersAtom], (usersArr) => {
  return usersArr.reduce((acc, user) => {
    acc[user._id] = user;
    return acc;
  }, {} as Record<string, IUser>);
});

/**
 * user referenced by their userId (sub)
 */
export const usersByUserIdAtom = derive([usersAtom], (usersArr) => {
  return usersArr.reduce((acc, user) => {
    acc[user.userId] = user;
    return acc;
  }, {} as Record<string, IUser>);
});

/**
 * Just the _id, name, email and role of each user in the workspace
 */
export const usersBasicInfoAtom = derive([usersAtom], (users) => {
  return users.map((user) => ({ _id: user._id, name: user.name, email: user.email, role: user.role }));
});

export const { valueAtom: allTagsAtom } = asyncMutableDerivedAtom({
  loadData: async () => {
    const tags = (await getTags({ excludeWsComps: false, sortAlpha: true, excludeSampleData: true })[0]).data;
    return tags;
  },
  debugLabel: "All Tags",
});
export const deferredAllTagsAtom = derive([allTagsAtom], (tags) => tags);
deferredAllTagsAtom.debugLabel = "deferredAllTagsAtom";

export const unwrappedAllTagsAtom = unwrap(allTagsAtom, (prev) => prev ?? []);

export const createNewTagActionAtom = atom(null, async (get, set, newTag: string) => {
  const tags = await get(allTagsAtom);
  const newTags = [...tags];
  newTags.push({
    _id: newTag,
    total: 0,
  });

  set(allTagsAtom, newTags);
});

/**
 * Atom for all the variants in the workspace.
 */
export const { valueAtom: variantsAtom, refreshAtom: refreshVariantsAtom } = asyncMutableDerivedAtom({
  loadData: async () => {
    const variants = (await getVariants()[0]).data;
    return variants;
  },
  debugLabel: "All Variants",
});

/**
 * Derived atom for all the variants in the workspace.
 */
export const deferredVariantsAtom = derive([variantsAtom], (variants) => variants);

/**
 * Derived atom for a map of variant names to their IDs.
 */
export const deferredVariantsNamesMapAtom = derive([variantsAtom], (variants) => {
  return variants.reduce((acc, variant) => {
    acc[variant._id] = variant.name;
    return acc;
  }, {} as Record<string, string>);
});

/**
 * Atom family that returns a variant's name given its ID.
 * Returns undefined if the variant is not found.
 * @param variantId - The ID of the variant to get the name for.
 */
export const variantNameFamilyAtom = atomFamily((variantId: string) => {
  const variantNameAtom = derive([deferredVariantsAtom], (variants): string => {
    if (variantId === BASE_VARIANT_ID) return "Base";
    return variants.find((v) => v._id === variantId)?.name ?? "";
  });
  variantNameAtom.debugLabel = `Variant Name (${variantId})`;

  return variantNameAtom;
});
