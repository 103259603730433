import { PartialKeys, useVirtualizer, Virtualizer, VirtualizerOptions } from "@tanstack/react-virtual";
import classNames from "classnames";
import { Getter, PrimitiveAtom, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import React, { useCallback, useEffect } from "react";
import style from "./index.module.css";

interface IProps<T> {
  /**
   * The unique identifier for the component. Needed to handle scrolling and for complex scroll scenarios.
   */
  id: string;

  /**
   * The data items to render in the list.
   */
  items: T[];

  /**
   * The component that renders each item in the list.
   */
  children: (
    item: T,
    options: Pick<VirtualizerOptions<Element, Element>, "getScrollElement" | "initialOffset" | "scrollMargin">,
    virtualizerAtom: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>
  ) => React.ReactNode;

  /**
   * When the list is nested inside another virtualized list, this prop should be set to true.
   */
  isNested?: boolean;

  /**
   * The atom that stores the virtualizer for this list. Used to store nested virtualizer instances as well.
   */
  virtualizerAtom: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>;

  /**
   *
   */
  virtualizeOptions: PartialKeys<
    VirtualizerOptions<Element, Element>,
    "observeElementRect" | "observeElementOffset" | "scrollToFn" | "count" | "getScrollElement"
  >;

  className?: string;
  style?: React.CSSProperties;
}

export const VirtualizedList = function VirtualizedList<T>(props: IProps<T>) {
  const listRef = React.useRef<HTMLDivElement>(null);

  const setAtomToVirtualizer = useAtomCallback(
    useCallback(
      (get: Getter, set: Setter, virtualizer: Virtualizer<Element, Element>) => {
        set(props.virtualizerAtom, (prev) => {
          return { ...prev, [props.id]: virtualizer };
        });
      },
      [props.id, props.virtualizerAtom]
    )
  );

  const virtualizer = useVirtualizer({
    ...props.virtualizeOptions,
    count: props.items.length,
    getScrollElement: props.virtualizeOptions.getScrollElement ?? (() => listRef.current),
  });

  useEffect(
    function keepVirtualizerInSync() {
      setAtomToVirtualizer(virtualizer);
    },
    [setAtomToVirtualizer, virtualizer]
  );

  const virtualItems = virtualizer.getVirtualItems();

  let translateY = virtualItems[0]?.start ?? 0;

  if (props.isNested) {
    translateY -= virtualizer.options?.scrollMargin ?? 0;
  }

  return (
    <div
      ref={listRef}
      style={props.style}
      className={classNames(style.VirtualizedListWrapper, props.className)}
      data-testid="virtualized-list"
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
          overflowAnchor: "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${translateY}px)`,
            overflowAnchor: "none",
          }}
        >
          {virtualItems.map((virtualRow) => (
            <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
              {props.children(
                props.items[virtualRow.index],
                {
                  getScrollElement: virtualizer.options.getScrollElement,
                  initialOffset: virtualizer.scrollOffset ?? 0,
                  scrollMargin: virtualRow.start,
                },
                props.virtualizerAtom
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VirtualizedList;
