import { selectedLibraryFolderIdAtom, sidebarCollapseStateAtom } from "@/stores/Library";
import LibraryFilterBar from "@ds/organisms/FilterBar";
import { IObjectId } from "@shared/types/lib";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LibraryContent from "./components/LibraryContent";
import NavBar from "./components/LibraryNavBar";
import style from "./style.module.css";

import useSegment from "@/hooks/useSegment";
import { designPreviewToggledAtom, searchAtom } from "@/stores/Location";
import {
  libraryHiddenFieldsListAtom,
  resetHiddenFieldsActionAtom,
  updateHiddenFieldsActionAtom,
} from "@/stores/TextItemMetadata";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";

function Library() {
  const { folderId } = useParams<{ folderId?: IObjectId }>();
  const setSelectedLibraryFolderId = useSetAtom(selectedLibraryFolderIdAtom);

  useEffect(
    function setSelectedFolderId() {
      setSelectedLibraryFolderId(folderId);
    },
    [folderId, setSelectedLibraryFolderId]
  );

  return (
    <div className={style.libraryContainer}>
      <NavBar />
      <LibraryFilterBar
        sidebarCollapseStateAtom={sidebarCollapseStateAtom}
        filterContextId="library"
        designPreviewToggledAtom={designPreviewToggledAtom}
        searchAtom={searchAtom}
        useSegment={useSegment}
        hiddenFieldsListAtom={libraryHiddenFieldsListAtom}
        resetHiddenFieldsActionAtom={resetHiddenFieldsActionAtom}
        updateHiddenFieldsActionAtom={updateHiddenFieldsActionAtom}
        filters={{}}
      />
      <LibraryContent />
      {process.env.ENV === "development" && (
        <DevTools
          options={{ shouldShowPrivateAtoms: false, shouldExpandJsonTreeViewInitially: true }}
          position="bottom-right"
        />
      )}
    </div>
  );
}

export default Library;
