import { z } from "zod";

export const ZPopulateDittoProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    numberOfBlocks: z.number(),
    maxNumberOfTextItemsInBlocks: z.number().gt(0),
    textItemsOutsideOfBlocks: z.number(),
  }),
};
export const ZPopulateDittoProjectResponse = z.unknown();
