import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import ErrorIcon from "@mui/icons-material/Error";
import classnames from "classnames";
import { matchSorter } from "match-sorter";
import React, { useMemo, useRef } from "react";
import ReactTags from "react-tag-autocomplete";
import { EMAIL_REGEX } from "../../../../shared/utils/email";
import { EmailTagWithRole, EmailTagWithoutRole } from "./EmailTag";
import { UserSuggestionWithRole, UserSuggestionWithoutRole } from "./UserSuggestion";
import style from "./style.module.css";
import { InviteUsersInputProps, UserInvitation } from "./types";

const WHITE_SPACE_REGEX = new RegExp(/\s/g);

const InviteUsersInput = (props: InviteUsersInputProps) => {
  const {
    disabled,
    currentUserRole,
    existingInvitedEmails = {},
    invitedUserList,
    userList,
    defaultRole,
    placeholder = "Search for a teammate, or invite one via email",
    onUserInvited,
    onUserRemoved,
  } = props;

  const {
    workspaceInfo: { plan },
  } = useWorkspace();

  const { user } = useAuthenticatedAuth();

  const reactTags = useRef();
  const userInviteTags = useMemo(() => {
    return invitedUserList.map(({ email, type, name, role, isAlreadyInvited }, index) => ({
      id: index,
      type,
      name,
      email,
      role,
      permissionGroups: [role],
      isValid: EMAIL_REGEX.test(email),
      canInvite: currentUserRole !== "commenter" || role !== "editor",
      isAlreadyInvited,
    }));
  }, [invitedUserList, currentUserRole]);

  const currentUserSuggestions = useMemo(() => {
    return userList
      .map((listUser) => ({
        _id: listUser._id,
        type: "existing",
        name: listUser.name,
        email: listUser.email,
        role: listUser.role,
      }))
      .filter(({ email }) => {
        return email !== user.email && !invitedUserList.some((userInvite) => userInvite.email === email);
      });
  }, [userList, invitedUserList, currentUserRole]);

  const suggestionsTransform = (query, suggestions) => {
    const searchQuery = query.toLowerCase();
    const isValidEmail = !WHITE_SPACE_REGEX.test(searchQuery) && EMAIL_REGEX.test(searchQuery);
    const canInviteEmail =
      isValidEmail &&
      !userList.some((user) => user.email.toLocaleLowerCase() === searchQuery) &&
      !invitedUserList.some((user) => user?.email?.toLocaleLowerCase() === searchQuery) &&
      !existingInvitedEmails[searchQuery];

    let allSuggestions = suggestions;

    if (canInviteEmail) {
      const newInviteRoles = defaultRole ? [defaultRole] : ["admin", "editor", "commenter"];
      allSuggestions = newInviteRoles
        .map((role) => ({
          _id: "__new__",
          type: "new",
          role,
          permissionGroups: [role],
          name: searchQuery,
          email: searchQuery,
        }))
        .concat(suggestions)
        .filter(({ role }) => {
          const isFreeTierCommenterInvite = plan === "free" && role === "commenter";
          if (isFreeTierCommenterInvite) return false;

          const canInviteRole =
            currentUserRole === "admin" ||
            (currentUserRole === "editor" && role !== "admin") ||
            (currentUserRole === "commenter" && role === "commenter");

          return canInviteRole;
        });
    }

    return matchSorter<UserInvitation>(allSuggestions, searchQuery, {
      keys: ["email", "name"],
      sorter: (rankedItems) => {
        return rankedItems.sort((a, b) => {
          if (a.item.type === b.item.type) return 0;
          else if (a.item?.type === "existing") return -1;
          return 1;
        });
      },
    });
  };

  const invalidEmailPresent = useMemo(() => userInviteTags.some((invite) => !invite.isValid), [userInviteTags]);

  const invalidCommenterUserInvites = useMemo(
    () => userInviteTags.some((invite) => !invite.canInvite),
    [userInviteTags]
  );

  const emailAlreadyInvited = useMemo(() => userInviteTags.some((invite) => invite.isAlreadyInvited), [userInviteTags]);

  return (
    <div className={style.container}>
      <div
        className={classnames(style.wrapper, {
          [style.disableTagInput]: disabled,
        })}
      >
        <ReactTags
          ref={reactTags}
          tags={userInviteTags}
          onDelete={onUserRemoved}
          onAddition={onUserInvited}
          addOnBlur={true}
          allowNew={true}
          placeholderText={placeholder}
          delimiters={["Enter", "Tab"]}
          suggestions={currentUserSuggestions}
          suggestionsTransform={suggestionsTransform}
          minQueryLength={1}
          maxSuggestionsLength={1000}
          tagComponent={defaultRole ? EmailTagWithoutRole : EmailTagWithRole}
          suggestionComponent={defaultRole ? UserSuggestionWithoutRole : UserSuggestionWithRole}
        />
      </div>
      {invalidEmailPresent && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          Please select a valid name or email.
        </div>
      )}
      {invalidCommenterUserInvites && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          As a commenter, you aren’t able to add editors to this folder.
        </div>
      )}
      {emailAlreadyInvited && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          User has already joined a different Ditto workspace. Try a different email.
        </div>
      )}
    </div>
  );
};

export default InviteUsersInput;
