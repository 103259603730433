import useSegment from "@/hooks/useSegment";
import { isSubscribedToBetaLibraryUpdatesAtom, subscribeToBetaLibraryUpdatesActionAtom } from "@/stores/Auth";
import { libraryInteractionViewAtom } from "@/stores/ProjectSelection";
import { NS_LIBRARY } from "@/utils/featureFlags";
import LibrarySection from "@ds/organisms/LibrarySection";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_LIBRARY_INTEREST } from "@shared/segment-event-names";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";

const defaultStore = getDefaultStore();

function createTrackingEvent(action: string) {
  return {
    event: NS_BETA_LIBRARY_INTEREST,
    properties: {
      action,
      location: "selected-edit-panel",
    },
  };
}

function Library() {
  const isSubscribed = useAtomValue(isSubscribedToBetaLibraryUpdatesAtom, { store: defaultStore });
  const subscribeToUpdates = useSetAtom(subscribeToBetaLibraryUpdatesActionAtom, { store: defaultStore });
  const setLibraryInteractionView = useSetAtom(libraryInteractionViewAtom);
  const { track } = useSegment();

  const onClickPublish = useCallback(() => {
    if (NS_LIBRARY) {
      setLibraryInteractionView("PUBLISH");
    }
    track(createTrackingEvent("publish"));
  }, [track, setLibraryInteractionView]);

  const onClickLink = useCallback(() => {
    if (NS_LIBRARY) {
      setLibraryInteractionView("LINK");
    }

    track(createTrackingEvent("component-link"));
  }, [track, setLibraryInteractionView]);

  const handleSubscribe = useCallback(async () => {
    subscribeToUpdates();
    track(createTrackingEvent("subscribe"));
  }, [subscribeToUpdates, track]);

  const handleClickLearnMore = useCallback(() => {
    window.open(LEARN_MORE_URL.LIBRARY, "_blank");
    track(createTrackingEvent("learn-more"));
  }, [track]);

  return (
    <LibrarySection
      dividerBelow
      isSubscribed={isSubscribed}
      onClickPublish={onClickPublish}
      onClickLink={onClickLink}
      onClickSubscribe={handleSubscribe}
      onClickLearnMore={handleClickLearnMore}
      useComingSoon={!NS_LIBRARY}
    />
  );
}

export default Library;
