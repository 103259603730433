import classNames from "classnames";
import React from "react";
import Label from "../../atoms/Label";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface ICompactInputLabelProps {
  /**
   * Label for the input element.
   */
  label: string;
  /**
   * Optional helper text.
   */
  helper?: string;

  /**
   * Input element to render.
   */
  input: React.ReactNode;

  className?: string;
  style?: React.CSSProperties;
}

export function CompactInputLabel(props: ICompactInputLabelProps) {
  const { label, helper, input } = props;

  return (
    <div
      style={props.style}
      className={classNames(style.CompactInputLabelWrapper, props.className)}
      data-testid="compact-input-label"
    >
      <div className={style.CompactInputLabelAndTextWrapper}>
        <Label type="bold" size="sm">
          {label}
        </Label>
        {helper && (
          <Text color="tertiary" size="small">
            {helper}
          </Text>
        )}
      </div>
      {input}
    </div>
  );
}

export default CompactInputLabel;
