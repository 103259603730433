import { z } from "zod";
import { ZComponentSuggestionJobData } from "../../types/jobs/ComponentSuggestions";
import { ZFigmaGetBranchInfoJobData } from "../../types/jobs/FigmaGetBranchInfo";
import { ZFigmaGetPagesJobData } from "../../types/jobs/FigmaGetPages";
import { ZFigmaSyncNewFramesJobData } from "../../types/jobs/FigmaSyncNewFrames";

export const ZGetJobResponseRequest = {
  params: z.object({
    jobName: z.string(),
    jobId: z.string(),
  }),
};
export const ZGetJobResponseResponse = z.unknown();

export const ZGetSetupSuggestionsJobRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZGetSetupSuggestionsJobResponse = z.unknown();

export const ZCreateFigmaGetPagesJobRequest = {
  body: ZFigmaGetPagesJobData.omit({
    workspaceId: true,
    figmaToken: true,
  }),
};

export const ZCreateFigmaGetPagesJobResponse = z.unknown();

export const ZGetFigmaGetPagesJobRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZGetFigmaGetPagesJobResponse = z.unknown();

export const ZCreateFigmaGetBranchInfoJobRequest = {
  body: ZFigmaGetBranchInfoJobData.omit({
    workspaceId: true,
    figmaToken: true,
  }),
};
export const ZCreateFigmaGetBranchInfoJobResponse = z.unknown();

export const ZGetFigmaGetBranchInfoJobRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZGetFigmaGetBranchInfoJobResponse = z.unknown();

export const ZCreateFigmaSyncNewFramesJobRequest = {
  body: ZFigmaSyncNewFramesJobData.omit({
    workspaceId: true,
    userId: true,
  }),
};

export const ZCreateFigmaSyncNewFramesJobResponse = z.unknown();

export const ZConfirmFrameRemovalRequest = {
  params: z.object({ projectId: z.string() }),
};
export const ZConfirmFrameRemovalResponse = z.unknown();

export const ZGetFigmaSyncNewFramesJobRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZGetFigmaSyncNewFramesJobResponse = z.unknown();

export const ZGetResyncJobRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZGetResyncJobResponse = z.unknown();

export const ZGetImportJobRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZGetImportJobResponse = z.unknown();

export const ZGetPreviewJobRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZGetPreviewJobResponse = z.unknown();

export const ZCreateComponentSuggestionsJobRequest = {
  body: ZComponentSuggestionJobData.omit({
    workspaceId: true,
    userId: true,
  }),
};

export const ZCreateComponentSuggestionsJobResponse = z.unknown();

export const ZGetActivePreviewsJobForDittoProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetActivePreviewsJobForDittoProjectResponse = z.unknown();
