import ProgressCircle from "@/components/ProgressCircle/ProgressCircle";
import SampleBadge from "@/components/SampleBadge";
import MarkEmailUnread from "@/components/icons/MarkEmailUnreadIcon";
import { routes } from "@/defs";
import { useWorkspace } from "@/store/workspaceContext";
import Text from "@ds/atoms/Text";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import TestIDs from "@shared/frontend/testIds";
import { IProjectSummary } from "@shared/types/http/project";
import classNames from "classnames";
import { DittoComponent } from "ditto-react";
import React, { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useHistory } from "react-router-dom";
import style from "./style.module.css";

export interface IProjectCardProps {
  className?: string;
  classNameCard?: string;
  project: IProjectSummary;
  onClick?: () => void;
  options?: {
    canRemoveFromFolder?: boolean;
    handleRemoveFromFolder?: (folderId: string, projectId: string, isNorthStar: boolean) => void;
    hideFolder?: boolean;
  };
  "data-testid"?: string;
}

// Function to calculate the difference in days between two dates
function differenceInDays(date1: Date, date2Input?: Date | string) {
  let date2 = typeof date2Input === "string" ? new Date(date2Input) : date2Input;
  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2?.getTime() || date1.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date1_ms - date2_ms;

  // Convert the difference back to days
  return Math.round(difference_ms / (1000 * 60 * 60 * 24));
}

export default function ProjectCard(props: IProjectCardProps) {
  const history = useHistory();
  const workspace = useWorkspace();

  const daysSinceInvited = differenceInDays(new Date(), props.project.invitedAt);
  const showInvitedBy = !!props.project.invitedBy && !!props.project.invitedAt;
  const showRemoveFromFolderDropdown = Boolean(
    props.options?.canRemoveFromFolder && props.options.handleRemoveFromFolder && props.project.folder
  );

  const editedAt = differenceInDays(new Date(), props.project.edited_at);
  const editedBy = props.project.edited_by || "";
  const editedDittoComponentId = editedBy ? "last-edited-by" : "last-edited";

  // for the sample project, only show the edited text if the project has actually
  // been edited by somebody since it was created
  const showEditedText = !showInvitedBy && (!props.project.isSample || editedBy);

  const projectLink = useMemo(() => {
    if (workspace.workspaceInfo.feature_flags.northStar && props.project.isNorthStar) {
      return routes.nonNavRoutes.projectBeta.getPath(props.project._id);
    }
    if (props.project.branchId) {
      return routes.nonNavRoutes.branchProject.getPath(props.project._id, props.project.branchId);
    }

    return routes.nonNavRoutes.project.getPath(props.project._id);
  }, [props.project._id, props.project.branchId]);

  function onCardClick(_event: React.MouseEvent<HTMLAnchorElement>) {
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <div className={classNames(style.projectCard, props.className)}>
      {!props.options?.hideFolder && (
        <div className={style.header}>
          {props.project.folder && (
            <div className={style.textWrapper}>
              <FolderOutlinedIcon className={style.folderIcon} />
              <span className={style.folderName}>{props.project.folder?.name}</span>
            </div>
          )}
        </div>
      )}
      {showRemoveFromFolderDropdown && (
        <div className={style.cardDropDownWrapper}>
          <Dropdown alignRight className={style.dropdownContainer}>
            <Dropdown.Toggle>
              <MoreVert className={style.dropDownIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  props.options?.handleRemoveFromFolder?.(
                    props.project.folder!._id,
                    props.project._id,
                    !!props.project.isNorthStar
                  )
                }
              >
                Remove from Folder
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <Link
        data-testid={TestIDs.projectCard(props.project.name)}
        className={classNames(style.card, props.classNameCard)}
        onClick={onCardClick}
        to={projectLink}
      >
        <div className={style.topCard}>
          <span className={style.projectName}>
            {props.project.name}{" "}
            {props.project.isSample && <SampleBadge className={style.sampleTag}>Sample</SampleBadge>}
          </span>
        </div>
        <div className={style.progress}>
          {props.project.isNorthStar && (
            <>
              <div className={style.betaTag}>
                <Text color="secondary" size="micro" weight="medium">
                  BETA
                </Text>
              </div>
              <div className={style.circle}></div>
            </>
          )}

          {props.project.componentProgress && (
            <>
              <ProgressCircle {...props.project.componentProgress} />
              <div className={style.circle}></div>
            </>
          )}
          <ProgressCircle {...props.project.statusProgress} />
          {props.project.commentCount > 0 && (
            <>
              <div className={style.circle}></div>
              <div className={style.commentCount}>
                <ChatBubbleIcon className={style.commentsIcon} />
                <span className={style.commentCount}>{props.project.commentCount}</span>
              </div>
            </>
          )}
        </div>
      </Link>
      <div className={style.footer}>
        {showEditedText && (
          <span className={style.lastEdited}>
            <DittoComponent
              componentId={editedDittoComponentId}
              variables={{ numDays: editedAt, fullName: editedBy }}
              count={editedAt}
            />
          </span>
        )}
        {showInvitedBy && (
          <span className={style.invited}>
            <MarkEmailUnread className={style.invitedIcon} />
            <DittoComponent
              componentId="invited-by-with-time-ago"
              variables={{
                numDays: daysSinceInvited,
                fullName: props.project.invitedBy || "Your Teammate",
              }}
              count={daysSinceInvited}
            />
          </span>
        )}
      </div>
    </div>
  );
}
