import { z } from "zod";

export const ZCreateVariantFolderRequest = {
  body: z.object({
    name: z.string(),
    variant_ids: z.array(z.string()).optional(),
  }),
};
export const ZCreateVariantFolderResponse = z.unknown();

export const ZUpdateVariantFolderRequest = {
  body: z.object({
    name: z.string(),
  }),
};
export const ZUpdateVariantFolderResponse = z.unknown();

export const ZDeleteVariantFolderRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZDeleteVariantFolderResponse = z.unknown();

export const ZMoveVariantsToFolderRequest = {
  body: z.object({
    variant_ids: z.array(z.string()),
  }),
};
export const ZMoveVariantsToFolderResponse = z.unknown();
