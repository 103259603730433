import ChevronLeft from "@mui/icons-material/ChevronLeft";
import FilterIcon from "@mui/icons-material/FilterList";
import classNames from "classnames";
import { Atom, PrimitiveAtom, useAtomValue } from "jotai";
import React from "react";
import Skeleton from "react-loading-skeleton";
import batchedAsyncAtomFamily from "../../../../shared/frontend/stores/batchedAsyncAtomFamily";
import { IObjectId } from "../../../../shared/types/lib";
import { ILibraryComponent } from "../../../../shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "../../../../shared/types/LibraryComponentFolder";
import { IUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import TextInput from "../../atoms/TextInput";
import Toggle from "../../atoms/Toggle";
import ButtonWrapFooter from "../../molecules/ButtonWrapFooter";
import ComponentFolderNavButton from "../../molecules/ComponentFolderNavButton";
import Scrollbar from "../../molecules/Scrollbar";
import { TextItemStatic } from "../../molecules/TextItem";
import style from "./index.module.css";

type FamilyAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"];

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  componentIds: IObjectId[];
  folders: ILibraryComponentFolder[];
  currentFolder: Pick<ILibraryComponentFolder, "_id" | "name"> | null;

  searchQuery: string;
  onSearchQueryChange: (query: string) => void;

  selectedComponent: ILibraryComponent | null;
  onComponentClick: (component: ILibraryComponent) => void;
  onComponentLinkClick: (component: ILibraryComponent) => void;
  onComponentCancelClick: (component: ILibraryComponent) => void;

  onFolderClick: (folder: ILibraryComponentFolder) => void;
  onBackFolderClick: () => void;
  onLibraryLinkClick: () => void;

  componentFamilyAtom: (id: string | null) => ReturnType<FamilyAtomType> | PrimitiveAtom<null>;
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  originalText?: string;
}

export function CompactLibraryComponentList(props: IProps) {
  const inFolder = !!props.currentFolder;

  return (
    <div
      style={props.style}
      className={classNames(style.compactLibraryComponentListWrapper, props.className)}
      data-testid="compact-library-component-list"
    >
      <TextInput
        autoFocus
        className={style.searchInput}
        placeholder="Search in the library..."
        value={props.searchQuery}
        onChange={props.onSearchQueryChange}
      />

      <div className={classNames(style.listHeader, { [style.inFolder]: inFolder })}>
        <div className={style.topRow}>
          {inFolder && (
            <Button type="icon" level="secondary" onClick={props.onBackFolderClick}>
              <Icon Icon={<ChevronLeft />} />
            </Button>
          )}

          <Text className={style.headerText} weight={inFolder ? "base" : "strong"} size="small">
            {inFolder ? props.currentFolder?.name : "All components"}
          </Text>

          {/* Dummy button for now -- filtering coming in later user story! */}
          <Toggle size="small" pressed={false} onPressedChange={() => {}}>
            <Icon Icon={<FilterIcon />} size="xs" />
            Filter
          </Toggle>
        </div>

        {!inFolder && (
          <div className={style.folderList}>
            {props.folders.map((folder) => (
              <ComponentFolderNavButton
                key={folder._id}
                name={folder.name ?? "Folder"}
                onClick={() => props.onFolderClick(folder)}
              />
            ))}
          </div>
        )}
      </div>

      <Scrollbar className={style.scrollArea}>
        <div className={style.componentList}>
          {props.componentIds.map((componentId) => (
            <ComponentTextItemWrapper
              key={componentId}
              selectedComponent={props.selectedComponent}
              componentId={componentId}
              componentFamilyAtom={props.componentFamilyAtom}
              usersByIdAtom={props.usersByIdAtom}
              onComponentClick={props.onComponentClick}
              onComponentLinkClick={props.onComponentLinkClick}
              onComponentCancelClick={props.onComponentCancelClick}
              originalText={props.originalText}
            />
          ))}

          {props.componentIds.length === 0 && props.searchQuery && (
            <div className={style.noResults}>
              <Text textAlign="center" color="secondary" size="small">
                Nothing found for "{props.searchQuery}"
              </Text>
              <Button level="secondary" size="small" onClick={() => props.onSearchQueryChange("")}>
                Clear search
              </Button>
            </div>
          )}

          {props.componentIds.length === 0 && !props.searchQuery && (
            <div className={style.emptyLibrary}>
              {/* 
              TODO: full copy should be:

              Your library has no components yet. Publish a text item from a project or create a new component in the library.

              but we don't have the ability to publish a text item from a project yet
             */}
              <Text inline textAlign="center" color="secondary" size="small">
                Your library has no components yet. Create a new component in{" "}
                <Text color="action" inline size="small" onClick={props.onLibraryLinkClick}>
                  the library.
                </Text>
              </Text>
            </div>
          )}
        </div>
      </Scrollbar>
    </div>
  );
}

function ComponentTextItemWrapper(props: {
  selectedComponent: ILibraryComponent | null;
  componentId: string;
  componentFamilyAtom: (id: string | null) => ReturnType<FamilyAtomType> | PrimitiveAtom<null>;
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  onComponentClick: (component: ILibraryComponent) => void;
  onComponentLinkClick: (component: ILibraryComponent) => void;
  onComponentCancelClick: (component: ILibraryComponent) => void;
  originalText?: string;
}) {
  const usersByUserId = useAtomValue(props.usersByIdAtom);
  const component = useAtomValue(props.componentFamilyAtom(props.componentId));
  if (!component) return null;

  const assignee = component?.assignee ? usersByUserId[component.assignee] : null;

  const isSelected = props.selectedComponent?._id === component._id;
  return (
    <ButtonWrapFooter
      key={component._id}
      visible={isSelected}
      theme="purple"
      renderSeparator
      primaryText="Link"
      onPrimary={() => props.onComponentLinkClick(component)}
      secondaryText="Cancel"
      onSecondary={() => props.onComponentCancelClick(component)}
      helperText={props.originalText !== component.text ? "Text will be updated to match" : ""}
    >
      <TextItemStatic
        borderColor={isSelected ? "transparent" : undefined}
        level="compact"
        state={"default"}
        component={component}
        showComponentInstances
        defaultText={component.text}
        defaultValue={component.rich_text}
        onClick={() => props.onComponentClick(component)}
        status={component.status}
        notes={component.notes}
        tags={component.tags}
        assignee={assignee ?? undefined}
        instanceCount={component.instances.length}
      />
    </ButtonWrapFooter>
  );
}

function FallbackComponentList() {
  return (
    <div className={style.compactLibraryComponentListWrapper}>
      <TextInput className={style.searchInput} placeholder="Search in the library..." value={""} onChange={() => {}} />

      <div className={style.listHeader}>
        <div className={style.topRow}>
          <Text className={style.headerText} weight="strong" size="small">
            All components
          </Text>

          {/* Dummy button for now -- filtering coming in later user story! */}
          <Toggle size="small" pressed={false} onPressedChange={() => {}}>
            <Icon Icon={<FilterIcon />} size="xs" />
            Filter
          </Toggle>
        </div>

        <div className={style.folderList}>
          <Skeleton className={style.folderListSkeleton} height={32} />
          <Skeleton className={style.folderListSkeleton} height={32} />
        </div>
      </div>

      <div className={style.scrollArea}>
        <div className={style.componentList}>
          <Skeleton className={style.componentListSkeleton} height={50} />
          <Skeleton className={style.componentListSkeleton} height={50} />
          <Skeleton className={style.componentListSkeleton} height={50} />
          <Skeleton className={style.componentListSkeleton} height={50} />
        </div>
      </div>
    </div>
  );
}

CompactLibraryComponentList.Fallback = FallbackComponentList;

export default CompactLibraryComponentList;
