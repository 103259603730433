import { IRole } from "@shared/types/User";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../defs";
import { UserWithAccess } from "../permissions/FolderAccessList";

import http, { API } from "../../http";

interface InviteFolderUserProps {
  folder: {
    _id: string;
    name: string;
  };
  currentUser: {
    _id: string;
    email: string;
    name: string;
    role: IRole;
  };
  onHide: () => void;
}
const useInviteFolderUsers = (props: InviteFolderUserProps) => {
  const { folder, currentUser, onHide } = props;

  const [hasNotInvitedUsers, setHasNotInvitedUsers] = useState<boolean>(true);
  const [usersWithAccess, setUsersWithAccess] = useState<UserWithAccess[]>([]);

  const history = useHistory();

  const invitedEmails = useMemo(() => {
    const temp = usersWithAccess.reduce((acc, curr) => {
      acc[curr.email] = true;
      return acc;
    }, {});
    temp[currentUser.email] = true;
    return temp;
  }, [usersWithAccess, currentUser]);

  const onUserRemoved = async (email: string) => {
    const userToRemove = usersWithAccess.find((user) => user.email === email);

    if (userToRemove) {
      await handleRemoveUser(userToRemove.type, {
        _id: userToRemove._id,
        email: userToRemove.email,
      });
      setUsersWithAccess((prev) => {
        let temp = prev;
        temp = temp.filter((t) => t.email !== email);
        return [...temp];
      });
    }
  };

  const handleSendInvite = () => {
    setHasNotInvitedUsers(false);
    fetchFolderInvitedUsers();
  };

  const handleRemoveUser = async (type: "existing" | "invited", user: { _id: string | null; email: string }) => {
    try {
      const { url, body } = API.invite.post.removeFolderUser;
      await http.post(
        url(folder._id),
        body({
          type,
          user,
        })
      );

      const userRemovedSelf = user._id == currentUser._id;
      if (userRemovedSelf) {
        return;
      }

      await fetchFolderInvitedUsers();
    } catch (error) {
      console.error("Error removing");
    }
  };
  const fetchFolderInvitedUsers = async () => {
    try {
      const { url } = API.invite.get.folder;
      const { data } = await http.get(url(folder._id));
      setUsersWithAccess(data);
    } catch (error) {
      console.error("Error fetching invited folder users");
      console.error(error);
    }
  };

  const goToFolder = () => {
    history.push(routes.nonNavRoutes.folder.getPath(folder._id));
  };

  const onComplete = () => {
    goToFolder();
    onHide();
  };

  useEffect(() => {
    fetchFolderInvitedUsers();
  }, []);

  return {
    hasNotInvitedUsers,
    usersWithAccess,
    invitedEmails,
    onUserRemoved,
    handleSendInvite,
    onComplete,
  };
};

export { InviteFolderUserProps, useInviteFolderUsers };
