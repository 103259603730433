import { z } from "zod";

export const ZGetCommentsByPageDocIdRequest = {
  params: z.object({
    doc_id: z.string(),
  }),
};
export const ZGetCommentsByPageDocIdResponse = z.unknown();
export const GetCommentsByPageDocIdMethod = "GET";
export const GetCommentsByPageDocIdPath = "/changes/comments/:doc_id";

export const ZGetActivityByItemIdsRequest = {
  query: z.object({
    itemIds: z.string().array(),
    projectId: z.string().optional(),
  }),
};
export const ZGetActivityByItemIdsResponse = z.unknown();
export const GetActivityByItemIdsMethod = "GET";
export const GetActivityByItemIdsPath = "/changes";

export const ZGetDittoProjectActivityRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
  }),
};
export const ZGetDittoProjectActivityResponse = z.unknown();
export const GetDittoProjectActivityMethod = "GET";
export const GetDittoProjectActivityPath = "/changes/project/:projectId";

export const ZGetTextItemActivityRequest = {
  params: z.object({
    textItemId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
  }),
};
export const ZGetTextItemActivityResponse = z.unknown();
export const GetTextItemActivityMethod = "GET";
export const GetTextItemActivityPath = "/changes/text-item/:textItemId";
