import EmailIcon from "@mui/icons-material/EmailOutlined";
import React from "react";
import style from "./style.module.css";
import { UserSuggestionProps } from "./types";

const UserSuggestion = ({ item, displayRole }: UserSuggestionProps & { displayRole: boolean }) => {
  if (item.type === "existing") {
    return (
      <div id={item._id} className={style.userSuggestion}>
        <div className={style.userName}>{item.name}</div>
        <div className={style.userEmail}>{item.email}</div>
      </div>
    );
  }

  return (
    <div id={item._id} className={style.userSuggestion}>
      <EmailIcon className={style.emailIcon} />
      <span className={style.inviteText}>Invite</span>
      {displayRole ? (
        <>
          <span className={style.emailSuggestion}>{`${item.email} as`}</span>
          <span className={style.userRole}>{item?.role}</span>
        </>
      ) : (
        <span className={style.emailSuggestion}>{item.email}</span>
      )}
    </div>
  );
};

export const UserSuggestionWithRole = (props: UserSuggestionProps) => {
  return <UserSuggestion {...props} displayRole={true} />;
};

export const UserSuggestionWithoutRole = (props: UserSuggestionProps) => {
  return <UserSuggestion {...props} displayRole={false} />;
};
