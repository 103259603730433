import { IProjectSummary, UpsertGroup } from "@shared/types/http/project";
import { IProject } from "@shared/types/Project";
import http from ".";
import { UserInvitation } from "../components/permissions/InviteUsersInput/types";
import { createHttpRequest } from "./lib/createHttpRequest";

const ROOT = "/api/projects";

async function upsertGroup(arg: { projectId: string; groupId: string; data: UpsertGroup.Request["body"] }) {
  const url = `${ROOT}/${arg.projectId}/groups/${arg.groupId}`;
  const { data } = await http.put<UpsertGroup.Response["body"]>(url, arg.data);

  return UpsertGroup.response.body.parse(data);
}

export default {
  upsertGroup,
  put: {
    updateProject: {
      url: (projectId: string) => `${ROOT}/${projectId}`,
      body: ({ name }: { name: string }) => ({ name }),
    },
    updateFeatureFlag: {
      url: (projectId: string) => `${ROOT}/${projectId}/feature-flags`,
      body: ({ flagName, enabled }: { flagName: string; enabled: boolean }) => ({
        flagName,
        enabled,
      }),
    },
  },
  get: {
    all: {
      url: ROOT,
    },
    isLocked: {
      url: (projectId: string) => `${ROOT}/${projectId}/is-locked`,
    },
    branchInfo: {
      url: (projectId: string, shouldValidateFigma: boolean) =>
        `${ROOT}/${projectId}/branch-info${shouldValidateFigma ? "?validateFigma=true" : ""}`,
    },
    featureFlags: {
      url: (projectId: string) => `${ROOT}/${projectId}/feature_flags`,
    },
  },
  post: {
    create: {
      url: ROOT,
      body: ({ projectName, folderId }: { projectName: string; folderId: string | null }) => ({
        projectName,
        folderId,
      }),
    },
    createTextItem: {
      url: (projectId, groupId) => `${ROOT}/${projectId}/groups/${groupId}/textItem`,
      body: ({ text, status, notes, tags }: { text: string; status: string; notes?: string; tags?: string[] }) => ({
        text,
        status,
        notes,
        tags,
      }),
    },
    invite: {
      url: (projectId) => `${ROOT}/${projectId}/invite`,
      body: ({
        invites,
        folderId,
        message,
      }: {
        invites: UserInvitation[];
        folderId: string | null;
        message: string;
      }) => ({ invites, folderId, message }),
    },
  },
  delete: {
    deleteTextItem: {
      url: (projectId, groupId, textItemId) => `${ROOT}/${projectId}/groups/${groupId}/textItem/${textItemId}`,
    },
  },
};

export const getProjectGroups = createHttpRequest<
  { projectId: string; groupIds?: string[] },
  { integrations: IProject["integrations"]; groups: IProject["groups"] }
>({
  method: "get",
  getUrl: (args) => {
    const { projectId, groupIds } = args;
    let url = `/doc/groups/${projectId}`;
    if (groupIds && groupIds.length > 0) {
      url += `?groupIds=${groupIds.join(",")}`;
    }
    return url;
  },
});

export const getProjectSummaries = createHttpRequest<{ projectIds: string[] } | void, IProjectSummary[]>({
  method: "get",
  url: `${ROOT}/summaries`,
  getConfig: (args) => {
    if (!args) return {};
    return { params: { projectIds: args.projectIds } };
  },
});

export const updateProjectFeatureFlag = createHttpRequest<
  { projectId: string; flagName: string; enabled: boolean; flagMetadata?: object },
  void
>({
  method: "put",
  getUrl: (args) => `${ROOT}/${args.projectId}/feature-flags`,
  getConfig: (args) => ({
    data: { flagName: args.flagName, enabled: args.enabled, ...args.flagMetadata },
  }),
});

interface UpdateGroupNameArgs {
  newName: string;
  projectId: string;
  groupId: string;
  groupIndex: number | undefined;
  onSuccess?: (upsertResult: Awaited<ReturnType<typeof upsertGroup>>) => void;
}

// Promise to track any ongoing updateGroupName request
let updateGroupNamePromise: Promise<void> | null = null;
// Timer used for debouncing, ensuring only the last call within a certain time executes
let updateGroupNameTimer: NodeJS.Timeout | null = null;

/**
 * Debounces the update of a group's name, ensuring that only the last call within a 500ms window is executed.
 * If a previous update is in progress, subsequent updates will wait for it to complete before proceeding.
 */
export const updateGroupName = (args: UpdateGroupNameArgs): Promise<void> => {
  // If there's already a timer running, clear it to prevent the previous call from executing
  if (updateGroupNameTimer) {
    clearTimeout(updateGroupNameTimer); // This prevents the function from running if a new call is made before the timeout
  }

  // Return a new Promise that will resolve once the debounced update is complete
  return new Promise((resolve) => {
    // Start a new timer that will wait 500ms before executing the update logic
    updateGroupNameTimer = setTimeout(async () => {
      // If there's an ongoing update process, wait for it to complete before starting a new one
      if (updateGroupNamePromise) {
        await updateGroupNamePromise; // Ensures no race conditions between multiple requests
      }

      // Now initiate the update process and assign it to the updateGroupNamePromise
      updateGroupNamePromise = (async () => {
        try {
          const result = await upsertGroup({
            projectId: args.projectId,
            groupId: args.groupId,
            data: { name: args.newName, group_index: args.groupIndex },
          });

          args.onSuccess?.(result);
        } catch (error) {
          console.error("Failed to update group name:", error);
        } finally {
          // Reset the promise to null, allowing future updates to proceed
          updateGroupNamePromise = null;
        }
      })();

      // Wait for the current update process to complete before resolving the outer promise
      await updateGroupNamePromise;
      resolve();
    }, 500);
  });
};

export const getNorthStarProjectSummaries = createHttpRequest<{ projectIds: string[] } | void, IProjectSummary[]>({
  method: "get",
  url: `ditto-project/summaries`,
  getConfig: (args) => {
    if (!args) return {};
    return { params: { projectIds: args.projectIds } };
  },
});
