import CloseIcon from "@mui/icons-material/Close";
import { IVariable } from "@shared/types/Variable";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import http, { API } from "../../../../http/index";
import style from "./style.module.css";

interface Props {
  variable: IVariable;
  handleUpdateName: (newName: string) => void;
  onHide: () => void;
}
const EditVariableNameModal = ({ variable, handleUpdateName, onHide }: Props) => {
  const [nameInput, setNameInput] = useState<string>(variable.name);
  const [variableNames, setVariableNames] = useState<string[]>([]);
  const [loadingRename, setLoadingRename] = useState<boolean>(false);

  const nameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedName = e.target.value.replace(/[^a-z0-9_]/gi, "");
    setNameInput(updatedName);
  };

  async function getVariableNames() {
    const { url } = API.variable.get.all;
    const response = await http.get(url);
    const { data: variables } = response;
    if (!variables) {
      throw Error(`Error doing request to ${url} because ${response.statusText}`);
    }
    return variables.filter((v) => !!v).map((v) => v.name);
  }

  const onUpdateName = async () => {
    try {
      setLoadingRename(true);
      await handleUpdateName(nameInput);
      onHide();
    } catch (err) {
      console.error("Error updating variable name");
    } finally {
      setLoadingRename(false);
    }
  };
  useEffect(() => {
    getVariableNames()
      .then((names) => setVariableNames(names))
      .catch((err) => console.error(err));
  }, []);

  const nameError = nameInput !== variable.name && variableNames.includes(nameInput);

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>Variable name</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <input
            className={classnames({ [style.errorInput]: nameError })}
            value={nameInput}
            onChange={nameInputChange}
          />
          {nameError && (
            <div className={style.error}>
              A variable with this name already exists in your workspace! Variable names must be unique.
            </div>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>
          <ButtonPrimary
            text={loadingRename ? "Renaming..." : "Rename"}
            onClick={onUpdateName}
            disabled={variableNames.includes(nameInput.trim()) || nameInput.trim().length == 0 || loadingRename}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
};

export default EditVariableNameModal;
