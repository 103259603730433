import { buildDittoClient } from "@shared/client/buildDittoClient";
import * as DittoEvents from "@shared/ditto-events";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { REFRESH } from "@shared/frontend/stores/symbols";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { atom, PrimitiveAtom, WritableAtom } from "jotai";
import asyncMutableDerivedAtom from "./asyncMutableDerivedAtom";

type FamilyAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"];
type ResetAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["resetAtom"];
type UpdateComponentActionAtomType = WritableAtom<
  null,
  [data: { _id: string; update: ILibraryComponent | typeof REFRESH }],
  void
>;
type HandleLibraryComponentsUpdatedActionAtomType = WritableAtom<
  null,
  [data: DittoEvents.ILibraryComponentsUpdatedData],
  void
>;
type HandleLibraryComponentCreatedActionAtomType = WritableAtom<
  null,
  [data: DittoEvents.ILibraryComponentCreatedData],
  void
>;
type LibraryComponentFoldersAtomType = ReturnType<
  typeof asyncMutableDerivedAtom<ILibraryComponentFolder[]>
>["valueAtom"];

interface LibraryComponentsStore {
  componentFamilyAtom: FamilyAtomType;
  nullableComponentFamilyAtom: (id: string | null) => ReturnType<FamilyAtomType> | PrimitiveAtom<null>;
  resetAtom: ResetAtomType;
  updateComponentActionAtom: UpdateComponentActionAtomType;
  handleLibraryComponentsUpdatedActionAtom: HandleLibraryComponentsUpdatedActionAtomType;
  handleLibraryComponentCreatedActionAtom: HandleLibraryComponentCreatedActionAtomType;
  libraryComponentFoldersAtom: LibraryComponentFoldersAtomType;
}

export function createLibraryComponentsStore(client: ReturnType<typeof buildDittoClient>): LibraryComponentsStore {
  const { familyAtom: componentFamilyAtom, resetAtom: resetComponentFamilyAtomActionAtom } =
    batchedAsyncAtomFamily<ILibraryComponent>({
      asyncFetchRequest: async (get, ids) => {
        const data = await client.libraryComponent.getLibraryComponents({ componentIds: ids });
        return data.components;
      },
      getId: (item) => item._id.toString(),
      debugPrefix: "Library Component",
      throttleOptions: {
        leading: true,
      },
    });

  const nullAtom = atom(null);
  /**
   * This is a wrapper around the componentFamilyAtom that returns an atom that is null if the id is null.
   * This is useful for when we want to fetch a component by a TextItem's ws_comp field.
   */
  const nullableComponentFamilyAtom = function (id: string | null) {
    if (id === null) {
      // it's ABSOLUTELY CRITICAL that we define this atom outside of this function! if we instead do
      // return atom(null) here, then the componentFamilyAtom will be re-evaluated on every render,
      // which will cause a re-render loop.
      return nullAtom;
    } else {
      return componentFamilyAtom(id);
    }
  };

  /**
   * This is an action atom that updates a component. Since the return value of componentFamilyAtom could
   * be a null atom, we need to check if the component is null and manually case the type to update.
   */
  const updateComponentActionAtom = atom(
    null,
    (get, set, data: { _id: string; update: ILibraryComponent | typeof REFRESH }) => {
      const componentAtom = componentFamilyAtom(data._id);
      const component = get(componentAtom);

      // make sure the component's not null, then we can do normal batchedAsyncAtomFamily updates
      if (component !== null) {
        if (data.update === REFRESH) {
          set(componentAtom as ReturnType<typeof componentFamilyAtom>, REFRESH);
        } else {
          set(componentAtom as ReturnType<typeof componentFamilyAtom>, {
            ...component,
            ...data.update,
          });
        }
      }
    }
  );

  const handleLibraryComponentsUpdatedActionAtom = atom(
    null,
    (get, set, data: DittoEvents.ILibraryComponentsUpdatedData) => {
      for (const componentId of data.libraryComponentIds) {
        set(updateComponentActionAtom, { _id: componentId, update: REFRESH });
      }
    }
  );

  const { valueAtom: libraryComponentFoldersAtom } = asyncMutableDerivedAtom<ILibraryComponentFolder[]>({
    loadData: async () => {
      const data = await client.libraryComponentFolder.getLibraryComponentFolders({} as never);
      return data.folders;
    },
  });

  const handleLibraryComponentCreatedActionAtom = atom(
    null,
    async (get, set, data: DittoEvents.ILibraryComponentCreatedData) => {
      const _val = await get(componentFamilyAtom(data.componentId));
    }
  );

  return {
    componentFamilyAtom,
    nullableComponentFamilyAtom,
    resetAtom: resetComponentFamilyAtomActionAtom,
    updateComponentActionAtom,
    handleLibraryComponentsUpdatedActionAtom,
    handleLibraryComponentCreatedActionAtom,
    libraryComponentFoldersAtom,
  };
}
