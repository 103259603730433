import CompactLabel from "@/components/CompactLabel";
import ApiID from "@/components/api-id";
import ButtonPrimary from "@/components/button/buttonprimary";
import ButtonSecondary from "@/components/button/buttonsecondary";
import { useWorkspace } from "@/store/workspaceContext";
import MoveVariantModal from "@/views/Variants/components/MoveVariantModal";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CodeIcon from "@mui/icons-material/Code";
import EditIcon from "@mui/icons-material/Edit";
import { default as InfoOutlined, default as InfoOutlinedIcon } from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@shared/frontend/Tooltip";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { IVariantFolder } from "@shared/types/VariantFolder";
import classnames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import TextareaAutosize from "react-textarea-autosize";
import { UnsavedChangesContext } from "../../../../store/unsavedChangesContext";
import { VariantContext } from "../../VariantContext";
import style from "./EditVariant.module.css";
import EditVariantNameModal from "./EditVariantNameModal";

export interface ISelectedVariant {
  _id: string;
  name: string;
  apiID: string;
  description: string;
  isSample: boolean;
  integrations: ("split" | "launchDarkly")[];
  instances: (
    | {
        type: "project";
        projectId: string;
        projectName: string;
        groupsAttached: number;
        groupsTotal: number;
      }
    | {
        type: "NSproject";
        projectId: string;
        projectName: string;
      }
    | {
        type: "component";
        componentId: string;
        componentName: string;
      }
  )[];
}

interface IProps {
  variant: ISelectedVariant;
  variantActions: {
    onDelete: (variantId: string) => void;
    onMoveToFolder: (variantId: string, folder: { id: string; name: string }) => void;
    onUpdateDescription: (variantId: string, updatedDescription: string) => void | Promise<void>;
    onUpdateName: (variantId: string, updatedName: string) => void;
    onEditApiID: (variantId: string, apiId: string) => Promise<string>;
  };

  variantFolders?: IVariantFolder[];
  selectedFolderId?: string;
}

interface IEditState {
  description: string;
}

export const EditVariant = (props: IProps) => {
  const { variant, variantActions } = props;

  /**
   * This context is necessary for using the MoveVariantModal, but the component
   * can be used outside of it if props are passed in.
   */
  const variantContext = useContext(VariantContext);
  const variantFolders = props.variantFolders || variantContext?.variantFolders || [];
  const selectedFolderId = props.selectedFolderId || variantContext?.selectedFolderId || null;

  const { workspaceInfo } = useWorkspace();
  const foldersAllowed = workspaceInfo?.plan === "growth" || workspaceInfo?.plan === "enterprise";

  const initialState = useRef<IEditState>({
    description: variant.description || "",
  });
  const [editState, setEditState] = useState<IEditState>({
    description: initialState.current.description,
  });

  const hasUnsavedChanges = editState.description !== initialState.current.description;

  /**
   * Responsible for re-initializing state if the `variant` prop changes.
   * I wanted to avoid a `useEffect` for this, but seemed messier to do it
   * using a `key` prop that lives outside of this component.
   */
  useEffect(
    function syncInternalStateWithProps() {
      initialState.current = { description: props.variant.description };
      setEditState(initialState.current);
    },
    [props.variant]
  );

  const [isSaving, setIsSaving] = useState(false);

  const isEditEnabled = userHasResourcePermission("variant_folder:edit");

  const handleSaveChanges = () => {
    return variantActions.onUpdateDescription(variant._id, editState.description);
  };

  const onSubmitButtonClick = async () => {
    if (!hasUnsavedChanges || isSaving) return;

    setIsSaving(true);
    await handleSaveChanges();
    setIsSaving(false);
  };

  const submitButtonDisabled = !hasUnsavedChanges || isSaving;
  const submitButtonText = isSaving ? "Saving..." : "Save Edits";

  const handleResetChanges = () => {
    setEditState((prevState) => {
      return {
        ...prevState,
        description: initialState.current.description || "",
      };
    });
  };

  const onCancelButtonClick = () => handleResetChanges();
  const cancelButtonDisabled = !hasUnsavedChanges || isSaving;

  /**
   * This context is necessary for interacting with the unsaved changes modal.
   */
  const {
    canSaveEdits: [_, setCanSaveEditsUnsavedChangesContext],
    setModalParams,
  } = useContext(UnsavedChangesContext);

  useEffect(
    function registerUnsavedChangesContext() {
      setModalParams({
        saveCallback: handleSaveChanges,
        discardCallback: () => {
          handleResetChanges();
          setCanSaveEditsUnsavedChangesContext(false);
        },
      });
    },
    [setModalParams, handleSaveChanges, handleResetChanges]
  );

  useEffect(
    function syncUnsavedChangesContext() {
      setCanSaveEditsUnsavedChangesContext(hasUnsavedChanges);
    },
    [hasUnsavedChanges]
  );

  useEffect(function clearUnsavedChangesOnUnmount() {
    return () => setCanSaveEditsUnsavedChangesContext(false);
  }, []);

  const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setEditState((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false);
  const onMoveToFolderClick = () => setShowMoveToFolderModal(true);
  const handleHideMoveToFolderModal = () => setShowMoveToFolderModal(false);

  const onSubmitMoveToFolderModal = (folder: { id: string; name: string }) => {
    variantActions.onMoveToFolder(variant._id, folder);
    handleHideMoveToFolderModal();
  };

  function onDeleteVariantClick() {
    variantActions.onDelete(variant._id);
  }

  const [showEditVariantNameModal, setShowEditVariantNameModal] = useState(false);
  const onEditVariantNameClick = () => setShowEditVariantNameModal(true);
  const onHideEditVariantNameModal = () => setShowEditVariantNameModal(false);

  function onSaveVariantName(updatedName: string) {
    variantActions.onUpdateName(variant._id, updatedName);
    setShowEditVariantNameModal(false);
  }

  return (
    <div className={style.container}>
      <div className={classnames(style.editComp, "edit-component")}>
        {variant.isSample && (
          <div className={style.sampleInfo}>
            <div className={style.sampleInfoIcon}>
              <InfoOutlined fontSize="inherit" />
            </div>
            &nbsp;This is a sample variant.
          </div>
        )}
        {!isEditEnabled && <div className={style.grayInfoMessage}>You don't have edit access to this variant.</div>}
        {isEditEnabled && variant.integrations.length > 0 && (
          <div className={style.grayInfoMessage}>
            This variant is currently synced to {getIntegrationName(variant.integrations[0])}.
          </div>
        )}
        <div className={style.variantNameContainer}>
          <div className={style.variantName}>
            <span>
              <CallSplitIcon transform="rotate(90)" className={style.variantNameIcon} />
            </span>
            <span className={style.name}>{variant.name}</span>
            {isEditEnabled && (
              <span>
                <EditIcon className={style.variantNameEditIcon} onClick={onEditVariantNameClick} />
              </span>
            )}
          </div>
          {isEditEnabled && (!!variant.instances.length || foldersAllowed) && (
            <Dropdown>
              <Dropdown.Toggle>
                <MoreVertIcon className={style.more_icon} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {foldersAllowed && (
                  <Tooltip
                    hideOnClick={false}
                    disabled={!variant.isSample}
                    className={style.tooltip}
                    content={<div className={style.body}>Sample data cannot be moved to other folders.</div>}
                    placement="top"
                    theme="dark"
                  >
                    <div>
                      <Dropdown.Item disabled={variant.isSample} onClick={onMoveToFolderClick}>
                        Move to folder
                      </Dropdown.Item>
                    </div>
                  </Tooltip>
                )}
                {!variant.instances.length && !variant.integrations.length && (
                  <Tooltip
                    hideOnClick={false}
                    disabled={!variant.isSample}
                    className={style.tooltip}
                    content={<div className={style.body}>Deleting is disabled for sample data.</div>}
                    theme="dark"
                  >
                    <div>
                      <Dropdown.Item
                        disabled={variant.isSample}
                        onClick={onDeleteVariantClick}
                        className={style.danger}
                      >
                        Delete variant
                      </Dropdown.Item>
                    </div>
                  </Tooltip>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className={style.form}>
          {hasUnsavedChanges && (
            <div className={style.warning}>
              <span>You have unsaved changes!</span>
            </div>
          )}
          <div>
            <div className={style.descriptionArea}>
              <CompactLabel Icon={InfoOutlinedIcon} text="Description" className={style.descriptionLabel} />
              <div className={style.textBoxWrapper}>
                <TextareaAutosize
                  placeholder="No description written."
                  disabled={!isEditEnabled}
                  value={editState.description}
                  onChange={onDescriptionChange}
                  maxRows={8}
                />
              </div>
            </div>
          </div>
          <div className={style.buttons}>
            <ButtonSecondary text="Cancel" onClick={onCancelButtonClick} disabled={cancelButtonDisabled} />
            <ButtonPrimary text={submitButtonText} onClick={onSubmitButtonClick} disabled={submitButtonDisabled} />
          </div>
        </div>
      </div>
      <div>
        <div className={style.idSection}>
          <CompactLabel text="Developer ID" Icon={CodeIcon} className={style.idLabel} />
          <ApiID
            id={variant.apiID}
            className={style.variantApiId}
            onEdit={(apiID) => variantActions.onEditApiID(variant._id, apiID)}
            canEdit={isEditEnabled}
            projectLevel={false}
          />
        </div>
      </div>
      {showEditVariantNameModal && (
        <EditVariantNameModal
          variantName={variant.name}
          onHide={onHideEditVariantNameModal}
          onUpdateName={onSaveVariantName}
        />
      )}
      {showMoveToFolderModal && (
        <MoveVariantModal
          variants={[variant]}
          variantFolders={variantFolders.filter((v) => !v.isSample)}
          onHide={handleHideMoveToFolderModal}
          onSubmit={onSubmitMoveToFolderModal}
          selectedFolderId={selectedFolderId}
        />
      )}
    </div>
  );
};

function getIntegrationName(integration: "launchDarkly" | "split") {
  if (integration === "launchDarkly") {
    return "LaunchDarkly";
  }

  if (integration === "split") {
    return "Split";
  }

  throw new Error("Unsupported variant integration: " + integration);
}
