export function chunkArray<T>(array: T[], size: number): T[][] {
  const result: T[][] = [];
  let current = 0;
  while (current < array.length) {
    result.push(array.slice(current, current + size));
    current += size;
  }
  return result;
}

export function insertAt<T>(array: T[], index: number, ...elementsArray: T[]) {
  array.splice(index, 0, ...elementsArray);
}

export function removeFrom<T>(array: T[], index: number, numItems: number) {
  array.splice(index, numItems);
}

export function isSuperset<T>(array: T[], target: T[]): boolean {
  return array.every((item) => target.includes(item));
}

/**
 * Ensures that the provided array has at least one value.
 * Useful for using with jotai soonAll
 */
export function isNonEmptyArray<T>(value: T[]): value is [T, ...T[]] {
  return Array.isArray(value) && value.length > 0;
}
