/**
 * When performing a shift-click or an arrow-shift selection, we need to determine the existing selections that are not
 * contiguous with the new selections. This function takes care of that logic.
 *
 * @param selectedIds The set of selected item IDs.
 * @param allItems All item IDs in the context.
 * @param currentIndex The clicked element index, or the 'pointer' index in case of an arrow-shift selection.
 * @param mostRecentlySelectedIndex The 'pivot' index of the shift range selection.
 * @param startIndex Start index of the shift range selection.
 * @param endIndex End index of the shift range selection.
 *
 * Note: `currentIndex` and `endIndex`, as well as `mostRecentlySelectedIndex` and `startIndex`, are designed to account for
 * both mouse-based shift-click selection and keyboard-driven arrow-shift selection. In pure shift-click, these values will
 * typically be equal, as the selection forms a contiguous range. However, if command-click is used to
 * select non-contiguous items, these parameters may differ, since `currentIndex` can point to individual selections and
 * the notion of a continuous range may not apply.
 *
 * @return The elements which are not contiguous with the new selections.
 */
export function getPriorSelections(
  selectedIds: string[],
  allItems: { _id: string; sortKey: string }[],
  currentIndex: number,
  mostRecentlySelectedIndex: number,
  startIndex: number,
  endIndex: number
) {
  /**
   * Contiguous prior selections are selections in the opposite direction of the current selection that are contiguous
   * with the pivot, i.e. the most recently selected item. This is needed to effectively unselect the prior pivot-based
   * selections.
   */
  const contiguousPriorSelectionsFromStart = (() => {
    const priorSelections: string[] = [];

    const seekDirection = currentIndex < mostRecentlySelectedIndex ? 1 : -1;

    for (let i = mostRecentlySelectedIndex; i >= 0 && i < allItems.length; i += seekDirection) {
      const id = allItems[i]._id;
      if (selectedIds.includes(id)) {
        priorSelections.push(id);
      } else {
        break;
      }
    }

    return new Set(priorSelections);
  })();

  const contiguousPriorSelectionsFromEnd = (() => {
    const priorSelections: string[] = [];

    const seekDirection = currentIndex < mostRecentlySelectedIndex ? -1 : 1;

    for (let i = currentIndex; i >= 0 && i < allItems.length; i += seekDirection) {
      const id = allItems[i]._id;
      if (selectedIds.includes(id)) {
        priorSelections.push(id);
      } else {
        break;
      }
    }

    return new Set(priorSelections);
  })();

  const allItemsIdToIndexMap = new Map(allItems.map((item, index) => [item._id, index]));

  // If there are selections present that are not between the start and end indices, include them in the new selections,
  // unless they are contiguous with the start or end indices.
  const alreadyPresentSelections = selectedIds
    .filter((id) => {
      const index = allItemsIdToIndexMap.get(id);
      return index !== undefined && (index < startIndex || index >= endIndex);
    })
    .filter((id) => !contiguousPriorSelectionsFromStart.has(id) && !contiguousPriorSelectionsFromEnd.has(id));

  return alreadyPresentSelections;
}
