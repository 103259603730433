import { ZLibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { z } from "zod";

export const ZGetLibraryComponentFoldersRequest = {
  query: z.object({
    folderIds: z.array(z.string()).optional(),
  }),
};
export const ZGetLibraryComponentFoldersResponse = z.object({
  folders: z.array(ZLibraryComponentFolder),
});
export const GetLibraryComponentFoldersMethod = "GET";
export const GetLibraryComponentFoldersPath = "/library-component-folder";
