import classnames from "classnames";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Text from "../Text";
import style from "./index.module.css";

interface IBreadcrumbLinkProps {
  className?: string;
  style?: React.CSSProperties;
  text: string;
  route: string;
}

export function BreadcrumbLink(props: IBreadcrumbLinkProps) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(props.route);
  }, [props.route, history]);

  return (
    <Text
      color="secondary"
      onClick={handleClick}
      className={classnames(style.BreadcrumbWrapper, props.className)}
      data-testid="breadcrumb"
    >
      {props.text}
    </Text>
  );
}

export default BreadcrumbLink;
