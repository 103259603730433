import { UserInvitation } from "@/components/permissions/InviteUsersInput/types";
import { IChangeItem } from "@shared/types/ActualChange";
import {
  IDittoProject,
  IDittoProjectBlock,
  IDittoProjectBlockUpdate,
  ZTextItemsUpdate,
} from "@shared/types/DittoProject";
import { IFigmaGroup } from "@shared/types/FigmaGroup";
import {
  GetSyncedFigmaPagesResponse,
  ICreateBlocksProps,
  ICreateDittoProject,
  ICreateTextItemsFromWebAppProps,
  IDittoProjectData,
  IGetFramePreviewsData,
  IMoveTextItemsAction,
  ISyncedTextNodesMapData,
  ITextItemsMapData,
} from "@shared/types/http/DittoProject";
import { IFigmaSyncRequestBody } from "@shared/types/http/figma";
import { IObjectId } from "@shared/types/lib";
import { ITextItem } from "@shared/types/TextItem";
import { z } from "zod";
import { InviteToken } from "../../schema/inviteToken.types";
import { createHttpRequest } from "./lib/createHttpRequest";

export const updateTextItems = createHttpRequest<{
  projectId: string;
  updates: z.infer<typeof ZTextItemsUpdate>[];
  variantId?: string;
  newVariant?: {
    name: string;
    variantId: string;
  };
}>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ updates, variantId, newVariant }) {
    return {
      data: { updates, variantId, newVariant },
    };
  },
});

export const getProject = createHttpRequest<
  {
    projectId: string;
    projectContentSearchQuery?: string;
    statuses?: string[];
    tags?: string[];
    assignees?: string[];
    pages?: string[];
  },
  IDittoProjectData
>({
  method: "get",
  getConfig({ projectContentSearchQuery, statuses, tags, assignees, pages }) {
    return {
      params: {
        projectContentSearchQuery,
        statuses,
        tags,
        assignees,
        pages,
      },
    };
  },
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
});

export const updateProject = createHttpRequest<
  { projectId: string; projectData: Partial<IDittoProjectData> },
  IDittoProjectData
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
  getConfig({ projectData }) {
    return {
      data: { projectData },
    };
  },
});

export const deleteProject = createHttpRequest<{ projectId: string }, { success: boolean }>({
  method: "delete",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
});

export const inviteUsersToProject = createHttpRequest<
  { projectId: string; invitations: UserInvitation[]; message: string },
  { invites: Pick<InviteToken, "email" | "role" | "permissionGroups" | "data">[] }
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/invite`;
  },
  getConfig({ invitations, message }) {
    return {
      data: {
        invitations,
        message,
      },
    };
  },
});

export const getTextItems = createHttpRequest<{ ids: string[]; projectId: string }, ITextItem[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const createTextItems = createHttpRequest<
  { projectId: string } & ICreateTextItemsFromWebAppProps,
  { textItems: ITextItem[]; block?: IDittoProjectBlock }
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ textItems, addToStart }) {
    return {
      data: {
        textItems,
        addToStart,
        source: "web_app",
      },
    };
  },
});

export const deleteTextItem = createHttpRequest<{ projectId: string; textItemId: string }, ITextItem[]>({
  method: "delete",
  getUrl({ projectId, textItemId }) {
    return `/ditto-project/${projectId}/text-items/${textItemId}`;
  },
});

export const getBlocks = createHttpRequest<{ ids: string[]; projectId: string }, IDittoProjectBlock[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const createBlocks = createHttpRequest<{ projectId: string } & ICreateBlocksProps, IDittoProjectBlock[]>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blocks, atIndex, source }) {
    return {
      data: {
        blocks,
        atIndex,
        source,
      },
    };
  },
});

export const deleteBlocks = createHttpRequest<{ projectId: string; blockIds: string[] }, void>({
  method: "delete",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blockIds }) {
    return {
      data: { blockIds },
    };
  },
});

export const getActivityByProjectId = createHttpRequest<
  { projectId: string; page: number; pageSize: number },
  IChangeItem[]
>({
  method: "get",
  getUrl({ projectId }) {
    return `/changes/project/${projectId}`;
  },
  getConfig({ page, pageSize }) {
    return {
      params: { skip: page * pageSize, limit: pageSize },
    };
  },
});

export const getActivityByItemIds = createHttpRequest<{ projectId?: string; itemIds: string[] }, IChangeItem[]>({
  method: "get",
  getUrl() {
    return `/changes`;
  },
  getConfig({ itemIds, projectId }) {
    return {
      params: { itemIds, projectId },
    };
  },
});

export const getActivityByTextItemId = createHttpRequest<
  { textItemId: string; page?: number; pageSize?: number },
  IChangeItem[]
>({
  method: "get",
  getUrl({ textItemId }) {
    return `/changes/text-item/${textItemId}`;
  },
  getConfig({ page, pageSize }) {
    if (page === undefined || pageSize === undefined) {
      return {};
    }
    return {
      params: { skip: page * pageSize, limit: pageSize },
    };
  },
});

export const reorderTextItems = createHttpRequest<
  { projectId: string; actions: IMoveTextItemsAction[] },
  Record<string, string>
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-text-items`;
  },
  getConfig({ actions }) {
    return {
      data: { actions },
    };
  },
});

export const getAllTags = createHttpRequest<{ projectId: string }, string[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/tags`;
  },
  getConfig({ projectId }) {
    return {
      params: { projectId },
    };
  },
});

export const updateBlocks = createHttpRequest<
  { projectId: string; blocks: IDittoProjectBlockUpdate[] },
  IDittoProjectBlock[]
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blocks }) {
    return {
      data: {
        blocks,
      },
    };
  },
});

export const reorderBlocks = createHttpRequest<{ projectId: string; blockIds: string[]; newIndex: number }>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks/reorder`;
  },
  getConfig({ blockIds, newIndex }) {
    return {
      data: {
        blockIds,
        newIndex,
      },
    };
  },
});
export const getActivePreviewsJob = createHttpRequest<{ projectId: string }, { jobId: string | null }>({
  method: "get",
  getUrl({ projectId }) {
    return `/jobs/ditto-project-figma-previews/${projectId}`;
  },
});

export const getTopLevelFramesMap = createHttpRequest<{ projectId: string }, Record<string, IFigmaGroup[]>>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/top-level-frames`;
  },
});

export const getFramePreviewsMap = createHttpRequest<{ projectId: string }, IGetFramePreviewsData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/frame-previews`;
  },
});

export const getTextItemsMap = createHttpRequest<{ projectId: string }, ITextItemsMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items-map`;
  },
});

export const getSyncedTextNodesMap = createHttpRequest<{ projectId: string }, ISyncedTextNodesMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/synced-text-nodes-map`;
  },
});

export const createDittoProject = createHttpRequest<ICreateDittoProject, IDittoProject>({
  method: "post",
  url: "/ditto-project/create",
  getConfig: (args) => ({
    data: args,
  }),
});

export const moveProjectToFolder = createHttpRequest<{ projectId: string; folderId: string | null }, IDittoProject>({
  method: "put",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-to-folder`;
  },
  getConfig({ folderId }) {
    return {
      data: { folderId },
    };
  },
});

export const getSyncedFigmaPages = createHttpRequest<{ projectId: string }, GetSyncedFigmaPagesResponse>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/synced-figma-pages`;
  },
});

export const syncFigma = createHttpRequest<IFigmaSyncRequestBody, { jobId: string }>({
  method: "post",
  url: "/figma/sync",
  getUrl({ projectId }) {
    return `/figma/sync/${projectId}`;
  },
  getConfig(data) {
    return { data };
  },
});

export const removeVariant = createHttpRequest<
  { variantId: IObjectId; projectId: IObjectId; textItemIds: IObjectId[] },
  void
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/remove-variant`;
  },
  getConfig({ variantId, textItemIds }) {
    return {
      data: { variantId, textItemIds },
    };
  },
});
