import useAutoScroll, { composeCallbacks } from "@/hooks/useAutoScroll";
import { reorderTextItemsActionAtom } from "@/stores/Editing";
import {
  blockFamilyAtom,
  INavBlockItem,
  isValidBlock,
  moveBlocksActionAtom,
  projectBlocksAtom,
} from "@/stores/Project";
import { onClickBlockActionAtom, selectedBlockIdAtom, selectionTypeAtom } from "@/stores/ProjectSelection";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import NavItem from "@ds/molecules/NavigatorRow";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { leftSidebarScrollRefAtom } from ".";
import { textItemListScrollRefAtom } from "../../TextItemList";
import style from "./style.module.css";

function BlockNavItem(props: { item: INavBlockItem; textRefs: React.RefObject<HTMLDivElement>[] }) {
  const selectionType = useAtomValue(selectionTypeAtom);
  const block = useAtomValue(blockFamilyAtom(props.item._id));
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const onClickBlockAction = useSetAtom(onClickBlockActionAtom);
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const leftSidebarScrollContainer = useAtomValue(leftSidebarScrollRefAtom);
  const moveBlockItemsAction = useSetAtom(moveBlocksActionAtom);

  const projectBlocksAtoms = useAtomCallback((get) => get(projectBlocksAtom));
  const scrollProps = useAutoScroll(leftSidebarScrollContainer, textItemScrollContainer);

  const getDraggableItems = useCallback(
    function _getDraggableItems() {
      return [
        {
          "ditto/blockItem": block._id,
          "plain/text": block._id,
        },
      ];
    },
    [block._id]
  );

  async function handleDrop(itemIds: string[], dragLocation: "above" | "below" | null) {
    /**
     * When dropping text items onto a block, we always infer that we should insert them at
     * the top of the block, since this is in a navbar context.
     */
    if (selectionType === "text") {
      const blocks = await projectBlocksAtoms();

      const currentBlock = blocks.find((b) => b._id === props.item._id)!;

      const referenceTextItem = currentBlock.allTextItems[0];

      reorderTextItemsAction([
        {
          textItemIds: itemIds,
          blockId: props.item._id,
          before: referenceTextItem?._id,
        },
      ]);
    } else {
      moveBlockItemsAction({
        blockIds: itemIds,
        destinationBlockId: block._id,
        direction: dragLocation,
      });
    }
  }

  if (!isValidBlock(block)) return <></>;

  function onDragStart(e: DragStartEvent) {
    if (props.item._id) {
      onClickBlockAction(props.item._id);

      // Set data-state="dragging" on associated text items
      props.textRefs.forEach((ref) => {
        if (ref && ref.current) {
          ref.current.setAttribute("data-state", "dragging");
        }
      });
    }
  }

  function resetDragState() {
    // Remove data-state from associated text items
    props.textRefs.forEach((ref) => {
      if (ref && ref.current) {
        ref.current.removeAttribute("data-state");
      }
    });
  }

  function onDragEnd() {
    resetDragState();
  }

  function onDragCancel() {
    resetDragState();
  }

  const dragProps = composeCallbacks([scrollProps, { onDragStart, onDragEnd, onDragCancel }]);

  const isSelected = selectedBlockId === props.item._id;

  return (
    <DragAndDroppable
      className={style.draggableNavItem}
      getDraggableItems={getDraggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string(), "ditto/blockItem": z.string() }}
      onDrop={handleDrop}
      onDragStart={onDragStart}
      {...dragProps}
      selectionType={selectionType}
    >
      <div data-selectiontype="block" data-dragindicatorabove className={style.dropIndicatorWrapper}>
        <div className={style.dropIndicator} />
      </div>
      <NavItem
        key={block._id}
        className={style.navItem}
        type="block"
        pressed={isSelected}
        onChange={(pressed) => {
          if (pressed) onClickBlockAction(block._id!);
        }}
      >
        {block.name || "Untitled Block"}
      </NavItem>
      <div
        data-selectiontype="text"
        data-dragindicatorbelow
        className={classNames(style.dropIndicatorWrapper, style.textItem)}
      >
        <div className={style.dropIndicator} />
      </div>
      <div data-selectiontype="block" data-dragindicatorbelow className={style.dropIndicatorWrapper}>
        <div className={style.dropIndicator} />
      </div>
    </DragAndDroppable>
  );
}

export default BlockNavItem;
