import { IVariant } from "@shared/types/Variant";
import { ITextItemVariant } from "../../shared/types/TextItem";
import { createHttpRequest } from "./lib/createHttpRequest";

const url = (path: string) => `/variant${path}`;

export const updateDescription = createHttpRequest<{ variantId: string; description: string }, IVariant>({
  method: "put",
  getUrl: (args) => url(`/description/${args.variantId}`),
  getConfig: (args) => ({
    data: { description: args.description },
  }),
});

export const getForWorkspace = createHttpRequest<void, IVariant[]>({
  method: "get",
  url: url(""),
});

export const getForComponents = createHttpRequest<void, IVariant[]>({
  method: "get",
  url: url(`?components=true`),
});

export const getForProject = createHttpRequest<{ projectId: string }, IVariant[]>({
  method: "get",
  getUrl: (args) => url(`?project_id=${args.projectId}`),
});

export const updateMultipleTextItemVariants = createHttpRequest<
  {
    projectId: string;
    textItemsToUpdate: {
      textItemIds: string[];
      variantId: string;
    }[];
    updates: Partial<ITextItemVariant>;
  },
  void
>({
  method: "post",
  getUrl: () => url(`/textItems`),
  getConfig: (args) => ({ data: args }),
});

export const updateMultipleComponentVariants = createHttpRequest<
  {
    componentsToUpdate: {
      componentIds: string[];
      variantId: string;
    }[];
    updates: Partial<ITextItemVariant>;
  },
  void
>({
  method: "post",
  getUrl: () => url(`/components`),
  getConfig: (args) => ({ data: args }),
});

export const updateVariantTextInstances = createHttpRequest<
  {
    projectId: string;
    allTextItemsToUpdate: {
      textItemId: string;
      componentId?: string;
      variantId: string;
    }[];
    updates: Partial<ITextItemVariant>;
  },
  void
>({
  method: "post",
  getUrl: () => url(`/textInstances`),
  getConfig: (args) => ({ data: args }),
});
