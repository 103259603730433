import {
  libraryActivityAtom,
  libraryActivityLoadingAtom,
  libraryFetchNextActivityPageActionAtom,
  libraryHasMoreActivityAtom,
} from "@/stores/Activity";
import Scrollbar from "@ds/molecules/Scrollbar";
import ChangeItemList from "@ds/organisms/ChangeItemList";
import { useAtomValue, useSetAtom } from "jotai";
import LibraryGeneralActivityItem from "./LibraryGeneralActivityItem";
import style from "./style.module.css";

function LibraryGeneralActivityPanel() {
  const allActivity = useAtomValue(libraryActivityAtom);
  const activityLoading = useAtomValue(libraryActivityLoadingAtom);
  const hasMoreActivity = useAtomValue(libraryHasMoreActivityAtom);
  const fetchNextActivityPageAction = useSetAtom(libraryFetchNextActivityPageActionAtom);

  return (
    <Scrollbar className={style.scrollArea}>
      <ChangeItemList
        changeItems={allActivity}
        ChangeItemComponent={LibraryGeneralActivityItem}
        hasMore={hasMoreActivity}
        isLoadingMore={activityLoading}
        fetchMore={fetchNextActivityPageAction}
      />
    </Scrollbar>
  );
}

export default LibraryGeneralActivityPanel;
