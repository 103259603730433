import { ZCommentType } from "@shared/types/CommentThread";
import { ZDittoProject, ZTextItemConflictResolution, ZTextItemsUpdate } from "@shared/types/DittoProject";
import {
  ZCreateBlocksProps,
  ZCreateTextItemsFromPluginProps,
  ZCreateTextItemsFromWebAppProps,
  ZMoveTextItemsProps,
} from "@shared/types/http/DittoProject";
import { ZObjectId } from "@shared/types/lib";
import { ZFigmaV2Instance, ZTextItemStatus } from "@shared/types/TextItem";
import { ZRole } from "@shared/types/User";
import { z } from "zod";

const DITTO_PROJECT_BASE_PATH = "/ditto-project";

export const ZUpdateTextItemsRequest = {
  body: z.object({
    updates: z.array(ZTextItemsUpdate),
    variantId: z.string().optional(),
    newVariant: z
      .object({
        name: z.string(),
        variantId: ZObjectId,
      })
      .optional(),
  }),
};
export const ZUpdateTextItemsResponse = z.unknown();

export const ZConnectTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    figmaTextNodeInstancesByTextItemId: z.record(z.string(), z.array(ZFigmaV2Instance)),
    fromBlockSuggestion: z.boolean(),
  }),
};
export const ZConnectTextItemsResponse = z.unknown();

export const ZDisconnectTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    figmaNodeIdsByTextItemId: z.record(z.string(), z.array(z.string())),
  }),
};
export const ZDisconnectTextItemsResponse = z.unknown();

export const ZCreateTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.union([ZCreateTextItemsFromPluginProps, ZCreateTextItemsFromWebAppProps]),
};
export const ZCreateTextItemsResponse = z.unknown();

export const ZUnlinkTextItemRequest = {
  params: z.object({
    projectId: z.string(),
    textItemId: z.string(),
  }),
  body: z.object({
    figmaNodeId: z.string(),
  }),
};
export const ZUnlinkTextItemResponse = z.unknown();

export const ZDeleteTextItemRequest = {
  params: z.object({
    projectId: z.string(),
    textItemId: z.string(),
  }),
};
export const ZDeleteTextItemResponse = z.unknown();

export const ZResolveTextItemConflictRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    projectId: ZObjectId,
    update: ZTextItemConflictResolution,
  }),
};
export const ZResolveTextItemConflictResponse = z.unknown();

export const ZGetProjectDataRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    projectContentSearchQuery: z.string().optional(),
    statuses: z.array(ZTextItemStatus).optional(),
    tags: z.array(z.string()).optional(),
    assignees: z.array(z.string()).optional(),
    pages: z.array(z.string()).optional(),
  }),
};
export const ZGetProjectDataResponse = z.unknown();

export const ZDeleteProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZDeleteProjectResponse = z.unknown();

export const ZUpdateProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    projectData: ZDittoProject.partial(),
  }),
};
export const ZUpdateProjectResponse = z.unknown();

export const ZGetTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    ids: z.array(z.string()).optional(),
  }),
};
export const ZGetTextItemsResponse = z.unknown();

export const ZGetBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    ids: z.array(z.string()).optional(),
  }),
};
export const ZGetBlocksResponse = z.unknown();

export const ZGetProjectsRequest = {
  query: z.object({
    search: z.string().optional(),
    skip: z.string().optional(),
    limit: z.string().optional(),
    figma: z.string().optional(),
  }),
};
export const ZGetProjectsResponse = z.unknown();

export const ZConnectFigmaToProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    fileId: z.string(),
    branchId: z.string().optional(),
    dittoComponentNodeId: z.string(),
    dittoComponentKey: z.string(),
  }),
};
export const ZConnectFigmaToProjectResponse = z.unknown();

export const ZInviteUsersToProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    invitations: z.array(
      z.object({
        type: z.enum(["new", "existing"]),
        role: ZRole,
        permissionGroups: z.array(z.string()),
        email: z.string().email(),
        name: z.string(),
        isAlreadyInvited: z.boolean(),
      })
    ),
    message: z.string(),
  }),
};
export const ZInviteUsersToProjectResponse = z.unknown();

export const ZCreateBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: ZCreateBlocksProps,
};
export const ZCreateBlocksResponse = z.unknown();

export const ZDeleteBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    blockIds: z.array(z.string()),
  }),
};
export const ZDeleteBlocksResponse = z.unknown();

export const ZUpdateBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    blocks: z.array(
      z.object({
        _id: z.string(),
        name: z.string().optional(),
        frameCount: z.number().optional(),
      })
    ),
  }),
};
export const ZUpdateBlocksResponse = z.unknown();

export const ZReorderBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    blockIds: z.array(z.string()),
    newIndex: z.number(),
  }),
};
export const ZReorderBlocksResponse = z.unknown();

export const ZMoveTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: ZMoveTextItemsProps,
};
export const ZMoveTextItemsResponse = z.unknown();

export const ZValidateLegacyProjectRequest = {
  params: z.object({
    legacyDocId: z.string(),
  }),
  query: z.object({
    fileId: z.string(),
    branchId: z.string().optional(),
    dittoComponentNodeId: z.string(),
    dittoComponentKey: z.string(),
  }),
};
export const ZValidateLegacyProjectResponse = z.unknown();

export const ZValidateProjectRequest = {
  params: z.object({
    projectId: z.string().optional(),
  }),
  query: z.object({
    fileId: z.string(),
    branchId: z.string().optional(),
    dittoComponentNodeId: z.string(),
    dittoComponentKey: z.string(),
  }),
};
export const ZValidateProjectResponse = z.unknown();

export const ZGetProjectSummariesInWorkspaceRequest = {
  query: z.object({
    projectIds: z.array(z.string()).optional(),
  }),
};
export const ZGetProjectSummariesInWorkspaceResponse = z.unknown();

export const ZGetAllProjectTagsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetAllProjectTagsResponse = z.unknown();

export const ZCreatePopulatedDittoProjectRequest = {
  body: z
    .object({
      name: z.string(),
      numberOfBlocks: z.number(),
      maxNumberOfTextItemsInBlocks: z.number(),
      textItemsOutsideOfBlocks: z.number(),
    })
    .partial(),
};
export const ZCreatePopulatedDittoProjectResponse = z.unknown();

export const ZGetTopLevelFramesMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetTopLevelFramesMapResponse = z.unknown();

export const ZGetFramePreviewsMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetFramePreviewsMapResponse = z.unknown();

export const ZGetTextItemsMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetTextItemsMapResponse = z.unknown();

export const ZGetSyncedTextNodesMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetSyncedTextNodesMapResponse = z.unknown();

export const ZMoveProjectToFolderRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    folderId: ZObjectId.nullable(),
  }),
};
export const ZMoveProjectToFolderResponse = z.unknown();

export const ZGetMostRecentCommentThreadIdByTextItemIdRequest = {
  params: z.object({
    projectId: z.string(),
    textItemId: z.string(),
  }),
  query: z.object({
    type: ZCommentType.optional(),
  }),
};
export const ZGetMostRecentCommentThreadIdByTextItemIdResponse = z.object({ _id: ZObjectId }).nullable();
export const GetMostRecentCommentThreadIdByTextItemIdMethod = "GET";
export const GetMostRecentCommentThreadIdByTextItemIdPath = `${DITTO_PROJECT_BASE_PATH}/:projectId/text-items/:textItemId/comments/most-recent`;

export const ZGetSyncedFigmaPagesRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetSyncedFigmaPagesResponse = z.unknown();

export const ZRemoveVariantFromTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    variantId: ZObjectId,
    textItemIds: z.array(ZObjectId),
  }),
};
export const ZRemoveVariantFromTextItemsResponse = z.unknown();
