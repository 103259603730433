import { removeDiacritics } from "@shared/utils/removeDiacritics";
import ObjectId from "bson-objectid";

import {
  IApiIdCasingAdjustment,
  IApiIdReplacementCharacter,
  IComponentsApiIdGenerationConfig,
} from "@shared/types/Workspace";
import { Applicator, DEFAULT_COMPONENTS_CONFIG, UNDERSCORE_REGEX, camelCaseString, tryToParseRegex } from "./lib";

const REGEX_CHARS_TO_ESCAPE = new Set([".", "-", "_"]);

export const regexCharWrapper = (char: string) => (REGEX_CHARS_TO_ESCAPE.has(char) ? `\\${char}` : char);

export const regexExpressionWrapper = (pattern: string, exceptions: string[]) =>
  `[^${pattern}|${exceptions.join("|")}]`;

export const getApplyAcceptedCharsFunction = (pattern: string | null, exceptions: string[]): Applicator => {
  if (!pattern) {
    return (v) => v;
  }

  const regex = tryToParseRegex(regexExpressionWrapper(pattern, exceptions), "g");
  return (v) => v.replace(regex || "", "");
};

const getApplyCasingAdjustmentComponentFunction = (
  adjustment: IApiIdCasingAdjustment | null,
  /**
   * Needed for special behavior case explained in the body of this function.
   */
  slashReplacement: IApiIdReplacementCharacter | null
): Applicator => {
  if (!adjustment) {
    return (v) => v;
  }

  if (adjustment === "lower") {
    return (v) => v.toLocaleLowerCase();
  }

  if (adjustment === "upper") {
    return (v) => v.toLocaleUpperCase();
  }

  /**
   * If slashes are being removed and not replaced with another delimiter,
   * camelCasing the string ends up having the same effect as lowercasing it.
   *
   * Therefore, special behavior when slash replacement is an empty string:
   * camelCase across the value as a whole rather than camelCasing each delimited
   * substring individually.
   */
  if (slashReplacement === "") {
    return (v) => {
      if (!v?.length) return v;
      return camelCaseString(v, (c) => [" ", "/"].includes(c), adjustment === "pascal");
    };
  }

  return (v) => {
    if (!v?.length) return v;

    return v
      .split("/")
      .map((str) => camelCaseString(str, (c) => [" "].includes(c), adjustment === "pascal"))
      .join("/");
  };
};

const getApplySlashReplacementFunction = (replacement: IApiIdReplacementCharacter | null): Applicator => {
  if (replacement === null) {
    return (v) => v;
  }

  return (v) =>
    v
      // remove whitespace around slashes
      .replace(/\s*\/\s*/g, "/")
      // slashes --> replacement character
      .replace(/\//g, replacement);
};

const getApplySpaceReplacementFunction = (replacement: IApiIdReplacementCharacter | null): Applicator => {
  if (replacement === null) {
    return (v) => v;
  }

  return (v) =>
    v
      // whitespace --> replacement character
      .replace(/\s{1,}/g, replacement);
};

type createNameToApiIdArgs = {
  config: Partial<IComponentsApiIdGenerationConfig>;

  /**
   * A list of characters that should be accepted by the API ID generator, even if they
   * would otherwise be excluded by the `acceptedCharsPattern` config.
   */
  acceptedCharsExceptions?: string[];

  /**
   * A list of steps to skip as part of the API ID generation process.
   */
  skipSteps?: Set<Steps>;
};

/**
 * Creates a function for transforming names to API IDs. Generates all applicator functions
 * ahead of time to ensure performance inside of an API ID generator.
 * @param config A configuration for generating API IDs, found in the following places:
 * - `workspace.config.components.apiIdGeneration`
 */
const createNameToApiIdFunction = (
  args: createNameToApiIdArgs
): ((name: string, prefix?: string, fallbackId?: string) => string) => {
  const { config } = args;

  const acceptedCharsExceptions: string[] = [];
  if (args.acceptedCharsExceptions) acceptedCharsExceptions.push(...args.acceptedCharsExceptions.map(regexCharWrapper));
  if (config.spaceReplacement) acceptedCharsExceptions.push(regexCharWrapper(config.spaceReplacement));
  if (config.slashReplacement) acceptedCharsExceptions.push(regexCharWrapper(config.slashReplacement));

  // Make sure you heavily consider the implications of changing the order
  // of these applicators before doing so. The order in which they're applied
  // WILL affect the resulting API ID.
  const applicators: Applicator[] = [];

  if (!args.skipSteps?.has("acceptedChars")) {
    applicators.push(getApplyAcceptedCharsFunction(config.acceptedCharsPattern ?? null, acceptedCharsExceptions));
  }
  if (!args.skipSteps?.has("casingAdjustment")) {
    applicators.push(
      getApplyCasingAdjustmentComponentFunction(config.casingAdjustment ?? null, config.slashReplacement ?? null)
    );
  }
  if (!args.skipSteps?.has("slashReplacement")) {
    applicators.push(getApplySlashReplacementFunction(config.slashReplacement ?? null));
  }
  if (!args.skipSteps?.has("spaceReplacement")) {
    applicators.push(getApplySpaceReplacementFunction(config.spaceReplacement ?? null));
  }

  return (name, prefix = "id_", fallbackId) => {
    let apiId = applicators.reduce((acc, fn) => fn(acc), removeDiacritics(name));

    const isEmpty = apiId === "";
    const isJustPrefix = UNDERSCORE_REGEX.test(apiId);
    if (isEmpty || isJustPrefix) {
      apiId = `${prefix}${fallbackId || new ObjectId()}`;
    }

    return apiId;
  };
};

type Steps = "acceptedChars" | "casingAdjustment" | "slashReplacement" | "spaceReplacement";

interface createApiIdGeneratorArgs {
  config?: Partial<IComponentsApiIdGenerationConfig> & { maxLength?: number };
  initialApiIds?: string[];
  acceptedCharsExceptions?: string[];
  skipSteps?: Set<Steps>;
}

/**
 * The most performant way to generate batches of API IDs. Returns a function that you can call over and over which
 * will return an API ID for a given name that has been deduped against the results of any previous function calls.
 * @param config A configuration for generating API IDs, found in the following places:
 * - `workspace.config.components.apiIdGeneration`
 * @param initialApiIds an optional array of initial values to populate the generator with for deduping against.
 */
export const createApiIdGenerator = function createApiIdGenerator(
  {
    config = DEFAULT_COMPONENTS_CONFIG,
    initialApiIds = [],
    acceptedCharsExceptions = ["\\s", "\\/"],
    skipSteps = undefined,
  }: createApiIdGeneratorArgs = {
    config: DEFAULT_COMPONENTS_CONFIG,
    acceptedCharsExceptions: ["\\s", "\\/"],
    skipSteps: undefined,
  }
) {
  const apiIds = new Set<string>(initialApiIds);
  const nameToApiId = createNameToApiIdFunction({
    config,
    acceptedCharsExceptions,
    skipSteps,
  });
  const dedupeApiId = getDedupeFunction(config.acceptedCharsPattern);

  return {
    /**
     * @param apiId the API ID to add to the generator's internal set of API IDs for deduping against.
     */
    add: (apiId: string) => {
      apiIds.add(apiId);
    },
    /** Takes an API ID and dedupes it against the generator's internal set of API IDs.
     * @returns the deduped API ID
     */
    dedupe: (apiId: string) => {
      const apiIdDeduped = dedupeApiId(apiId, apiIds);
      apiIds.add(apiIdDeduped);
      return apiIdDeduped;
    },
    /**
     * Generates an API ID and adds it to the generator's internal set of API IDs for deduping against.
     * @param name
     * @param prefix
     * @returns
     */
    generate: (name: string, prefix?: string, fallbackId?: string) => {
      let apiId = nameToApiId(name, prefix, fallbackId);
      if (config.maxLength) {
        apiId = apiId.substring(0, config.maxLength);
      }
      const apiIdDeduped = dedupeApiId(apiId, apiIds);
      apiIds.add(apiIdDeduped);
      return apiIdDeduped;
    },
  };
};

const deduperWithHyphen = (apiId: string, apiIds: Set<string>) => dedupe(apiId, apiIds, "-", /-(\d+)$/);

// edge case: if api id ends in a number, full number will be incremented
const deduperWithEmptyString = (apiId: string, apiIds: Set<string>) => dedupe(apiId, apiIds, "", /(\d+)$/);

function getDedupeFunction(acceptedCharsPattern: string | null | undefined) {
  if (!acceptedCharsPattern) {
    return deduperWithHyphen;
  }

  const regex = tryToParseRegex(regexExpressionWrapper(acceptedCharsPattern, []), "g");
  if (!regex) {
    return deduperWithHyphen;
  }

  const hyphenIsSupported = "-".replace(regex, "") === "-";
  if (hyphenIsSupported) {
    return deduperWithHyphen;
  }

  return deduperWithEmptyString;
}

function dedupe(apiId: string, apiIds: Set<string>, separator: string, dedupeSubstringPattern: RegExp) {
  let apiIdDeduped = apiId;
  while (apiIds.has(apiIdDeduped)) {
    const lastChar = apiIdDeduped.match(dedupeSubstringPattern);
    if (lastChar) {
      apiIdDeduped = apiIdDeduped.replace(dedupeSubstringPattern, (_match, number) => {
        return `${separator}${parseInt(number, 10) + 1}`;
      });
    } else {
      apiIdDeduped = `${apiId}${separator}1`;
    }
  }
  return apiIdDeduped;
}
