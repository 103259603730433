import TextItemTextInputs from "@/components/TextItemTextInputs/TextItemTextInputs";
import {
  derivedOnlySelectedComponentAtom,
  derivedSelectedComponentsAtom,
  selectedComponentIdsAtom,
} from "@/stores/Library";
import { createNewTagActionAtom, unwrappedAllTagsAtom, usersByIdAtom } from "@/stores/Workspace";
import { TextItemMetaData } from "@ds/organisms/TextItemMetadata";
import { isDiffRichText } from "@shared/lib/text";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { CharacterLimit, RichTextInputProps, RichTextInputRef } from "@shared/types/RichText";
import { ITextItemStatus, ITipTapRichText, ZTextItemPluralType } from "@shared/types/TextItem";
import { IUser } from "@shared/types/User";
import { Editor } from "@tiptap/react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import style from "./style.module.css";

interface SkinnyPlural {
  rich_text: ITipTapRichText;
  form: z.infer<typeof ZTextItemPluralType>;
}

/**
 * If all statuses are the same, return that status. Otherwise, return "MIXED".
 *
 * @param components The text items to check.
 * @returns The merged status.
 */
function getMergedStatus(components: ILibraryComponent[]): ITextItemStatus | "MIXED" {
  const statuses = components.map((textItem) => textItem.status);

  let status: ITextItemStatus | "MIXED" = "MIXED";

  if (statuses.every((status) => status === statuses[0])) {
    status = statuses[0];
  }

  return status;
}

/**
 * If all assignees are the same, return that assignee. Otherwise, return "MIXED".
 *
 * @param components The text items to check.
 * @returns The merged assignee.
 */
function getMergedAssignee(components: ILibraryComponent[], usersById: Record<string, IUser>) {
  const assignees = components.map((textItem) => textItem.assignee);

  if (assignees.every((assignee) => assignee === assignees[0])) {
    // If the common assignee does not exist in the workspace, return null.
    if (!assignees[0] || !usersById[assignees[0]]) return null;

    return { value: assignees[0], label: usersById[assignees[0]].name };
  }

  return { value: "MIXED", label: "Mixed Assignees" };
}

/**
 * If all sets of tags are the same, return that set of tags. Otherwise, return a 'mixed tags' object.
 */
function getMergedTags(components: ILibraryComponent[]): string[] | { mixed: true } {
  if (components.length === 0) {
    return [];
  }

  const referenceTags = [...components[0].tags].sort();

  // Check if every item's tags matches the reference
  for (let i = 1; i < components.length; i++) {
    const currentSorted = [...components[i].tags].sort();

    if (currentSorted.length !== referenceTags.length) {
      return { mixed: true };
    }

    for (let j = 0; j < referenceTags.length; j++) {
      if (currentSorted[j] !== referenceTags[j]) {
        return { mixed: true };
      }
    }
  }

  return referenceTags;
}

/**
 * Returns a list of tags to write and list of tags to delete based on diff between original and current tags.
 *
 * @param baseTags The original base tags in selection.
 * @param tags The current tags in state.
 * @returns The lists of tags to write and delete.
 */
function getTagsChanges(baseTags: string[][], tags: string[]) {
  const baseTagsSet = new Set(baseTags.flat());
  const tagsSet = new Set(tags);

  /**
   * The new behavior is to set all selected text items to the tags currently
   * selected in the tag list. This requires that the set of tags to create is
   * exactly the selected tags, while the set of tags to delete is all other tags
   * which are not selected.
   */
  const tagsToDelete = [...baseTagsSet].filter((baseTag) => !tagsSet.has(baseTag));

  return {
    tagsToWrite: tags,
    tagsToDelete,
  };
}

/**
 * If all texts are the same, return that text. Otherwise, return null.
 */
function getMergedText(components: ILibraryComponent[]) {
  const baseTexts = components.map((textItem) => textItem.rich_text);

  if (baseTexts.every((text) => text === baseTexts[0])) {
    return baseTexts[0];
  }

  return null;
}

/**
 * If there is only one text item, return its notes. Otherwise, return a mixed notes object.
 */
function getMergedNotes(textItems: ILibraryComponent[]) {
  const allNotesSame = textItems.every((t) => t.notes === textItems[0].notes);
  return allNotesSame ? textItems[0].notes : ({ mixed: true } as const);
}

/**
 * The merged character limit is the minimum of all character limits.
 */
function getMergedCharacterLimit(textItems: ILibraryComponent[]) {
  const characterLimits = textItems.map((textItem) => textItem.characterLimit ?? Infinity);

  const minimum = Math.min(...characterLimits);

  return minimum === Infinity ? null : minimum;
}

/**
 * The merged plurals only exist if only one item is selected and it has plurals.
 */
function getMergedPlurals(textItems: ILibraryComponent[]) {
  return textItems.length === 1 ? textItems[0].plurals : null;
}

function LibraryMetadataPanel() {
  const usersById = useAtomValue(usersByIdAtom);
  const allTags = useAtomValue(unwrappedAllTagsAtom);
  const onCreateNewTag = useSetAtom(createNewTagActionAtom);
  const selectedComponentIds = useAtomValue(selectedComponentIdsAtom);
  const selectedComponents = useAtomValue(derivedSelectedComponentsAtom);
  const onlySelectedComponent = useAtomValue(derivedOnlySelectedComponentAtom);

  // Details panel atom state

  const [baseText, setBaseText] = useState(() => getMergedText(selectedComponents));
  const [status, setStatus] = useState(() => getMergedStatus(selectedComponents));
  const [assignee, setAssignee] = useState(() => getMergedAssignee(selectedComponents, usersById));
  const [tags, setTags] = useState(() => getMergedTags(selectedComponents));
  const [notes, setNotes] = useState(() => getMergedNotes(selectedComponents));
  const [characterLimit, setCharacterLimit] = useState<CharacterLimit>(() =>
    getMergedCharacterLimit(selectedComponents)
  );

  const setBaseTextCallback = useCallback((richText: ITipTapRichText) => setBaseText(richText), [setBaseText]);

  // Ref for rich text input so we can reset editor state
  const richTextInputRef = useRef<RichTextInputRef>(null);

  const resetState = useCallback(() => {
    setBaseText(getMergedText(selectedComponents));
    setStatus(getMergedStatus(selectedComponents));
    setAssignee(getMergedAssignee(selectedComponents, usersById));
    setTags(getMergedTags(selectedComponents));
    setNotes(getMergedNotes(selectedComponents));
    setCharacterLimit(getMergedCharacterLimit(selectedComponents));
  }, [selectedComponents, usersById]);

  useLayoutEffect(resetState, [selectedComponentIds, resetState]);

  const [plurals, setPlurals] = useState<SkinnyPlural[] | null>(getMergedPlurals(selectedComponents));

  // TextItemMetaData takes a lot of props, so make sure we are memoizing everything we can!
  const setAssigneeProp = useCallback(
    (value: { id: string; name: string }) => setAssignee(value ? { value: value.id, label: value.name } : null),
    [setAssignee]
  );
  const usersProp = useMemo(() => usersById, [usersById]);
  const assigneeProp = useMemo(() => (assignee ? { id: assignee.value, name: assignee.label } : null), [assignee]);
  const allTagsProp = useMemo(() => allTags.map((tag) => ({ value: tag._id, label: tag._id })), [allTags]);

  const calculateDiffFromState = useCallback(
    (textItem: ILibraryComponent) => {
      const mergedTags = getMergedTags(selectedComponents);
      /**
       * Tags are considered to have changed if the tags were set in the input, and either the original
       * value was a merged set, or the new set of tags is different than the user input value.
       */
      const tagsChanged =
        Array.isArray(tags) &&
        (!Array.isArray(mergedTags) ||
          JSON.stringify(tags.map((t) => t.toLowerCase())) !== JSON.stringify(mergedTags.map((t) => t.toLowerCase())));

      return {
        baseTextChanged: Boolean(baseText) && isDiffRichText(baseText, textItem.rich_text),
        statusChanged: status !== "MIXED" && status !== textItem.status,
        assigneeChanged: assignee?.value !== "MIXED" && (assignee?.value || null) !== textItem.assignee,
        tagsChanged,
        notesChanged: notes !== null && notes !== (textItem.notes ?? "") && typeof notes === "string",
        characterLimitChanged: characterLimit !== (textItem.characterLimit ?? null),
        pluralsChanged:
          plurals !== null &&
          JSON.stringify(plurals) !==
            JSON.stringify(
              textItem.plurals?.map((p) => ({
                rich_text: p.rich_text,
                form: p.form,
              })) || []
            ),
      };
    },
    [selectedComponents, tags, status, baseText, assignee?.value, notes, characterLimit, plurals]
  );

  // Check if changes have been made.
  useEffect(
    function checkForChanges() {
      const anyTextItemChanged = Object.values(selectedComponents).some((textItem) =>
        Object.values(calculateDiffFromState(textItem)).some((value) => value)
      );

      // setEditableHasChanges(anyTextItemChanged);
    },
    [selectedComponents, calculateDiffFromState]
  );

  const resetFormState = useCallback(() => {
    richTextInputRef.current?.reset();
    resetState();
  }, [resetState]);

  const onSave = async () => {
    // TODO: handle save
  };

  return (
    <div className={style.wrapper}>
      <TextItemMetaData
        status={status}
        setStatus={setStatus}
        users={usersProp}
        assignee={assigneeProp}
        setAssignee={setAssigneeProp}
        allTags={allTagsProp}
        tags={tags}
        setTags={setTags}
        onCreateNewTag={onCreateNewTag}
        notes={notes}
        setNotes={setNotes}
        onCancel={resetFormState}
        onSave={onSave}
        onInsertVariable={() => {}}
        showCTAButtons={true}
        onDeleteVariant={() => {}}
        // Only allow text editing if we have a single text item selected and it has no plurals
        richTextInput={
          !!onlySelectedComponent &&
          !onlySelectedComponent.plurals.length && (
            <RichTextInput
              ref={richTextInputRef}
              initialVariableRichValue={onlySelectedComponent as any}
              setBaseText={setBaseTextCallback}
              setPlurals={setPlurals}
              setCharacterLimit={setCharacterLimit}
              characterLimit={characterLimit}
            />
          )
        }
        RichTextInputWithProps={RichTextInput}
        variantPlaceholderText={onlySelectedComponent?.text}
      />
    </div>
  );
}

/**
 * Wrapper for our legacy TextItemTextInput component that wraps TipTap's rich text editor.
 * @param props Wrapper props:
 * - initialVariableRichValue: The value to pass down to the legacy TextItemTextInput component.
 *
 * @important
 * The initialVariableRichValue prop gets passed down to our legacy TextItemTextInput component, and passes down many layers of prop drilling
 * before being used to initialize the TipTap editor. It's important that the value here does *not* change on edit --
 * it's used for initializing editor state, and thus should only change when we're re-initializing the selected page
 * (e.g. changing our selection).
 */
export const RichTextInput = memo(
  forwardRef<RichTextInputRef, RichTextInputProps>(function RichTextInput(props: RichTextInputProps, ref) {
    const { initialVariableRichValue, setBaseText, setPlurals, setCharacterLimit, characterLimit } = props;

    // Ref for the TipTap editor instance
    const editorRef = useRef<Editor | null>(null);

    const handleCharacterLimitChange = useCallback(
      (newCharacterLimit: number | null) => {
        setCharacterLimit(newCharacterLimit);
      },
      [setCharacterLimit]
    );

    const legacyHandleTextChange = useCallback(
      function _legacyHandleTextChange(
        fieldStates: {
          label: string | undefined;
          form: string | undefined;
          value: { text: string; richText: ITipTapRichText; variables: ILibraryComponent["variables"] };
        }[]
      ): void {
        const base = fieldStates.find((fieldState) => fieldState.form === undefined);
        const nonBases = fieldStates.filter((fieldState) => fieldState.form !== undefined);

        if (nonBases.length === 0 && base) {
          setBaseText(base.value.richText);
        } else if (nonBases[0]) {
          setBaseText(nonBases[0].value.richText);
        }

        setPlurals(
          nonBases.map((fieldState) => ({
            rich_text: fieldState.value.richText,
            form: fieldState.form as z.infer<typeof ZTextItemPluralType>,
          }))
        );
      },
      [setBaseText, setPlurals]
    );

    /**
     * Callback for resetting the TipTap editor with initial values
     * TODO: Handle resetting plurals
     */
    const reset = useCallback(() => {
      if (editorRef.current) {
        editorRef.current.commands.setContent(initialVariableRichValue.rich_text);
      }
    }, [initialVariableRichValue.rich_text]);

    // Expose reset function through ref
    useImperativeHandle(
      ref,
      () => ({
        reset,
      }),
      [reset]
    );

    const accessEditorInstance = useCallback((editor: Editor) => {
      editorRef.current = editor;
    }, []);

    const textLabelLeft = useMemo(() => <div></div>, []);

    return (
      <TextItemTextInputs
        overrideClassname={style.richTextInput}
        hideTopLabels={true}
        useNSLabels
        textItem={initialVariableRichValue}
        legacyHandleTextChange={legacyHandleTextChange}
        readonly={false}
        isBaseText={true}
        isVariant={false}
        shouldShowRichText={true}
        handleCharacterLimitChange={handleCharacterLimitChange}
        characterLimit={characterLimit}
        textLabelLeft={textLabelLeft}
        disabled={props.richTextInputDisabled}
        pluralInputsDisabled={props.pluralsDisabled}
        variablesDisabled={props.variablesDisabled}
        characterLimitDisabled={props.characterLimitDisabled}
        placeholder={props.placeholder}
        emptyEditorClass={props.emptyEditorClass}
        accessEditorInstance={accessEditorInstance}
      />
    );
  })
);

export default LibraryMetadataPanel;
