import ArrowBack from "@mui/icons-material/ArrowBack";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import { commaList } from "@shared/frontend/lib/commaList";
import { IResourcePermissionAction } from "@shared/types/PermissionGroups";
import { IPermissionGroupWithUsers, IUser } from "@shared/types/User";
import classNames from "classnames";
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { UnsavedChangesContext } from "../../../store/unsavedChangesContext";
import EditableName from "../../EditableName";
import ButtonPrimary from "../../button/buttonprimary";
import ConfirmationModal from "../../shared/confirmation-modal";
import AddMembersModal from "./AddMembersModal";
import { DeleteGroupModal } from "./DeleteGroupModal";
import style from "./style.module.css";
import { PermissionGroupDetailViewProps, ResourceTypeTabProps } from "./types";

export const PermissionGroupDetailView = (props: PermissionGroupDetailViewProps) => {
  const DEFAULT_GROUP_NAME = "Editor";
  const DEFAULT_GROUP_ID = "editor";

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState<IUser | null>(null);
  const {
    canSaveEdits: [, setHasEdits],
    unsavedDetailChangesExist,
    showUnsavedChangesModal,
    setModalParams,
  } = useContext(UnsavedChangesContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<IPermissionGroupWithUsers>(props.initialGroup);

  setModalParams({
    modalText: {
      title: "Unsaved Changes",
      body: "You have unsaved changes. Are you sure you want to discard them?",
      actionPrimary: "Save Changes",
      actionSecondary: "Discard",
    },
    saveCallback: () => onSaveUnsavedChanges(),
    discardCallback: () => setHasEdits(false),
  });

  const onBackClick = () => {
    if (unsavedDetailChangesExist) {
      showUnsavedChangesModal(() => props.handleBack());
    } else props.handleBack();
  };

  const onAddMembersClick = () => {
    setShowAddMembersModal(true);
  };

  const onRemoveMemberClick = (userId: string) => {
    const user = props.workspaceUsers.find((user) => user._id === userId);
    if (user) setUserToRemove(user);
  };

  const onInviteMembers = (invitedUserEmails: string[]) => {
    setCurrentGroup((prev) => {
      return {
        ...prev,
        invitedUserEmails: prev.invitedUserEmails.concat(invitedUserEmails).sort((a, b) => a.localeCompare(b)),
      };
    });
  };

  const onAddMembers = (userIds: string[]) => {
    setCurrentGroup((prev) => {
      const usersToAdd: IUser[] = [];
      for (const userId of userIds) {
        const user = props.workspaceUsers.find((user) => user._id === userId);
        if (user) {
          usersToAdd.push(user);
        }
      }

      return {
        ...prev,
        users: prev.users.concat(usersToAdd).sort((a, b) => a.email.localeCompare(b.email)),
      };
    });
  };

  const onRemoveMember = (userId: string) => {
    setCurrentGroup((prev) => {
      return {
        ...prev,
        users: prev.users.filter((user) => user._id !== userId),
      };
    });
    setUserToRemove(null);
  };

  const onChangePermissions = (
    resourceId: string,
    resourceType: "project_folder" | "component_folder",
    permissionActions: IResourcePermissionAction[]
  ) => {
    setCurrentGroup((prev) => {
      const prevResources = prev.permissions.resources;
      const currIdx = prevResources.findIndex((r) => r.resource_id === resourceId && r.resource_type === resourceType);

      let newResources = [...prevResources];

      // if we unchecked all permissions for this resource, then remove it entirely;
      // no need to store { resource_id: "abc", permissions: [] }
      if (permissionActions.length === 0) {
        newResources = newResources.filter((r) => r.resource_id !== resourceId || r.resource_type !== resourceType);
      }
      // if the resource already exists in the group, update it
      else if (currIdx !== -1) {
        newResources[currIdx] = {
          ...newResources[currIdx],
          access: permissionActions,
        };
      } else {
        newResources.push({
          resource_id: resourceId,
          resource_type: resourceType,
          access: permissionActions,
        });
      }

      return {
        ...prev,
        permissions: {
          ...prev.permissions,
          resources: newResources,
        },
      };
    });
  };

  const onEditGroupName = (newName: string) => {
    setCurrentGroup((prev) => {
      return {
        ...prev,
        name: newName,
      };
    });
  };

  const onSaveClick = () => {
    if (!unsavedDetailChangesExist) return;
    props.handleSaveGroup(currentGroup._id, currentGroup);
    props.setSelectedGroup(currentGroup);
  };

  const onDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const getRemoveUserPrompt = (user: IUser) => {
    const userNotInOtherGroups = user.permissionGroups.length === 1;

    if (userNotInOtherGroups) {
      return (
        <p>
          Because the user is not in any other permission groups, they will be moved back to the default{" "}
          <strong>{DEFAULT_GROUP_NAME}</strong> group.
        </p>
      );
    }

    return "The user will lose access to the permissions associated with this group.";
  };

  const onRevokeInvite = (email: string) => {
    setCurrentGroup((prev) => {
      return {
        ...prev,
        invitedUserEmails: prev.invitedUserEmails.filter((e) => e !== email),
      };
    });

    props.handleRevokeInvite(email);
  };

  useEffect(
    function checkIfCanSaveChanges() {
      setHasEdits(JSON.stringify(props.initialGroup) !== JSON.stringify(currentGroup));
    },
    [props.initialGroup, currentGroup]
  );

  // This is just a complicated way to get the number of project folders to which the
  // selected group *currently* has access.
  const projectPermissionsCount = useMemo(() => {
    const allResources = currentGroup?.permissions.resources ?? {};
    const projectResources = Object.values(allResources).filter((value) =>
      value.access.some((permission) => permission.action.includes("project"))
    );

    return projectResources.length;
  }, [currentGroup]);

  const componentPermissionsCount = useMemo(() => {
    const allResources = currentGroup?.permissions.resources ?? {};
    const componentResources = Object.values(allResources).filter((value) =>
      value.access.some((permission) => permission.action.includes("component"))
    );

    return componentResources.length;
  }, [currentGroup]);

  const variantPermissionsCount = useMemo(() => {
    const allResources = currentGroup?.permissions.resources ?? {};
    const variantResources = Object.values(allResources).filter((value) =>
      value.access.some((permission) => permission.action.includes("variant"))
    );

    return variantResources.length;
  }, [currentGroup]);

  const variablePermissionsCount = useMemo(() => {
    const allResources = currentGroup?.permissions.resources ?? {};
    const variableResources = Object.values(allResources).filter((value) =>
      value.access.some((permission) => permission.action.includes("variable"))
    );

    return variableResources.length;
  }, [currentGroup]);

  let accessText = "This group has access to ";
  if (props.initialGroup.built_in) {
    accessText = "This is a default group that can ";
    switch (props.initialGroup.name) {
      case "Admin":
      case "Editor":
        accessText += "edit and comment on all resources.";
        break;
      case "Commenter":
        accessText += "comment on all resources.";
        break;
    }
  } else {
    const projectText = projectPermissionsCount
      ? `${projectPermissionsCount} project folder${projectPermissionsCount > 1 ? "s" : ""}`
      : "";

    const componentText = componentPermissionsCount
      ? `${componentPermissionsCount} component folder${componentPermissionsCount > 1 ? "s" : ""}`
      : "";

    const variantText = variantPermissionsCount
      ? `${variantPermissionsCount} variant folder${variantPermissionsCount > 1 ? "s" : ""}`
      : "";

    const variableText = variablePermissionsCount
      ? `${variablePermissionsCount} variable folder${variablePermissionsCount > 1 ? "s" : ""}`
      : "";

    accessText += commaList([projectText, componentText, variantText, variableText], "no resources");
    accessText += ".";
  }

  const onSaveUnsavedChanges = async () => {
    await props.handleSaveGroup(currentGroup._id, currentGroup);
    setHasEdits(false);
  };

  const userIsLastAdmin = useMemo(() => {
    const isAdminGroup = currentGroup._id === "admin";
    if (!isAdminGroup) return false;
    return currentGroup.users.length < 2;
  }, [currentGroup]);

  function userIsOnlyInThisDefaultGroup(user: IUser) {
    return (
      currentGroup.built_in &&
      user.permissionGroups.length === 1 &&
      currentGroup._id === user.permissionGroups[0] &&
      currentGroup._id === DEFAULT_GROUP_ID
    );
  }

  return (
    <div className={style.groupDetails}>
      <div className={style.backRow}>
        <Button className={style.backBtn} variant="link" onClick={onBackClick} data-testid="back-button">
          <ArrowBack className={style.arrowIcon} /> Back to all groups
        </Button>
      </div>

      <div className={style.titleRow}>
        <EditableName
          value={currentGroup.name}
          onSave={onEditGroupName}
          isEditEnabled={!props.initialGroup.built_in && !props.initialGroup.invite_only}
          className={style.groupName}
        />

        <div className={style.buttons}>
          <ButtonPrimary
            data-testid="save-changes-button"
            text="Save Changes"
            className={style.saveBtn}
            disabled={!unsavedDetailChangesExist}
            onClick={onSaveClick}
          />
          {!props.initialGroup.built_in && (
            <Dropdown>
              <Dropdown.Toggle as={OptionsToggle} />
              <Dropdown.Menu className={style.optionsMenu} alignRight>
                <Dropdown.Item className={classNames(style.dropdownItem, style.red)} onClick={onDeleteClick}>
                  Delete group
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>

      <div className={style.topLevelTabs}>
        <Tabs defaultActiveKey="members" id="topLevelTabs" transition={false}>
          <Tab
            eventKey="members"
            title={
              <span>
                Members
                <span className={style.count}>({currentGroup.users.length})</span>
              </span>
            }
            tabClassName={style.tab}
          >
            <div className={style.membersTab}>
              {currentGroup.users.length === 0 && currentGroup.invitedUserEmails.length === 0 && (
                <div className={style.noMembers}>
                  <p className={style.noMembersText}>No group members.</p>
                  <ButtonPrimary
                    data-testid="add-members-btn"
                    text="Add Members"
                    className={style.addMembersBtn}
                    onClick={() => onAddMembersClick()}
                  />
                </div>
              )}
              {(currentGroup.users.length > 0 || currentGroup.invitedUserEmails.length > 0) && (
                <div className={style.membersList}>
                  <table>
                    <tbody>
                      {currentGroup.users.map((user) => (
                        <tr key={user._id.toString()}>
                          <td>
                            <strong>{user.name}</strong>
                          </td>
                          <td>{user.email}</td>
                          <td>
                            {!userIsLastAdmin && !userIsOnlyInThisDefaultGroup(user) && (
                              <Dropdown>
                                <Dropdown.Toggle as={OptionsToggle} />
                                <Dropdown.Menu className={style.optionsMenu} alignRight>
                                  <Dropdown.Item
                                    className={classNames(style.dropdownItem, style.red)}
                                    onClick={() => onRemoveMemberClick(user._id.toString())}
                                  >
                                    Remove from group
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </td>
                        </tr>
                      ))}
                      {currentGroup.invitedUserEmails.map((email) => (
                        <tr key={email}>
                          <td>{email}</td>
                          <td>
                            <span className={style.pendingBadge}>Pending</span>
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle as={OptionsToggle} />
                              <Dropdown.Menu className={style.optionsMenu} alignRight>
                                <Dropdown.Item
                                  className={classNames(style.dropdownItem, style.red)}
                                  onClick={() => onRevokeInvite(email)}
                                >
                                  Revoke invite
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <ButtonPrimary
                    text="Add Members"
                    className={style.addMembersBtn}
                    onClick={() => onAddMembersClick()}
                  />
                </div>
              )}
            </div>
          </Tab>
          <Tab
            eventKey="resources"
            title={
              <span data-testid="resources-tab">
                Resources
                <span className={style.count}>({Object.keys(currentGroup.permissions.resources).length})</span>
              </span>
            }
            tabClassName={style.tab}
          >
            <div className={style.resourcesTab}>
              {/* TODO: dynamic */}
              <p>{accessText}</p>
              <Tabs defaultActiveKey="projects" id="resourcesTabs" transition={false}>
                <Tab
                  eventKey="projects"
                  title={
                    <span>
                      Projects
                      <span className={style.count}>({projectPermissionsCount})</span>
                    </span>
                  }
                  tabClassName={style.tab}
                >
                  <ResourceTab
                    permissions={currentGroup.permissions}
                    workspaceResources={props.projectFolders}
                    onChangePermissions={onChangePermissions}
                    resourceType="project_folder"
                    defaultPermission={currentGroup.built_in ? currentGroup._id : false}
                    inviteOnly={currentGroup.invite_only}
                  />
                </Tab>

                <Tab
                  eventKey="components"
                  title={
                    <span>
                      Components
                      <span className={style.count}>({componentPermissionsCount})</span>
                    </span>
                  }
                  tabClassName={style.tab}
                >
                  <ResourceTab
                    permissions={currentGroup.permissions}
                    workspaceResources={props.componentFolders}
                    onChangePermissions={onChangePermissions}
                    resourceType="component_folder"
                    defaultPermission={currentGroup.built_in ? currentGroup._id : false}
                    inviteOnly={currentGroup.invite_only}
                  />
                </Tab>
                <Tab
                  eventKey="variants"
                  title={
                    <span>
                      Variants
                      <span className={style.count}>({variantPermissionsCount})</span>
                    </span>
                  }
                  tabClassName={style.tab}
                >
                  <ResourceTab
                    permissions={currentGroup.permissions}
                    workspaceResources={props.variantFolders}
                    onChangePermissions={onChangePermissions}
                    resourceType="variant_folder"
                    defaultPermission={currentGroup.built_in ? currentGroup._id : false}
                    inviteOnly={currentGroup.invite_only}
                  />
                </Tab>
                <Tab
                  eventKey="variables"
                  title={
                    <span>
                      Variables
                      <span className={style.count}>({variablePermissionsCount})</span>
                    </span>
                  }
                  tabClassName={style.tab}
                >
                  <ResourceTab
                    permissions={currentGroup.permissions}
                    workspaceResources={props.variableFolders}
                    onChangePermissions={onChangePermissions}
                    resourceType="variable_folder"
                    defaultPermission={currentGroup.built_in ? currentGroup._id : false}
                    inviteOnly={currentGroup.invite_only}
                  />
                </Tab>
              </Tabs>
            </div>
          </Tab>
        </Tabs>
      </div>

      {showAddMembersModal && currentGroup && (
        <AddMembersModal
          groupName={currentGroup.name}
          isDefaultGroup={currentGroup.built_in}
          setShowAddMembersModal={setShowAddMembersModal}
          handleAddMembers={onAddMembers}
          handleInviteMembers={onInviteMembers}
          workspaceUsers={props.workspaceUsers}
          currentUsers={currentGroup.users}
        />
      )}
      {userToRemove && currentGroup && (
        <ConfirmationModal
          title="Are you sure you want to remove this user?"
          body={getRemoveUserPrompt(userToRemove)}
          actionPrimary="Remove"
          onPrimary={() => onRemoveMember(userToRemove._id)}
          actionSecondary="Cancel"
          onSecondary={() => setUserToRemove(null)}
        />
      )}
      {showDeleteModal && (
        <DeleteGroupModal
          groupToDelete={currentGroup}
          handleDeleteGroup={() => props.handleDeleteGroup(currentGroup._id)}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export const OptionsToggle = React.forwardRef<HTMLButtonElement, any>(({ onClick }, ref) => (
  <button
    className={style.optionsBtn}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <MoreVert className={style.dotsIcon} />
  </button>
));

// we're working on two parallel sets of information
//    1. the different resources available in the workspace
//    2. the current resource permissions for this group
const ResourceTab = (props: ResourceTypeTabProps) => {
  // if we uncheck edit, we need to uncheck comment
  const handleEditClick = (e: ChangeEvent<HTMLInputElement>, resourceId: string) => {
    const checked = e.target.checked;
    const currentPermissions =
      props.permissions.resources.find((p) => p.resource_id === resourceId && p.resource_type === props.resourceType)
        ?.access || [];

    let newPermissions: IResourcePermissionAction[] = [];

    if (checked) {
      newPermissions = currentPermissions.concat([{ action: `${props.resourceType}:edit` }]);
      // only concat comment if it's not already there
      if (!currentPermissions.find((p) => p.action.includes("comment"))) {
        newPermissions = newPermissions.concat([{ action: `${props.resourceType}:comment` }]);
      }
    } else {
      newPermissions = currentPermissions.filter((p) => p.action !== `${props.resourceType}:edit`);
    }

    props.onChangePermissions(resourceId, props.resourceType, newPermissions);
  };

  const handleCommentClick = (e: ChangeEvent<HTMLInputElement>, resourceId: string) => {
    const checked = e.target.checked;
    const currentPermissions =
      props.permissions.resources.find((p) => p.resource_id === resourceId && p.resource_type === props.resourceType)
        ?.access || [];

    let newPermissions: IResourcePermissionAction[] = [];

    if (checked) {
      newPermissions = currentPermissions.concat([{ action: `${props.resourceType}:comment` }]);
    } else {
      // if we uncheck comment, we need to uncheck edit, too
      newPermissions = currentPermissions.filter(
        (p) => p.action !== `${props.resourceType}:comment` && p.action !== `${props.resourceType}:edit`
      );
    }

    props.onChangePermissions(resourceId, props.resourceType, newPermissions);
  };

  return (
    <div className={style.resourcesList}>
      <table>
        <thead>
          <tr>
            <th className={style.radio}>
              {["variant_folder", "variable_folder"].includes(props.resourceType) ? "MANAGE" : "EDIT"}
            </th>
            <th className={style.radio}>
              {" "}
              {["variant_folder", "variable_folder"].includes(props.resourceType) ? "USE" : "COMMENT"}
            </th>
            <th>RESOURCE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.workspaceResources.map((resource) => {
            const resourcePermissions = props.permissions.resources.find(
              (r) => r.resource_id === resource._id && r.resource_type === props.resourceType
            );

            const canEdit =
              (props.defaultPermission && ["admin", "editor"].includes(props.defaultPermission)) ||
              !!resourcePermissions?.access.find((p) => p.action.includes("edit"));
            const canComment =
              !!props.defaultPermission || !!resourcePermissions?.access.find((p) => p.action.includes("comment"));

            return (
              <tr key={resource._id}>
                <td>
                  <div className={style.radioWrapper}>
                    <input
                      data-testid="edit-checkbox"
                      type="checkbox"
                      checked={canEdit}
                      disabled={!!props.defaultPermission || !!props.inviteOnly}
                      onChange={(e) => handleEditClick(e, resource._id)}
                    />
                  </div>
                </td>
                <td>
                  <div className={style.radioWrapper}>
                    <input
                      data-testid="comment-checkbox"
                      type="checkbox"
                      checked={canComment}
                      disabled={!!props.defaultPermission || !!props.inviteOnly}
                      onChange={(e) => handleCommentClick(e, resource._id)}
                    />
                  </div>
                </td>
                <td className={style.resourceName}>
                  {!resource._id.endsWith("_no_id") && <FolderOpenOutlined className={style.icon} />}
                  <span className={style.resourceActualName}>
                    <strong>{resource.name}</strong>
                  </span>{" "}
                  <span className={style.count}>({resource.count})</span>
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
