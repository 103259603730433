import { useWorkspace } from "@/store/workspaceContext";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import Info from "@mui/icons-material/InfoOutlined";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { JsonTree } from "react-editable-json-tree";
import Tooltip from "../../../shared/frontend/Tooltip";
import { IWorkspaceSlackAuthenticated } from "../../../shared/types/Workspace";
import { ICreateTestComponentsArgs } from "../../../shared/types/http/Component";
import http from "../../http";
import * as httpWorkspace from "../../http/workspaceNew";
import style from "./TrustedWorkspaceDebugPanel.module.css";
import { defaultJsonTreeStyles } from "./debugPanelUtils";

const DAYS_IN_MS = 1000 * 60 * 60 * 24;

const TrustedWorkspaceDebugPanel = () => {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const [editorJSON, setEditorJSON] = useState<IWorkspaceSlackAuthenticated | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(false);

  const [componentVersion, setComponentVersion] = useState<"legacy" | "northstar">("legacy");
  const [createComponentsArgs, setCreateComponentsArgs] = useState<ICreateTestComponentsArgs>({
    count: 10,
    workspaceId: workspaceInfo._id,
    projectId: undefined,
    groupsCount: undefined,
    blocksCount: undefined,
    distributeBetweenFolders: undefined,
  });
  const [componentActionState, setComponentActionState] = useState<"idle" | "loading" | "error" | "done">("idle");

  const unsavedChanges = JSON.stringify(editorJSON) !== JSON.stringify(workspaceInfo);

  function onEditJson(newJson: IWorkspaceSlackAuthenticated) {
    setEditorJSON({ ...newJson });
  }

  // Take a new IWorkspaceSlackAuthenticated object, and update it on both the frontend and backend
  // Any "shortcut" buttons that modify workspaceInfo should call this function to make their updates,
  // otherwise the editor panel will get out of sync.
  function saveChanges(updatedData: IWorkspaceSlackAuthenticated) {
    // make updates to the debug panel
    setEditorJSON(updatedData);

    // actually update the workspace
    updateWorkspaceInfo(updatedData);
    http.put("/trusted/updateWorkspace", {
      workspaceId: workspaceInfo._id,
      workspaceData: updatedData,
    });
  }

  function resetEditorChanges() {
    setEditorJSON({ ...workspaceInfo });
  }

  function closePanel() {
    resetEditorChanges();
    setExpanded(false);
  }

  // Resets a workspace back to brand-new billing -- Starter plan, no Stripe subscription, no previous trials
  function resetWorkspaceBillingState() {
    const newInfo = { ...workspaceInfo };
    newInfo.plan = "free";
    newInfo.customer_id = null;
    newInfo.ws_trial = null;
    newInfo.devToolsTrial = null;
    newInfo.devTools = false;

    saveChanges(newInfo);
  }

  async function regenerateAllDevIds() {
    setRegenerateLoading(true);
    try {
      const componentsConfig = workspaceInfo.config?.components.apiIdGeneration;
      const projectsConfig = workspaceInfo.config?.projects.apiIdGeneration;
      await httpWorkspace.updateWorkspaceConfig(
        {
          components: { apiIdGeneration: componentsConfig },
          projects: { apiIdGeneration: projectsConfig },
        },
        true
      );
    } catch (error) {
    } finally {
      setRegenerateLoading(false);
    }
  }

  // MARK: Components
  // todo: would be nice to automatically refresh component state, but that's a deep rabbithole; better to prompt
  // the user to refresh right now.

  const timeoutRef = useRef<NodeJS.Timeout>();
  function finishedComponentAction() {
    setComponentActionState("done");

    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setComponentActionState("idle");
    }, 3000);
  }

  async function createComponents(args: ICreateTestComponentsArgs) {
    setComponentActionState("loading");
    try {
      if (componentVersion === "legacy") {
        await http.post("/trusted/createComponents", args, { timeout: 120000 });
      } else {
        await http.post("/trusted/createLibraryComponents", args, { timeout: 120000 });
      }
      finishedComponentAction();
    } catch (error) {
    } finally {
    }
  }

  async function clearComponents() {
    setComponentActionState("loading");
    try {
      if (componentVersion === "legacy") {
        await http.post("/trusted/clearComponents", { workspaceId: workspaceInfo._id });
      } else {
        await http.post("/trusted/clearLibraryComponents", { workspaceId: workspaceInfo._id });
      }
      finishedComponentAction();
    } catch (error) {
    } finally {
    }
  }

  function onPlanInputChange(e: React.ChangeEvent<HTMLSelectElement>) {
    saveChanges({
      ...workspaceInfo,
      plan: e.target.value as any,
    });
  }

  function onWsTrialInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    saveChanges({
      ...workspaceInfo,
      ws_trial: new Date(e.target.value).toISOString() as any,
    });
  }

  function onDevTrialInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    saveChanges({
      ...workspaceInfo,
      devToolsTrial: new Date(e.target.value).toISOString() as any,
    });
  }

  const resetWsTrial = () => saveChanges({ ...workspaceInfo, ws_trial: null });
  const resetDevTrial = () => saveChanges({ ...workspaceInfo, devToolsTrial: null });

  // Hate that this is necessary, but workspaceInfo initializes to null, so we gotta reset the
  // JSON editor once real data comes in
  useEffect(
    function refreshWorkspaceJSON() {
      if (!editorJSON && workspaceInfo) {
        setEditorJSON({ ...workspaceInfo });
      }
    },
    [workspaceInfo]
  );

  if (!workspaceInfo || !editorJSON) return <></>;

  return (
    <div
      className={classNames(style.container, {
        [style.expanded]: expanded,
      })}
    >
      <div className={style.topRow}>
        <div className={style.workspaceId} onClick={() => setExpanded(true)}>
          {workspaceInfo._id}
        </div>
        {expanded && <button onClick={closePanel}>Close</button>}
      </div>

      {expanded && (
        <div className={style.body}>
          <div className={style.shortcuts}>
            <span className={style.label}>Handy shortcuts:</span>
            <button onClick={resetWorkspaceBillingState}>Reset workspace billing state</button>
            <button
              className={classNames(style.regenerateButton, {
                [style.loading]: regenerateLoading,
              })}
              onClick={regenerateAllDevIds}
              disabled={regenerateLoading}
            >
              <span>Regenerate all dev IDs</span>
              <div className={style.spinner}>
                <Spinner animation="border" />
              </div>
            </button>
          </div>

          <div className={style.divider} />
          <span className={style.quiet}>Changes in this area are saved automatically.</span>
          <div className={style.inputs}>
            <div className={style.col}>
              <div className={style.inputRow}>
                <span>plan:</span>
                <select value={workspaceInfo.plan} onChange={onPlanInputChange}>
                  <option value="free">Starter</option>
                  <option value="trial">Trial</option>
                  <option value="team">Team</option>
                  <option value="growth">Growth</option>
                  <option value="enterprise">Enterprise</option>
                </select>
              </div>
            </div>
            <div className={style.col}>
              <div className={style.inputRow}>
                <span>ws_trial date:</span>
                <input
                  type="date"
                  value={convertDateToInputString(workspaceInfo.ws_trial)}
                  onChange={onWsTrialInputChange}
                />
                <button onClick={resetWsTrial}>Reset</button>
              </div>
              <div className={style.inputRow}>
                <span>dev trial date:</span>
                <input
                  type="date"
                  value={convertDateToInputString(workspaceInfo.devToolsTrial)}
                  onChange={onDevTrialInputChange}
                />
                <button onClick={resetDevTrial}>Reset</button>
              </div>
            </div>
          </div>

          <div className={style.divider} />
          <div className={style.createComponentsArea}>
            <div className={style.createComponentsSubtitle}>
              Create components in this workspace
              <Tooltip
                className={style.tooltip}
                content={
                  <div className={style.tooltipBody}>
                    Components get random group and block names. Distributions are roughly uniform with large counts.
                    <ul>
                      <li>
                        <strong>Groups:</strong> # of different possible group names; components will be roughly evenly
                        split between these groups.
                      </li>
                      <li>
                        <strong>Blocks:</strong> # of different possible block names. <em>All</em> groups will have
                        roughly this number of blocks.
                      </li>
                      <li>
                        <strong>Folders:</strong> Components will be divided evenly between this many folders + the
                        root.
                      </li>
                    </ul>
                  </div>
                }
                placement="bottom"
                theme="light"
                disabled={false}
                hideOnClick={false}
              >
                <div className={style.iconWrapper}>
                  <Info className={style.infoIcon} />
                </div>
              </Tooltip>
            </div>
            <div className={style.inputRow}>
              <span>Component version</span>
              <select value={componentVersion} onChange={(e) => setComponentVersion(e.target.value as any)}>
                <option value="legacy">Legacy</option>
                <option value="northstar">Northstar</option>
              </select>
            </div>
            <div className={style.inlineInputs}>
              <div className={style.inlineInputBlock}>
                <span>Count</span>
                <span className={style.required}>*</span>
                <input
                  type="text"
                  value={createComponentsArgs.count}
                  onChange={(e) =>
                    setCreateComponentsArgs({ ...createComponentsArgs, count: parseInt(e.target.value) || 0 })
                  }
                />
              </div>

              <div className={style.inlineInputBlock}>
                <span>Groups</span>
                <input
                  type="text"
                  value={createComponentsArgs.groupsCount}
                  onChange={(e) =>
                    setCreateComponentsArgs({
                      ...createComponentsArgs,
                      groupsCount: parseInt(e.target.value) || undefined,
                    })
                  }
                />
              </div>
              <div className={style.inlineInputBlock}>
                <span>Blocks</span>
                <input
                  type="text"
                  value={createComponentsArgs.blocksCount}
                  onChange={(e) =>
                    setCreateComponentsArgs({
                      ...createComponentsArgs,
                      blocksCount: parseInt(e.target.value) || undefined,
                    })
                  }
                />
              </div>
              <div className={style.inlineInputBlock}>
                <span>Folders</span>
                <input
                  type="text"
                  value={createComponentsArgs.distributeBetweenFolders}
                  onChange={(e) =>
                    setCreateComponentsArgs({
                      ...createComponentsArgs,
                      distributeBetweenFolders: parseInt(e.target.value) || undefined,
                    })
                  }
                />
              </div>
            </div>
            <div className={style.buttons}>
              <button onClick={() => createComponents(createComponentsArgs)}>Create Components</button>
              <button onClick={clearComponents}>Clear All Components</button>
              {componentActionState === "loading" && (
                <div className={style.spinner}>
                  <Spinner animation="border" />
                </div>
              )}
            </div>
            {componentActionState === "done" && (
              <div className={style.successMessage}>
                <Check className={style.successIcon} />
                <span>Success! You might need to refresh the page.</span>
              </div>
            )}
          </div>

          <div className={style.divider} />
          <div className={style.workspaceJsonPanel}>
            <div className={style.topRow}>
              <span className={style.label}>Edit raw workspace JSON</span>
              <div className={style.buttons}>
                <button onClick={() => saveChanges(editorJSON)} disabled={!unsavedChanges}>
                  Save Changes
                </button>

                <button onClick={resetEditorChanges} disabled={!unsavedChanges}>
                  Reset Changes
                </button>
              </div>
            </div>
            <div className={style.jsonContainer}>
              <JsonTree
                data={editorJSON}
                onFullyUpdate={onEditJson}
                cancelButtonElement={<CancelButton />}
                editButtonElement={<EditButton />}
                getStyle={getStyle}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function convertDateToInputString(date: Date | string | null | undefined) {
  if (!date) return "";
  return new Date(date).toISOString().split("T")[0];
}

interface ButtonProps {
  onClick?: () => void;
}
function CancelButton(props: ButtonProps) {
  return (
    <button className={style.cancel} onClick={props.onClick}>
      <Close className={style.icon} />
    </button>
  );
}

function EditButton(props: ButtonProps) {
  return (
    <button className={style.edit} onClick={props.onClick}>
      <Check className={style.icon} />
    </button>
  );
}

function getStyle(keyName, data, keyPath, deep, dataType) {
  const baseStyleChanges = { minus: { display: "none" } };
  switch (dataType) {
    case "Object":
    case "Error":
      return { ...defaultJsonTreeStyles.object, ...baseStyleChanges };
    case "Array":
      return { ...defaultJsonTreeStyles.array, ...baseStyleChanges };
    case "Null":
      return { ...defaultJsonTreeStyles.value, ...baseStyleChanges, value: { color: "#c43136" } };
    case "Boolean":
      return { ...defaultJsonTreeStyles.value, ...baseStyleChanges, value: { color: "#f78f39" } };
    default:
      return { ...defaultJsonTreeStyles.value, ...baseStyleChanges };
  }
}

export default TrustedWorkspaceDebugPanel;
