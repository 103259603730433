import BoltIcon from "@mui/icons-material/Bolt";
import classNames from "classnames";
import { useAtom, WritableAtom } from "jotai";
import React, { Suspense, useRef } from "react";
import { IDittoClient } from "../../../../shared/client/DittoClient";
import { useAnimatedEllipsis } from "../../../../shared/frontend/AnimatedEllipsis";
import asyncMutableDerivedAtom from "../../../../shared/frontend/stores/asyncMutableDerivedAtom";
import Icon from "../../atoms/Icon";
import TextInput, { IProps as ITextInputProps } from "../../atoms/TextInput";
import Tooltip from "../Tooltip";
import style from "./index.module.css";

export interface IProps extends Omit<ITextInputProps, "trailingIcon" | "value" | "onChange"> {
  valueAtom: WritableAtom<string | Promise<string>, [string | Promise<string>], void>;
  onRegenerateAutoName: () => void;
}

export function LibraryComponentAutoNameInput(props: IProps) {
  return (
    <Suspense fallback={<LibraryComponentAutoNameLoading />}>
      <LibraryComponentAutoNameInputLoaded {...props} />
    </Suspense>
  );
}

function LibraryComponentAutoNameLoading() {
  const animatedEllipsis = useAnimatedEllipsis();
  return <TextInput value="" placeholder={`Generating suggestion${animatedEllipsis}`} disabled onChange={() => null} />;
}

function LibraryComponentAutoNameInputLoaded(props: IProps) {
  const { valueAtom, onRegenerateAutoName, ...rest } = props;

  const [value, setValue] = useAtom(props.valueAtom);
  const valueInitial = useRef(value);
  const isDisplayingAutoName = value === valueInitial.current;

  return (
    <TextInput
      {...rest}
      onChange={(newValue) => setValue(newValue)}
      value={value}
      trailingIcon={<AutogenerateIcon active={isDisplayingAutoName} onClick={onRegenerateAutoName} />}
    />
  );
}

function AutogenerateIcon(props: { active?: boolean; onClick?: () => void }) {
  return (
    <Tooltip
      content="Auto-generated based on the text value"
      side="left"
      type="invert"
      align="center"
      textAlign="center"
    >
      <div
        onClick={props.onClick}
        className={classNames(style.AutogenerateIcon, {
          [style.active]: props.active,
          [style.inactive]: !props.active,
        })}
      >
        <Icon Icon={<BoltIcon />} size="small" color="warning" />
      </div>
    </Tooltip>
  );
}

export function createNameAtom(client: IDittoClient, text: string) {
  return asyncMutableDerivedAtom({
    cached: false,
    loadData: async () => {
      const [result] = await Promise.all([
        client.libraryComponent.autoName({ text }),
        // minimum latency while using the non-smart endpoint
        new Promise((r) => setTimeout(r, 1000)),
      ]);

      return result.autoName;
    },
  });
}

export default LibraryComponentAutoNameInput;
