import classNames from "classnames";
import React, { forwardRef } from "react";
import BreadcrumbLink from "../../atoms/BreadcrumbLink";
import BreadcrumbSeparator from "../../atoms/BreadcrumbSeparator";
import BreadcrumbTerminus from "../../atoms/BreadcrumbTerminus";
import style from "./index.module.css";

interface IProps extends React.PropsWithChildren<{}> {
  className?: string;
  style?: React.CSSProperties;
  previousCrumbClassName?: string;
  previousCrumbs: { text: string; route: string }[];
  finalCrumbClassName?: string;
  finalCrumb: string | React.ReactNode;
  separator?: string; // Default is "/" but you can pass in any character (or maybe ReactNode in the future)
}

export const Breadcrumbs = forwardRef<HTMLDivElement, IProps>(function BreadCrumbs(props, ref) {
  return (
    <div
      style={props.style}
      className={classNames(style.BreadcrumbsWrapper, props.className)}
      data-testid="breadcrumbs"
      ref={ref}
    >
      {props.previousCrumbs.map((crumb) => (
        <React.Fragment key={crumb.text}>
          <BreadcrumbLink {...crumb} className={props.previousCrumbClassName} />
          <BreadcrumbSeparator className={props.previousCrumbClassName} separator={props.separator} />
        </React.Fragment>
      ))}
      {typeof props.finalCrumb === "string" ? (
        <BreadcrumbTerminus className={props.finalCrumbClassName} text={props.finalCrumb} />
      ) : (
        <>{props.finalCrumb}</>
      )}
    </div>
  );
});

export default Breadcrumbs;
