import { libraryDetailsGeneralPanelSelectedTabAtom } from "@/stores/Library";
import TabGroup from "@ds/molecules/TabGroup";
import { useAtom } from "jotai";
import { Suspense, useMemo } from "react";
import LibraryGeneralActivityPanel from "../LibraryGeneralActivityPanel";
import LibraryGeneralCommentsPanel from "../LibraryGeneralCommentsPanel";
import style from "./style.module.css";

const tabs = [
  {
    id: "ACTIVITY",
    label: "Activity",
    Content: LibraryGeneralActivityPanel,
  },
  {
    id: "COMMENTS",
    label: "Comments",
    Content: LibraryGeneralCommentsPanel,
  },
];

function LibraryGeneralDetailsPanel() {
  const [selectedTab, setSelectedTab] = useAtom(libraryDetailsGeneralPanelSelectedTabAtom);

  const selectedTabValue = useMemo(() => {
    let matchingTab = tabs.find((tab) => tab.id === selectedTab);
    if (matchingTab) {
      return matchingTab.id;
    }
    return "ACTIVITY";
  }, [selectedTab]);

  return (
    <div className={style.libraryEditDetailsPanelContainer}>
      <Suspense fallback={<></>}>
        <TabGroup
          value={selectedTabValue}
          onChange={setSelectedTab}
          tabs={tabs}
          className={style.tabRoot}
          triggerClassName={style.tabTrigger}
          size="small"
          noActiveTabLine
        />
      </Suspense>
    </div>
  );
}

export default LibraryGeneralDetailsPanel;
