import { Types } from "mongoose";
import { z } from "zod";

export const isNode = () => Boolean(typeof process !== "undefined" && process.versions?.node);

export type Populate<Entity extends object, Key extends keyof Entity, PopulatedValue extends object> = Omit<
  Entity,
  Key
> & {
  [key in Key]: PopulatedValue;
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type IObjectId = CONTEXT extends "backend" ? Types.ObjectId : string;

export const ZObjectId = z
  .custom<IObjectId>(
    (data: any) => {
      // Check if data is valid based on environment
      if (isNode()) {
        // Assuming ObjectId is imported in Node.js context
        return Types.ObjectId.isValid(data);
      } else {
        return typeof data === "string" && /^[0-9a-fA-F]{24}$/.test(data);
      }
    },
    { message: "Invalid ObjectId" }
  )
  .transform<IObjectId>((data) => {
    if (isNode()) {
      return new Types.ObjectId(data) as unknown as IObjectId;
    }
    // only need to transform in the backend as ObjectId does not exist in the frontend.
    return data;
  });

/**
 * Allows ZObjectId or a string - helpful for cases like the FE where ObjectId is serialized to string.
 */
export const ZCreatableId = z.union([z.string(), ZObjectId]);
export type ZCreatableId = z.infer<typeof ZCreatableId>;

/**
 * This handles the case where a Date is serialized as a string over the API and we want to parse it in the frontend.
 */
export const ZDate = z.union([z.string(), z.date()]).transform((val) => new Date(val));

// Returns an array that ensures that all values are of type T are present.s
export const arrayOfAll =
  <T extends string>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : `Missing Permission in Array: ${T}`)) =>
    array;
