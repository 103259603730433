import { z } from "zod";
import { ZTipTapRichText } from "./TextItem";
import { ZVariant } from "./Variant";
import { ZObjectId } from "./lib";

export const NO_FOLDER_ID = "no_folder_id";
export const BASE_VARIANT_ID = "__base__";

export const ZDFVariableCollection = z.object({
  enabled: z.boolean().optional(),
  // Corresponds to the Ditto Component Folder
  componentFolderId: z.string().nullable(),
  // Corresponds with the Figma Variable Collection
  variableCollectionId: z.string().nullable(),
  // This is a map from the Ditto variant IDs to the known
  // Ditto managed Variable Collections. Could be null
  // on the chance that we don't know the figma variable
  // collection (e.g. I added a new variant to my project
  // in Ditto and this is my first sync back to the plugin.
  variableModeAndVariantIdPairs: z.array(
    z.object({
      variantId: z.string(),
      figmaVariableModeId: z.string().nullable(),
    })
  ),
});

export type IDFVariableCollection = z.infer<typeof ZDFVariableCollection>;

export const ZDFPopulatedVariableCollection = ZDFVariableCollection.extend({
  variableModeAndVariantIdPairs: z.array(
    z.object({
      variantId: ZObjectId,
      variantName: z.string(),
      figmaVariableModeId: z.string().nullable(),
    })
  ),
  // The name of the figma variable collection based on the folder name
  name: z.string(),
});

export type IDFPopulatedVariableCollection = z.infer<typeof ZDFPopulatedVariableCollection>;

export const ZDFVariable = z.object({
  // The Ditto Component._id field of the related component.
  componentId: ZObjectId,
  // the id of the figma variable this variable is associated with. May be null
  // if it is new.
  figmaVariableId: z.string().nullable(),
});

export type IDFVariable = z.infer<typeof ZDFVariable>;

const ZDFPopulatedVariable = ZDFVariable.extend({
  name: z.string(),
  // pulled from the componentFolderId model
  collectionId: z.string(),
  // collection of figma modeIds to their string values
  modeTextValues: z.record(z.string()),
  // This is the actual rich text map between modes
  // and rich text values
  modeRichTextValues: z.record(ZTipTapRichText),
  // All the figma node ids that this component is attached
  // to in this project.
  figmaNodeIds: z.array(z.string()),
  // The description of the variable, pulled from the component notes
  notes: z.string(),
});

export type IDFPopulatedVariable = z.infer<typeof ZDFPopulatedVariable>;

export type IFDFPopulatedVariablePartial = {
  name?: string;
  notes?: string;
  figmaVariableId: string;
  variantTextValues?: Record<string, string>;
};

const ZFVConfigComponentFolder = z.object({
  name: z.string(),
  folderId: z.string().nullable(),
  componentIds: z.array(z.string()),
  numberOfComponents: z.number(),
  variants: z.array(ZVariant),
});

export type IFVConfigComponentFolder = z.infer<typeof ZFVConfigComponentFolder>;

export const ZFVConfigVariableCollection = z.object({
  id: z.string(),
  name: z.string(),
  numberOfVariables: z.number(),
  numberOfVariablesInUse: z.number(),
  modes: z.array(z.object({ modeId: z.string(), name: z.string() })),
  defaultModeId: z.string(),
  modeIdVariantIdMap: z.record(z.object({ _id: z.string(), name: z.string() }).nullable()),
  variables: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      description: z.string(),
      valuesByMode: z.record(z.string()),
    })
  ),
  textNodeIdToVariableIdMap: z.record(z.string()),
});

export type IFVConfigVariableCollection = z.infer<typeof ZFVConfigVariableCollection>;

const ZFVConfigBaseRowData = z.object({
  initialEnabled: z.boolean(),
  enabled: z.boolean(),
  expanded: z.boolean(),
});

const ZFVConfigOnlyFolderRowData = ZFVConfigBaseRowData.extend({
  componentFolder: ZFVConfigComponentFolder,
  variableCollection: z.undefined(),
});

export type IFVConfigOnlyFolderRowData = z.infer<typeof ZFVConfigOnlyFolderRowData>;

const ZFVConfigOnlyCollectionRowData = ZFVConfigBaseRowData.extend({
  componentFolder: z.undefined(),
  variableCollection: ZFVConfigVariableCollection,
});

export type IFVConfigOnlyCollectionRowData = z.infer<typeof ZFVConfigOnlyCollectionRowData>;

const ZFVConfigEnabledRowData = ZFVConfigBaseRowData.extend({
  componentFolder: ZFVConfigComponentFolder,
  variableCollection: ZFVConfigVariableCollection,
});

export type IFVConfigEnabledRowData = z.infer<typeof ZFVConfigEnabledRowData>;

export type IFVConfigRowData = IFVConfigOnlyFolderRowData | IFVConfigOnlyCollectionRowData | IFVConfigEnabledRowData;

const ZFVConfigBaseRowProps = z.object({
  setEnabled: z.function(z.tuple([z.boolean()]), z.void()),
  setExpanded: z.function(z.tuple([z.boolean()]), z.void()),
  setModeIdVariantMap: z.function(z.tuple([z.string(), z.object({ _id: z.string(), name: z.string() })]), z.void()),
});

const ZFVConfigOnlyFolderRowProps = ZFVConfigBaseRowProps.merge(ZFVConfigOnlyFolderRowData);

export type IFVConfigOnlyFolderRowProps = z.infer<typeof ZFVConfigOnlyFolderRowProps>;

const ZFVConfigOnlyCollectionRowProps = ZFVConfigBaseRowProps.merge(ZFVConfigOnlyCollectionRowData);

export type IFVConfigOnlyCollectionRowProps = z.infer<typeof ZFVConfigOnlyCollectionRowProps>;

const ZFVConfigEnabledRowProps = ZFVConfigBaseRowProps.merge(ZFVConfigEnabledRowData);

export type IFVConfigEnabledRowProps = z.infer<typeof ZFVConfigEnabledRowProps>;

export type IFVConfigRowProps =
  | IFVConfigOnlyFolderRowProps
  | IFVConfigOnlyCollectionRowProps
  | IFVConfigEnabledRowProps;

// MARK; Figma Variables API Types

// MARK: - Zod Types

const ZFigmaVariablesInvalidParameterResponse = z.object({
  status: z.literal(400),
  error: z.literal(true),
  message: z.string(),
});

export type IFigmaVariablesInvalidParameterResponse = z.infer<typeof ZFigmaVariablesInvalidParameterResponse>;

const ZFigmaVariablesUnauthorizedResponse = z.object({
  status: z.literal(401),
  error: z.literal(true),
  message: z.string(),
});

const ZFigmaVariablesAPIUnavailableResponse = z.object({
  status: z.literal(403),
  error: z.literal(true),
  message: z.string(),
});

const ZVariableAlias = z.object({
  type: z.literal("VARIABLE_ALIAS"),
  id: z.string(),
});

/**
 * This was previously an enum with the following values,
 * but we changed to z.string() because a strict zod.parse()
 * was throwing an error:
  | "ALL_SCOPES"
  | "TEXT_CONTENT"
  | "WIDTH_HEIGHT"
  | "GAP"
  | "ALL_SCOPES"
  | "ALL_FILLS"
  | "FRAME_FILL"
  | "SHAPE_FILL"
  | "TEXT_FILL"
  | "STROKE_COLOR"
  | "CORNER_RADIUS"

 * Can re-introduce enum if we ever come to depend on the field again.
 */
const ZVariableScope = z.string();

const ZVariableCodeSyntax = z.object({
  WEB: z.string().optional(),
  ANDROID: z.string().optional(),
  iOS: z.string().optional(),
});

const ZFigmaVariable = z.object({
  id: z.string(),
  name: z.string(),
  key: z.string(),
  variableCollectionId: z.string(),
  resolvedType: z.string(),
  valuesByMode: z.record(z.union([z.boolean(), z.number(), z.string(), ZVariableAlias, z.any()])),
  remote: z.boolean(),
  description: z.string(),
  hiddenFromPublishing: z.boolean(),
  scopes: z.array(ZVariableScope),
  codeSyntax: ZVariableCodeSyntax,
});

export type IFigmaVariable = z.infer<typeof ZFigmaVariable>;

const ZFigmaVariableMode = z.object({
  modeId: z.string(),
  name: z.string(),
});

const ZFigmaVariableCollection = z.object({
  id: z.string(),
  name: z.string(),
  modes: z.array(ZFigmaVariableMode),
  defaultModeId: z.string(),
  remote: z.boolean(),
  hiddenFromPublishing: z.boolean(),
});

export type IFigmaVariableCollection = z.infer<typeof ZFigmaVariableCollection>;

const ZFigmaVariablesSuccessResponse = z.object({
  status: z.number(),
  error: z.literal(false),
  meta: z.object({
    variables: z.record(ZFigmaVariable),
    variableCollections: z.record(ZFigmaVariableCollection),
  }),
});

export type IFigmaVariablesSuccessResponse = z.infer<typeof ZFigmaVariablesSuccessResponse>;

const ZFigmaVariablesErrorResponse = z.union([
  ZFigmaVariablesInvalidParameterResponse,
  ZFigmaVariablesUnauthorizedResponse,
  ZFigmaVariablesAPIUnavailableResponse,
]);

export type IFigmaVariablesErrorResponse = z.infer<typeof ZFigmaVariablesErrorResponse>;

export const ZFigmaVariablesResponse = z.union([ZFigmaVariablesErrorResponse, ZFigmaVariablesSuccessResponse]);
export type IFigmaVariablesResponse = z.infer<typeof ZFigmaVariablesResponse>;

const ZFigmaVariablesConfigEnableUpdate = z.object({
  type: z.literal("enableIntegration"),
  newVariableCollections: z.array(ZDFVariableCollection),
  newVariables: z.array(ZDFVariable.extend({ componentId: z.string() })),
});

export type IFigmaVariablesConfigEnableUpdate = z.infer<typeof ZFigmaVariablesConfigEnableUpdate>;
const ZFigmaVariablesConfigUpdate = z.union([
  ZFigmaVariablesConfigEnableUpdate,
  z.object({
    type: z.literal("createVariableCollection"),
    newVariableCollection: ZDFVariableCollection,
  }),
  z.object({
    type: z.literal("reEnableVariableCollection"),
    componentFolderId: z.string(),
  }),
  z.object({
    type: z.literal("disableVariableCollection"),
    componentFolderId: z.string(),
  }),
  z.object({
    type: z.literal("updateVariableCollection"),
    componentFolderId: z.string(),
    variableCollectionId: z.string(),
    newVariantIdPairs: z.array(
      z.object({
        variantId: z.string(),
        figmaVariableModeId: z.string().nullable(),
      })
    ),
    updatedVariantIdPairs: z.array(
      z.object({
        variantId: z.string(),
        figmaVariableModeId: z.string().nullable(),
      })
    ),
    deletedVariantIds: z.array(z.string()),
  }),
  z.object({
    type: z.literal("deleteVariableCollection"),
    componentFolderId: z.string(),
  }),
  z.object({
    type: z.literal("upsertVariable"),
    variable: z.object({
      componentId: z.string(),
      figmaVariableId: z.string().nullable(),
    }),
  }),
  z.object({
    type: z.literal("deleteVariable"),
    componentId: z.string(),
  }),
]);

export type IFigmaVariablesConfigUpdate = z.infer<typeof ZFigmaVariablesConfigUpdate>;

export const ZUpdateFigmaVariablesConfigBody = z.object({
  updates: z.array(ZFigmaVariablesConfigUpdate),
});

export type IUpdateFigmaVariablesConfigBody = z.infer<typeof ZUpdateFigmaVariablesConfigBody>;
