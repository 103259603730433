import { useWorkspace } from "@/store/workspaceContext";
import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import CreatableSelect from "react-select/creatable";
import http, { API } from "../../http/index";
import ButtonPrimary from "../button/buttonprimary";
import ModalInfoAlert from "../modal-info-alert";
import VariableSelectInput from "./VariableSelectInput";

import { VALID_URL } from "../../defs";

import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { IVariable } from "@shared/types/Variable";
import { IPopulatedVariableFolder } from "@shared/types/VariableFolder";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import { onAddListRow, onDeleteListRow, onVariableListChange } from "../../../util/manageListVariableChange";
import { onAddMapEntry, onMapEntryChange, onRemoveMapEntry } from "../../../util/manageMapVariableChange";
import EditMapVariable from "../../views/Variables/components/EditVariable/EditMapVariable";
import FolderSelect from "../FolderSelect";
import { getActualComponentVariable } from "./lib";
import style from "./style.module.css";

const URL_REGEX = new RegExp(VALID_URL);
const NUMBER_REGEX = new RegExp(/(\d+(?:\.\d+)?)/);

const colourStyles = {
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#19191A",
      fontWeight: "bold",
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B7B7B8",
    };
  },
};

const variableTypeOptions = [
  {
    value: "string",
    label: "String",
  },
  {
    value: "number",
    label: "Number",
  },
  {
    value: "hyperlink",
    label: "Hyperlink",
  },
  {
    value: "list",
    label: "List",
  },
  {
    value: "map",
    label: "Map",
  },
];

interface AddVariableModalProps {
  onHide: () => void;
  onInsertVariable: (variable: IVariable & { variable_id: string }) => void;
  type: "new" | "edit" | "select";
  defaultExample?: string;
  selectedFolderId?: string;
  inSampleProject?: boolean;
  stopPropagationOnEscape?: boolean;
}

const AddVariableModal = (props: AddVariableModalProps) => {
  const { onHide, onInsertVariable, type, defaultExample, selectedFolderId } = props;
  const history = useHistory();

  const [selectedVariable, setSelectedVariable] = useState<IVariable | null>(null);
  const [searchFolder, setSearchFolder] = useState<IPopulatedVariableFolder | null>(null);
  const [createFolder, setCreateFolder] = useState<Pick<IPopulatedVariableFolder, "_id" | "name">>({
    _id: "default",
    name: "All Variables",
  });
  const [variableFolders, setVariableFolders] = useState<IPopulatedVariableFolder[]>([]);

  const [urlError, setURLError] = useState<boolean>(false);
  const [duplicateNameError, setDuplicateNameError] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isNewVariable = useMemo(() => selectedVariable && selectedVariable?._id === "__new__", [selectedVariable]);

  useEffect(function fetchVariableFolders() {
    const { url } = API.variableFolder.get.folders;
    http.get(url).then(({ data }) => {
      const filteredFolders = data
        .filter((folder) => {
          if (!props.inSampleProject) return !folder.isSample;
          else return folder.isSample;
        })
        .sort((a: IPopulatedVariableFolder, b: IPopulatedVariableFolder) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

      setVariableFolders(filteredFolders);

      if (selectedFolderId) {
        const selectedFolder = filteredFolders.find((folder) => folder._id === selectedFolderId);
        if (selectedFolder) {
          setCreateFolder(selectedFolder);
        }
      }
    });
  }, []);

  const variableTypeSelectRef = useRef(null);

  const buttonText = useMemo(() => {
    if (isNewVariable || type === "new") {
      return !isSaving ? "Create Variable" : "Creating Variable...";
    }
    return !isSaving ? "Insert Variable" : "Inserting Variable...";
  }, [isNewVariable, isSaving, type]);

  const canSubmit = useMemo(() => {
    if (props.inSampleProject && selectedVariable?._id === "__new__") {
      return false;
    }

    if (isSaving || duplicateNameError) return false;

    const hasRequiredFields = selectedVariable?.name && selectedVariable?.type && selectedVariable?.data;

    if (!hasRequiredFields) {
      return false;
    }

    if (selectedVariable.type === "hyperlink") {
      const { text, url } = selectedVariable.data;
      return Boolean(text && url && URL_REGEX.test(url || ""));
    }

    if (selectedVariable.type === "string" || selectedVariable.type === "number") {
      const { example } = selectedVariable.data;
      return example !== null && example !== undefined;
    }

    if (selectedVariable.type === "list") {
      return (
        selectedVariable.data.length > 0 &&
        selectedVariable.data.every((e) => e !== "") &&
        selectedVariable.data.length === new Set(selectedVariable.data).size
      );
    }

    if (selectedVariable.type === "map") {
      return (
        Object.keys(selectedVariable.data).length > 0 &&
        Object.keys(selectedVariable.data).every((e) => e.indexOf("__duplicate") === -1) &&
        Object.entries(selectedVariable.data).every(([key, value]) => Boolean(key) && Boolean(value))
      );
    }

    return false;
  }, [selectedVariable, isSaving, duplicateNameError]);

  const onVariableTypeChange = (e) => {
    if (e && e.value) {
      if (!isNewVariable && isNewVariable !== null) setSelectedVariable((prev: any) => ({ ...prev, type: e.value }));
      else {
        setSelectedVariable((prev: any) => {
          let updatedValue = { ...prev };
          delete updatedValue.data;
          updatedValue.type = e.value;
          if (e.value === "list") {
            updatedValue.data = [""];
          } else if (e.value === "map") {
            updatedValue.data = { __empty__: "" };
          } else {
            updatedValue.data = {};
          }
          return updatedValue;
        });
      }
    } else {
      setSelectedVariable((prev: any) => ({ ...prev, type: null, data: null }));
    }
  };

  const onVariableExampleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value;

    setSelectedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, example: updatedValue },
    }));
  };

  const onVariableNumberExampleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value;

    // Adding a "-" into the input causes the value to be "" so we have to check for that
    // https://linear.app/dittowords/issue/DIT-8737/invalid-number-variable-doesnt-throw-error
    const isNumber = updatedValue === "" ? false : !isNaN(Number(e.target.value));

    if (isNumber) {
      onVariableExampleChange(e);
    }
  };

  const onVariableFallbackChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value;
    setSelectedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, fallback: updatedValue },
    }));
  };

  const onVariableTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value;
    setSelectedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, text: updatedValue },
    }));
  };

  const onURLInputFocus = () => setURLError(false);
  const validateURL = () => {
    const url =
      selectedVariable && selectedVariable.data && selectedVariable.type === "hyperlink" && selectedVariable.data.url
        ? selectedVariable.data.url
        : "";
    if (url.length > 0 && !URL_REGEX.test(url)) {
      setURLError(true);
    } else {
      setURLError(false);
    }
  };

  const onVariableURLChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value;

    setSelectedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, url: updatedValue },
    }));
  };

  const createNewVariable = async () => {
    if (!selectedVariable) return;

    try {
      setIsSaving(true);
      const { url, body } = API.variable.post.create;

      // don't include _id in request to create new variable
      const { _id: _selectedVariableId, ...selectedVariableProperties } = selectedVariable;

      let folder_id = searchFolder?._id;
      if (!folder_id && createFolder?._id !== "default") {
        folder_id = createFolder._id;
      }

      const { data: newVariable } = await http.post(
        url,
        body({
          variable: {
            ...selectedVariableProperties,
            folder_id,
          },
        })
      );

      if (type === "new") {
        if (!selectedFolderId && folder_id) {
          history.push(`/variables/folder/${folder_id}/${newVariable._id}`);
          return;
        } else if (selectedFolderId && !folder_id) {
          history.push(`/variables/${newVariable._id}`);
          return;
        }
      }

      onInsertVariable(getActualComponentVariable(newVariable));

      // Uncomment for testing in Storybook
      // onInsertVariable(selectedVariable);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const addVariable = async () => {
    if (selectedVariable) {
      if (isNewVariable) await createNewVariable();
      else onInsertVariable(getActualComponentVariable(selectedVariable));
    }

    onHide();
  };

  useEffect(() => {
    if (
      selectedVariable &&
      selectedVariable.name &&
      selectedVariable.type &&
      selectedVariable.data &&
      !selectedVariable.data.hasOwnProperty("example") &&
      !selectedVariable.data.hasOwnProperty("text") &&
      defaultExample
    ) {
      if (
        selectedVariable.type === "string" ||
        (selectedVariable.type === "number" && NUMBER_REGEX.test(defaultExample))
      ) {
        setSelectedVariable((prev: any) => ({
          ...prev,
          data: { ...prev.data, example: defaultExample },
        }));
      } else if (selectedVariable.type === "hyperlink") {
        setSelectedVariable((prev: any) => ({
          ...prev,
          data: { ...prev.data, text: defaultExample },
        }));
      }
    }
  }, [selectedVariable, defaultExample]);

  const { workspaceInfo } = useWorkspace();
  const foldersAllowed = workspaceInfo?.plan === "growth" || workspaceInfo?.plan === "enterprise";

  const selectableFolders = useMemo(() => {
    return [
      ...(!props.inSampleProject
        ? [
            {
              _id: "default",
              name: "All Variables",
            },
          ]
        : []),
      ...variableFolders,
    ];
  }, [variableFolders, props.inSampleProject]);

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (props.stopPropagationOnEscape && event.key === "Escape") {
      onHide();
      event.stopPropagation();
    }
  }

  return (
    <div onKeyDown={handleKeyDown}>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            {type === "new" ? "Create" : "Add"} Variable
          </BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <ModalInfoAlert
            linkUrl="https://www.dittowords.com/docs/variables"
            text="Want tips on how you can use variables in Ditto for dynamic content and links?"
          />
          <p>
            {type === "select"
              ? "Create or select a variable to insert in this text item."
              : "Create a new variable to use in projects and components."}{" "}
          </p>{" "}
          {type === "select" && (
            <div className={style.folderSearch}>
              <strong>Searching in</strong>
              <Dropdown
                className={classNames(style.dropdown, {
                  [style.inFolder]: searchFolder || props.inSampleProject,
                })}
              >
                <Dropdown.Toggle
                  className={classNames(style.dropdownToggle, {
                    [style.inFolder]: searchFolder || props.inSampleProject,
                  })}
                >
                  {(searchFolder || props.inSampleProject) && (
                    <FolderOpenOutlined className={style.folderIcon} style={{ fontSize: 16 }} />
                  )}
                  <span className={classNames(style.dropdownToggleText)}>
                    {searchFolder?.name || selectableFolders[0]?.name}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {selectableFolders.map((option: IPopulatedVariableFolder) => (
                    <Dropdown.Item
                      onClick={() => {
                        if (option._id === "default") setSearchFolder(null);
                        else setSearchFolder(option);
                      }}
                      key={option._id}
                    >
                      {option.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div className={style.inputGroup}>
            <label className={style.label}>{type === "select" ? "Variable" : "Name"}</label>
            <VariableSelectInput
              filterSampleData={!props.inSampleProject}
              foldersAllowed={foldersAllowed}
              isDisabled={isSaving}
              isCreateOnly={type === "new"}
              selectedVariable={[selectedVariable, setSelectedVariable]}
              selectedVariableFolder={searchFolder}
              placeholder={type === "select" ? "Create or insert an existing variable..." : "Name your variable"}
              handleVariableNameError={setDuplicateNameError}
              variableTypeSelectRef={variableTypeSelectRef}
            />
          </div>
          {(isNewVariable || type === "new") && (
            <div className={style.inputGroup}>
              <label className={style.label}>Type</label>
              <CreatableSelect
                ref={variableTypeSelectRef}
                isClearable
                defaultInputValue={selectedVariable?.type ? selectedVariable.type : ""}
                isDisabled={type === "select" && (isSaving || !isNewVariable)}
                placeholder="Select type (string, number, link, list, map)"
                onChange={onVariableTypeChange}
                onInputChange={() => {}}
                options={variableTypeOptions}
                className={style.dropdown}
                isValidNewOption={() => false}
                styles={colourStyles}
              />
            </div>
          )}
          {type !== "new" && !isNewVariable && selectedVariable?.type && (
            <div className={style.inputGroup}>
              <label className={style.label}>Type</label>
              <input
                className={style.dropdown}
                disabled
                type="text"
                value={selectedVariable.type}
                onChange={onVariableExampleChange}
                data-testid="variable-example-input"
              />
            </div>
          )}
          {selectedVariable &&
            selectedVariable.name &&
            selectedVariable.type &&
            selectedVariable.data &&
            (selectedVariable.type === "string" || selectedVariable.type === "number") && (
              <>
                <div className={style.inputGroup}>
                  <label className={style.label}>Example</label>
                  <input
                    className={style.input}
                    disabled={isSaving || !isNewVariable}
                    type={selectedVariable.type === "string" ? "text" : "number"}
                    value={selectedVariable.data.example ?? ""}
                    onChange={
                      selectedVariable.type === "number" ? onVariableNumberExampleChange : onVariableExampleChange
                    }
                    data-testid="variable-example-input"
                  />
                </div>
                <div className={style.inputGroup}>
                  <label className={style.label}>Fallback {isNewVariable && <span>(optional)</span>}</label>
                  <input
                    className={style.input}
                    disabled={isSaving || !isNewVariable}
                    type="text"
                    placeholder="Add fallback value"
                    value={selectedVariable.data.fallback ?? ""}
                    onChange={onVariableFallbackChange}
                    data-testid="variable-fallback-input"
                  />
                </div>
              </>
            )}
          {selectedVariable &&
            selectedVariable.name &&
            selectedVariable.type &&
            selectedVariable.type === "hyperlink" && (
              <>
                <div className={style.inputGroup}>
                  <label className={style.label}>Text</label>
                  <input
                    className={style.input}
                    disabled={isSaving || !isNewVariable}
                    type="text"
                    value={selectedVariable?.data?.text || ""}
                    onChange={onVariableTextChange}
                  />
                </div>
                <div className={style.inputGroup}>
                  <label className={style.label}>URL</label>
                  <input
                    className={classnames([style.input], {
                      [style.errorInput]: urlError,
                    })}
                    disabled={isSaving || !isNewVariable}
                    value={selectedVariable.data.url}
                    onChange={onVariableURLChange}
                    onBlur={validateURL}
                    onFocus={onURLInputFocus}
                  />
                  {urlError && <div className={style.errorMsg}>Not a valid URL</div>}
                </div>
              </>
            )}
          {selectedVariable && selectedVariable.name && selectedVariable.type && selectedVariable.type === "list" && (
            <div className={style.inputGroup}>
              <label className={style.label}>Values</label>
              {selectedVariable?.data?.map((e, index) => (
                <div className={style.listInputWrap} key={index}>
                  <input
                    className={style.input}
                    type="text"
                    value={e}
                    disabled={!isNewVariable}
                    onChange={(newList) => onVariableListChange(newList, index, setSelectedVariable)}
                    placeholder="Insert value"
                  />
                  {isNewVariable && (
                    <CloseIcon className={style.closeBtn} onClick={() => onDeleteListRow(index, setSelectedVariable)} />
                  )}
                </div>
              ))}
              {isNewVariable && (
                <button className={style.addRowBtn} onClick={() => onAddListRow(setSelectedVariable)}>
                  +
                </button>
              )}
            </div>
          )}
          {selectedVariable && selectedVariable.name && selectedVariable.type && selectedVariable.type === "map" && (
            <EditMapVariable
              disabled={!isNewVariable}
              variable={selectedVariable}
              onEntryChange={(key, newEntry) => onMapEntryChange(key, newEntry, setSelectedVariable)}
              onAddEntry={() => onAddMapEntry(setSelectedVariable)}
              onDeleteEntry={(key) => onRemoveMapEntry(key, setSelectedVariable)}
            />
          )}
          {props.inSampleProject && selectedVariable?._id === "__new__" && (
            <div className={style.sampleInfo}>
              <div className={style.sampleInfoIcon}>
                <InfoOutlined fontSize="inherit" />
              </div>
              &nbsp;Creating new variables is disabled for sample data.
            </div>
          )}
          <div className={style.actionContainer}>
            <div>
              {type === "new" && selectableFolders.length > 1 && (
                <FolderSelect
                  folders={selectableFolders}
                  selectedFolder={createFolder}
                  setSelectedFolder={setCreateFolder}
                  text="Creating in"
                  defaultId={"default"}
                />
              )}
            </div>
            <span className={style.form}>
              <ButtonPrimary
                text={buttonText}
                disabled={!canSubmit}
                onClick={addVariable}
                data-testid="variable-save-button"
              />
            </span>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};
export default AddVariableModal;
