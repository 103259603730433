import useAutoScroll from "@/hooks/useAutoScroll";
import { nullableComponentFamilyAtom } from "@/stores/Components";
import { reorderTextItemsActionAtom } from "@/stores/Editing";
import { INavTextItem, textItemFamilyAtom } from "@/stores/Project";
import {
  draggableItemsForTextItemAtom,
  onTextItemClickActionAtomFamily,
  selectedBlockIdAtom,
  selectionTypeAtom,
  textItemIsSelectedAtom,
} from "@/stores/ProjectSelection";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import Text from "@ds/atoms/Text";
import { LibraryComponentNameSpan } from "@ds/molecules/LibraryComponentNameSpan";
import NavItem from "@ds/molecules/NavigatorRow";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { forwardRef, useCallback, useMemo } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { leftSidebarScrollRefAtom } from ".";
import { textItemListScrollRefAtom } from "../../TextItemList";
import style from "./style.module.css";

function TextNavItem(props: { item: INavTextItem }) {
  const textItem = useAtomValue(textItemFamilyAtom(props.item._id));
  const component = useAtomValue(nullableComponentFamilyAtom(textItem.ws_comp));
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.item._id));
  const isSelected = useAtomValue(textItemIsSelectedAtom(props.item._id));

  const itemType = useMemo(() => (textItem.blockId ? "block-child" : "default"), [textItem.blockId]);
  const parentBlockIsSelected = useMemo(
    () => Boolean(textItem.blockId && selectedBlockId === textItem.blockId),
    [textItem.blockId, selectedBlockId]
  );

  const textItemFormattedTextJSX = useMemo(() => {
    return textItem.rich_text.content
      .reduce<(string | { variableName: string })[]>((acc, paragraph) => {
        for (const content of paragraph.content || []) {
          if (content.type === "text") {
            if (typeof acc[acc.length - 1] === "string") {
              acc[acc.length - 1] += content.text;
            } else {
              acc.push(content.text);
            }
          } else {
            acc.push({ variableName: content.attrs.name });
          }
        }

        return acc;
      }, [])
      .map((part, index) => {
        if (typeof part === "string") {
          return <Text key={index}>{part}</Text>;
        } else {
          return (
            <Text key={index} color="variable">
              {part.variableName}
            </Text>
          );
        }
      });
  }, [textItem.rich_text]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onTextItemClick({ richText: textItem.rich_text, skipInlineEdit: true, e });
    },
    [onTextItemClick, textItem.rich_text]
  );

  return (
    <NavItem
      key={textItem._id}
      className={style.navItem}
      type={itemType}
      pressed={isSelected}
      parentSelected={parentBlockIsSelected}
      onClick={onClick}
    >
      {component ? (
        <LibraryComponentNameSpan size="small" iconSize="xxs" weight="light">
          {component.name}
        </LibraryComponentNameSpan>
      ) : (
        <Text className={style.textItemText}>{textItemFormattedTextJSX}</Text>
      )}
    </NavItem>
  );
}

const TextNavItemDragWrapper = forwardRef(function TextNavItemDragWrapper(
  props: {
    children: React.ReactNode;
    textItemId: string;
  },
  ref
) {
  const selectionType = useAtomValue(selectionTypeAtom);
  const getDraggableItems = useAtomCallback(
    useCallback((get) => get(draggableItemsForTextItemAtom(props.textItemId)), [props.textItemId])
  );
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const textItem = useAtomValue(textItemFamilyAtom(props.textItemId));
  const isSelected = useAtomValue(textItemIsSelectedAtom(props.textItemId));
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const leftSidebarScrollContainer = useAtomValue(leftSidebarScrollRefAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.textItemId));
  const selectedType = useAtomValue(selectionTypeAtom);

  function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: textItem.blockId,
        before: dragLocation === "above" ? props.textItemId : undefined,
        after: dragLocation === "below" ? props.textItemId : undefined,
      },
    ]);
  }

  const scrollProps = useAutoScroll(textItemScrollContainer, leftSidebarScrollContainer);

  /**
   * If this text item was not already selected, ensure that it becomes the only text item selected.
   */
  const handleDragStart = useCallback(
    function handleDragStart(e: DragStartEvent) {
      if (!isSelected) {
        onTextItemClick({ richText: textItem.rich_text, e });
      }
    },
    [isSelected, onTextItemClick, textItem.rich_text]
  );

  return (
    <DragAndDroppable
      className={style.draggableNavItem}
      ref={ref}
      getDraggableItems={getDraggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      onDrop={handleDrop}
      onDragStart={handleDragStart}
      {...scrollProps}
      selectionType={selectionType}
    >
      <div data-selectiontype="text" data-dragindicatorabove className={style.dropIndicatorWrapper}>
        <div className={style.dropIndicator} />
      </div>
      {props.children}
      <div data-selectiontype="text" data-dragindicatorbelow className={style.dropIndicatorWrapper}>
        <div className={style.dropIndicator} />
      </div>
    </DragAndDroppable>
  );
});

const TextNavItemWithDrag = forwardRef(function TextNavItemWithDrag(props: { item: INavTextItem }, ref) {
  return (
    <TextNavItemDragWrapper textItemId={props.item._id} ref={ref}>
      <TextNavItem {...props} />
    </TextNavItemDragWrapper>
  );
});

export default TextNavItemWithDrag;
