import Close from "@mui/icons-material/Close";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IPermissionGroupWithUsers, IUser } from "../../../shared/types/User";
import http from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import PermissionGroupsInput from "../permissions/PermissionGroupsInput";
import style from "./style.module.css";

interface ManagePermissionsModalProps {
  user: IUser;
  onHide: () => void;
  fetchUsers: () => void;
}

const ManagePermissionsModal = (props: ManagePermissionsModalProps) => {
  const [loading, setLoading] = useState(true);
  const [workspacePermissionGroups, setWorkspacePermissionGroups] = useState<IPermissionGroupWithUsers[]>([]);
  const [currentPermissions, setCurrentPermissions] = useState<IPermissionGroupWithUsers[]>([]);

  const fetchPermissionGroups = async () => {
    http.get(`/workspace/permission-groups`).then((res) => {
      const { permissionGroupsWithUsers } = res.data;
      setWorkspacePermissionGroups(permissionGroupsWithUsers);

      // initialize current permissions based on workspace groups
      setCurrentPermissions(
        props.user.permissionGroups.map((groupId) => permissionGroupsWithUsers.find((g) => g._id === groupId))
      );

      setLoading(false);
    });
  };

  useEffect(function fetchPermissionGroupsOnMount() {
    fetchPermissionGroups();
  }, []);

  const handleUpdatePermissions = async (updatedGroupIds: string[]) => {
    try {
      const res = await http.put(`/user/${props.user._id}/updatePermissions`, {
        permissionGroupIds: updatedGroupIds,
      });

      props.fetchUsers();
      props.onHide();
    } catch (error) {
      console.error(error);
    }
  };

  const canSaveChanges = useMemo(() => {
    const initialGroupIds = props.user.permissionGroups;
    const currentGroupIds = currentPermissions.map((group) => group._id);

    return initialGroupIds.sort().toString() !== currentGroupIds.sort().toString();
  }, [currentPermissions, props.user]);

  return (
    <Modal show onHide={props.onHide} className={style.addMembersModal} centered>
      <Modal.Header className={style.header}>
        <h2>Manage Permissions</h2>

        <Close className={style.closeIcon} onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body className={style.body}>
        <p>Permission Groups</p>
        <PermissionGroupsInput
          className={style.permissionGroupsInput}
          permissionGroupOptions={workspacePermissionGroups}
          currentGroups={currentPermissions}
          setCurrentGroups={setCurrentPermissions}
          disabled={loading}
        />

        <ButtonPrimary
          onClick={() => handleUpdatePermissions(currentPermissions.map((group) => group._id))}
          text="Save"
          disabled={!canSaveChanges}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ManagePermissionsModal;
