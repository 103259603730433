import classNames from "classnames";
import React, { forwardRef, memo } from "react";
import { useNativeProps } from "../../useNativeProps";
import Icon from "../Icon";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  children: React.ReactNode;

  /**
   * The icon to be rendered. Intended for use with @mui/icons-material.
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   *
   * This is *not* the way to render an icon-only button. Use the type="icon" prop and pass in the icon
   * as the children instead.
   */
  leadingIcon?: React.ReactNode | React.ReactSVGElement;

  /**
   * The color of the icon. Defaults to the color of the label.
   */
  iconColor?: "primary" | "secondary" | "minimal" | "positive" | "warning" | "danger" | "invert" | "blue";
  iconSize?: "xxs" | "xs" | "small" | "base";

  size?: "base" | "small" | "micro";
  expansion?: "inline" | "block";
  alignment?: "start" | "center" | "end";
  level?: "primary" | "secondary" | "danger" | "outline" | "subtle" | "subtleAction" | "invert" | "inherit";
  type?: "text" | "icon";
  disabled?: boolean;
  showDot?: boolean;
  autoFocus?: boolean;

  clickOnBackspace?: boolean;

  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void;
  onMouseDown?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void;
  onMouseOver?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void;
  onMouseOut?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | void;
}

const Button = memo(
  forwardRef<HTMLButtonElement, IProps>((props, ref) => {
    const size = props.size ?? "base";
    const type = props.type ?? "text";
    const expansion = props.expansion ?? "inline";
    const level = props.level ?? "primary";
    const disabled = props.disabled ?? false;

    const alignment = props.alignment ?? (props.expansion === "block" ? "center" : "start");

    function getIconColor() {
      if (props.iconColor) return props.iconColor;
      if (level === "primary") return "invert";
      if (level === "secondary") return "secondary";
      if (level === "danger") return "danger";
      if (level === "subtle") return "secondary";
      return "primary";
    }

    function getIconSize() {
      if (props.iconSize) return props.iconSize;
      if (type === "icon") {
        if (size === "base") return "icon-button-size";
        if (size === "small") return "xs";
        if (size === "micro") return "xxs";
      } else {
        if (size === "base") return "small";
        if (size === "small") return "xs";
        if (size === "micro") return "xxs";
      }

      return "small";
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLButtonElement>) {
      if (event.key === "Backspace" && props.clickOnBackspace) {
        props.onClick?.();
      }
    }

    function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      if (props.onClick) {
        event.stopPropagation();
        props.onClick(event);
      }
    }

    function handleMouseDown(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      if (props.onMouseDown) {
        event.stopPropagation();
        props.onMouseDown(event);
      }
    }

    const nativeProps = useNativeProps<HTMLButtonElement, IProps>(props, {
      clickOnBackspace: true,
      disabled: true,
      iconColor: true,
      iconSize: true,
      level: true,
      size: true,
      leadingIcon: true,
      expansion: true,
      type: true,
      showDot: true,
      alignment: true,
    });

    return (
      <button
        {...nativeProps}
        style={props.style}
        className={classNames(
          style.ButtonWrapper,
          {
            [style[`alignment-${alignment}`]]: true,
            [style[`size-${size}`]]: true,
            [style[`expansion-${expansion}`]]: true,
            [style[`level-${level}`]]: true,
            [style[`type-${type}`]]: true,
          },
          props.className
        )}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        data-testid="button"
        ref={ref}
        disabled={disabled}
        onKeyDown={handleKeyDown}
      >
        {props.leadingIcon && <Icon Icon={props.leadingIcon} size={getIconSize()} color={getIconColor()} />}
        {type !== "icon" && props.children}
        {type === "icon" && <Icon Icon={props.children} size={getIconSize()} color={getIconColor()} />}
        {props.showDot && (
          <div className={style.buttonDotWrapper}>
            <div className={style.buttonDot}></div>
          </div>
        )}
      </button>
    );
  })
);

export default Button;
