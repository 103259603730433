import { IFullComponentMergeSuggestion, IListWithCountComponent } from "@shared/types/Component";
import { useEffect, useState } from "react";
import * as httpWsComp from "../../http/ws_comp_typed";

interface IUseComponentMergeSuggestionPanelProps {
  onSelectComponent: (componentId: string) => void;
  onMergeSuggestion: (mergedComponent: IListWithCountComponent, deletedComponentIds: string[]) => Promise<void>;
}

export function useComponentMergeSuggestionPanel(props: IUseComponentMergeSuggestionPanelProps) {
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  const [suggestions, setSuggestions] = useState<IFullComponentMergeSuggestion[]>([]);
  function regenerateSuggestions() {
    setLoading(true);
    const [request, cancel] = httpWsComp.getComponentMergeSuggestions();
    request.then(({ data }) => {
      setSuggestions(data.suggestions);
      setLoading(false);
    });
    return () => cancel();
  }
  useEffect(function generateInitialSuggestions() {
    return regenerateSuggestions();
  }, []);

  const [loading, setLoading] = useState(false);

  const onIgnoreSuggestion = async (key: string) => {
    if (key === selectedKey) {
      setSelectedKey(null);
    }

    const [request] = httpWsComp.ignoreComponentMergeSuggestion({ key });
    const { data: success } = await request;

    if (!success) {
      alert("Failed to ignore suggestion for " + key);
      return;
    }

    setSuggestions((suggestions) =>
      suggestions.map((s) => {
        if (s.key === key) s.ignored = true;
        return s;
      })
    );
  };

  const onUnignoreSuggestion = async (key: string) => {
    const [request] = httpWsComp.unignoreComponentMergeSuggestion({ key });
    const { data: success } = await request;

    if (!success) {
      alert("Failed to ignore suggestion for " + key);
      return;
    }

    setSuggestions((suggestions) =>
      suggestions.map((s) => {
        if (s.key === key) s.ignored = false;
        return s;
      })
    );
  };

  const onMergeSuggestion = async (key: string, targetComponentId: string) => {
    const [request] = httpWsComp.acceptComponentMergeSuggestion({
      key,
      targetComponentId,
    });

    const {
      data: [mergedComponent, componentIdsRemoved],
    } = await request;

    props.onMergeSuggestion(mergedComponent, componentIdsRemoved);

    setSuggestions((suggestions) => suggestions.filter((s) => s.key !== key));
  };

  const onBulkMergeSuggestions = async (bulkSuggestions: httpWsComp.BulkSuggestion[]) => {
    const [request] = httpWsComp.bulkAcceptComponentMergeSuggestion({
      bulkSuggestions,
    });

    const keys = bulkSuggestions.map((sugg) => sugg.suggestionKey);

    const {
      data: [mergedComponents, componentIdsRemoved],
    } = await request;

    props.onMergeSuggestion(mergedComponents, componentIdsRemoved);

    setSuggestions((suggestions) => suggestions.filter((s) => !keys.includes(s.key)));
  };

  const onBulkIgnoreSuggestions = async (bulkSuggestionKeys: string[]) => {
    const [request] = httpWsComp.bulkIgnoreComponentMergeSuggestions({
      bulkSuggestionKeys,
    });

    const { data: success } = await request;

    if (!success) {
      alert("Failed to ignore suggestions");
      return;
    }

    setSuggestions((sugg) =>
      sugg.map((s) => {
        if (bulkSuggestionKeys.includes(s.key)) s.ignored = true;
        return s;
      })
    );
  };

  const onReviewSuggestion = (key: string) => {
    setSelectedKey(key);
  };

  const hasSelectedSuggestion = () => selectedKey !== null;
  const clearSelectedSuggestion = () => setSelectedKey(null);

  return {
    selectedKey,
    suggestionsLoading: loading,
    suggestions,
    regenerateSuggestions,
    onMergeSuggestion,
    onIgnoreSuggestion,
    onUnignoreSuggestion,
    onSelectComponent: props.onSelectComponent,
    onReviewSuggestion,
    hasSelectedSuggestion,
    clearSelectedSuggestion,
    onBulkMergeSuggestions,
    onBulkIgnoreSuggestions,
  };
}
