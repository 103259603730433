import { atom } from "jotai";
import {
  editableHasChangesAtom,
  formattedTextItemVariantsWithChangesAtom,
  hasInlineEditingChangesAtom,
  hasNewVariantChangesAtom,
  hasTextItemVariantChangesAtom,
  isInlineEditingNewTextAtom,
  resetAddVariantFormChangesActionAtom,
  resetTextItemVariantChangesActionAtom,
  stopInlineEditingActionAtom,
} from "./Editing";
import { commentEditsAtom, detailsPanelPropsAtom, resetCommentEditsActionAtom } from "./ProjectSelection";

export const modalAtom = atom<IModalState | null>(null);

interface IModalState {
  headline: string;
  content: string;
  onAction: () => void;
  onOpenChange: (open: boolean) => void;
  onCancel?: () => void;
  actionText?: string;
  cancelText?: string;
  type?: "default" | "danger";
}

/**
 * Resets state after choosing Discard from the confirmation modal.
 * If `ignoreInlineChanges` is true, inline editing changes will not be discarded.
 */
export const discardChangesActionAtom = atom(
  null,
  (get, set, { ignoreInlineChanges }: { ignoreInlineChanges: boolean }) => {
    const hasTextItemVariantChanges = get(hasTextItemVariantChangesAtom);
    if (!ignoreInlineChanges) {
      set(stopInlineEditingActionAtom, { skipConfirmation: true });
    }
    set(editableHasChangesAtom, false);
    set(resetCommentEditsActionAtom);
    set(resetTextItemVariantChangesActionAtom);
    set(resetAddVariantFormChangesActionAtom);
    set(modalAtom, null);

    // If we had variant changes, clear variant form state in Variants panel when discarding changes
    if (hasTextItemVariantChanges) {
      set(detailsPanelPropsAtom, (props) => ({
        ...props,
        variants: undefined,
        resetFormState: true,
      }));
    }
  }
);

/**
 * An action that checks if the user has unsaved changes before performing a specified action.
 * If the user has unsaved changes, a modal will be displayed to confirm discarding changes.
 * Otherwise, the action will be performed immediately.
 *
 * Pass `ignoreInlineChanges: true` if the onConfirm action will not discard inline editing changes.
 */
export const discardChangesModalActionAtom = atom(
  null,
  (
    get,
    set,
    {
      onConfirm,
      ignoreInlineChanges,
      activeElement,
    }: { onConfirm: () => void; ignoreInlineChanges: boolean; activeElement: Element | null }
  ) => {
    let headline = "";
    let content = "";

    // TEXT ITEM CHANGES
    const hasTextItemChanges = get(editableHasChangesAtom);

    // VARIANT CHANGES
    const hasTextItemVariantChanges = get(hasTextItemVariantChangesAtom);
    const hasNewVariantChanges = get(hasNewVariantChangesAtom);
    const hasVariantChanges = hasTextItemVariantChanges || hasNewVariantChanges;

    // INLINE EDITING CHANGES
    const hasInlineEditingChanges = ignoreInlineChanges ? false : get(hasInlineEditingChangesAtom);

    // ADDING NEW TEXT ITEM
    const isAddingNewTextItem = hasInlineEditingChanges && get(isInlineEditingNewTextAtom);

    // COMENT CHANGES
    const commentEdits = get(commentEditsAtom);
    const hasCommentChanges = commentEdits.size > 0;

    const changesCount =
      Number(isAddingNewTextItem) +
      Number(hasTextItemChanges) +
      Number(hasCommentChanges) +
      Number(hasVariantChanges) +
      Number(hasInlineEditingChanges);

    if (changesCount === 0) {
      onConfirm();
      return;
    }

    if (changesCount === 1) {
      if (isAddingNewTextItem) {
        headline = "Discard new text item?";
        content = "Your changes will be discarded. This can't be undone.";
      } else if (hasTextItemChanges || hasInlineEditingChanges) {
        headline = "Discard unsaved text item changes?";
        content = "Your changes will be discarded. This can't be undone.";
      } else if (hasCommentChanges) {
        const entity = commentEdits.size === 1 ? "comment" : "comments";
        headline = `Discard unsaved ${entity}?`;
        content = `Your ${entity} will be discarded. This can't be undone.`;
      } else if (hasVariantChanges) {
        if (hasTextItemVariantChanges) {
          headline = "Discard changes to variants?";
          content = get(formattedTextItemVariantsWithChangesAtom);
        } else if (hasNewVariantChanges) {
          headline = "Discard unsaved text item variant changes?";
          content = "Your changes will be discarded. This can't be undone.";
        }
      }
    } else {
      headline = "Discard unsaved changes?";
      const changedEntities: string[] = [];
      if (isAddingNewTextItem) {
        changedEntities.push("new text item");
      }
      if (hasCommentChanges) {
        changedEntities.push(commentEdits.size === 1 ? "comment" : "comments");
      }
      if (hasTextItemChanges || hasInlineEditingChanges) {
        changedEntities.push("text item changes");
      }
      if (hasVariantChanges) {
        changedEntities.push("text item variant changes");
      }
      const entitiesString = changedEntities.slice(0, -1).join(", ") + " and " + changedEntities.at(-1);
      content = `Your ${entitiesString} will be discarded. This can't be undone.`;
    }

    set(modalAtom, {
      headline,
      content,
      actionText: "Discard",
      onAction: () => {
        set(discardChangesActionAtom, { ignoreInlineChanges });

        onConfirm();
      },
      onOpenChange: (open) => {
        if (open) return;
        set(modalAtom, null);
      },
      onCancel: () => {
        if (activeElement) {
          // check if active element has the focus method and call it if so
          (activeElement as HTMLElement).focus?.();
        }
        set(modalAtom, null);
      },
    });
  }
);
