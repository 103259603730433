import ChatBubble from "@mui/icons-material/ChatBubble";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import classNames from "classnames";
import { DittoComponent } from "ditto-react";
import React from "react";
import { IRecentComponent } from "../../../shared/types/http/Homepage";
import RichTextRender from "../RichTextRender";
import style from "./style.module.css";

// should match the CSS
const CARD_VERTICAL_PADDING_TOTAL = 18;

// Function to calculate the difference in days between two dates
function differenceInDays(date1: Date, date2?: Date) {
  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2?.getTime() || date1.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date1_ms - date2_ms;

  // Convert the difference back to days
  return Math.round(difference_ms / (1000 * 60 * 60 * 24));
}

export interface ComponentCardProps {
  className?: string;
  classNameHeader?: string;
  onClick?: () => void;
  component: IRecentComponent;
}

export default function ComponentCard(props: ComponentCardProps) {
  const { component } = props;

  const type = component.multiEditedIds?.length ? "multi" : "single";
  const showMultipleComponentsUI = type === "multi" && (component.multiEditedIds?.length ?? 0) > 1;

  const daysSinceLastEdited = differenceInDays(new Date(), new Date(component.lastEdited));
  const editedBy = component.lastEditedBy || "";
  const editedDittoComponentId = editedBy ? "last-edited-by" : "last-edited";

  const showLastEdited = !!new Date(component.lastEdited);
  const hasStatus = component.status !== "NONE";
  const showCardFooter = component.notes || component.tags.length > 0 || (component.commentCount || 0) > 0;
  let statusClassName = "";

  if (hasStatus) {
    switch (component.status) {
      case "WIP":
        statusClassName = style.wip;
        break;
      case "REVIEW":
        statusClassName = style.review;
        break;
      case "FINAL":
        statusClassName = style.final;
        break;
    }
  }

  const cardBodyWithoutStatus = showCardFooter && !hasStatus;
  const cardBodyWithStatus = showCardFooter && hasStatus;
  const cardBodyWithStatusWithoutFooter = !showCardFooter && hasStatus;
  const cardBodyWithoutStatusOrFooter = !showCardFooter && !hasStatus;

  const getCardMaxHeight = () => {
    if (cardBodyWithoutStatus) {
      return 84;
    }
    if (cardBodyWithStatus) {
      return 78;
    }
    if (cardBodyWithStatusWithoutFooter) {
      return 115;
    }
    if (cardBodyWithoutStatusOrFooter) {
      return 121;
    }

    throw new Error("Unreachable state");
  };

  const cardMaxHeight = getCardMaxHeight();
  const cardBodyTruncateHeight = cardMaxHeight - CARD_VERTICAL_PADDING_TOTAL;

  return (
    <div
      className={classNames([style.componentCard, props.className], {
        [style.isMultiEditComponent]: showMultipleComponentsUI,
      })}
      data-testid="component-card"
    >
      <div
        className={classNames({
          [style.header]: true,
          [style.isMultiEditComponent]: showMultipleComponentsUI,
        })}
      >
        {component.folder && (
          <div className={style.folderWrapper}>
            <FolderOutlinedIcon className={style.folderIcon} />
            <span className={style.folderName}>{component.folder?.name}</span>
          </div>
        )}
      </div>
      <div className={style.card} onClick={props.onClick}>
        {showMultipleComponentsUI && (
          <>
            <div className={style.multiEditedIdsBackdropTwo}></div>
            <div className={style.multiEditedIdsBackdropOne}></div>
          </>
        )}
        <div className={style.cardHeader}>
          <span className={style.cardTitle}>{component.name}</span>
          {hasStatus && <div className={classNames(style.status, statusClassName)}></div>}
        </div>
        <div
          className={classNames(style.cardBody, {
            [style.cardBodyWithStatusWithoutFooter]: cardBodyWithStatusWithoutFooter,
            [style.cardBodyWithoutStatusOrFooter]: cardBodyWithoutStatusOrFooter,
          })}
          style={{ height: cardMaxHeight }}
        >
          <RichTextRender
            richText={component.richText}
            renderProps={{ className: style.text }}
            truncate={cardBodyTruncateHeight}
          />
        </div>
        {showCardFooter && (
          <div className={style.cardFooter}>
            {component.notes && <span className={style.cardNotes}>{component.notes}</span>}
            {component.tags.length > 0 && (
              <span className={style.cardTags}>
                <DittoComponent
                  componentId="tag-count"
                  variables={{ numTags: component.tags.length }}
                  count={component.tags.length}
                />
              </span>
            )}
            {(component.commentCount || 0) > 0 && (
              <div className={style.commentCount}>
                <ChatBubble className={style.commentsIcon} />
                <span className={style.commentCount}>{component.commentCount}</span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={style.footer}>
        {showMultipleComponentsUI && (
          <span className={style.multiEditedCount}>
            <LanguageIcon />
            {component.multiEditedIds?.length}
          </span>
        )}
        {showLastEdited && (
          <span className={style.lastEdited}>
            <DittoComponent
              componentId={editedDittoComponentId}
              variables={{
                numDays: daysSinceLastEdited,
                fullName: editedBy || "Your Teammate",
              }}
              count={daysSinceLastEdited}
            />
          </span>
        )}
      </div>
    </div>
  );
}
