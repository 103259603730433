import logger from "@shared/utils/logger";
import { ITipTapRichText } from "../../shared/types/TextItem";

export const isRichTextStyled = (input: ITipTapRichText | {}) => {
  if (!input || typeof input !== "object") {
    // We should remove this `logger.error` call once we figure out what is causing `input` to be invalid
    // https://making-ditto.slack.com/archives/C03P3UK8RPT/p1699975117151789?thread_ts=1699973427.418549&cid=C03P3UK8RPT
    logger.error("isRichTextStyled: input is not an object", { context: { input } }, new Error(""));

    return false;
  }

  if (!("type" in input) || (input?.type !== "doc" && !input?.content?.length)) return false;
  return (input?.content || []).some((paragraph) => {
    return (paragraph?.content || []).some((node) => {
      return node.type === "variable" || (node.type === "text" && (node?.marks || []).length > 0);
    });
  });
};

// Checks to see if each ITipTapText has bold mark
export const isRichTextFullyBold = (richText: ITipTapRichText) => {
  const res = richText.content.every((paragraph) => {
    if (paragraph.type === "paragraph") {
      return paragraph.content?.every((text) => {
        if (text.type === "text") {
          return text.marks && text.marks.map((mark) => mark.type).includes("bold");
        }
        return true;
      });
    }
    return true;
  });

  return res;
};

export const removeBoldFromRichText = (richText: ITipTapRichText) => {
  const richTextWithoutBold = { ...richText };
  richText.content!.forEach((paragraph, paragraphIdx) =>
    paragraph.content?.forEach((text, textIdx) => {
      if (paragraph.content && paragraph.content[textIdx]) {
        const textToEdit = richTextWithoutBold.content![paragraphIdx].content![textIdx];
        // Some silly typescript checks
        if (textToEdit.type === "text" && text.type === "text") {
          textToEdit.marks = text.marks?.filter((m) => m.type !== "bold");
          console.log({ textToEdit });
        }
      }
    })
  );

  return richTextWithoutBold;
};
