import { derivedOnlySelectedComponentAtom } from "@/stores/Library";
import DeveloperId from "@/views/NS/components/DeveloperId";
import InstanceCount from "@ds/molecules/InstanceCount";
import { useAtomValue } from "jotai";
import React, { Suspense } from "react";
import DeleteItem from "../../../components/DeleteItem";
import LibraryMetadataPanel from "../LibraryComponentMetadata";

// TODO: Implement this with correct functionality
function LibraryEditEditPanel() {
  const instanceCount = 24;
  const derivedOnlySelectedComponent = useAtomValue(derivedOnlySelectedComponentAtom);

  const previewBannerClick = () => {};

  return (
    <>
      {instanceCount > 0 && (
        <Suspense fallback={<InstanceCount content={`Loading instances...`} actionText="" onActionClick={() => {}} />}>
          {/* {!designPreviewToggled && ( */}
          <InstanceCount
            content={`${instanceCount} instances across 4 projects`}
            actionText="Preview"
            onActionClick={previewBannerClick}
          />
          {/* )} */}
        </Suspense>
      )}
      <Suspense fallback={<div>Loading Metadata...</div>}>
        <LibraryMetadataPanel />
      </Suspense>

      {/* These elements should only be rendered if exactly one text item is selected */}
      {!!derivedOnlySelectedComponent && (
        <>
          {/* <Suspense fallback={<></>}>
            <Comments />
          </Suspense> */}
          <DeveloperId />
          <DeleteItem onClick={() => {}} text="Delete component" />
        </>
      )}
    </>
  );
}

export default LibraryEditEditPanel;
