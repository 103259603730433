import { useWorkspace } from "@/store/workspaceContext";
import { SUBERSCRIPT_ENABLED_WORKSPACES } from "@/utils/featureFlags";
import prepareRichTextNodes from "@shared/common/richText/prepareRichTextNodes";
import { ITipTapRichText } from "@shared/types/TextItem";
import logger from "@shared/utils/logger";
import { generateHTML } from "@tiptap/react";
import classnames from "classnames";
import parse from "html-react-parser";
import React, { useEffect, useMemo, useRef } from "react";
import { getExtensions } from "../../editor/Extensions";
import style from "./RichTextRender.module.css";

interface IProps {
  richText: ITipTapRichText;
  renderProps?: React.HTMLProps<HTMLDivElement>;
  highlightBrackets?: boolean;
  truncate?: boolean | number;
}

function getLineHeight(el) {
  var temp = document.createElement(el.nodeName),
    ret;
  temp.setAttribute(
    "style",
    "margin:0; padding:0; " +
      "font-family:" +
      (el.style.fontFamily || "inherit") +
      "; " +
      "font-size:" +
      (el.style.fontSize || "inherit")
  );
  temp.innerHTML = "A";

  el.parentNode.appendChild(temp);
  ret = temp.clientHeight;
  temp.parentNode.removeChild(temp);

  return ret;
}

const RichTextRender = (props: IProps) => {
  const { richText, renderProps } = props;
  const divRef = useRef<HTMLDivElement>(null);

  const { workspaceInfo } = useWorkspace();
  const suberscriptEnabled = useMemo(() => {
    if (!workspaceInfo) {
      return false;
    }

    return SUBERSCRIPT_ENABLED_WORKSPACES.includes(workspaceInfo._id);
  }, [workspaceInfo]);

  const extensions = getExtensions({
    isRichTextEnabled: true,
    highlightBrackets: props.highlightBrackets,
  });

  const richTextHTML = useMemo(() => {
    try {
      return parse(
        generateHTML(
          prepareRichTextNodes(richText, {
            highlightBrackets: props.highlightBrackets,
          }),
          extensions
        )
      );
    } catch (e) {
      logger.error("Failed to parse rich text", { context: { richText } }, e);
      return <></>;
    }
  }, [richText, props.highlightBrackets]);

  const cypressTag = useMemo(() => {
    return (
      (props.richText.content &&
        props.richText.content[0] &&
        props.richText.content[0].content &&
        props.richText.content[0].content[0] &&
        "text" in props.richText.content[0].content[0] &&
        props.richText.content[0].content[0].text.replace(/ /g, "-").replace(/’/g, "").replace(/,/g, "")) ||
      ""
    );
  }, [props.richText]);

  useEffect(
    function calculateTruncation() {
      if (!props.truncate || !divRef.current) {
        return;
      }

      // If props.truncate is a number, treat the value as the height which should be truncated against.
      // Supporting this is necessary for <RichTextRender /> usage in parents where a height cannot
      // be implicitly calculated.
      const divHeight = typeof props.truncate === "number" ? props.truncate : divRef.current.clientHeight;

      const paragraphs = divRef.current.querySelectorAll("p");

      let totalHeight = 0;
      let overflowed = false;

      // Calculate the total height of paragraphs until it exceeds the div's height
      for (const paragraph of paragraphs) {
        paragraph.style.visibility = "unset";
        if (overflowed) {
          paragraph.style.display = "none";
          continue;
        }

        let newTotalHeight = totalHeight + paragraph.clientHeight;
        if (newTotalHeight < divHeight) {
          totalHeight = newTotalHeight;
          continue;
        }

        overflowed = true;
        const paragraphLineHeight = getLineHeight(paragraph);
        const lines = Math.floor((divHeight - totalHeight) / paragraphLineHeight);
        paragraph.style.webkitLineClamp = lines.toString();

        // Remove the excess paragraphs
      }
    },
    [props.truncate, divRef.current, richTextHTML]
  );

  return (
    <div
      ref={divRef}
      {...renderProps}
      className={classnames(style.container, renderProps?.className)}
      data-suberscript={suberscriptEnabled ? "enabled" : "disabled"}
      data-testid={`rich-text-render-${cypressTag}`}
    >
      {richTextHTML}
    </div>
  );
};

export default RichTextRender;
