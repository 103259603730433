import React from "react";

function LibraryComponentIcon(props: { className?: string; style?: React.CSSProperties }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <rect x="0.4" y="0.4" width="11.2" height="11.2" rx="3.6" stroke="#6C40EA" strokeWidth="0.8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00007 2.90137H9.00007V3.70132H9V4.70117H8.20005V3.70132H6.39956V8.29788H7.60018V9.09783H4.40039V8.29788H5.59961V3.70132H3.79995V4.70117H3V3.70117H3.00007V2.90137Z"
        fill="#6C40EA"
      />
    </svg>
  );
}

export default LibraryComponentIcon;
