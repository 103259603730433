import { createAndDownloadFile } from "@shared/frontend/lib";
import { IWebhookLog } from "@shared/types/WebhookLog";
import { IWebhookConfig } from "@shared/types/Workspace";
import { useEffect, useRef } from "react";
import * as httpWebhookLog from "../../http/webhookLog";
import * as httpWorkspace from "../../http/workspaceNew";
import { IProps as IWebhookModalProps } from "./WebhookModal";
import { useComponentFolders } from "./useComponentFolders";

interface IUseWebhookModalProps {
  webhook: IWebhookConfig | null;
  onHide: () => void;
  onWebhookModified: () => void;
}

export function useWebhookModal(props: IUseWebhookModalProps): IWebhookModalProps {
  // track cancel functions for inflight requests so we can bail
  // if the user closes the modal before they finish, avoiding memory leaks
  const inflightRequests = useRef<(() => void)[]>([]);
  const inflightRequestStarted = (cancelFunction: () => void) => {
    inflightRequests.current.push(cancelFunction);
  };
  const inflightRequestFinished = (cancelFunction: () => void) => {
    inflightRequests.current = inflightRequests.current.filter((r) => r !== cancelFunction);
  };
  useEffect(function cancelInflightRequests() {
    return () => inflightRequests.current.forEach((cancelRequest) => cancelRequest());
  }, []);

  const componentFolderState = useComponentFolders();
  const componentFolders = componentFolderState.loading
    ? []
    : componentFolderState.data.filter((folder) => !folder.isSample);

  async function onSendTestRequest(webhookId: string, url: string): Promise<IWebhookLog> {
    const [request, cancelRequest] = httpWorkspace.sendWebhookTestRequest({
      webhookId,
      url,
    });
    inflightRequestStarted(cancelRequest);
    const { data } = await request;
    inflightRequestFinished(cancelRequest);
    return data;
  }

  async function onValidateUniqueUrl(webhookId: string, url: string): Promise<boolean> {
    const [request, cancelRequest] = httpWorkspace.validateWebhookUrl({
      webhookId,
      url,
    });
    inflightRequestStarted(cancelRequest);
    const { data } = await request;
    inflightRequestFinished(cancelRequest);
    return data;
  }

  async function onSave(isNew: boolean, config: IWebhookConfig) {
    const requestFunction = isNew ? httpWorkspace.createWebhookEndpoint : httpWorkspace.updateWebhookEndpoint;

    const [request, cancelRequest] = requestFunction(config);
    inflightRequestStarted(cancelRequest);
    const { data } = await request;
    inflightRequestFinished(cancelRequest);
    props.onWebhookModified();
    props.onHide();
    return data;
  }

  async function onDelete(webhookId: string) {
    const [request, cancelRequest] = httpWorkspace.deleteWebhookEndpoint({
      webhookId,
    });
    inflightRequestStarted(cancelRequest);
    const { data } = await request;
    inflightRequestFinished(cancelRequest);
    props.onWebhookModified();
    props.onHide();
    return data;
  }

  async function loadDeliveryHistory(webhookId: string) {
    const [request, cancelRequest] = httpWebhookLog.fetchWebhookLogs({
      webhookId,
    });
    inflightRequestStarted(cancelRequest);
    const { data } = await request;
    inflightRequestFinished(cancelRequest);
    return data;
  }

  async function onExportDeliveryHistory(webhookId: string) {
    const [request, cancelRequest] = httpWebhookLog.exportWebhookLogsCsv({
      webhookId,
    });
    inflightRequestStarted(cancelRequest);
    const { data } = await request;
    inflightRequestFinished(cancelRequest);

    const now = new Date().toISOString();
    createAndDownloadFile(`webhook-delivery-history-${now}.csv`, data, "text/csv;");
  }

  function onHide() {
    props.onHide();
  }

  return {
    webhook: props.webhook,
    componentFolders,
    onSendTestRequest,
    onValidateUniqueUrl,
    onSave,
    onDelete,
    onExportDeliveryHistory,
    loadDeliveryHistory,
    onHide,
  };
}
