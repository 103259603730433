import * as HttpConnections from "@/http/connections";
import { useWorkspace } from "@/store/workspaceContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useScroll } from "../../hooks/useScroll";
import http, { API } from "../../http";
import { useVariantSelectionState } from "./useVariantSelectionState";

const useVariants = () => {
  const [loadingVariants, setLoadingVariants] = useState(true);
  const history = useHistory();
  const [variants, setVariants] = useState([]);
  const [variantSelectionState, variantSelectionActions] = useVariantSelectionState(variants);
  const [query, setQuery] = useState("");
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [variantHistory, setVariantHistory] = useState({
    items: [],
    skip: 20,
    page: 0,
    limit: 20,
    allHistoryFetched: false,
  });
  const [loadingVariantFolders, setLoadingVariantFolders] = useState(true);
  const [variantFolders, setVariantFolders] = useState([]);
  const [variantsToMove, setVariantsToMove] = useState([]);
  const [notification, setNotification] = useState(null);

  const { workspaceInfo } = useWorkspace();
  const foldersAllowed = workspaceInfo?.plan === "growth" || workspaceInfo?.plan === "enterprise";

  const { scrollToId } = useScroll({ containerId: "variantContainer" });

  // Hacky flag to prevent infinite loading state
  // Have to do this because "variants" is initialized as []
  // [] is a valid, post API call state
  const hasInitialized = useRef(false);
  // If variants are set, then loading must be done
  useEffect(() => {
    if (loadingVariants && hasInitialized.current) {
      setLoadingVariants(false);
    }
    hasInitialized.current = true;
  }, [variants]);

  /**
   * Fetches array of ActivityItems for variant history
   * @param {integer} skip How many items to skip
   * @param {integer} limit How many items to take
   * @returns {array} Array of ActivityItems
   */
  async function fetchVariantHistory(skip, limit) {
    const { url } = API.changes.get.variantAll;
    const { data: activityHistory } = await http.get(url(skip, limit, selectedFolderId));

    return activityHistory.info;
  }

  const refreshVariantHistory = async () => {
    try {
      let page = variantHistory.page;
      const skip = 0;
      const limit = variantHistory.limit * page;

      let activityHistory = await fetchVariantHistory(skip, limit);

      setVariantHistory({
        ...variantHistory,
        items: [...activityHistory],
        page: page,
      });
    } catch (error) {
      console.error("in useVariants.js while refreshing variants history: " + error.message);
    }
  };

  // we handle selection through a useEffect in views/Variants/index.jsx because we need to
  // keep synchronized with URL state
  const handleSelectFolder = (folderId) => {
    history.push(folderId ? `/variants/folder/${folderId}` : "/variants");
  };

  const fetchVariantHistoryNewPage = async () => {
    try {
      setLoadingHistory(true);
      let page = variantHistory.page;
      const skip = variantHistory.skip * page;
      const limit = variantHistory.limit;

      page++;

      let activityHistory = await fetchVariantHistory(skip, limit);

      setVariantHistory((prev) => ({
        ...prev,
        items: [...variantHistory.items, ...activityHistory],
        page: page,
        allHistoryFetched: activityHistory.length < 20 ? true : false,
      }));
      setLoadingHistory(false);
    } catch (error) {
      setLoadingHistory(false);
      console.error("in useVariants.js while fetching page of variants history: " + error.message);
    }
  };

  const handleQueryChange = (value) => {
    setQuery(value);
  };

  const { folder_id: selectedFolderId, id } = useParams();
  const fetchVariants = async () => {
    try {
      setLoadingVariants(true);

      // if the user has has either of the A/B testing integrations enabled, we need to sync
      // the variants with the external service first
      if (workspaceInfo?.integrations.launchDarkly?.tokenPreview) {
        try {
          const [loadRequest] = HttpConnections.loadLaunchDarklyData();
          const data = (await loadRequest).data.data;

          const [syncRequest] = HttpConnections.launchDarklySync({ data });
          await syncRequest;
        } catch (error) {
          console.error("Error syncing with LaunchDarkly", error);
        }
      }

      if (workspaceInfo?.integrations.split?.tokenPreview) {
        try {
          const [loadRequest] = HttpConnections.loadSplitData();
          const data = (await loadRequest).data.data;

          const [syncRequest] = HttpConnections.splitSync({ data });
          await syncRequest;
        } catch (error) {
          console.error("Error syncing with Split", error);
        }
      }

      let { url } = API.variant.get.getVariantsForWorkspace;

      if (selectedFolderId) {
        url += `?folderId=${selectedFolderId}`;
      } else if (!selectedFolderId) {
        url += `?folderId=none`;
      }
      const { data: result } = await http.get(url);
      setVariants(result);
    } catch (err) {
      console.error("Error fetching variants", err);
    }
  };

  const initialized = useRef(false);
  useEffect(
    function handleInitialSelection() {
      if (!initialized.current && id && variants.length > 0) {
        initialized.current = true;
        variantSelectionActions.handleSingleSelect(id);
      }
    },
    [variantSelectionActions]
  );
  useEffect(() => {
    variantSelectionActions.handleSingleSelect(id);
  }, [id]);

  const handleMoveVariants = async (folderId, variantIds) => {
    try {
      setLoadingVariants(true);
      let { url, body } = API.variantFolder.post.moveVariantsToFolder;
      await http.post(url(folderId), body(variantIds));
      await fetchVariants();
      handleSelectFolder(folderId);
      return;
    } catch (err) {
      console.error("Error moving variants", err);
    }
  };

  const handleRenameVariant = async (id, newName) => {
    try {
      const { url, body } = API.variant.put.nameWithId;
      const { data: updatedVariant } = await http.put(
        url(id),
        body({
          newName,
        })
      );

      setVariants((prev) => {
        let temp = [...prev];
        let index = temp.findIndex((v) => v._id === updatedVariant._id);
        if (index !== -1) temp[index].name = newName;
        return temp;
      });
      refreshVariantHistory();
      return true;
    } catch (err) {
      console.error("Error renaming variant", err);
      return false;
    }
  };

  const handleRenameVariantApiID = async (id, newValue) => {
    try {
      const { url, body } = API.variant.put.apiID;
      const { data: updatedVariant } = await http.put(url(id), body({ newValue }));

      setVariants((prev) => {
        let temp = [...prev];
        let index = temp.findIndex((v) => v._id === updatedVariant._id);
        if (index !== -1) temp[index].apiID = newValue;
        return temp;
      });
      refreshVariantHistory();
      return "success";
    } catch (err) {
      if (err.response.data === "Variant apiID already exists") return "Conflict";

      console.error("Error renaming variant apiID", err);
      return "Unable to update Variant 'apiID'";
    }
  };
  const handleCreateNewVariant = async ({ name, description }, folderId) => {
    try {
      const { url, body } = API.variant.post.createVariant;
      const { data: newVariant } = await http.post(
        url,
        body({
          name,
          description,
          folderId,
        })
      );
      await Promise.all([refreshVariantHistory(), fetchVariants()]);

      // if we're creating in a different folder, navigate to that folder after creation
      if (selectedFolderId !== folderId) {
        handleSelectFolder(folderId);
      }

      //  scroll to the new variant
      if (newVariant && newVariant.id) {
        scrollToId(newVariant.id);
      }

      return "success";
    } catch (e) {
      if (e.response && e.response.status === 409) {
        return "A variant with this name already exists.";
      } else return "Unable to create new variant at this time.";
    }
  };
  const handleDeleteVariants = async (variantIds) => {
    try {
      const { url, body } = API.variant.post.deleteVariants;
      const { data } = await http.post(url, body({ variantIds }));
      const deletedCount = data.deletedCount;
      const deletedMessage = `Successfully deleted ${deletedCount} variant${deletedCount > 1 ? "s" : ""}`;

      setNotification({
        message: deletedMessage,
        time: 5000,
        type: "success",
      });

      setVariants((prev) => {
        let temp = [...prev];
        temp = temp.filter((v) => !variantIds.includes(v._id));
        return temp;
      });

      setVariantsToMove([]);
      refreshVariantHistory();
      return true;
    } catch (err) {
      console.error("Error deleting variant", err);
      setNotification({
        message: "Error deleting variants",
        time: 5000,
        type: "error",
      });
      return false;
    }
  };

  useEffect(
    function autoHideNotification() {
      if (notification) {
        setTimeout(() => {
          setNotification(null);
        }, notification.time);
      }
    },
    [notification]
  );

  useEffect(() => {
    if (workspaceInfo) {
      analytics.page("Variants", {
        workspace_id: workspaceInfo?._id,
        version: "legacy",
        application: "web_app",
      });
    }
  }, [workspaceInfo]);

  useEffect(() => {
    if (workspaceInfo) {
      fetchVariants();
    }
  }, [selectedFolderId, workspaceInfo]);

  useEffect(() => {
    fetchVariantHistoryNewPage();
  }, []);

  const uniqueVariantNames = useMemo(() => {
    return variants.map((v) => v.name.toLowerCase());
  }, [variants]);

  const uniqueApiIDs = useMemo(() => {
    return variants.map((v) => v.apiID);
  }, [variants]);

  const filteredVariants = useMemo(() => {
    if (query === "") return variants;
    return variants.filter((v) => v.name.toLowerCase().indexOf(query) !== -1);
  }, [variants, query]);

  useEffect(() => {
    fetchVariantFolders();
  }, [variants]);

  async function fetchVariantFolders() {
    setLoadingVariantFolders(true);
    const { url } = API.variantFolder.get.folders;
    const { data } = await http.get(url);
    setVariantFolders(data);
    setLoadingVariantFolders(false);
    refreshVariantHistory();
  }

  async function handleCreateNewVariantFolder(name) {
    const { url } = API.variantFolder.post.createFolder;
    const { data } = await http.post(url, { name });
    setVariantFolders((folders) => {
      const array = [...folders, data].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      return array;
    });
    refreshVariantHistory();
    return data;
  }

  async function handleRenameVariantFolder(name, folderId) {
    const { url } = API.variantFolder.put.updateFolder;
    await http.put(url(folderId), { name });
    fetchVariantFolders();
  }

  async function handleDeleteVariantFolder(folderId) {
    const { url } = API.variantFolder.delete.deleteFolder;
    await http.delete(url(folderId));
    fetchVariantFolders();
  }

  function updateVariantInMemory(variantId, variantData) {
    setVariants((variants) =>
      variants.map((v) => (v._id.toString() === variantId.toString() ? { ...v, ...variantData } : v))
    );
  }

  return {
    loadingVariants,
    setLoadingVariants,
    unfilteredVariants: variants,
    variants: filteredVariants,
    updateVariantInMemory,
    loadingHistory,
    variantHistory,
    uniqueApiIDs,
    uniqueVariantNames,
    query,
    setQuery,
    handleQueryChange,
    handleCreateNewVariant,
    handleRenameVariant,
    handleRenameVariantApiID,
    handleDeleteVariants,
    handleMoveVariants,
    fetchVariantHistoryNewPage,
    fetchVariants,
    refreshVariantHistory,
    foldersAllowed,
    loadingVariantFolders,
    variantFolders,
    handleCreateNewVariantFolder,
    handleRenameVariantFolder,
    handleDeleteVariantFolder,
    selectedFolderId,
    variantsToMove,
    setVariantsToMove,
    notification,
    variantSelectionState,
    variantSelectionActions,
  };
};
export { useVariants };
