import * as httpDittoProject from "@/http/dittoProject";
import { derivedSelectedTextItemsAtom, selectedBlockAtom } from "@/stores/ProjectSelection";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import {
  IFramePreviewDataWithSelectionData,
  ITextNodeDiff,
  ITextNodeWithSelectionData,
} from "@shared/types/DittoProject";
import { errors } from "@shared/types/http/DittoProject";
import logger from "@shared/utils/logger";
import { AxiosError } from "axios";
import { Atom, atom } from "jotai";
import { derive } from "jotai-derive";
import {
  blockIdToTextItemIdsMapAtom,
  projectFigmaFileIdAtom,
  projectIdAtom,
  textItemsMapAtom,
} from "./Project";

export const { valueAtom: syncedTextNodesMapAtom, refreshAtom: refreshSyncedTextNodesMapAtom } =
  asyncMutableDerivedAtom({
    async loadData(get) {
      const projectId = get(projectIdAtom);
      if (!projectId) return {};
      const [request] = httpDittoProject.getSyncedTextNodesMap({ projectId });
      return (await request).data;
    },
  });

/**
 * This atom holds a map of frame preview data that we fetch from the backend. This data is pulled from the database,
 * *not* from a background job, but we do want to re-fetch it if a preview job completes.
 */
export const {
  valueAtom: framePreviewDataAtom,
  refreshAtom: refreshFramePreviewDataAtom,
  isRefreshingAtom: frameDataIsRefreshingAtom,
} = asyncMutableDerivedAtom({
  async loadData(get) {
    const projectId = get(projectIdAtom);
    if (!projectId) throw new Error("projectIdAtom is not set");

    const projectFileId = await get(projectFigmaFileIdAtom);
    if (!projectFileId)
      return { framePreviewMap: {}, textItemIdsToTopLevelFrameNodeIds: {}, textNodeIdsToTopLevelFrameNodeIds: {} };

    try {
      const [request] = httpDittoProject.getFramePreviewsMap({ projectId });
      const { framePreviewMap, textItemIdsToTopLevelFrameNodeIds, textNodeIdsToTopLevelFrameNodeIds } = (await request)
        .data;
      return { framePreviewMap, textItemIdsToTopLevelFrameNodeIds, textNodeIdsToTopLevelFrameNodeIds };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.message === errors.NO_FIGMA_FILE_ASSOCIATED_WITH_PROJECT) {
          logger.warn("Could not fetch frame previews -- no Figma file associated with project", {
            context: { projectId },
          });
          return { framePreviewMap: {}, textItemIdsToTopLevelFrameNodeIds: {}, textNodeIdsToTopLevelFrameNodeIds: {} };
        }
      }
      logger.error("Error fetching frame previews", { context: { projectId } }, error);
      return { framePreviewMap: {}, textItemIdsToTopLevelFrameNodeIds: {}, textNodeIdsToTopLevelFrameNodeIds: {} };
    }
  },
});

// Given a string like "{{Hello}} {{world}}", return "Hello world"
function stripVariableBrackets(text: string) {
  return text.replaceAll(/{{([^}]+)}}/g, "$1");
}

/**
 * Map from text item IDs to all the diffs for that text item.
 */
const textDiffsMapAtom = atom(async (get) => {
  const [syncedTextNodesMap, textItemsMap, framePreviewData] = await Promise.all([
    get(syncedTextNodesMapAtom),
    get(textItemsMapAtom),
    get(framePreviewDataAtom),
  ]);

  const { textNodeIdsToTopLevelFrameNodeIds } = framePreviewData;

  const textDiffsMap = Object.values(textItemsMap).reduce<Record<string, ITextNodeDiff[]>>((textDiffsMap, textItem) => {
    textItem.integrations.figmaV2?.instances?.forEach((instance) => {
      const syncedTextNode = syncedTextNodesMap[instance.figmaNodeId];
      if (!syncedTextNode) return;

      // Note: because of the way we store plain text w/ variables, we need to strip the variables before comparing
      // to Figma node text.
      //
      // We should ideally factor in the variables when comparing, as well as rich text, but that will have to wait
      // until the EditedText component is updated to support variables and rich text -- DIT-8478
      const hasDiff = stripVariableBrackets(textItem.text) !== syncedTextNode.text;
      if (!hasDiff) return;

      textDiffsMap[textItem._id] ??= [];
      textDiffsMap[textItem._id].push({
        textItemId: textItem._id,
        nodeId: instance.figmaNodeId,
        textBefore: syncedTextNode.text,
        textAfter: textItem.text,
        textItemUpdatedAt: textItem.text_last_modified_at.toString(),
        frameNodeId: textNodeIdsToTopLevelFrameNodeIds[instance.figmaNodeId],
      });
    });
    return textDiffsMap;
  }, {});

  return textDiffsMap;
});

/**
 * Take the frame preview data from the backend and add in text diffs calculated from our local state.
 */
const allFramePreviewsAtom: Atom<IFramePreviewDataWithSelectionData[] | Promise<IFramePreviewDataWithSelectionData[]>> =
  derive([framePreviewDataAtom, textDiffsMapAtom], ({ framePreviewMap }, textDiffsMap) => {
    const previews = Object.values(framePreviewMap).map((preview) => {
      let frameHasModifiedTextItems = false;

      // Map over the text nodes we get from the backend, and add in the diff data if it exists
      const textNodesToHighlight: ITextNodeWithSelectionData[] = [];
      for (const node of preview.textNodesToHighlight) {
        const diff = textDiffsMap[node.pluginData?.textItemId ?? ""]?.find((diff) => diff.nodeId === node.nodeId);
        if (diff) frameHasModifiedTextItems = true;

        textNodesToHighlight.push({
          ...node,
          isSelected: false, // default to false -- selection data included at the next step!
          diff,
        });
      }

      return {
        ...preview,
        textNodesToHighlight,
        frameIsModified: frameHasModifiedTextItems,
      };
    });

    return previews;
  });

/**
 * The list of selected text items that we want to show previews for. This is the list of selected text items, or if
 * we have a block selected, all the text items that are in that block.
 */
export const selectedTextItemsForPreviewAtom = atom(async (get) => {
  const [selectedTextItems, selectedBlock, blockIdToTextItemIdsMap, textItemsMap] = await Promise.all([
    get(derivedSelectedTextItemsAtom),
    get(selectedBlockAtom),
    get(blockIdToTextItemIdsMapAtom),
    get(textItemsMapAtom),
  ]);

  if (selectedBlock) {
    const textItemIdsForBlock = blockIdToTextItemIdsMap[selectedBlock._id];
    if (!textItemIdsForBlock) return [];
    return textItemIdsForBlock.map((id) => textItemsMap[id]).filter(Boolean);
  } else {
    return selectedTextItems;
  }
});

const selectedTextNodeIdsAtom = derive([selectedTextItemsForPreviewAtom], (selectedTextItems) => {
  const selectedTextNodeIds = selectedTextItems.reduce((acc, textItem) => {
    textItem.integrations.figmaV2?.instances?.forEach((instance) => {
      acc.add(instance.figmaNodeId);
    });
    return acc;
  }, new Set<string>());

  return selectedTextNodeIds;
});

/**
 * List of previews with selection data added in. List is filtered by the currently selected text items, if any.
 */
export const filteredFramePreviewsAtom: Atom<
  IFramePreviewDataWithSelectionData[] | Promise<IFramePreviewDataWithSelectionData[]>
> = derive(
  [
    framePreviewDataAtom,
    selectedTextItemsForPreviewAtom,
    allFramePreviewsAtom,
    selectedBlockAtom,
    selectedTextNodeIdsAtom,
  ],
  ({ textItemIdsToTopLevelFrameNodeIds }, selectedTextItems, allFramePreviews, selectedBlock, selectedTextNodeIds) => {
    const previewsWithSelectionData = allFramePreviews.map((preview) => ({
      ...preview,
      textNodesToHighlight: preview.textNodesToHighlight.map((node) => ({
        ...node,
        isSelected: selectedTextNodeIds.has(node.nodeId),
      })),
    }));

    // Get all the top-level frame node IDs that are associated with the selected text items, so we know which frames to show.
    const framesOfSelectedTextItems = new Set<string>();
    selectedTextItems.forEach((textItem) => {
      const topLevelFrameNodeIds = textItemIdsToTopLevelFrameNodeIds[textItem._id];
      if (!topLevelFrameNodeIds) return;
      topLevelFrameNodeIds.forEach((frameNodeId) => {
        framesOfSelectedTextItems.add(frameNodeId);
      });
    });

    if (selectedTextItems.length === 0) return previewsWithSelectionData;
    else return previewsWithSelectionData.filter((preview) => framesOfSelectedTextItems.has(preview.frameNodeId));
  }
);

function reverseMap(map: Record<string, string[]>): Record<string, string[]> {
  const reversedMap: Record<string, string[]> = {};
  Object.entries(map).forEach(([key, values]) => {
    values.forEach((value) => {
      reversedMap[value] = [...(reversedMap[value] || []), key];
    });
  });
  return reversedMap;
}

const pinnedNodeIdOverrideAtom = atom<string | null>(null);

export const setPinnedNodeIdOverrideAtom = atom(null, (get, set, nodeId: string | null) => {
  set(pinnedNodeIdOverrideAtom, nodeId);
});

export const pinnedNodeIdAtom = derive(
  [pinnedNodeIdOverrideAtom, selectedTextNodeIdsAtom],
  (pinnedNodeIdOverride, selectedTextNodeIds) => {
    if (!pinnedNodeIdOverride) return null;
    if (selectedTextNodeIds.has(pinnedNodeIdOverride)) return pinnedNodeIdOverride;
    return null;
  }
);
