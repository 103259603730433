import React from "react";

export default function StarIcon(props: { style?: React.CSSProperties; className?: string }) {
  return (
    <svg
      style={props.style}
      className={props.className}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5578 9.29655C2.17832 9.52232 1.71385 9.18129 1.81562 8.75161L2.39195 6.31819C2.4352 6.1356 2.37257 5.94416 2.22976 5.82244L0.309271 4.18545C-0.0284158 3.89761 0.14826 3.34524 0.590307 3.30681L3.12708 3.08622C3.31049 3.07027 3.47029 2.95483 3.54306 2.78572L4.5397 0.469445C4.71331 0.0659581 5.28553 0.06637 5.45856 0.470107L6.45647 2.79856C6.52934 2.9686 6.69016 3.08451 6.87452 3.09987L9.40309 3.31058C9.84614 3.34751 10.0243 3.90098 9.68592 4.18938L7.77004 5.82244C7.62724 5.94416 7.56461 6.1356 7.60785 6.31819L8.18419 8.75161C8.28596 9.18129 7.82149 9.52232 7.442 9.29655L5.25555 7.99575C5.098 7.90202 4.9018 7.90202 4.74426 7.99575L2.5578 9.29655Z"
        fill="#737373"
      />
    </svg>
  );
}
