import http from "@/http";
import { getComponentBlock, getComponentGroup, getComponentLibraryRoot } from "@/http/ws_comp_typed";
import { componentsCreatedAction } from "@/views/Components/components/comp-library-nav/componentsCreatedAction";
import { componentsDeletedAction } from "@/views/Components/components/comp-library-nav/componentsDeletedAction";
import { componentsRenamedAction } from "@/views/Components/components/comp-library-nav/componentsRenamedAction";
import { IComponentRenameMap } from "@shared/ditto-events";
import {
  ComponentNames,
  getBlockTreeItem,
  getDeterministicUniqueId,
  getGroupTreeItem,
  getLoadingSpinnerTreeItem,
} from "@shared/lib/components";
import { IComponent } from "@shared/types/Component";
import { IComponentFolder } from "@shared/types/ComponentFolder";
import { IBlockTreeItem, IFTreeItem, IGroupTreeItem } from "@shared/types/http/Component";
import { AxiosError, CanceledError } from "axios";
import { createContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { NodeApi, TreeApi } from "react-arborist";
import { SelectionState } from "../../useSelectionState";

export type LibraryNavState = {
  treeItems: IFTreeItem[];
};
type Action =
  | { type: "addGroup"; id: string; name: string }
  | { type: "setItems"; items: IFTreeItem[] }
  | {
      type: "addGroupChildren";
      position?: "front" | "back";
      id: string;
      children: IFTreeItem[];
      filterOutToBeLoaded?: boolean;
    }
  | { type: "setGroupChildren"; id: string; children: IFTreeItem[] }
  | {
      type: "addBlockChildren";
      position?: "front" | "back";
      groupId: string;
      blockId: string;
      children: IFTreeItem[];
      filterOutToBeLoaded?: boolean;
    }
  | {
      type: "setBlockChildren";
      groupId: string;
      blockId: string;
      children: IFTreeItem[];
    }
  | { type: "addBlock"; id: string; groupName: string; name: string }
  | { type: "setSelectedNodeIds"; nodeIds: string[] }
  | { type: "componentsRenamed"; data: IComponentRenameMap }
  | { type: "componentsDeleted"; data: { deletedComponentIds: string[] } }
  | { type: "componentsCreated"; data: { componentMap: Record<string, ComponentNames> } };

type LibraryNavStateActions = { [key in Action["type"]]: key };

export const LIB_NAV_ACTIONS: LibraryNavStateActions = {
  setItems: "setItems",
  addGroup: "addGroup",
  addGroupChildren: "addGroupChildren",
  setGroupChildren: "setGroupChildren",
  addBlockChildren: "addBlockChildren",
  setBlockChildren: "setBlockChildren",
  addBlock: "addBlock",
  setSelectedNodeIds: "setSelectedNodeIds",
  componentsRenamed: "componentsRenamed",
  componentsDeleted: "componentsDeleted",
  componentsCreated: "componentsCreated",
};

export function reducer(treeState: LibraryNavState, action: Action) {
  const { treeItems } = treeState;

  switch (action.type) {
    case "setItems": {
      return { ...treeState, treeItems: action.items };
    }
    case "addGroup": {
      const group = action.name;
      const newGroup: IFTreeItem = {
        id: action.id,
        name: group,
        type: "group",
        children: [],
      };
      const newTreeItems = [...treeItems, newGroup];
      return { ...treeState, treeItems: newTreeItems };
    }
    case "addGroupChildren": {
      const position = action.position || "back";

      const newTreeItems = treeItems.map((item) => {
        if (item.type === "group" && item.id === action.id) {
          const itemChildren = item.children || [];
          const newGroup: IFTreeItem = {
            ...item,
            children: [
              ...(position === "front" ? [...itemChildren, ...action.children] : [...action.children, ...itemChildren]),
            ].filter((child) => !(child.type === "to-be-loaded" && action.filterOutToBeLoaded)),
          };

          return newGroup;
        } else {
          return item;
        }
      });
      return { ...treeState, treeItems: newTreeItems };
    }
    case "setGroupChildren": {
      const newTreeItems = treeItems.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            children: action.children,
          };
        } else {
          return item;
        }
      });
      return { ...treeState, treeItems: newTreeItems };
    }
    case "addBlockChildren": {
      const { groupId, blockId } = action;
      const position = action.position || "back";

      const newTreeItems = treeItems.map((item) => {
        if (item.type === "group" && item.id === groupId) {
          const newGroupChildren = item.children?.map((block) => {
            if (block.type === "block" && block.id === blockId) {
              const blockChildren = block.children || [];
              const newBlock = {
                ...block,
                children: [
                  ...(position === "front"
                    ? [...action.children, ...blockChildren]
                    : [...blockChildren, ...action.children]),
                ].filter((child) => !(child.type === "to-be-loaded" && action.filterOutToBeLoaded)),
              };
              return newBlock;
            } else {
              return block;
            }
          });
          const newGroup: IFTreeItem = {
            ...item,
            children: newGroupChildren,
          };

          return newGroup;
        } else {
          return item;
        }
      });
      return { ...treeState, treeItems: newTreeItems };
    }
    case "setBlockChildren": {
      const { groupId, blockId } = action;

      const newTreeItems = treeItems.map((item) => {
        if (item.type === "group" && item.id === groupId) {
          const newGroupChildren = item.children?.map((item) => {
            if (item.type === "block" && item.id === blockId) {
              const newBlock = {
                ...item,
                children: action.children,
              };
              return newBlock;
            } else {
              return item;
            }
          });
          const newGroup: IFTreeItem = {
            ...item,
            children: newGroupChildren,
          };

          return newGroup;
        } else {
          return item;
        }
      });
      return { ...treeState, treeItems: newTreeItems };
    }
    case "addBlock": {
      const group = action.groupName;
      const block = action.name;

      const newGroup = getGroupTreeItem(group, [getBlockTreeItem(block, group)]);
      const newTreeItems = [...treeItems, newGroup];
      return { ...treeState, treeItems: newTreeItems };
    }
    case "setSelectedNodeIds": {
      const selectedNodeIdsSet = new Set(action.nodeIds);
      return treeState;
    }
    // if you have found your way to this branch, ask Reed for help
    case "componentsRenamed": {
      return componentsRenamedAction(treeState, action.data);
    }
    case "componentsDeleted": {
      return componentsDeletedAction(treeState, action.data.deletedComponentIds);
    }
    case "componentsCreated": {
      return componentsCreatedAction(treeState, action.data.componentMap);
    }
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
}

export const initialState: LibraryNavState = {
  treeItems: [],
};

export function useLibraryNavState() {
  const [treeState, dispatch] = useReducer(reducer, initialState);

  return { treeState, dispatch };
}

type TreeContextType = {
  treeState: LibraryNavState;
  dispatch: React.Dispatch<Action>;
  fetchRootTreeItems: () => () => void;
  fetchGroupChildren: (groupName: string, groupId: string) => Promise<IFTreeItem[]>;
  fetchBlockChildren: (groupName: string, groupId: string, blockName: string, blockId: string) => Promise<void>;
  treeRef: React.RefObject<TreeApi<IFTreeItem>> | null;
  onTreeSelectCallback: (nodes: NodeApi<IFTreeItem>[]) => void;
  rootLoading: React.StatePair<boolean>;
  selectedNodeIdSet: Set<string>;
  goToGroupPage: (groupName: string) => Promise<void>;
  goToGroupBlockPage: (groupName: string, blockName: string) => Promise<void>;
};

export const LibraryNavContext = createContext<TreeContextType>({
  treeState: { treeItems: [] },
  dispatch: () => {},
  fetchRootTreeItems: () => () => {},
  fetchGroupChildren: async () => [],
  fetchBlockChildren: async () => {},
  treeRef: null,
  onTreeSelectCallback: () => {},
  rootLoading: [false, () => {}],
  selectedNodeIdSet: new Set<string>(),
  goToGroupPage: async () => {},
  goToGroupBlockPage: async () => {},
});

export const LibraryNavContextProvider = (props: {
  children: React.ReactNode;
  treeState: LibraryNavState;
  dispatch: React.Dispatch<Action>;
  selectionState: SelectionState;
  selectComponents: (componentIds: string[]) => void;
  handleSingleSelect: (componentId: string) => void;
  allComponentsCache: React.MutableRefObject<IComponent[]>;
  selectedFolder: IComponentFolder | null;
  goToGroupPage: (groupName: string) => Promise<void>;
  goToGroupBlockPage: (groupName: string, blockName: string) => Promise<void>;
}) => {
  const { treeState, dispatch } = props;
  const [rootLoading, setRootLoading] = useState(false);

  const treeRef = useRef<TreeApi<IFTreeItem> | null>(null);

  const groupNameToIdMap = useRef(new Map<string, string>());
  const groupIdCacheValid = useRef(new Map<string, boolean>());
  const groupBlockNameToIdMap = useRef(new Map<string, string>());
  const blockIdCacheValid = useRef(new Map<string, boolean>());

  function fetchRootTreeItems() {
    setRootLoading(true);
    const [request, cancel] = getComponentLibraryRoot({ folderId: props.selectedFolder?._id });
    request
      .then(({ data }) => {
        if (data) {
          data.children.forEach((group) => {
            groupNameToIdMap.current.set(group.name, group.id);
          });

          setRootLoading(false);

          dispatch({ type: LIB_NAV_ACTIONS.setItems, items: data.children });
        }
      })
      .catch((err: AxiosError) => {
        if (err instanceof CanceledError) {
          console.warn("Canceling fetchRootTreeItems");
        } else throw err;
      });

    return cancel;
  }

  async function fetchGroupChildren(groupName: string, groupId: string) {
    dispatch({
      type: LIB_NAV_ACTIONS.addGroupChildren,
      position: "back",
      id: groupId,
      children: [getLoadingSpinnerTreeItem()],
      filterOutToBeLoaded: true,
    });

    const [request, cancel] = getComponentGroup({ folderId: props.selectedFolder?._id, groupName });
    const res = await request;
    if (res.data) {
      const { children } = res.data;
      children.forEach((item) => {
        if (item.type === "block") {
          groupBlockNameToIdMap.current.set(`${groupName}/${item.name}`, item.id);
        }
      });

      // when we fetch the group, the backend returns "empty" blocks that have no children
      // if any of the groups blocks have *already* been fetched, we don't want to replace them with empty blocks
      const existingGroup = treeState.treeItems.find((item) => item.id === groupId && item.type === "group") as
        | IGroupTreeItem
        | undefined;
      const existingGroupChildren = existingGroup?.children || [];

      // create a map of the existing block children
      const existingBlockChildrenMap = new Map<string, IBlockTreeItem>();
      existingGroupChildren.forEach((item) => {
        if (item.type === "block") {
          existingBlockChildrenMap.set(item.id, item);
        }
      });

      // we want to return all the new children, *unless* they are already in the existing children
      const newChildren = children.map((item) => {
        if (item.type === "block") {
          if (existingBlockChildrenMap.has(item.id)) {
            return existingBlockChildrenMap.get(item.id) as IBlockTreeItem;
          } else {
            return item;
          }
        } else {
          return item;
        }
      });

      groupIdCacheValid.current.set(groupId, true);

      dispatch({
        type: LIB_NAV_ACTIONS.setGroupChildren,
        id: groupId,
        children: newChildren,
      });

      return newChildren;
    } else {
      return [];
    }
  }

  async function fetchBlockChildren(groupName: string, groupId: string, blockName: string, blockId: string) {
    dispatch({
      type: LIB_NAV_ACTIONS.addBlockChildren,
      position: "back",
      blockId,
      groupId,
      children: [getLoadingSpinnerTreeItem()],
      filterOutToBeLoaded: true,
    });

    const [request, cancel] = getComponentBlock({ folderId: props.selectedFolder?._id, groupName, blockName });
    const res = await request;
    if (res.data) {
      const { children } = res.data;

      blockIdCacheValid.current.set(blockId, true);

      dispatch({
        type: LIB_NAV_ACTIONS.setBlockChildren,
        blockId,
        groupId,
        children,
      });
    }
  }

  async function fetchComponentAncestors(component: {
    _id: string;
    name: string;
    groupName: string;
    blockName: string;
    componentName: string;
  }) {
    const tree = treeRef.current;
    if (!tree) return;

    if (component.groupName?.length > 0) {
      const groupId = getDeterministicUniqueId(component.groupName);
      if (!groupIdCacheValid.current.get(groupId)) {
        await fetchGroupChildren(component.groupName, groupId);
      }

      if (component.blockName?.length > 0) {
        const blockId = getDeterministicUniqueId(`${component.groupName}/${component.blockName}`);
        if (!blockIdCacheValid.current.get(blockId)) {
          await fetchBlockChildren(component.groupName, groupId, component.blockName, blockId);
        }
      }
    }

    dispatch({ type: LIB_NAV_ACTIONS.setSelectedNodeIds, nodeIds: [component._id] });
  }

  async function fetchMultiComponentAncestors(componentIds: string[]) {
    const response = await http.get(`/ws_comp/namesByIds?componentIds[]=${componentIds.join("&componentIds[]=")}`);
    const components = response.data;

    // we want to run these in series, so that we don't re-fetch shared ancestors
    await Promise.all(components.map((c) => fetchComponentAncestors(c)));
  }

  async function openAllGroupChildren(groupName: string) {
    const groupId = getDeterministicUniqueId(groupName);
    let groupChildren: IFTreeItem[] = [];
    groupChildren = await fetchGroupChildren(groupName, groupId);
    treeRef.current?.open(groupId);

    const group = treeState.treeItems.find((item) => item.id === groupId && item.type === "group") as IGroupTreeItem;
    if (!group) return;

    for (const child of groupChildren) {
      if (child.type === "block") {
        if (!blockIdCacheValid.current.get(child.id)) {
          await fetchBlockChildren(groupName, groupId, child.name, child.id);
        }
        treeRef.current?.open(child.id);
      }
    }
  }

  async function openAllGroupsAndBlocks() {
    await Promise.all(
      treeState.treeItems.map(async (item) => {
        if (item.type === "group") {
          await openAllGroupChildren(item.name);
        }
      })
    );
  }

  function onTreeSelectCallback(nodes: NodeApi<IFTreeItem>[]) {
    const nodeIds = nodes.filter((node) => node.data.type === "component").map((node) => node.data.id);
    if (nodeIds.length === 0) return;

    if (nodeIds.length === 1) props.handleSingleSelect(nodeIds[0]);
    else props.selectComponents(nodeIds);
  }

  function getSelectedIds() {
    if (!props.selectionState) return [];

    if (props.selectionState.type === "single") {
      return [props.selectionState.selectedId];
    } else if (props.selectionState.type === "multi") {
      return props.selectionState.selectedIds;
    } else {
      return [];
    }
  }

  const selectedComponentIds = useMemo(getSelectedIds, [props.selectionState]);

  useEffect(
    function synchronizeTreeSelectionState() {
      async function synchronizeSelectionState(componentIds: string[]) {
        await fetchMultiComponentAncestors(componentIds);

        setImmediate(() => {
          for (const id of componentIds) {
            treeRef.current?.openParents(id);
          }
          treeRef.current?.scrollTo(componentIds[0]);
        });
      }

      if (selectedComponentIds?.length) {
        synchronizeSelectionState(selectedComponentIds);
      }
    },
    [selectedComponentIds, treeRef.current]
  );
  const selectedNodeIdSet = useMemo(() => new Set(selectedComponentIds), [selectedComponentIds]);

  const selectedFolderId = props.selectedFolder?._id;
  useEffect(
    function fetchTreeItemsOnMount() {
      // clear caches
      groupNameToIdMap.current.clear();
      groupIdCacheValid.current.clear();
      groupBlockNameToIdMap.current.clear();
      blockIdCacheValid.current.clear();

      // reset tree state before reloading tree items
      treeRef.current?.deselectAll();
      treeRef.current?.closeAll();

      // fetchRootTreeItems should return a function to cancel the request
      return fetchRootTreeItems();
    },
    [selectedFolderId]
  );

  // CMD + B will toggle open all groups and blocks
  useEffect(
    function registerKeyDownHandler() {
      function handleKeyDown(e: KeyboardEvent) {
        if (e.key === "b" && e.metaKey) {
          e.preventDefault();

          // check if any top-level groups are open
          const groups = treeState.treeItems.filter((item) => item.type === "group");
          if (groups.length === 0) return;

          const anyOpen = groups.some((group) => treeRef.current?.isOpen(group.id));
          if (anyOpen) {
            treeRef.current?.closeAll();
          } else {
            openAllGroupsAndBlocks();
          }
        }
      }

      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    },
    [selectedFolderId, treeState.treeItems]
  );

  return (
    <LibraryNavContext.Provider
      value={{
        treeState,
        dispatch,
        fetchRootTreeItems,
        fetchGroupChildren,
        fetchBlockChildren,
        treeRef,
        onTreeSelectCallback,
        rootLoading: [rootLoading, setRootLoading],
        selectedNodeIdSet,
        goToGroupPage: props.goToGroupPage,
        goToGroupBlockPage: props.goToGroupBlockPage,
      }}
    >
      {props.children}
    </LibraryNavContext.Provider>
  );
};
