import { LibraryDetailsEditPanelTabAtom } from "@/stores/Library";
import TabGroup from "@ds/molecules/TabGroup";
import TitleRow from "@ds/organisms/DetailsPanelTitleRow";
import { useAtom } from "jotai";
import { Suspense, useMemo } from "react";
import LibraryEditActivityPanel from "../LibraryEditActivityPanel";
import LibraryEditCommentsPanel from "../LibraryEditCommentsPanel";
import LibraryEditEditPanel from "../LibraryEditEditPanel";
import LibraryEditVariantsPanel from "../LibraryEditVariantsPanel";
import style from "./style.module.css";

const tabs = [
  {
    id: "EDIT",
    label: "Edit",
    Content: LibraryEditEditPanel,
  },
  {
    id: "ACTIVITY",
    label: "Activity",
    Content: LibraryEditActivityPanel,
  },
  {
    id: "COMMENTS",
    label: "Comments",
    Content: LibraryEditCommentsPanel,
  },
  {
    id: "VARIANTS",
    label: "Variants",
    Content: LibraryEditVariantsPanel,
  },
];

function LibraryEditDetailsPanel() {
  const [selectedTab, setSelectedTab] = useAtom(LibraryDetailsEditPanelTabAtom);

  const selectedTabValue = useMemo(() => {
    let matchingTab = tabs.find((tab) => tab.id === selectedTab);
    if (matchingTab) {
      return matchingTab.id;
    }
    return "ACTIVITY";
  }, [selectedTab]);

  return (
    <>
      <TitleRow title={"Selected Component"} closeAction={() => {}} />
      <Suspense fallback={<></>}>
        <TabGroup
          value={selectedTabValue}
          onChange={setSelectedTab}
          tabs={tabs}
          className={style.tabRoot}
          triggerClassName={style.tabTrigger}
          size="small"
          noActiveTabLine
        />
      </Suspense>
    </>
  );
}
export default LibraryEditDetailsPanel;
