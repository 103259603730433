import { routes } from "@/defs";
import {
  libraryCurrentFolderNameAtom,
  libraryCurrentFolderParentIdAtom,
  libraryNavItemsAtom,
  libraryNavItemsVirtualizerAtom,
} from "@/stores/Library";
import Text from "@ds/atoms/Text";
import VirtualizedList from "@ds/atoms/VirtualizedList";
import NavItem from "@ds/molecules/NavItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import logger from "@shared/utils/logger";
import { useAtomValue } from "jotai";
import { Suspense, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import LibraryComponentFolderNavItem from "../LibraryComponentFolderNavItem";
import LibraryComponentNavItem from "../LibraryComponentNavItem";
import LibraryComponentsNavHeader from "../LibraryComponentsNavHeader";
import style from "./style.module.css";

function LibraryLeftSidebar() {
  return (
    <div className={style.libraryLeftSidebarContainer}>
      <Suspense fallback={<LeftNavSidebarFallback />}>
        <LibraryComponentsNavHeader />
        <FolderBackButton />
        <LibraryLeftSidebarItems />
      </Suspense>
    </div>
  );
}

function LeftNavSidebarFallback() {
  return (
    <div className={style.libraryNavFallback}>
      <Skeleton count={25} className={style.libraryNavFallbackSkeleton} height={28} />
    </div>
  );
}

function LibraryLeftSidebarItems() {
  const libraryNavItems = useAtomValue(libraryNavItemsAtom);

  if (libraryNavItems.length === 0) {
    return (
      <div className={style.noItemsContainer}>
        <Text color="tertiary" size="small">
          Components and folders in your library will appear here
        </Text>
      </div>
    );
  }

  return (
    <VirtualizedList
      id={"LIBRARY_NAV"}
      items={libraryNavItems}
      virtualizerAtom={libraryNavItemsVirtualizerAtom}
      virtualizeOptions={{
        estimateSize: () => 28,
        overscan: 50,
      }}
    >
      {(item, options, atom) => {
        switch (item.type) {
          case "component":
            return (
              <Suspense fallback={<NavItem.Fallback />}>
                <LibraryComponentNavItem item={item} options={options} atom={atom} />
              </Suspense>
            );
          case "componentFolder":
            return (
              <Suspense fallback={<NavItem.Fallback />}>
                <LibraryComponentFolderNavItem item={item} options={options} atom={atom} />
              </Suspense>
            );
          default:
            logger.error("Unknown library item type", { context: { item } }, new Error("Unknown library item type"));
            return null;
        }
      }}
    </VirtualizedList>
  );
}

function FolderBackButton() {
  const history = useHistory();
  const currentFolderParentId = useAtomValue(libraryCurrentFolderParentIdAtom);
  const currentFolderName = useAtomValue(libraryCurrentFolderNameAtom);

  const handleFolderBack = useCallback(
    function _handleFolderBack() {
      history.push(routes.nonNavRoutes.library.getPath(currentFolderParentId));
    },
    [currentFolderParentId, history]
  );

  if (!currentFolderName) return null;

  return (
    <div className={style.libraryFolderNavBar} onClick={handleFolderBack}>
      <ChevronLeftIcon className={style.libraryFolderNavBarIcon} />
      <Text size="small" truncate>
        {currentFolderName}
      </Text>
    </div>
  );
}

export default LibraryLeftSidebar;
