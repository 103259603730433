import useSegment from "@/hooks/useSegment";
import { designPreviewToggledAtom } from "@/stores/Location";
import { projectSidebarCollapsedAtom } from "@/stores/Project";
import { onTextItemKeyDownActionAtom } from "@/stores/ProjectSelection";
import Button from "@ds/atoms/Button";
import TabGroup from "@ds/molecules/TabGroup";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { NS_BETA_LIBRARY_INTEREST } from "@shared/segment-event-names";
import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import LibraryPanel from "./LibraryPanel";
import style from "./style.module.css";
import TextPanel from "./TextPanel";

const sidebarTabs = [
  { id: "text", label: "Text", Content: TextPanel },
  { id: "library", label: "Library", Content: LibraryPanel },
];

function LeftSidebar() {
  const onTextItemKeyDownAction = useSetAtom(onTextItemKeyDownActionAtom);
  const [sidebarCollapseState, setSidebarCollapseState] = useAtom(projectSidebarCollapsedAtom);
  const designPreviewToggled = useAtomValue(designPreviewToggledAtom);
  const { track } = useSegment();

  const onTabSelect = useCallback(
    (value: string) => {
      if (value === "library") {
        track({
          event: NS_BETA_LIBRARY_INTEREST,
          properties: {
            action: "view-tab",
            location: "project-nav-panel",
          },
        });
      }
    },
    [track]
  );

  // TODO: This sidebar should be resizeable by the user, and store that resize in localstorage. DIT-8138
  return (
    <div
      className={classNames(style.leftSidebarContainer, {
        [style.relPos]: !designPreviewToggled,
        [style.absPos]: designPreviewToggled,
      })}
      data-state={sidebarCollapseState}
      tabIndex={0}
      onKeyDown={onTextItemKeyDownAction}
    >
      <div className={style.tabs}>
        <TabGroup tabs={sidebarTabs} onChange={onTabSelect} size="small" contentClassName={style.tabContent} />

        <Button
          type="icon"
          level="subtle"
          className={style.sidebarCollapseBtn}
          onClick={() => setSidebarCollapseState("closed")}
        >
          <KeyboardDoubleArrowLeft />
        </Button>
      </div>
    </div>
  );
}

export default LeftSidebar;
