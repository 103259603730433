import { ITipTapRichText } from "../types/TextItem";

const generateBaseRichTextObject = (text: string): ITipTapRichText => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text,
        },
      ],
    },
  ],
});

export { generateBaseRichTextObject };
