import { userAtom } from "@/stores/Auth";
import { userTokenAtom } from "@/stores/User";
import { IRole, IUserWithPermissionsFigmaAuthenticated } from "@shared/types/User";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { AuthState, PREFERRED_LOGIN_PAGE_KEY } from ".";
import http from "../../http";

const TRUSTED_AUTH_JWT_KEY = "local-auth-jwt";

const useTrustedAuth = (): AuthState => {
  const setJotaiUser = useSetAtom(userAtom);
  const setUserToken = useSetAtom(userTokenAtom);
  const [user, _setUser] = useState<(IUserWithPermissionsFigmaAuthenticated & { billingRole: IRole }) | null>(null);
  const setUser = (user: Parameters<typeof _setUser>[0]) => {
    _setUser(user);
    setJotaiUser(user);
  };
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(function initWrapper() {
    const init = async () => {
      const token = localStorage.getItem(TRUSTED_AUTH_JWT_KEY);
      setUserToken(token);
      if (!token) {
        setUser(null);

        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.BACKEND_URL}/user/getInfo`, {
          credentials: "include",
          headers: { "x-ditto-app": "web_app", Authorization: `Bearer ${token}` },
        });

        if (response.status === 401) {
          logout();
          throw new Error("Current user unauthorized");
        }

        const user = await response.json();

        const userInfo = {
          picture: "https://i.pravatar.cc/300",
          ["https://saydit.to/user_metadata"]: {
            hasFigmaPluginLogin: false,
          },
          ...user,
        };

        setUser(userInfo);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    init();
  }, []);

  const getTokenSilently = async () => Promise.resolve(localStorage.getItem(TRUSTED_AUTH_JWT_KEY));

  const refreshUser = async () => {
    const token = await getTokenSilently();

    try {
      const r = await http.get(`${process.env.BACKEND_URL}/user/getInfo`);
      const userInfo = r.data;
      setUser({ ...user, ...userInfo });
    } catch (e) {
      console.error("refreshUser failed in Trusted Auth: ", e);
    }
  };

  const loginWithRedirect = async ({ email, plan, isTest, devTools, enterpriseKey }) => {
    const { token } = await fetch(
      `${process.env.BACKEND_URL}/trusted/sign-in?email=${email}&plan=${plan || "free"}${isTest ? "&isTest=true" : ""}${
        devTools ? "&devTools=true" : ""
      }`,
      {
        headers: { "x-ditto-app": "web_app" },
        credentials: "include",
      }
    ).then((response) => response.json());
    setUserToken(token);

    localStorage.setItem(TRUSTED_AUTH_JWT_KEY, token);
    if (!!enterpriseKey) localStorage.setItem(PREFERRED_LOGIN_PAGE_KEY, enterpriseKey);

    await refreshUser();

    setLoading(false);
  };

  const onDittoOverviewFinished = () => setUser((u) => (!!u ? { ...u, finishedDittoOverview: true } : null));

  const logout = () => {
    localStorage.removeItem(TRUSTED_AUTH_JWT_KEY);
    window?.analytics?.reset();
    window.location.reload();
    setUserToken(null);
  };

  if (loading) {
    return {
      user: null,
      loading,
      isAuthenticated: false,
      getTokenSilently,
      refreshUser,
      onDittoOverviewFinished,
      loginWithRedirect,
      logout,
    };
  } else if (!user) {
    return {
      user,
      loading,
      isAuthenticated: false,
      getTokenSilently,
      refreshUser,
      onDittoOverviewFinished,
      loginWithRedirect,
      logout,
    };
  } else {
    return {
      user,
      loading,
      isAuthenticated: true,
      getTokenSilently,
      refreshUser,
      onDittoOverviewFinished,
      loginWithRedirect,
      logout,
    };
  }
};

export default useTrustedAuth;
