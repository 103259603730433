import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import classnames from "classnames";
import React from "react";
import { IComponentFolder } from "../../../shared/types/ComponentFolder";
import { IPopulatedVariableFolder } from "../../../shared/types/VariableFolder";
import { IVariantFolder } from "../../../shared/types/VariantFolder";
import { useComponentFolderDroppable } from "../DragAndDrop/lib";
import SampleBadge from "../SampleBadge";
import style from "./style.module.css";

interface Props {
  folder: IComponentFolder | IPopulatedVariableFolder | IVariantFolder;
  onClick: () => void;
  content: string;
  count: number;
  responsiveSize?: "normal" | "large";
}

const CommonFolderCard = (props: Props) => {
  const { folder, onClick, content, count } = props;
  const { _id: folderId, name } = folder;
  const numberOfComponents = count;

  const { isOver, setNodeRef } = useComponentFolderDroppable(folderId, folder.isSample);

  return (
    <div className={props.responsiveSize === "large" ? style.largeResponsiveCol : style.responsiveCol}>
      <div
        data-testid="folder-card"
        data-folder-id={folderId}
        data-folder-name={name}
        className={classnames({
          [style.folderCard]: true,
          [style.regular]: true,
          [style.isOver]: isOver,
        })}
        onClick={onClick}
        ref={setNodeRef}
      >
        <div className={style.folderLabel}>
          <div className={style.folderIconWrapper}>
            <FolderOpenOutlinedIcon className={style.icon} />
          </div>
          <div className={style.folderName}>
            {folder.isSample && (
              <div className={style.sampleTitle}>
                <SampleBadge className={style.sampleBadge}>Sample</SampleBadge>
                <span className={style.sampleName}>{folder.name.replace("Sample ", "")}</span>
              </div>
            )}
            {!folder.isSample && <h3>{name}</h3>}
            <div className={style.numProjects}>{`${numberOfComponents} ${content}${
              numberOfComponents !== 1 ? "s" : ""
            }`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonFolderCard;
