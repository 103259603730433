import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import * as SegmentEvents from "@shared/segment-event-names";
import { Elements } from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { MAX_NUMBER_OF_FREE_PLAN_USERS } from "../../../util/constants";
import useSegment from "../../hooks/useSegment";
import http from "../../http";
import { BillingContext } from "../../store/billingContext";
import { loadStripe } from "../../utils/stripe";
import ButtonPrimary from "../button/buttonprimary";
import PaymentModal from "../paymentmodal/paymentmodal";
import style from "./style.module.css";

const PaywallModal = () => {
  const segment = useSegment();

  const { showBillingModal } = useContext(BillingContext);
  const { users: workspaceUsers, workspaceInfo } = useWorkspace();

  const trialEndDate = workspaceInfo.ws_trial
    ? new Date(new Date(workspaceInfo.ws_trial).getTime() + 60 * 60 * 24 * 14 * 1000)
    : null;
  const trialEndedDaysAgo = trialEndDate
    ? Math.round((Date.now() - trialEndDate.getTime()) / 1000 / 60 / 60 / 24)
    : null;
  const trialEndedUnit =
    trialEndedDaysAgo < 7 ? "day" : trialEndedDaysAgo < 30 ? "week" : trialEndedDaysAgo < 365 ? "month" : "year";
  const trialEndedCount =
    trialEndedUnit === "day"
      ? trialEndedDaysAgo
      : trialEndedUnit === "week"
      ? Math.floor(trialEndedDaysAgo / 7)
      : trialEndedUnit === "month"
      ? Math.floor(trialEndedDaysAgo / 30)
      : Math.floor(trialEndedDaysAgo / 365);
  const trialEndedText =
    trialEndedDaysAgo === 1 ? "yesterday" : `${trialEndedCount} ${trialEndedUnit}${trialEndedCount > 1 ? "s" : ""} ago`;

  const stripePromise = loadStripe();

  const { user } = useAuthenticatedAuth();
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [trialOpen, setTrialOpen] = useState(false);

  const fetchData = async () => {
    let reqBody = {
      num_users: workspaceUsers.length,
      workspace_name: workspaceInfo.name,
      domain_name: workspaceInfo.domain_name,
      customer_id: workspaceInfo.customer_id,
    };
    try {
      await http.post("/planlimit", reqBody);
    } catch (e) {
      console.log("in planlimit.jsx: ", e.message);
    }
  };

  const openIntercomChat = () => {
    window.Intercom("show");
  };

  useEffect(() => {
    analytics.page("Paywall modal");
    fetchData();
  }, []);

  const upgrade = async () => {
    segment.track({
      event: SegmentEvents.UPGRADE_PLAN_LINK_CLICKED,
      properties: {
        location: "paywall modal",
        trial_completed: workspaceInfo && workspaceInfo.ws_trial ? workspaceInfo.ws_trial : "false",
        application: "web_app",
        version: "legacy",
      },
    });
    showBillingModal();
  };

  const hidePayment = async () => {
    segment.track({
      event: "Payment Modal Closed",
      properties: {
        location: "paywall modal",
        trial_completed: workspaceInfo && workspaceInfo.ws_trial ? workspaceInfo.ws_trial : "false",
      },
    });
    setPaymentOpen(false);
  };

  const onHide = () => {
    // you cant hide this
  };

  const toggleTrialOpen = () => {
    if (!trialOpen) {
      segment.track({
        event: "Trial CTA Clicked",
        properties: {
          type: "2 weeks",
          location: "paywall modal",
        },
      });
    } else {
      segment.track({
        event: "Trial Info Back Button Clicked",
        properties: {
          type: "2 weeks",
          location: "paywall modal",
        },
      });
    }
    setTrialOpen(!trialOpen);
  };

  const startTrial = async () => {
    try {
      const { data: newWorkspaceInfo } = await http.post("/starttrial", {});
      if (newWorkspaceInfo.ws_trial) {
        location.reload();
      }
    } catch (e) {
      console.log("Error starting trial in paywallmodal.jsx: ", e);
    }
  };

  return (
    <div>
      {!paymentOpen && (
        <BootstrapModal
          show={true}
          className={style.modal}
          dialogClassName={style.dialog}
          backdropClassName={style.backdrop}
          onHide={onHide}
          centered
        >
          <BootstrapModal.Header className={style.header}>
            <BootstrapModal.Title className={style.title}>
              {trialOpen ? (
                <span className={style.title}>
                  <ChevronLeftIcon className={style.icon} onClick={() => toggleTrialOpen()} />
                  Team Plan Trial
                </span>
              ) : (
                <span>
                  {workspaceInfo && workspaceInfo.ws_trial ? (
                    <span>Thanks for trying out the Team Plan!</span>
                  ) : (
                    <span>You’ve hit the Starter Plan limit!</span>
                  )}
                </span>
              )}
            </BootstrapModal.Title>
          </BootstrapModal.Header>
          {trialOpen ? (
            <span className={style.trial}>
              <BootstrapModal.Body className={style.body}>
                <p>
                  During this <strong>2-week trial</strong>, your entire workspace (and any new members!) can use Ditto
                  with Team Plan features such as <strong>export</strong> and <strong>priority support</strong>.
                </p>
                <p>If you have any questions or feedback during the trial, please reach out!</p>
              </BootstrapModal.Body>
              <BootstrapModal.Footer className={style.footer}>
                <ButtonPrimary text={"Start free trial 🎉"} onClick={() => startTrial()} />
              </BootstrapModal.Footer>
            </span>
          ) : (
            <span>
              <BootstrapModal.Body className={style.body}>
                {workspaceInfo && !workspaceInfo.ws_trial && (
                  <span>
                    <p>
                      This plan is limited to {MAX_NUMBER_OF_FREE_PLAN_USERS} team members. Currently, the members of
                      your workspace are:{" "}
                    </p>
                    <ul>
                      {workspaceUsers.map((user, index) => (
                        <li key={index}>
                          <strong>{user.name}</strong> ({user.email})
                        </li>
                      ))}
                    </ul>
                  </span>
                )}
                {workspaceInfo && workspaceInfo.ws_trial && <p>Your free trial ended {trialEndedText}.</p>}
                {user?.role === "admin" ? (
                  <p>
                    In order to continue collaborating with Ditto, you'll need to{" "}
                    <strong>upgrade your workspace</strong> or <a href="/account/workspace">modify your workspace</a> to
                    meet Starter Plan requirements.
                  </p>
                ) : (
                  <p>
                    In order to continue collaborating with Ditto, upgrade the workspace or ask a workspace admin to
                    modify the workspace to meet Starter Plan requirements.
                  </p>
                )}
                {workspaceInfo && workspaceInfo.ws_trial && (
                  <p>
                    If you have any questions, please reach out via <a href="mailto:founders@dittowords.com">email</a>{" "}
                    or{" "}
                    <span className={style.bodyLink} onClick={() => openIntercomChat()}>
                      chat
                    </span>
                    !
                  </p>
                )}
              </BootstrapModal.Body>
              <BootstrapModal.Footer className={style.footer}>
                <ButtonPrimary text={"Upgrade Plan"} onClick={upgrade} />
                {workspaceInfo && !workspaceInfo.ws_trial && (
                  <p>
                    <a onClick={() => toggleTrialOpen()} href="#">
                      Try for free for 2 weeks
                    </a>
                  </p>
                )}
              </BootstrapModal.Footer>
            </span>
          )}
        </BootstrapModal>
      )}
      {paymentOpen && (
        <Elements stripe={stripePromise}>
          <PaymentModal onHide={hidePayment} users={workspaceUsers} name={user.name} email={user.email} />
        </Elements>
      )}
    </div>
  );
};

export default PaywallModal;
