import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;

  /**
   * Presets for badge style.
   */
  type?: "default" | "code" | "outline" | "action-outline" | "subtle";

  /**
   * Options for border radii. Defaults to sm.
   */
  borderRadius?: "xs" | "sm" | "md" | "lg";

  /**
   * Color of the badge. Defaults to black.
   */
  color?: "black" | "transparent" | "blue" | "blue-inverted" | "neutral" | "purple";

  /**
   * Size of the badge. Defaults to md.
   */
  size?: "micro" | "xs" | "sm" | "md" | "lg";
}

export function Badge(props: IProps) {
  const color = props.color ?? "black";
  const type = props.type ?? "default";
  const size = props.size ?? "md";
  const borderRadius = props.borderRadius ?? "sm";

  return (
    <div
      style={props.style}
      className={classNames(style.BadgeWrapper, props.className, {
        [style[`color-${color}`]]: true,
        [style[`border-radius-${borderRadius}`]]: borderRadius,
        [style[`type-${type}`]]: true,
        [style[`size-${size}`]]: size,
      })}
      data-testid="badge"
    >
      {props.children}
    </div>
  );
}

export default Badge;
