import VariableRichTextArea from "@/components/VariableTextArea/VariableRichTextArea";
import { useWorkspace } from "@/store/workspaceContext";
import { libraryComponentFoldersAtom } from "@/stores/Components";
import { createLibraryComponentActionAtom, libraryCreateComponentModalIsOpenAtom } from "@/stores/Library";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import { NO_ITEMS_VALUE } from "@ds/molecules/BaseCombobox";
import CompactInputLabel from "@ds/molecules/CompactInputLabel";
import LibraryComponentAutoNameInput from "@ds/molecules/LibraryComponentAutoNameInput";
import LibraryComponentFolderFilterDropdown, {
  ALL_COMPONENTS,
} from "@ds/molecules/LibraryComponentFolderFilterDropdown";
import Modal from "@ds/molecules/Modal";
import { TextItemStatic } from "@ds/molecules/TextItem";
import { DEFAULT_RICH_TEXT } from "@shared/common/constants";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { IObjectId } from "@shared/types/lib";
import { ITipTapRichText } from "@shared/types/TextItem";
import { IVariable } from "@shared/types/Variable";
import { atom, Atom, PrimitiveAtom, useAtom, useAtomValue, useSetAtom, WritableAtom } from "jotai";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import style from "./style.module.css";

function LibraryCreateComponentModal() {
  const workspace = useWorkspace();
  const [libraryCreateComponentModalIsOpen, setLibraryCreateComponentModalIsOpen] = useAtom(
    libraryCreateComponentModalIsOpenAtom
  );

  const createLibraryComponentAction = useSetAtom(createLibraryComponentActionAtom);

  const componentTextAtomRef = useRef(atom(""));
  const componentRichTextAtomRef = useRef(atom(DEFAULT_RICH_TEXT));
  const componentNameAtomRef = useRef(
    asyncMutableDerivedAtom({
      loadData: (get) => {
        const textValue = get(componentTextAtomRef.current);
        if (!textValue) return "";
        return textValue
          .split(" ")
          .slice(0, 3)
          .filter(Boolean)
          .map((str) => str[0].toUpperCase() + str.slice(1))
          .join(" ");
      },
    })
  );

  const regenerateComponentName = useSetAtom(componentNameAtomRef.current.refreshAtom);

  function resetModal() {
    componentTextAtomRef.current = atom("");
    componentRichTextAtomRef.current = atom(DEFAULT_RICH_TEXT);
    componentNameAtomRef.current = asyncMutableDerivedAtom({
      loadData: (get) => {
        const textValue = get(componentTextAtomRef.current);
        if (!textValue) return "";
        return textValue
          .split(" ")
          .slice(0, 3)
          .filter(Boolean)
          .map((str) => str[0].toUpperCase() + str.slice(1))
          .join(" ");
      },
    });
  }

  const handleOpenChange = useCallback(
    function handleOpenChange(open: boolean) {
      setLibraryCreateComponentModalIsOpen(open);
    },
    [setLibraryCreateComponentModalIsOpen]
  );

  const onCreate = useCallback(
    async function _handleCreate(props: {
      folderId: string | null;
      name: string;
      richText: ITipTapRichText;
      text: string;
    }) {
      createLibraryComponentAction({
        ...props,
        workspaceId: workspace.workspaceInfo._id,
      });
      resetModal();
      handleOpenChange(false);
    },
    [createLibraryComponentAction, handleOpenChange, workspace.workspaceInfo._id]
  );

  const onCancel = useCallback(
    function _closeModal() {
      handleOpenChange(false);
    },
    [handleOpenChange]
  );

  const onRegenerateAutoName = useCallback(() => {
    regenerateComponentName(null);
  }, [regenerateComponentName]);

  const createLibraryComponentProps = useCreateLibraryComponent({
    libraryCreateComponentModalIsOpen,
    onCancel,
    onCreate,
    onRegenerateAutoName,
    foldersAtom: libraryComponentFoldersAtom,
    componentNameAtom: componentNameAtomRef.current.valueAtom,
    componentTextAtom: componentTextAtomRef.current,
    componentRichTextAtom: componentRichTextAtomRef.current,
  });

  return (
    <Modal
      headline="Create text component"
      description="Some helper copy here assuming you’re doing this for the first time and we need to orient you."
      open={libraryCreateComponentModalIsOpen}
      onOpenChange={handleOpenChange}
      modal={false}
    >
      <div className={style.contentContainer}>
        <Text color="secondary" inline>
          Some helper copy here assuming you’re doing this for the first time and we need to orient you.
        </Text>
        <CompactInputLabel
          label="Text"
          input={<VariableRichTextArea {...createLibraryComponentProps.componentText} />}
        />
        <CompactInputLabel
          label="Publish to..."
          input={<LibraryComponentFolderFilterDropdown {...createLibraryComponentProps.folderDropdown} />}
        />
        <CompactInputLabel
          label="Component name"
          helper="You can change component names at any time"
          input={<LibraryComponentAutoNameInput {...createLibraryComponentProps.autoNameInput} />}
        />
        {createLibraryComponentProps.textItem.defaultText && (
          <CompactInputLabel
            label="Component preview"
            input={<TextItemStatic {...createLibraryComponentProps.textItem} className={style.componentPreview} />}
          />
        )}
        <div className={style.footer}>
          <Button level="outline" onClick={createLibraryComponentProps.onCancel}>
            Cancel
          </Button>
          <Button
            onClick={createLibraryComponentProps.onCreate}
            disabled={!createLibraryComponentProps.values.text || !createLibraryComponentProps.values.componentName}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const textLabelLeft = <div></div>;

export function useCreateLibraryComponent(props: {
  foldersAtom: Atom<{ _id: string; name: string }[] | Promise<{ _id: string; name: string }[]>>;
  componentTextAtom: PrimitiveAtom<string>;
  componentRichTextAtom: PrimitiveAtom<ITipTapRichText>;
  componentNameAtom: WritableAtom<string | Promise<string>, [string | Promise<string>], void>;
  libraryCreateComponentModalIsOpen: boolean;
  onRegenerateAutoName: () => void;
  onCancel: () => void;
  onCreate: (props: {
    folderId: string | null;
    name: string;
    richText: ITipTapRichText;
    text: string;
  }) => Promise<void>;
}) {
  const folders = useAtomValue(props.foldersAtom);
  const params = useParams<{ folderId?: IObjectId }>();
  const [richText, setRichText] = useAtom(props.componentRichTextAtom);
  const [text, setText] = useAtom(props.componentTextAtom);
  const componentName = useAtomValue(props.componentNameAtom);
  const [selectedFolder, setSelectedFolder] = useState<{ _id: string; name: string } | null>(
    params.folderId ? folders.find((f) => f._id === params.folderId) || null : null
  );

  // Update the currently selected folder when the modal opens
  useEffect(
    function handleModalOpen() {
      if (props.libraryCreateComponentModalIsOpen) {
        setSelectedFolder(params.folderId ? folders.find((f) => f._id === params.folderId) || null : null);
      }
    },
    [folders, params.folderId, props.libraryCreateComponentModalIsOpen]
  );

  const onFolderChange = useCallback(
    (folder: { _id: string; name: string } | null) => {
      if (!folder || folder?._id === NO_ITEMS_VALUE) setSelectedFolder(null);
      else setSelectedFolder(folder);
    },
    [setSelectedFolder]
  );
  const onEnablePlurals = useCallback(() => {}, []);
  const handleTextChange = useCallback(
    (
      newInputValue: {
        text: string;
        variables: IVariable[];
      },
      richText: ITipTapRichText | undefined
    ) => {
      if (richText) {
        setRichText(richText);
        setText(newInputValue.text);
      }
    },
    [setRichText, setText]
  );

  const onCreate = useCallback(
    async function _onCreate() {
      props.onCreate({
        folderId: selectedFolder && selectedFolder._id !== ALL_COMPONENTS.value ? selectedFolder?._id : null,
        name: componentName,
        richText,
        text: text,
      });
    },
    [componentName, props, richText, selectedFolder, text]
  );

  return {
    folderDropdown: {
      selectedFolder,
      foldersAtom: props.foldersAtom,
      onChange: onFolderChange,
    },
    autoNameInput: {
      valueAtom: props.componentNameAtom,
      onRegenerateAutoName: props.onRegenerateAutoName,
    },
    componentText: {
      className: style.richTextContainer,
      value: {},
      hideTopLabels: true,
      useNSLabels: true,
      onEnablePlurals,
      handleTextChange,
      isBaseText: true,
      isVariant: false,
      readonly: false,
      shouldShowRichText: true,
      characterLimitDisabled: false,
      disabled: false,
      hideLabels: true,
      textLabelLeft: textLabelLeft,
      pluralInputsDisabled: true,
    },
    textItem: {
      defaultValue: richText,
      defaultText: text,
      component: {
        name: props.componentNameAtom,
      },
    },
    values: {
      richText,
      text,
      componentName,
      selectedFolder,
    },
    onCancel: props.onCancel,
    onCreate,
  };
}

export default LibraryCreateComponentModal;
