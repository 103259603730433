import classNames from "classnames";
import React, { forwardRef } from "react";
import { useNativeProps } from "../../useNativeProps";
import Text, { TextColor } from "../Text";
import style from "./index.module.css";

export type MenuOption = {
  type: "option";
  text: string;
  textColor?: TextColor;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

type DropdownMenuItemProps = Omit<MenuOption, "type">;

const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenuItemProps>(function DropdownMenuItem(
  props: DropdownMenuItemProps,
  ref
) {
  const nativeProps = useNativeProps<HTMLDivElement, DropdownMenuItemProps>(props, {
    disabled: true,
    textColor: true,
    text: true,
  });

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  }

  return (
    <div
      {...nativeProps}
      className={classNames(
        style.DropdownMenuItemWrapper,
        {
          [style.clickable]: !!props.onClick,
          [style.disabled]: props.disabled,
        },
        props.className
      )}
      data-testid="dropdown-menu-item"
      onClick={handleClick}
      ref={ref}
    >
      <Text color={props.textColor} className={style.itemText}>
        {props.text}
      </Text>
    </div>
  );
});

export default DropdownMenuItem;
