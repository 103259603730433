import { z } from "zod";
import { ZFFigmaTextNode, ZFigmaTextNode } from "./FigmaTextNode";
import { ZObjectId } from "./lib";
import { ZActualComponentVariableSchema, ZTextItem, ZTipTapRichText } from "./TextItem";

// MARK: - Concerns

const ZDittoTextMismatchConcern = z.object({
  type: z.literal("dittoTextMismatch"),
  textItem: ZTextItem,
  figmaTextNode: ZFigmaTextNode,
  dittoRichText: ZTipTapRichText,
  figmaRichText: ZTipTapRichText,
  isFigmaEdit: z.boolean(),
});

export type IDittoTextMismatchConcern = z.infer<typeof ZDittoTextMismatchConcern>;

const ZTextOverrideConcern = z.object({
  type: z.literal("textOverride"),
  textItem: ZTextItem,
  figmaNodeId: z.string(),
  overrideRichText: ZTipTapRichText,
});

export type ITextOverrideConcern = z.infer<typeof ZTextOverrideConcern>;

const ZTextMismatchConcern = z.object({
  type: z.literal("textMismatch"),
  figmaTextNode: ZFigmaTextNode,
  textItem: ZTextItem,
  dittoRichText: ZTipTapRichText,
  figmaRichText: ZTipTapRichText,
  isConflict: z.boolean(),
  isFigmaEdit: z.boolean(),
});

export type ITextMismatchConcern = z.infer<typeof ZTextMismatchConcern>;

const ZUnlinkedFigmaNodeWithTextItemIdConcern = z.object({
  type: z.literal("unlinkedFigmaNodeWithTextItemId"),
  figmaTextNode: ZFigmaTextNode,
  textItem: ZTextItem,
  dittoRichText: ZTipTapRichText,
  figmaRichText: ZTipTapRichText,
  isConflict: z.boolean(),
});

export type IUnlinkedFigmaNodeWithTextItemIdConcern = z.infer<typeof ZUnlinkedFigmaNodeWithTextItemIdConcern>;

const ZFigmaNodeWithNonExistentTextItemIdConcern = z.object({
  type: z.literal("figmaNodeWithNonExistentTextItemId"),
  figmaNodeId: z.string(),
  textItemId: z.string(),
});

export type IFigmaNodeWithNonExistentTextItemIdConcern = z.infer<typeof ZFigmaNodeWithNonExistentTextItemIdConcern>;

const ZFigmaTextNodeDeletionConcern = z.object({
  type: z.literal("figmaTextNodeDeletion"),
  figmaNodeId: z.string(),
  textItemId: z.string(),
});

export type IFigmaTextNodeDeletionConcern = z.infer<typeof ZFigmaTextNodeDeletionConcern>;

const ZFigmaTextNodeTextLayerNameMismatchConcern = z.object({
  type: z.literal("figmaTextNodeTextLayerNameMismatch"),
  figmaNodeId: z.string(),
  textItemId: z.string(),
  expectedName: z.string(),
});

export type IFigmaTextNodeTextLayerNameMismatchConcern = z.infer<typeof ZFigmaTextNodeTextLayerNameMismatchConcern>;

const ZTextItemInstanceFigmaPageMismatchConcern = z.object({
  type: z.literal("textItemInstanceFigmaPageMismatch"),
  textItemId: ZObjectId,
  figmaNodeId: z.string(),
  figmaPageId: z.string(),
});

export type ITextItemInstanceFigmaPageMismatchConcern = z.infer<typeof ZTextItemInstanceFigmaPageMismatchConcern>;

export const ZConcern = z.discriminatedUnion("type", [
  ZTextMismatchConcern,
  ZDittoTextMismatchConcern,
  ZTextOverrideConcern,
  ZFigmaNodeWithNonExistentTextItemIdConcern,
  ZUnlinkedFigmaNodeWithTextItemIdConcern,
  ZFigmaTextNodeDeletionConcern,
  ZFigmaTextNodeTextLayerNameMismatchConcern,
  ZTextItemInstanceFigmaPageMismatchConcern,
]);
export type IConcern = z.infer<typeof ZConcern>;

// MARK: - Actions

const ZResolveTextConflictAction = z.object({
  type: z.literal("resolveTextConflict"),
  textItem: z.object({
    _id: z.string(),
    text: z.string(),
    variables: z.array(ZActualComponentVariableSchema),
    richText: ZTipTapRichText,
  }),
  figmaNodes: z.array(
    z.object({
      ids: z.array(z.string()),
      richText: ZTipTapRichText,
    })
  ),
});
export type IResolveTextConflictAction = z.infer<typeof ZResolveTextConflictAction>;

const ZLinkFigmaTextNodeAction = z.object({
  type: z.literal("linkFigmaTextNode"),
  textItemId: z.string(),
  figmaNode: ZFFigmaTextNode.pick({
    _id: true,
    workspaceId: true,
    nodeId: true,
    pageId: true,
    richText: true,
    position: true,
  }),
});
export type ILinkFigmaTextNodeAction = z.infer<typeof ZLinkFigmaTextNodeAction>;

const ZUnlinkFigmaTextNodeAction = z.object({
  type: z.literal("unlinkFigmaTextNode"),
  textItemId: z.string(),
  figmaNodeId: z.string(),
});

export type IUnlinkFigmaTextNodeAction = z.infer<typeof ZUnlinkFigmaTextNodeAction>;

const ZUpdateFigmaNodeRichTextChange = z.object({
  type: z.literal("richText"),
  before: ZTipTapRichText.optional(),
  after: ZTipTapRichText,
});

const ZUpdateFigmaNodeChange = z.discriminatedUnion("type", [ZUpdateFigmaNodeRichTextChange]);

export type IUpdateFigmaNodeChange = z.infer<typeof ZUpdateFigmaNodeChange>;

const ZUpdateFigmaNodeAction = z.object({
  type: z.literal("updateFigmaNode"),
  nodeId: z.string(),
  textItemId: z.string(),
  changes: z.array(ZUpdateFigmaNodeChange),
});

export type IUpdateFigmaNodeAction = z.infer<typeof ZUpdateFigmaNodeAction>;

const ZUpdateTextItemRichTextChange = z.object({
  type: z.literal("richText"),
  before: ZTipTapRichText,
  after: ZTipTapRichText,
});

const ZUpdateTextItemFigmaInstancePageChange = z.object({
  type: z.literal("figmaPageId"),
  figmaNodeId: z.string(),
  figmaPageId: z.string(),
});

const ZUpdateTextITemChange = z.discriminatedUnion("type", [
  ZUpdateTextItemRichTextChange,
  ZUpdateTextItemFigmaInstancePageChange,
]);

const ZUpdateDittoTextItemAction = z.object({
  type: z.literal("updateDittoTextItem"),
  textItemId: ZObjectId,
  changes: z.array(ZUpdateTextITemChange),
});

export type IUpdateDittoTextItemAction = z.infer<typeof ZUpdateDittoTextItemAction>;

const ZRenameFigmaNodeLayerAction = z.object({
  type: z.literal("renameFigmaNodeLayer"),
  textItemId: z.string(),
  figmaNodeId: z.string(),
  updatedName: z.string(),
});

export type IRenameFigmaNodeLayerAction = z.infer<typeof ZRenameFigmaNodeLayerAction>;

export const ZAction = z.discriminatedUnion("type", [
  ZUpdateFigmaNodeAction,
  ZUpdateDittoTextItemAction,
  ZLinkFigmaTextNodeAction,
  ZUnlinkFigmaTextNodeAction,
  ZResolveTextConflictAction,
  ZRenameFigmaNodeLayerAction,
]);
export type IAction = z.infer<typeof ZAction>;

// MARK: - Sync results after applying the changes in the figma plugin.

export const ZUpdateTextNodeInstanceLastReconciledRichText = z.object({
  type: z.literal("updateTextNodeInstanceLastReconciledRichText"),
  fileId: z.string(),
  branchId: z.string().nullable(),
  textItemId: z.string(),
  figmaNodeId: z.string(),
  lastReconciledRichText: ZTipTapRichText,
});

export type IUpdateTextNodeInstanceLastReconciledRichText = z.infer<
  typeof ZUpdateTextNodeInstanceLastReconciledRichText
>;

export const ZUpdateFigmaCacheTextNodeName = z.object({
  type: z.literal("updateFigmaTextNodeCacheStatus"),
  fileId: z.string(),
  branchId: z.string().nullable(),
  figmaNodeId: z.string(),
  name: z.string(),
});

export const ZFigmaSyncResponseType = z.discriminatedUnion("type", [
  ZUpdateTextNodeInstanceLastReconciledRichText,
  ZUpdateFigmaCacheTextNodeName,
]);

export type IFigmaSyncResponseType = z.infer<typeof ZFigmaSyncResponseType>;
