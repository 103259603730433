import { subscribeToBetaLibraryUpdates } from "@/http/user";
import { IRole, IUserWithPermissionsFigmaAuthenticated } from "@shared/types/User";
import logger from "@shared/utils/logger";
import { atom } from "jotai";
import { showToastActionAtom } from "./Toast";

export const userAtom = atom<(IUserWithPermissionsFigmaAuthenticated & { billingRole: IRole }) | null>(null);

export const isSubscribedToBetaLibraryUpdatesAtom = atom((get) => {
  const user = get(userAtom);
  return !!user?.isSubscribedToBetaLibraryUpdates;
});

export const userEmailAtom = atom((get) => {
  const user = get(userAtom);
  return user?.email;
});

export const userRoleAtom = atom((get) => {
  const user = get(userAtom);
  return user?.role;
});

/**
 * Subscribes the user to beta library updates.
 * Shows a toast message if the subscription fails.
 */
export const subscribeToBetaLibraryUpdatesActionAtom = atom(null, async (_get, set) => {
  // Optimistically update the UI
  set(userAtom, (prev) => (prev ? { ...prev, isSubscribedToBetaLibraryUpdates: true } : prev));
  try {
    const [request] = subscribeToBetaLibraryUpdates();
    await request;
  } catch (e) {
    logger.error("Error subscribing to beta library updates", {}, e);
    // Roll back the change and show a toast
    set(userAtom, (prev) => (prev ? { ...prev, isSubscribedToBetaLibraryUpdates: false } : prev));
    set(showToastActionAtom, { message: "Failed to subscribe. Please try again later." });
  }
});
