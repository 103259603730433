import { IVariable } from "@shared/types/Variable";

/**
 * Takes a variable returned from the backend
 * and transforms it into the type signature
 * expected for a variable instance stored in
 * actualcomponent.variables.
 */
export const getActualComponentVariable = (variable: IVariable): IVariable & { variable_id: string } => {
  if (!variable) {
    throw new Error("Variable is null");
  }

  return {
    ...variable,
    variable_id: variable._id,
    _id: variable._id, // match Variable type format
  };
};
