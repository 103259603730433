import React, { useCallback } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { Modal } from "../Modal";
import style from "./index.module.css";

/** A simple modal with headline text, body text, and two buttons - action and cancel. */

interface IDialogueModalProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The headline text of the modal.
   */
  headline: string;

  /**
   * The body text of the modal.
   */
  content: string;

  /**
   * Flag for whether modal is showing.
   */
  open: boolean;

  /**
   * Handler that's called when the open state changes.
   */
  onOpenChange: (open: boolean) => void;

  /**
   * Handler for when action button is clicked.
   */
  onAction: () => void;

  /**
   * Handler for when cancel button is clicked.
   */
  onCancel?: () => void;

  /**
   * Style variants for the action button.
   */
  type?: "default" | "danger";

  /**
   * Text for action button, defaults to "Submit".
   */
  actionText?: string;

  /**
   * Text for cancel button, defaults to "Cancel".
   */
  cancelText?: string;
}

export function DialogueModal({
  headline,
  content,
  open,
  onOpenChange,
  onAction,
  onCancel,
  type = "default",
  actionText = "Submit",
  cancelText = "Cancel",
}: IDialogueModalProps) {
  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      onOpenChange(isOpen);

      // We specifically use setTimeout instead of setImmediate here
      // because the plugin doesn't have setImmediate polyfilled.
      if (!isOpen && onCancel) {
        setTimeout(() => {
          onCancel();
        }, 0);
      }
    },
    [onCancel, onOpenChange]
  );

  const handleCancel = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  return (
    <Modal
      headline={headline}
      description={content}
      className={style.content}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Text color="secondary">{content}</Text>
      <div className={style.footer}>
        <Button level="outline" onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button level={type === "danger" ? "danger" : "primary"} onClick={onAction} autoFocus>
          {actionText}
        </Button>
      </div>
    </Modal>
  );
}

export default DialogueModal;
