import { client } from "@/http/lib/dittoClient";
import { createLibraryComponentsStore } from "@shared/frontend/stores/LibraryComponents";

export const {
  componentFamilyAtom,
  nullableComponentFamilyAtom,
  handleLibraryComponentsUpdatedActionAtom,
  updateComponentActionAtom,
  resetAtom,
  libraryComponentFoldersAtom,
  handleLibraryComponentCreatedActionAtom,
} = createLibraryComponentsStore(client);
