import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import CloseIcon from "@mui/icons-material/Close";
import { IPermissionGroupWithUsers, IRole } from "@shared/types/User";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import AddMembersInput from "../permissions/AddMembersInput";
import { UserInvitation } from "../permissions/AddMembersInput/types";
import PermissionGroupInput from "../permissions/PermissionGroupsInput";
import style from "./style.module.css";

interface InviteProjectContext {
  project: {
    id: string;
    name: string;
  };
  folder: { _id: string; name: string; invite_only: boolean } | null;
  type: "project";
}

interface InviteFolderContext {
  folder: { _id: string; name: string; invite_only: boolean };
  type: "folder";
}

interface InviteWorkspaceContext {
  type: "workspace";
}

type InviteContext = InviteProjectContext | InviteFolderContext | InviteWorkspaceContext;

interface InviteCollaboratorsModalProps {
  inviteContext: InviteContext;
  currentUser: {
    _id: string;
    email: string;
    role: IRole;
  };
  setNotification?: (input: { type: string; message: string; time?: number }) => void;
  onHide: () => void;
  onUsersInvited?: (
    invites: {
      email: string;
      role: string;
      permissionGroups: string[];
    }[]
  ) => void;
}

const InviteCollaboratorsModal = (props: InviteCollaboratorsModalProps) => {
  const { workspaceInfo } = useWorkspace();
  const { user } = useAuthenticatedAuth();

  const [invitedUsers, setInvitedUsers] = useState<UserInvitation[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [messageInput, setMessageInput] = useState<string>("");
  const [currentPermissionGroups, setCurrentPermissionGroups] = useState<IPermissionGroupWithUsers[]>([]);
  const [workspacePermissionGroups, setWorkspacePermissionGroups] = useState<IPermissionGroupWithUsers[]>([]);

  const isAdmin = useMemo(
    function isAdmin() {
      return user.permissionGroups.groups.some((group) => group._id === "admin");
    },
    [user.permissionGroups]
  );

  const permissionGroupOptions = useMemo(() => {
    // admins can invite anyone to any group
    // non-admins can only invite to groups they are a member of
    const allowedGroups = isAdmin
      ? workspacePermissionGroups
      : workspacePermissionGroups.filter((group) => {
          if (user.permissionGroups.groups.find((g) => g._id === group._id)) {
            return true;
          } else if (
            // special case where people in the built-in editor role can invite commenters
            group.built_in &&
            group._id === "commenter" &&
            user.permissionGroups.groups.find((g) => g._id === "editor")
          ) {
            return true;
          }
          return false;
        });

    // filter out currently selected options
    const currentGroupIds = currentPermissionGroups.map((group) => group._id);
    let options = allowedGroups.filter((group) => !currentGroupIds.includes(group._id));

    // if we're inviting from a folder or project, filter out groups that don't have access to that resource
    if (props.inviteContext.type === "folder" || props.inviteContext.type === "project") {
      const folderId = props.inviteContext.folder?._id;
      if (!folderId) return options;

      options = options.filter((group) => {
        const resourceAccess = group.permissions.resources.find((resource) => resource.resource_id === folderId);
        return !!resourceAccess || group.built_in;
      });
    }

    return options;
  }, [workspacePermissionGroups, currentPermissionGroups, isAdmin, user.permissionGroups]);

  const canUsePermissionGroups = useMemo(
    function canUsePermissionGroups() {
      return workspaceInfo?.plan === "enterprise";
    },
    [workspaceInfo?.plan]
  );
  const canSend = useMemo(
    function canSend() {
      return !isSending && invitedUsers.length > 0 && currentPermissionGroups.length > 0;
    },
    [isSending, invitedUsers, currentPermissionGroups]
  );

  const messageInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessageInput(e.target.value);
    },
    [setMessageInput]
  );

  async function inviteCollaborators() {
    try {
      setIsSending(true);
      const { url, body } = API.invite.post.createNew;
      const { data } = await http.post(
        url,
        body({
          users: invitedUsers,
          permissionGroups: currentPermissionGroups.map((group) => group._id),
          context: props.inviteContext,
          message: messageInput,
        })
      );

      if (props.onUsersInvited) props.onUsersInvited(data.invites);

      if (props.setNotification)
        props.setNotification({
          type: "success",
          message: invitedUsers.length === 1 ? "Invite sent! 🎉" : "Invites sent! 🎉",
          time: 5000,
        });
      props.onHide();
    } catch (error) {
      console.error("Error Inviting Users", error);
      if (props.setNotification)
        props.setNotification({
          type: "error",
          message: `There was an error inviting users.`,
          time: 5000,
        });
    }
    setIsSending(false);
  }

  const isEmailInUse = async (email: string) => {
    try {
      const { url, body } = API.user.post.checkExists;
      const { data: userExists } = await http.post(url, body({ email }));
      return userExists.exists && !userExists.sameWorkspace;
    } catch (error) {
      console.error("Error checking if email exists", error);
      return false;
    }
  };

  const onUserInvited = async (newInvite: UserInvitation) => {
    const isAlreadyInvited = newInvite.type !== "new" ? false : await isEmailInUse(newInvite.email);

    setInvitedUsers((prev) => {
      if (prev.find((invite) => invite.email === newInvite.email)) return prev;
      const update = prev.concat({ ...newInvite, isAlreadyInvited });
      return update;
    });
  };

  const onUserRemoved = (index: number) => {
    setInvitedUsers((prev) => prev.filter((_, i) => i !== index));
  };

  const getPermissionGroups = async () => {
    http.get(`/workspace/permission-groups`).then((res) => {
      const { permissionGroupsWithUsers } = res.data;

      setWorkspacePermissionGroups(permissionGroupsWithUsers);
    });
  };

  useEffect(function fetchPermissionGroupsOnMount() {
    getPermissionGroups();
  }, []);

  return (
    <BootstrapModal
      show={true}
      className={style.permissionGroupsShareModal}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={props.onHide}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>Invite Collaborators</BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={props.onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <div className={style.subTitle}>Email Addresses</div>
        <div className={style.inputPadding}>
          <AddMembersInput
            onUserInvited={onUserInvited}
            onUserRemoved={onUserRemoved}
            disabled={false}
            currentUsers={[]}
            invitedUserList={invitedUsers}
            existingInvitedEmails={{}}
            currentUserEmail={user.email}
            className={style.addMembersInput}
            workspaceContext={props.inviteContext.type === "workspace"}
          />
        </div>
        {canUsePermissionGroups && (
          <>
            <div className={style.subTitle}>Permissions</div>
            <div className={style.inputPadding}>
              <PermissionGroupInput
                currentGroups={currentPermissionGroups}
                permissionGroupOptions={permissionGroupOptions}
                setCurrentGroups={setCurrentPermissionGroups}
              />
            </div>
          </>
        )}
        <div className={style.subTitle}>
          Message <span className={style.subTitleSpan}>(optional)</span>
        </div>
        <div className={style.inputPadding}>
          <textarea
            placeholder="Add a custom message"
            value={messageInput}
            disabled={isSending}
            className={style.message}
            onChange={messageInputChange}
          />
        </div>
        {props.inviteContext.type !== "workspace" && (
          <div className={style.notice}>
            Heads up! People invited to collaborate on this {props.inviteContext.type} will join your workspace.
          </div>
        )}
      </BootstrapModal.Body>
      <BootstrapModal.Footer className={style.footer}>
        <ButtonPrimary
          data-testid="invite-button"
          text={isSending ? "Inviting..." : "Invite"}
          onClick={inviteCollaborators}
          disabled={!canSend}
        />
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default InviteCollaboratorsModal;
