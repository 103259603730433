import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import LibraryComponentIcon from "../../atoms/Icon/icons/LibraryComponentIcon";
import Text from "../../atoms/Text";

import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  size?: "large" | "micro" | "small" | "xl" | "h1" | "base";
  weight?: "base" | "strong" | "medium" | "light";
  iconSize?: "micro" | "small" | "base" | "icon-button-size" | "xs" | "xxs" | "xxxs";
}

export function LibraryComponentNameSpan(props: IProps) {
  const size = props.size ?? "micro";
  const iconSize = props.iconSize ?? "micro";
  const weight = props.weight ?? "base";
  return (
    <span
      style={props.style}
      className={classNames([
        style.LibraryComponentNameSpanWrapper,
        props.className,
        {
          [style[`size-${size}`]]: true,
          [style[`weight-${weight}`]]: true,
        },
      ])}
    >
      <Icon Icon={<LibraryComponentIcon />} size={iconSize} />
      <Text truncate size={size} color="purple" weight={weight}>
        {props.children}
      </Text>
    </span>
  );
}

export default LibraryComponentNameSpan;
