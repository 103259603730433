import { useWorkspace } from "@/store/workspaceContext";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import CodeIcon from "@mui/icons-material/Code";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import classNames from "classnames";
import React from "react";
import waveEmoji from "../../../../assets/wave_emoji.png";
import { IMPORT_MODAL_QUERY_STRING } from "../../../../views/Components/index";
import LockedFolderIcon from "../../../icons/LockedFolderIcon";
import BlueFigmaIcon from "../BlueFigmaIcon";
import style from "./style.module.css";

interface TitleProps {
  folder: { _id: string; name: string; invite_only: boolean } | null;
}
const Title = ({ folder }: TitleProps) => (
  <>
    Start a new project{folder ? " in " : ""}
    {folder?.name && (
      <div className={style.folderIconWrapper}>
        {!folder.invite_only ? <FolderIcon className={style.icon} /> : <LockedFolderIcon className={style.icon} />}
        <div>{folder?.name}</div>
      </div>
    )}
  </>
);
interface BodyProps {
  isFirstProject: boolean;
  onCreateNSProject: () => void;
  onDraftNewProject: () => void;
  onImportExistingFile: () => void;
}

function Body(props: BodyProps) {
  const { isFirstProject, onDraftNewProject, onImportExistingFile, onCreateNSProject } = props;
  const workspace = useWorkspace();
  const [selectedCard, setSelectedCard] = React.useState<
    "createNSProject" | "createLegacyProject" | "importLegacyProject" | null
  >("createNSProject");

  const isNorthStar = !!workspace.workspaceInfo.feature_flags.northStar;

  function handleContinue() {
    if (selectedCard === "createNSProject") {
      onCreateNSProject();
    } else if (selectedCard === "createLegacyProject") {
      onDraftNewProject();
    } else if (selectedCard === "importLegacyProject") {
      onImportExistingFile();
    }
  }

  if (isNorthStar) {
    return (
      <div className={style.verticalContainer}>
        <div className={style.verticalButtons}>
          <div className={style.nsCardWrapper}>
            <div
              className={classNames(style.selectCard, {
                [style.selected]: selectedCard === "createNSProject",
              })}
              onClick={() => setSelectedCard("createNSProject")}
            >
              <div className={style.betaLabel}>
                <Text color="secondary" size="micro" weight="medium">
                  BETA
                </Text>
              </div>
              <div className={style.cardContent}>
                <Text size="large" weight="strong">
                  Start a project with the new Ditto beta
                </Text>
                <Text color="secondary">
                  Draft, review, and collaborate on text in Ditto. Connect to design files or development at any time.
                </Text>
              </div>
            </div>
            <div className={style.ctaBox}>
              <Text inline size="small" weight="medium">
                What is the new Ditto beta?{" "}
                <Text inline size="small" color="secondary">
                  Create text, organize with blocks, and reuse text across designs, independent of Figma frames.
                </Text>{" "}
                <Text
                  inline
                  size="small"
                  color="action"
                  onClick={() => window.open(LEARN_MORE_URL.CREATE_PROJECT_MODAL, "_blank")}
                >
                  Learn more
                </Text>
              </Text>
            </div>
          </div>

          <div
            className={classNames(style.selectCard, {
              [style.selected]: selectedCard === "createLegacyProject",
            })}
            onClick={() => setSelectedCard("createLegacyProject")}
          >
            <div className={style.cardContent}>
              <Text weight="medium">Start a legacy Ditto project from scratch</Text>
              <Text color="secondary" size="small">
                Connect to design files or development later.
              </Text>
            </div>
          </div>
          <div
            data-testid="import-file-button"
            className={classNames(style.selectCard, {
              [style.selected]: selectedCard === "importLegacyProject",
            })}
            onClick={() => setSelectedCard("importLegacyProject")}
          >
            <div className={style.cardContent}>
              <Text weight="medium">Import a Figma file into a legacy Ditto project</Text>
              <Text color="secondary" size="small">
                Start with text in an existing Figma mockup.
              </Text>
            </div>
          </div>
        </div>
        <div className={style.continueButton}>
          <Button onClick={handleContinue} disabled={selectedCard === null}>
            Continue
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={style.container}>
        {isFirstProject && (
          <div className={style.guide}>
            <img className={style.emoji} src={waveEmoji} />
            <div>
              Looks like you’re starting your first Ditto project! We’re so glad you’re here.{" "}
              <a href="https://www.dittowords.com/docs/what-is-ditto" target="_blank">
                Help on getting started
              </a>
            </div>
          </div>
        )}

        <div className={style.buttons}>
          <div className={style.importButton} onClick={onDraftNewProject}>
            <div className={classNames(style.iconWrapper, style.draft)}>
              <DescriptionOutlinedIcon className={style.icon} />
            </div>
            <span className={style.title}>Start from scratch</span>
            <p>You can always connect to design files or development later.</p>
          </div>

          <div data-testid="import-file-button" className={style.importButton} onClick={onImportExistingFile}>
            <div className={classNames(style.iconWrapper, style.figma)}>
              <BlueFigmaIcon />
            </div>
            <span className={style.title}>Import a Figma file</span>
            <p>
              Start with text in an existing
              <br /> Figma mockup.
            </p>
          </div>
        </div>

        <a className={style.importSection} href={`/components?${IMPORT_MODAL_QUERY_STRING}=true`}>
          <CodeIcon className={style.icon} />
          <span>
            Store copy in spreadsheets or strings files in your codebase? Get started by{" "}
            <strong>importing them into Ditto {"->"}</strong>
          </span>
        </a>
      </div>
    );
  }
}

const Footer = () => null;
const WouldYouLikeTo = ({ children }: { children: React.ReactNode }) => <>{children}</>;
WouldYouLikeTo.Title = Title;
WouldYouLikeTo.Body = Body;
WouldYouLikeTo.Footer = Footer;

export default WouldYouLikeTo;
