import React from "react";
import Text from "../Text";

interface IProps {
  className?: string;
  text: string;
}

export function BreadcrumbTerminus(props: IProps) {
  return (
    <Text color="primary" weight="strong" className={props.className} data-testid="breadcrumb-terminus">
      {props.text}
    </Text>
  );
}

export default BreadcrumbTerminus;
