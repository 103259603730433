import ModeCommentIcon from "@mui/icons-material/ModeComment";
import React, { useCallback } from "react";
import { ITipTapRichText } from "../../../../shared/types/TextItem";
import { IUser } from "../../../../shared/types/User";
import Avatar from "../../atoms/Avatar";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import EditableTextArea from "../../atoms/EditableTextArea";
import Icon from "../../atoms/Icon";
import Tooltip from "../../molecules/Tooltip";
import VariantBadge, { VariantBadgeData, VariantNotPresentBadge } from "../../molecules/VariantBadge";
import style from "./index.module.css";

export type TextItemComponentState = "default" | "focus" | "disabled" | "dragging" | "loading";

// none: not editable
// editable: allow focusing the input but don't show the save/cancel buttons
// editing: show save/cancel buttons
export type TextItemEditState = "none" | "editable" | "editing";

/**
 * Text item levels:
 * - default: the default level, with all metadata and badges visible
 * - empty-variant: a variant with no text
 * - compact: a compact level, with badges visible but wrapped below the text
 * - minimal: show notes and tags, but not badges
 * - essential: show only status and instance count
 */
export type TextItemLevel = "default" | "empty-variant" | "compact" | "minimal" | "essential";

export function Badges(props: {
  variants?: VariantBadgeData[];
  numComments?: number;
  assignee?: Pick<IUser, "name" | "picture">;
  level?: TextItemLevel;
  instanceCount?: number;
  hideVariantsBadge?: boolean;
  variantNotPresent?: boolean;
  showComponentInstances?: boolean;
}) {
  return (
    <div className={style.badges}>
      {!props.hideVariantsBadge && (
        <>{props.variantNotPresent ? <VariantNotPresentBadge /> : <VariantBadge variants={props.variants} />}</>
      )}
      {!!props.numComments && (
        <Tooltip content={`${props.numComments} open comment${props.numComments === 1 ? "" : "s"}`} type="invert">
          <Badge className={style.commentsBadge} type="outline" size="sm" borderRadius="lg">
            <Icon className={style.reflect} Icon={<ModeCommentIcon />} size="micro" color="primary" />
            {props.numComments}
          </Badge>
        </Tooltip>
      )}
      {props.assignee && (
        <Tooltip content={props.assignee.name} type="invert">
          <Avatar userName={props.assignee.name} picture={props.assignee.picture} size="sm" />
        </Tooltip>
      )}
    </div>
  );
}

export function InstanceCountBadge(props: { instanceCount: number; level?: TextItemLevel; isComponent?: boolean }) {
  return (
    <div className={style.instanceBadges}>
      {props.level !== "compact" && props.level !== "minimal" && props.level !== "essential" && (
        <div className={style.badgeDivider} />
      )}
      <Tooltip content={`${props.instanceCount} instances in design`} type="invert">
        <Badge
          className={style.instanceCount}
          type={props.instanceCount ? "action-outline" : "subtle"}
          size="sm"
          borderRadius="md"
          color={props.isComponent ? "purple" : "blue"}
        >
          {props.instanceCount}
        </Badge>
      </Tooltip>
    </div>
  );
}

interface EditableInputProps {
  autoFocus?: boolean;
  editState: TextItemEditState;
  defaultValue: ITipTapRichText;
  highlightedPhrase?: string | null;
  placeholder?: string;
  onSave: (richText: ITipTapRichText) => void;
  onClickCancel: () => Promise<void> | void;
  onEscape?: () => Promise<void> | void; // By default, hitting escape runs onClickCancel. Pass a custom handler here to override
  onTextChange?: (richText: ITipTapRichText) => void;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

export function EditableInput(props: EditableInputProps) {
  const { onSave, onClickCancel, onEscape, onTextChange, onFocus, onBlur } = props;
  const [inputValue, setInputValue] = React.useState(props.defaultValue);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleChange = useCallback(
    function _handleChange(richText: ITipTapRichText) {
      setInputValue(richText);
      onTextChange?.(richText);
    },
    [onTextChange]
  );

  const handleSave = useCallback(
    (passedInInputValue: ITipTapRichText) => {
      setIsSaving(true);
      onSave(passedInInputValue);
      setIsSaving(false);
    },
    [onSave]
  );

  const handleEditorKeydown = useCallback(
    function _handleEditorKeydown(e: React.KeyboardEvent<HTMLDivElement>) {
      if (e.metaKey && e.key === "Enter") {
        handleSave(inputValue);
      }
      if (e.key === "Escape") {
        onEscape ? onEscape() : onClickCancel();
      }
    },
    [handleSave, inputValue, onClickCancel, onEscape]
  );

  const isEditable = props.editState === "editable" || props.editState === "editing";
  const isEditing = props.editState === "editing";

  return (
    <div className={style.editableInput}>
      <EditableTextArea
        placeholder={props.placeholder ?? "Edit text"}
        onKeyDown={handleEditorKeydown}
        onTextChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        editable={isEditable}
        editing={isEditing}
        content={props.defaultValue}
        highlightedPhrase={props.highlightedPhrase}
        autoFocus={props.autoFocus}
        onEnter={handleSave}
      />
      {isEditing && (
        <div className={style.inlineEditButtons}>
          <Button disabled={isSaving} size="micro" level="secondary" onClick={onClickCancel}>
            Cancel
          </Button>

          <Button disabled={isSaving} size="micro" level="primary" onClick={() => handleSave(inputValue)}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

export function TextIcon() {
  return (
    <Icon
      Icon={
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3H13V4V6H12V4H8.5V12H10V13H6V12H7.5V4H4V6H3V4V3Z"
            fill="#A3A3A3"
          />
        </svg>
      }
      size="xs"
    />
  );
}
