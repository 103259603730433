import { mostRecentTextItemCommentFamilyAtom, postCommentActionAtom, updateCommentEditsAtom } from "@/stores/Comments";
import { detailsPanelSelectionStateAtom, onlySelectedTextItemIdAtom } from "@/stores/ProjectSelection";
import { deferredUsersAtom } from "@/stores/Workspace";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import CommentEditor from "@ds/organisms/CommentEditor";
import { IUser } from "@shared/types/User";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import { CommentThreadWrapper } from "../../CommentThreadWrapper";
import style from "./style.module.css";

function Comments() {
  const selectedTextItemId = useAtomValue(onlySelectedTextItemIdAtom);
  const users = useAtomValue(deferredUsersAtom);
  const postCommentAction = useSetAtom(postCommentActionAtom);
  const updateIsEditingAtom = useSetAtom(updateCommentEditsAtom);

  const handlePostComment = async (commentText: string, mentionedUsers: IUser[], resetEditorState: () => void) => {
    if (!selectedTextItemId) {
      return;
    }

    await postCommentAction({
      textItemId: selectedTextItemId,
      commentText,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
    });

    resetEditorState();
  };

  const onTypeComment = useCallback(
    (contentToSave: string, _mentionedUsersInfo: IUser[]) => {
      updateIsEditingAtom({ commentThreadId: "new", hasUnsavedChanges: !!contentToSave });
    },
    [updateIsEditingAtom]
  );

  if (!selectedTextItemId) {
    return <></>;
  }

  return (
    <div className={style.commentsContainer}>
      <div className={style.commentEditorContainer}>
        <Text size="small" color="primary" weight="strong">
          Comment
        </Text>
        <CommentEditor
          key={selectedTextItemId}
          placeholderText="Add a comment..."
          submitButtonText="Add comment"
          onChange={onTypeComment}
          onSave={handlePostComment}
          mentionableUsers={users}
        />
      </div>
      <Suspense fallback={<></>}>
        <MostRecentThread />
      </Suspense>
    </div>
  );
}

function MostRecentThread() {
  const selectedTextItemId = useAtomValue(onlySelectedTextItemIdAtom);
  const mostRecentThreadId = useAtomValue(mostRecentTextItemCommentFamilyAtom(selectedTextItemId));
  const setSelectedDetailsTab = useSetAtom(detailsPanelSelectionStateAtom);

  const handleShowAll = useCallback(
    function _handleShowAll() {
      setSelectedDetailsTab("COMMENTS");
    },
    [setSelectedDetailsTab]
  );

  if (!mostRecentThreadId) {
    return <></>;
  }

  return (
    <div className={style.mostRecentContainer}>
      <div className={style.mostRecentHeader}>
        <Text size="small" weight="medium" color="secondary">
          Most recent
        </Text>
        <Button size="micro" level="subtle" onClick={handleShowAll}>
          See all comments
        </Button>
      </div>
      <CommentThreadWrapper commentThreadId={mostRecentThreadId} />
    </div>
  );
}

export default Comments;
