import { atom, Getter, SetStateAction, Setter, WritableAtom } from "jotai";

interface Location {
  searchParams?: URLSearchParams;
}

/**
 * An atom which derives its value from the URL search params, and syncs changes back to the URL.
 * For type-safety reasons, the value of the atom is always either an array of strings or null --
 * if you just want to store a single string, be aware that it'll be stored as an array of length 1.
 *
 * Note: this atom differentiates between a value of `null` and a value of `[]`. Setting the value to `null` will
 * remove the key from the URL entirely, but setting the value to an empty array will keep the key in the URL with
 * an empty value. This is useful for various rendering problems.
 */
function atomWithURLStorage(
  key: string,
  locationAtom: WritableAtom<Location, [SetStateAction<Location>], void>,
  options: {
    isString: true;
    onSet?: (get: Getter, set: Setter) => void;
  }
): WritableAtom<string | null, [string | null], void>;
function atomWithURLStorage(
  key: string,
  locationAtom: WritableAtom<Location, [SetStateAction<Location>], void>,
  options?: {
    isString?: false;
    onSet?: (get: Getter, set: Setter) => void;
  }
): WritableAtom<string[] | null, [string[] | null], void>;
function atomWithURLStorage(
  key: string,
  locationAtom: WritableAtom<Location, [SetStateAction<Location>], void>,
  options?: {
    isString?: boolean;
    // A function that is called when the atom is set (url changed for filters)
    onSet?: (get: Getter, set: Setter) => void;
  }
): WritableAtom<string | null, [string | null], void> | WritableAtom<string[] | null, [string[] | null], void> {
  /**
   * It's important to use a separate atom for the string value, because lots of atoms are capable of changing
   * the value of `location.searchParams`, and so this stringAtom will be recomputed every time the URL changes;
   * however, as long as the *value* it returns is the same, this string atom will not recompute, and not trigger
   * downstream updates.
   *
   * E.g., we use this atom to store both the selected text item IDS and the page filters; we want to refetch the
   * text items when the page filters change, but *not* whenever the selected text item IDs (in the url) change.
   */
  const stringAtom = atom((get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return null;
    }
    const value = location.searchParams.get(key);

    if (value === null) return null;
    return value;
  });

  if (options?.isString) {
    const stringURLParam = atom(
      (get) => {
        const value = get(stringAtom);
        if (value === null || value === "") return null;
        return value;
      },
      (get, set, newValue: string | null) => {
        const location = get(locationAtom);
        const newSearchParams = new URLSearchParams(location.searchParams);

        // Note: it's important for these cases to be separate! Setting the value to an array of length 0 is *different*
        // from setting the value to null. Setting to null should remove the key from the URL entirely, but setting to
        // an empty array should keep the key in the URL with an empty value.
        if (newValue === null || newValue === "") {
          newSearchParams.delete(key);
        } else {
          newSearchParams.set(key, newValue);
        }

        set(locationAtom, {
          ...location,
          searchParams: newSearchParams,
        });

        options.onSet?.(get, set);
      }
    );
    return stringURLParam;
  } else {
    const arrayURLParam = atom(
      (get) => {
        const value = get(stringAtom);
        if (value === null) return null;
        if (value === "") return [];
        return value.split(",");
      },
      (get, set, newValue: string[] | null) => {
        const location = get(locationAtom);
        const newSearchParams = new URLSearchParams(location.searchParams);

        // Note: it's important for these cases to be separate! Setting the value to an array of length 0 is *different*
        // from setting the value to null. Setting to null should remove the key from the URL entirely, but setting to
        // an empty array should keep the key in the URL with an empty value.
        if (newValue === null) {
          newSearchParams.delete(key);
        } else if (!newValue?.length) {
          newSearchParams.set(key, "");
        } else {
          newSearchParams.set(key, newValue.join(","));
        }

        set(locationAtom, {
          ...location,
          searchParams: newSearchParams,
        });

        options?.onSet?.(get, set);
      }
    );
    return arrayURLParam;
  }
}

export default atomWithURLStorage;
