import ChangeItem from "@ds/molecules/ChangeItem";
import { CustomChangeItemComponentProps } from "@ds/organisms/ChangeItemList";
import React from "react";

// TODO: Make this the general activity item for library change items

function LibraryGeneralActivityItem({ changeItem }: CustomChangeItemComponentProps) {
  return <ChangeItem changeItem={changeItem} />;
}

export default LibraryGeneralActivityItem;
