import z from "zod";
import { ZCreatableId, ZObjectId } from "./lib";

import { filterableFields as TextItemFilterableFields } from "./TextItem";

export const ZLibraryComponent = z.object({
  _id: ZObjectId,
  instances: z.array(ZObjectId),
  workspaceId: ZCreatableId,
  name: z.string(),
  apiId: z.string(),
  commentThreads: z.array(ZObjectId),
  folderId: ZObjectId.nullable(),
  assignedAt: z.date().nullable().optional(),
  editedAt: z.date().nullable().optional(),
  editedBy: ZObjectId.nullable().optional(),
  characterLimit: z.number().nullable().optional(),
  sortKey: z.string(),
  ...TextItemFilterableFields,
});
export type ILibraryComponent = z.infer<typeof ZLibraryComponent>;

export const ZCreateTestLibraryComponentsArgs = z.object({
  count: z.number(),
  workspaceId: z.string(),
  projectId: z.string().optional(),
  groupsCount: z.number().optional(),
  blocksCount: z.number().optional(),
  distributeBetweenFolders: z.number().optional(),
  maxInstanceCount: z.number().optional(),
});

export type ICreateTestLibraryComponentsArgs = z.infer<typeof ZCreateTestLibraryComponentsArgs>;
