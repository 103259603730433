import { z } from "zod";

export const ZInitializeFigmaPluginRequest = {
  body: z.object({
    file_id: z.string().nullable().optional(),
    file_name: z.string().nullable().optional(),
    branch_id: z.string().nullable().optional(),
    project_id: z.string().nullable().optional(),
    ditto_component_key: z.string(),
  }),
};
export const ZInitializeFigmaPluginResponse = z.unknown();
export const InitializeFigmaPluginMethod = "POST";
export const InitializeFigmaPluginPath = "/api/init";

export const ZGetFileNameRequest = {
  query: z.object({
    fileId: z.string().optional(),
  }),
};
export const ZGetFileNameResponse = z.unknown();
export const GetFileNameMethod = "GET";
export const GetFileNamePath = "/api/getFileName";
