import useSegment from "@/hooks/useSegment";
import { useWorkspace } from "@/store/workspaceContext";
import BetaButton from "@ds/atoms/BetaButton";
import Button from "@ds/atoms/Button";
import Breadcrumbs from "@ds/molecules/Breadcrumbs";
import DropdownMenu, { DropdownMenuItemType } from "@ds/molecules/DropdownMenu";
import { Tooltip } from "@ds/molecules/Tooltip";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_INTEREST_GENERAL } from "@shared/segment-event-names";
import { Suspense, useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

function LibraryNavBar() {
  const { track } = useSegment();
  const workspace = useWorkspace();

  const handleBetaButtonClick = useCallback(() => {
    window.open(LEARN_MORE_URL.BETA_BUTTON, "_blank");
    track({ event: NS_BETA_INTEREST_GENERAL, properties: { version: "NS" } });
  }, [track]);

  const dropdownMenuItems: DropdownMenuItemType[] = useMemo(() => {
    return [];
  }, []);

  const previousCrumbs = useMemo(() => {
    const previousCrumbs = [
      { text: workspace.workspaceInfo.name, route: "/" },
      { text: "Library", route: "/library" },
    ];
    // TODO: Add folders to the breadcrumbs
    return previousCrumbs;
  }, [workspace.workspaceInfo.name]);

  return (
    <div className={style.navBarContainer}>
      <Breadcrumbs
        className={style.breadcrumbs}
        previousCrumbs={previousCrumbs}
        finalCrumb={
          <Suspense fallback={<Skeleton height={17} width={120} baseColor="var(--bg-secondary)" />}>
            {/* <ProjectName /> */}
          </Suspense>
        }
      />
      <div className={style.rightSection}>
        <BetaButton leftText="BETA" rightText="Learn more" hoverRight onClick={handleBetaButtonClick} />
        <div className={style.rightSubgroup}>
          <Tooltip forceText={true} side="bottom" type="invert" content={"Coming soon..."}>
            <Button size="small" level="primary" disabled>
              Import
            </Button>
          </Tooltip>

          <DropdownMenu RDropdownMenuContentProps={{ align: "end" }} items={dropdownMenuItems} />
        </div>
      </div>
    </div>
  );
}

export default LibraryNavBar;
