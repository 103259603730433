import { z } from "zod";

export const ZCreateVariableFolderRequest = {
  body: z.object({
    name: z.string(),
    variable_ids: z.array(z.string()).optional(),
  }),
};
export const ZCreateVariableFolderResponse = z.unknown();

export const ZUpdateVariableFolderRequest = {
  body: z.object({
    name: z.string(),
  }),
};
export const ZUpdateVariableFolderResponse = z.unknown();

export const ZDeleteVariableFolderRequest = {
  params: z.object({
    id: z.string(),
  }),
};
export const ZDeleteVariableFolderResponse = z.unknown();

export const ZMoveVariablesToFolderRequest = {
  body: z.object({
    variable_ids: z.array(z.string()),
  }),
};
export const ZMoveVariablesToFolderResponse = z.unknown();
