import { ZFigmaTextNodeSerialized } from "@shared/types/FigmaTextNode";
import { ZObjectId } from "@shared/types/lib";
import { ZLibraryComponent } from "@shared/types/LibraryComponent";
import { ZTextItem, ZTipTapRichText } from "@shared/types/TextItem";
import { z } from "zod";

export const ZGetLibraryComponentsRequest = {
  query: z.object({
    folderId: z.string().optional(),
    componentIds: z.array(z.string()).optional(),
  }),
};
export const ZGetLibraryComponentsResponse = z.object({
  components: z.array(ZLibraryComponent),
});
export const GetLibraryComponentsMethod = "GET";
export const GetLibraryComponentsPath = "/library-component";

export const ZCreateLibraryComponentRequest = {
  body: z.object({
    _id: ZObjectId,
    name: z.string(),
    richText: ZTipTapRichText,
    folderId: ZObjectId.nullable(),
  }),
};
export const ZCreateLibraryComponentResponse = z.object({
  newComponent: ZLibraryComponent,
});
export const CreateLibraryComponentMethod = "POST";
export const CreateLibraryComponentPath = "/library-component";

export const ZPublishRequest = {
  body: z.object({
    name: z.string(),
    textItemId: z.string(),
    newComponentId: z.string().optional(),
    folderId: z.string().nullable().optional(),
  }),
};
export const ZPublishResponse = z.object({ autoName: z.string() });
export const PublishMethod = "POST";
export const PublishPath = "/library-component/publish";

export const ZAutoNameRequest = {
  query: z.object({
    text: z.string(),
  }),
};
export const ZAutoNameResponse = z.object({ autoName: z.string() });
export const AutoNameMethod = "GET";
export const AutoNamePath = "/library-component/auto-name";

export const ZLinkTextItemsRequest = {
  params: z.object({
    componentId: z.string(),
  }),
  body: z.object({
    projectId: z.string(),
    textItemIds: z.array(z.string()),
  }),
};
export const ZLinkTextItemsResponse = z.unknown();
export const LinkTextItemsMethod = "PATCH";
export const LinkTextItemsPath = "/library-component/:componentId/link";

export const ZUnlinkTextItemsRequest = {
  params: z.object({
    componentId: z.string(),
  }),
  body: z.object({
    projectId: z.string(),
    textItemIds: z.array(z.string()),
  }),
};
export const ZUnlinkTextItemsResponse = z.unknown();
export const UnlinkTextItemsMethod = "PATCH";
export const UnlinkTextItemsPath = "/library-component/:componentId/unlink";

export const ZLinkFigmaNodesRequest = {
  params: z.object({
    componentId: z.string(),
  }),
  body: z.object({
    projectId: ZObjectId,
    figmaNodes: z.array(ZFigmaTextNodeSerialized).nonempty(),
    textItemId: ZObjectId.optional(), // _id to use for the new text item (to support optimistic updates)
  }),
};
export const ZLinkFigmaNodesResponse = z.object({
  textItem: ZTextItem,
});
export const LinkFigmaNodesMethod = "PATCH";
export const LinkFigmaNodesPath = "/library-component/:componentId/linkFigmaNodes";
