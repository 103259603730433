import classNames from "classnames";
import { atom, Atom, createStore, getDefaultStore, useAtom, useAtomValue, WritableAtom } from "jotai";
import React, { Suspense, useCallback, useRef } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import CompactInputLabel from "../../molecules/CompactInputLabel";
import LibraryComponentAutoNameInput, {
  IProps as IAutoNameInputProps,
} from "../../molecules/LibraryComponentAutoNameInput";
import LibraryComponentFolderFilterDropdown, {
  IProps as IFilterDropdownProps,
} from "../../molecules/LibraryComponentFolderFilterDropdown";
import { IProps as ITextItemProps, TextItemStatic } from "../../molecules/TextItem";
import style from "./index.module.css";

type Store = ReturnType<typeof createStore>;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  store?: Store;

  filterDropdown: IFilterDropdownProps;
  autoNameInput: IAutoNameInputProps;
  textItem: ITextItemProps;

  onCancel: () => void;
  onPublish: (data: { name: string; folderId: string | null }) => void;
}

export function PublishToLibrary(props: IProps) {
  const storeRef = useRef(props.store || getDefaultStore());

  const selectedFolder = props.filterDropdown.selectedFolder;
  const nameAtom = props.autoNameInput.valueAtom;

  const onPublishClick = async () => {
    const name = await storeRef.current.get(nameAtom);
    if (!name) {
      throw new Error("Name is required");
    }

    if (!selectedFolder) {
      throw new Error("Folder ID is required");
    }

    const folderIdParsed = LibraryComponentFolderFilterDropdown.isAllComponentsOption(selectedFolder._id)
      ? null
      : selectedFolder._id;

    props.onPublish({ name, folderId: folderIdParsed });
  };

  return (
    <div
      style={props.style}
      className={classNames(style.PublishToLibraryWrapper, props.className)}
      data-testid="publish-to-library"
    >
      <CompactInputLabel
        label="Publish to..."
        input={<LibraryComponentFolderFilterDropdown {...props.filterDropdown} />}
      />
      <CompactInputLabel
        label="Component name"
        helper="You can change component names at any time"
        input={<LibraryComponentAutoNameInput {...props.autoNameInput} />}
      />
      <CompactInputLabel
        label="Component preview"
        input={<TextItemStatic {...props.textItem} className={style.componentPreview} />}
      />
      <Text size="micro" color="secondary">
        After publishing this text item to your library, it can be used across all of your projects.
      </Text>
      <div className={style.footer}>
        <Button level="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
        <PublishButton onClick={onPublishClick} folderId={selectedFolder?._id ?? null} nameAtom={nameAtom} />
      </div>
    </div>
  );
}

type PublishButtonProps = {
  onClick: () => void;
  folderId: string | null;
  nameAtom: Atom<string | Promise<string>>;
};
function PublishButton(props: PublishButtonProps) {
  return (
    <Suspense
      fallback={
        <Button level="primary" size="base" disabled>
          Publish
        </Button>
      }
    >
      <_PublishButton {...props} />
    </Suspense>
  );
}
function _PublishButton(props: PublishButtonProps) {
  const name = useAtomValue(props.nameAtom);
  const disabled = !(props.folderId && name);

  return (
    <Button level="primary" size="base" onClick={props.onClick} disabled={disabled}>
      Publish
    </Button>
  );
}

// we want this value to persist in memory throughout the user's session so that
// if they publish multiple components in sequence, the same folder remains selected throughout
//
// NOTE: this could be a pseudo id representing folders in the root of the library
const selectedFolderAtom = atom<{ _id: string; name: string } | null>(null);

export function usePublishToLibrary(props: {
  textItem: Partial<Pick<ITextItemProps, "defaultValue" | "defaultText" | "status" | "tags" | "notes">>;
  foldersAtom: Atom<{ _id: string; name: string }[] | Promise<{ _id: string; name: string }[]>>;
  componentNameAtom: WritableAtom<string | Promise<string>, [string | Promise<string>], void>;
  onRegenerateComponentName: () => void;
  onCancel: IProps["onCancel"];
  onPublish: IProps["onPublish"];
}): IProps {
  const [selectedFolder, setSelectedFolder] = useAtom(selectedFolderAtom);
  const onFolderChange = useCallback(
    (folder: { _id: string; name: string } | null) => setSelectedFolder(folder),
    [setSelectedFolder]
  );

  return {
    filterDropdown: {
      selectedFolder,
      foldersAtom: props.foldersAtom,
      onChange: onFolderChange,
    },
    autoNameInput: {
      valueAtom: props.componentNameAtom,
      onRegenerateAutoName: props.onRegenerateComponentName,
    },
    textItem: {
      ...props.textItem,
      component: {
        name: props.componentNameAtom,
      },
    },
    onCancel: props.onCancel,
    onPublish: props.onPublish,
  };
}

export default PublishToLibrary;
