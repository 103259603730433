import Close from "@mui/icons-material/Close";
import { IUser } from "@shared/types/User";
import { useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import http, { API } from "../../../http";
import ButtonPrimary from "../../button/buttonprimary";
import AddMembersInput from "../AddMembersInput";
import { ExistingUserInvitation, UserInvitation } from "../AddMembersInput/types";
import style from "./AddMembersModal.module.css";

interface AddMembersModalProps {
  groupName: string;
  isDefaultGroup: boolean;
  workspaceUsers: IUser[];
  currentUsers: IUser[];
  setShowAddMembersModal: (show: boolean) => void;
  handleAddMembers: (userIds: string[]) => void;
  handleInviteMembers: (emails: string[]) => void;
}

const AddMembersModal = (props: AddMembersModalProps) => {
  const {
    groupName,
    isDefaultGroup,
    workspaceUsers,
    currentUsers,
    setShowAddMembersModal,
    handleAddMembers,
    handleInviteMembers,
  } = props;

  const [userInvitations, setUserInvitations] = useState<UserInvitation[]>([]);

  const isEmailInUse = async (email: string) => {
    try {
      const { url, body } = API.user.post.checkExists;
      const { data: userExists } = await http.post(url, body({ email }));
      return userExists.exists && !userExists.sameWorkspace;
    } catch (error) {
      console.error("Error checking if email exists", error);
      return false;
    }
  };

  const isUserLastAdmin = (userId: string) => {
    const user = workspaceUsers.find((user) => user._id === userId);
    if (!user) return false;

    const isAdmin = user.permissionGroups.includes("admin");
    if (!isAdmin) return false;

    const otherAdmins = workspaceUsers.filter((user) => user.permissionGroups.includes("admin") && user._id !== userId);
    return otherAdmins.length === 0 && isDefaultGroup;
  };

  const onUserInvited = async (newInvite: UserInvitation) => {
    const isAlreadyInvited = newInvite.type !== "new" ? false : await isEmailInUse(newInvite.email);

    const isLastAdmin = newInvite.type === "existing" && isUserLastAdmin(newInvite._id);

    setUserInvitations((prev) => {
      if (prev.find((invite) => invite.email === newInvite.email)) return prev;
      const update = prev.concat({
        ...newInvite,
        isAlreadyInvited,
        isLastAdmin,
      });
      return update;
    });
  };

  const onUserRemoved = (index: number) => {
    setUserInvitations((prev) => prev.filter((_, i) => i !== index));
  };

  const onAddMembers = (userInvitations: UserInvitation[]) => {
    const userIds = userInvitations
      .filter((invite) => invite.type === "existing")
      .map((invite: ExistingUserInvitation) => invite._id);
    const emails = userInvitations.filter((invite) => invite.type === "new").map((invite) => invite.email);

    handleAddMembers(userIds);
    handleInviteMembers(emails);
    setShowAddMembersModal(false);
  };

  const canInviteUsers = useMemo(
    () => userInvitations.every((invite) => Boolean(invite.type) && !invite.isAlreadyInvited && !invite.isLastAdmin),
    [userInvitations]
  );

  return (
    <Modal show onHide={() => setShowAddMembersModal(false)} className={style.addMembersModal} centered>
      <Modal.Header className={style.header}>
        <h2>Add Teammates To Group</h2>

        <Close className={style.closeIcon} onClick={() => setShowAddMembersModal(false)} />
      </Modal.Header>
      <Modal.Body className={style.body}>
        <p>Users</p>
        <AddMembersInput
          onUserInvited={onUserInvited}
          onUserRemoved={onUserRemoved}
          disabled={false}
          currentUsers={currentUsers}
          invitedUserList={userInvitations}
          existingInvitedEmails={{}}
          currentUserEmail="" // todo
          className={style.addMembersInput}
        />
        {isDefaultGroup && (
          <div className={style.warning}>
            The <b>{groupName}</b> permission group is one of the <b>default groups (Admin, Editor, Commenter)</b>. If a
            user is currently in a default group, they'll be removed from their existing default group and placed in
            this one.
          </div>
        )}

        <ButtonPrimary
          data-testid="add-member-button"
          onClick={() => onAddMembers(userInvitations)}
          text="Add"
          disabled={!canInviteUsers}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddMembersModal;
