import ProgressCircle from "@/components/ProgressCircle/ProgressCircle";
import * as httpProject from "@/http/project";
import { ACTIVATION_PROGRESS_SEGMENT_COLORS, IProjectSummary } from "@shared/types/http/project";
import logger from "@shared/utils/logger";
import classNames from "classnames";
import { DittoComponent } from "ditto-react";
import React from "react";
import useDebouncedCallback from "./GroupDraft/state/useDebouncedCallback";
import style from "./ProjectSummarySection.module.css";

export type ProjectSummaryState =
  | { loading: true }
  | { loading: false; success: false; error: string }
  | { loading: false; success: true; data: IProjectSummary };

interface IProjectSummarySectionProps {
  summary: ProjectSummaryState;
}

export function ProjectSummarySection(props: IProjectSummarySectionProps) {
  if (props.summary.loading) {
    return <React.Fragment />;
  }

  if (!props.summary.success) {
    return <React.Fragment />;
  }

  const {
    wip: wipPercentComplete,
    review: reviewPercentComplete,
    final: finalPercentComplete,
  } = getProgressPercentagesByStatus(props.summary.data.statusProgress);

  const totalPercentComplete = wipPercentComplete + reviewPercentComplete + finalPercentComplete;

  return (
    <div className={style.container}>
      {props.summary.data.componentProgress && (
        <div className={style.componentsProgress}>
          <ProgressCircle hidePercentage className={style.circle} {...props.summary.data.componentProgress} />{" "}
          <div>
            <span className={classNames(style.text, style.componentsText)}>
              <DittoComponent
                componentId="percentages.componentized"
                variables={{ percentComplete: props.summary.data.componentProgress.segments[0].percentage }}
              />
            </span>
          </div>
        </div>
      )}
      <div className={style.statusProgress}>
        <ProgressCircle hidePercentage className={style.circle} {...props.summary.data.statusProgress} />
        <div>
          {(finalPercentComplete > 0 || totalPercentComplete === 0) && (
            <span className={classNames(style.text, style.finalText)}>
              <DittoComponent
                componentId="percentages.statusfinal"
                variables={{ percentComplete: finalPercentComplete }}
              />
            </span>
          )}
          {(reviewPercentComplete > 0 || totalPercentComplete === 0) && (
            <>
              <span className={classNames(style.text, style.inReviewText)}>
                {(finalPercentComplete > 0 || totalPercentComplete === 0) && ", "}
                <DittoComponent
                  componentId="percentages.statusinreview"
                  variables={{ percentComplete: reviewPercentComplete }}
                />
              </span>
            </>
          )}
          {(wipPercentComplete > 0 || totalPercentComplete === 0) && (
            <span className={classNames(style.text, style.wipText)}>
              {(finalPercentComplete > 0 || reviewPercentComplete > 0 || totalPercentComplete === 0) && ", "}
              <DittoComponent
                componentId="percentages.statusworkinprogress"
                variables={{ percentComplete: wipPercentComplete }}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export function useProjectSummaryState(projectId: string) {
  const [projectSummary, setProjectSummary] = React.useState<ProjectSummaryState>({
    loading: true,
  });

  const refreshProjectSummary = React.useCallback(
    function refreshProjectSummary() {
      if (!projectId) return;

      const [request, cancelRequest] = httpProject.getProjectSummaries({ projectIds: [projectId] });
      request
        .then(({ data: [summary] }) => setProjectSummary({ loading: false, success: true, data: summary }))
        .catch((e) => {
          setProjectSummary({ loading: false, success: false, error: e.message });
          logger.error("Failed to fetch project summary", { context: { projectId } }, e);
        });

      return cancelRequest;
    },
    [projectId]
  );

  // This function sometimes gets called from other functions that are executed
  // way more frequently than we should be; by debouncing it we can drastically
  // reduce the unnecessary amount of summaries we recompute.
  const refreshProjectSummaryDebounced = useDebouncedCallback(refreshProjectSummary, 300);

  React.useEffect(refreshProjectSummaryDebounced, [projectId]);

  return [projectSummary, refreshProjectSummaryDebounced] as const;
}

export const getProgressPercentagesByStatus = (data: { segments: { color: string; percentage: number }[] }) => ({
  wip: data.segments.find((segment) => segment.color === ACTIVATION_PROGRESS_SEGMENT_COLORS.WIP)?.percentage || 0,
  review: data.segments.find((segment) => segment.color === ACTIVATION_PROGRESS_SEGMENT_COLORS.REVIEW)?.percentage || 0,
  final: data.segments.find((segment) => segment.color === ACTIVATION_PROGRESS_SEGMENT_COLORS.FINAL)?.percentage || 0,
});
