import { client } from "@/http/lib/dittoClient";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";

export const { familyAtom: componentFolderFamilyAtom, resetAtom: resetComponentFamilyAtomActionAtom } =
  batchedAsyncAtomFamily<ILibraryComponentFolder>({
    asyncFetchRequest: async (get, ids) => {
      const data = await client.libraryComponentFolder.getLibraryComponentFolders({ folderIds: ids });
      return data.folders;
    },
    getId: (item) => item._id,
    debugPrefix: "Library Component Folder",
    throttleOptions: {
      leading: true,
    },
  });
