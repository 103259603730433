import { libraryCreateComponentModalIsOpenAtom, libraryCreateFolderModalIsOpenAtom } from "@/stores/Library";
import Text from "@ds/atoms/Text";
import DropdownMenu, { DropdownMenuItemType } from "@ds/molecules/DropdownMenu";
import AddIcon from "@mui/icons-material/Add";
import { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu";
import { useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import style from "./style.module.css";

const DROP_DOWN_TRIGGER_PROPS = { icon: <AddIcon /> };

const DROP_DOWN_PROPS: DropdownMenuContentProps = {
  align: "start",
};

function LibraryComponentsNavHeader() {
  const setLibraryCreateComponentModalIsOpen = useSetAtom(libraryCreateComponentModalIsOpenAtom);
  const setLibraryCreateFolderModalIsOpen = useSetAtom(libraryCreateFolderModalIsOpenAtom);

  const openCreateComponentModal = useCallback(() => {
    setLibraryCreateComponentModalIsOpen(true);
  }, [setLibraryCreateComponentModalIsOpen]);

  const openCreateFolderModal = useCallback(() => {
    setLibraryCreateFolderModalIsOpen(true);
  }, [setLibraryCreateFolderModalIsOpen]);

  const DROPDOWN_ITEMS: DropdownMenuItemType[] = useMemo(
    () => [
      {
        type: "option",
        text: "New Component",
        onClick: openCreateComponentModal,
      },
      {
        type: "option",
        text: "New Folder",
        onClick: openCreateFolderModal,
      },
    ],
    [openCreateComponentModal, openCreateFolderModal]
  );

  return (
    <div className={style.libraryComponentsNavHeaderContainer}>
      <Text size="small" weight="strong">
        All Components
      </Text>
      <DropdownMenu
        items={DROPDOWN_ITEMS}
        triggerProps={DROP_DOWN_TRIGGER_PROPS}
        RDropdownMenuContentProps={DROP_DOWN_PROPS}
      ></DropdownMenu>
    </div>
  );
}

export default LibraryComponentsNavHeader;
