import CloseIcon from "@mui/icons-material/Close";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { VariantSelect } from "@shared/frontend/VariantSelect";
import { ALL_VARIANTS_FOLDER_ID, useVariantSelect } from "@shared/frontend/useVariantSelect";
import { UserPermissionContext } from "@shared/frontend/userPermissionContext";
import { VARIANTS_NOT_IN_FOLDERS_ID } from "@shared/lib/PermissionGroups";
import { IVariant } from "@shared/types/Variant";
import React, { useContext, useEffect, useMemo, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import CreatableSelect from "react-select/creatable";
import { removeFigmaArtifactsFromGroupName } from "../../../shared/lib/groups";
import { ITextItemStatus } from "../../../shared/types/TextItem";
import http, { API } from "../../http";
import { VARIANT_STATUSES_ENABLED } from "../../utils/featureFlags";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import StatusSelect from "../StatusSelect";
import ButtonPrimary from "../button/buttonprimary";
import ModalInfoAlert from "../modal-info-alert";
import { VariantOption } from "../shared/VariantSelectInput";
import style from "./style.module.css";

interface IOption {
  label: string;
  value: string;
  __isNew__?: boolean;
}

type FrameVariant = {
  id: string;
  name: string;
};

interface VariantModalProps {
  doc_ID: string;
  frame_ID: string;
  frame_name: string;
  onHide: () => void;
  docVariants: FrameVariant[];
  frameVariants: FrameVariant[];
  workspaceVariants: {
    [variantId: string]: string;
  };
  handleAddNewVariants: (
    frameId: string,
    variants: FrameVariant[],
    folderId: string | null,
    applyToAllGroups: boolean,
    status?: ITextItemStatus
  ) => void;
  isSampleProject: boolean;
}

type StatusType = ITextItemStatus | "DEFAULT";

const VariantModal = ({
  frame_ID,
  doc_ID,
  frame_name,
  onHide,
  docVariants,
  frameVariants,
  workspaceVariants,
  handleAddNewVariants,
  isSampleProject,
}: VariantModalProps) => {
  const {
    doc: [doc],
  } = useProjectContext();
  const [variantOptions, setVariantOptions] = useState<{ label: string; value: string }[]>([]);
  const [availableVariants, setAvailableVariants] = useState<IVariant[]>([]);
  const [selectedVariants, setSelectedVariants] = useState<FrameVariant[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<StatusType>("DEFAULT");

  const [applyToAllGroups, setApplyToAllGroups] = useState(false);

  const { userHasResourcePermission } = useContext(UserPermissionContext);

  const fetchVariantFolders = async () => {
    const { data } = await http.get(API.variantFolder.get.folders.url);

    return data
      .filter((folder) => {
        if (!doc?.isSample) return !folder.isSample;
        return folder.isSample;
      })
      .map((folder) => ({
        _id: folder._id,
        name: folder.name,
        variantIds: folder.variantIds,
      }));
  };

  const variantSelect = useVariantSelect(fetchVariantFolders, {
    // this keeps the persisted option unique across projects
    key: `variant-select-${doc_ID}`,
    showUsedInProjectOption: !isSampleProject,
    showAllVariantsOption: !isSampleProject,
  });
  const selectedVariantFilter = variantSelect.selectedVariantFolder._id;

  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#B7B7B8",
      };
    },
  };

  const validateAbilityCreateVariant = (inputValue) => {
    const folderId =
      selectedVariantFilter === ALL_VARIANTS_FOLDER_ID ? VARIANTS_NOT_IN_FOLDERS_ID : selectedVariantFilter;

    const hasEditAccessToFolder = userHasResourcePermission("variant_folder:edit", folderId);

    const uniqueVariants = Object.values(variantOptions).map((variant) => variant.label.toLowerCase());
    return (
      hasEditAccessToFolder &&
      !uniqueVariants.includes(inputValue.toLowerCase()) &&
      inputValue &&
      inputValue.length > 0 &&
      !selectedVariants.some((variant) => variant.name === inputValue)
    );
  };

  const onSubmit = () => {
    if (!selectedVariants.length) return;

    const folderId =
      selectedVariantFilter === ALL_VARIANTS_FOLDER_ID || selectedVariantFilter === "in-this-project"
        ? null
        : selectedVariantFilter;

    // if the user wants new variants to have the same status as their base text, just don't send the
    // param to the backend; that's the default behavior.
    const statusToAdd = status === "DEFAULT" ? undefined : status;
    handleAddNewVariants(frame_ID, selectedVariants, folderId, applyToAllGroups, statusToAdd);
    onHide();
  };

  const workspaceVariantsByName = useMemo(() => new Set(Object.values(workspaceVariants)), [workspaceVariants]);

  const handleChange = (options: IOption[] | null) => {
    if (!options) {
      setSelectedVariants([]);
      return;
    }

    const variants: FrameVariant[] = [];
    let existingNewOptionEncountered = false;

    options.forEach((o) => {
      if (o.__isNew__ && workspaceVariantsByName.has(o.label)) {
        existingNewOptionEncountered = true;
        return;
      }

      // react-select messed up and kept `Create "${o.label}"` as an option
      // remove Create "" automatically
      if (o.label.match(/^Create ".*"$/)) {
        const sanitizedName = o.label.substring(8, o.label.length - 1);
        variants.push({
          id: o.__isNew__ ? "__new__" : o.value,
          name: sanitizedName,
        });
      } else {
        variants.push({
          id: o.__isNew__ ? "__new__" : o.value,
          name: o.label,
        });
      }
    });

    setError(existingNewOptionEncountered ? "That variant already exists in your workspace." : null);

    setSelectedVariants(variants);
  };

  function handleStatusChange(status: ITextItemStatus) {
    setStatus(status);
  }

  const fetchAvailableVariants = async () => {
    const { data } = await http.get(API.variant.get.getVariantsForWorkspace.url);

    let variants = data.filter((variant) => {
      if (!doc?.isSample) return !variant.isSample;
      return variant.isSample;
    });

    setAvailableVariants(variants);
  };

  useEffect(function fetchVariantsOnMount() {
    fetchAvailableVariants();
  }, []);

  useEffect(() => {
    const preExisting = {};
    frameVariants.forEach((variant) => (preExisting[variant.id] = true));

    const options = availableVariants
      .filter((variant) => {
        const variantId = variant._id;
        if (selectedVariantFilter === ALL_VARIANTS_FOLDER_ID) {
          return true;
        }
        if (selectedVariantFilter === "in-this-project") {
          return Object.keys(docVariants).includes(variantId);
        } else {
          return variantSelect.selectedVariantFolder.variantIds?.includes(variantId);
        }
      })
      .filter((variant) => !preExisting[variant._id] && workspaceVariants[variant._id])
      .map((variant) => ({
        value: variant._id,
        label: workspaceVariants[variant._id],
        description: variant.description,
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    setVariantOptions(options);
    setError(null);
  }, [frameVariants, docVariants, selectedVariantFilter, availableVariants]);

  const getPlaceholderText = () => {
    if (selectedVariantFilter === ALL_VARIANTS_FOLDER_ID) {
      return "Create or add an existing variant...";
    }
    if (selectedVariantFilter === "in-this-project") {
      return "Select a variant used in this project...";
    }
  };

  const headerTitle = (
    <>
      Add Variants for{" "}
      {applyToAllGroups ? (
        <>{doc?.groups.length || "all"} Frames</>
      ) : (
        <span>{removeFigmaArtifactsFromGroupName(frame_name)}</span>
      )}
    </>
  );

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>{headerTitle}</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <ModalInfoAlert
            className={style.infoAlert}
            linkUrl="https://www.dittowords.com/docs/variants"
            text="Want tips on how you can use variants in Ditto for explorations,
              user groups, or localization?"
          />
          <p className={style.createOrSelectMessage}>Create or select existing variants to add to this frame.</p>
          <VariantSelect
            {...variantSelect}
            classNameDropdownToggle={style.dropdownToggle}
            options={variantSelect.folders.map((f) => ({
              id: f._id,
              name: f.name,
            }))}
          />
          <CreatableSelect
            isMulti
            isClearable
            placeholder={getPlaceholderText()}
            onChange={handleChange}
            onInputChange={() => {}}
            options={variantOptions}
            className={style.dropdown}
            isValidNewOption={validateAbilityCreateVariant}
            styles={colourStyles}
            value={selectedVariants.map((v) => ({
              label: v.name,
              value: v.id,
              __isNew__: v.id === "__new__",
            }))}
            components={{
              Option: VariantOption,
            }}
          />{" "}
          {VARIANT_STATUSES_ENABLED && (
            <div className={style.statusRow}>
              <StatusSelect showDefaultOption status={status} handleStatusChange={handleStatusChange} />
            </div>
          )}
          {error && <div className={style.error}>{error}</div>}
          {isSampleProject && selectedVariants.some((v) => v.id === "__new__") && (
            <div className={style.sampleInfo}>
              <div className={style.sampleInfoIcon}>
                <InfoOutlined fontSize="inherit" />
              </div>
              &nbsp;Creating new variants is disabled in the sample project.
            </div>
          )}
          {(doc?.groups.length || 0) > 1 && (
            <div className={style.allFramesVariantSelect}>
              <input
                type="checkbox"
                checked={applyToAllGroups}
                onClick={() => setApplyToAllGroups(!applyToAllGroups)}
              />
              <span>Add variant to all {doc?.groups.length} frames in this project</span>
            </div>
          )}
          <span className={style.form}>
            <ButtonPrimary
              data-testid="component-new-variant-modal-add-variant-btn"
              text={"Add Variant"}
              disabled={
                !selectedVariants.length || (isSampleProject && selectedVariants.some((v) => v.id === "__new__"))
              }
              onClick={onSubmit}
            />
          </span>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default VariantModal;
