import { z } from "zod";

export const ZGetStructureOfFigmaFramesRequest = {
  params: z.object({
    figmaFileId: z.string(),
  }),
  body: z.object({
    frameIds: z.array(z.string()),
  }),
};
export const ZGetStructureOfFigmaFramesResponse = z.unknown();

export const ZGenerateSetupSuggestionsForAllGroupsRequest = {
  body: z.object({
    projectId: z.string(),
    selectedGroups: z.array(z.string()).optional(),
  }),
};
export const ZGenerateSetupSuggestionsForAllGroupsResponse = z.unknown();

export const ZUpdateSelectedPagesRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    pagesToRemove: z.array(z.string()),
    pagesToAdd: z.array(z.object({ figma_id: z.string(), name: z.string() })),
  }),
};
export const ZUpdateSelectedPagesResponse = z.unknown();

export const ZGetAllTagsRequest = {
  params: z.object({
    doc_id: z.string(),
  }),
  query: z.object({
    matchingTags: z.string().optional(),
  }),
};
export const ZGetAllTagsResponse = z.unknown();

export const ZDuplicateDraftProjectRequest = {
  params: z.object({
    project_id: z.string(),
  }),
};
export const ZDuplicateDraftProjectResponse = z.unknown();
