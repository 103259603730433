import UndoIcon from "@mui/icons-material/Undo";
import { PropagationWrapper } from "@shared/frontend/PropagationWrapper";
import { IComponent } from "@shared/types/Component";
import { PopulatedAttachSuggestion } from "@shared/types/ComponentSuggestions";
import { default as classNames, default as classnames } from "classnames";
import { Change, diffChars } from "diff";
import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import { useHasProjectFeatureFlag } from "../../../hooks/useHasProjectFeatureFlag";
import useRenderedLinks, { getRenderedLinks } from "../../../hooks/useRenderedLinks";
import RichTextRender from "../../RichTextRender";
import TextItem from "../../TextItem";
import { ActualComponentInterface } from "../../componentmodal/types";
import SuggestionPagination from "../SuggestionPagination";
import style from "./style.module.css";

interface ComponentAttachSuggestionsProps {
  compMap: { [key: string]: ActualComponentInterface };
  isEnabled: boolean;
  isSelected: boolean;
  multiSelectedSuggestions: PopulatedAttachSuggestion<string>[];
  handleMultiSelect: (suggestion_id: string) => void;
  index: number;
  suggestion: PopulatedAttachSuggestion<string>;
  handleSelected: (index: number) => void;
  handleIgnoreSuggestion: (id: string, type: string, ignored: boolean) => void;
  pageThroughDupes: (id: string | null) => void;
  handleMultiAttach: (args: {
    wsComp: IComponent;
    comp_ids: string[];
    isCreate: boolean;
    suggestionId?: string;
  }) => Promise<void>;
}

const ComponentAttachSuggestion = (props: ComponentAttachSuggestionsProps) => {
  const {
    compMap,
    isEnabled,
    isSelected,
    multiSelectedSuggestions,
    handleMultiSelect,
    index,
    suggestion,
    handleSelected,
    pageThroughDupes,
    handleMultiAttach,
    handleIgnoreSuggestion,
  } = props;

  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [isActiveHover, setIsActiveHover] = useState(false);
  const [diff, setDiff] = useState<{
    ws: Change[];
    comp: Change[];
  }>({
    ws: [],
    comp: [],
  });

  const { ignored, wsComp: comp, rating, text, variables, comp_ids } = suggestion;

  const wsComp = comp.instances[0];

  const RICH_TEXT_ENABLED = useHasProjectFeatureFlag("rich_text");

  const formattedWsCompNotes = useRenderedLinks(wsComp.notes || "");

  const formattedCompMapNotes = useMemo(() => {
    let formatted = {};
    for (const [key, value] of Object.entries(compMap)) {
      if (!!value.notes) {
        formatted[key] = getRenderedLinks(value.notes);
      }
    }
    return formatted;
  }, [compMap]);

  const getDiff = () => {
    const diff = {
      comp: diffChars(wsComp.text, text),
      ws: diffChars(text, wsComp.text),
    };

    setDiff(diff);
  };

  const decrementIndex = (e: React.PointerEvent) => {
    e.stopPropagation();
    if (suggestionIndex > 0) {
      let temp = suggestionIndex - 1;
      setSuggestionIndex(temp);

      pageThroughDupes(compMap[comp_ids[temp]]._id);
    }
  };
  const incrementIndex = (e: React.PointerEvent) => {
    e.stopPropagation();
    if (suggestionIndex < comp_ids.length - 1) {
      let temp = suggestionIndex + 1;
      setSuggestionIndex(temp);
      pageThroughDupes(compMap[comp_ids[temp]]?._id);
    }
  };

  const onAttach = (e) => {
    e.stopPropagation();
    if (suggestion.ignored) return;
    setSuggestionIndex(0);
    handleMultiAttach({ wsComp: comp, comp_ids, isCreate: false, suggestionId: suggestion._id });
  };

  const onIgnoreSuggestion = (e) => {
    e.stopPropagation();
    handleIgnoreSuggestion(suggestion._id, "attachable", !suggestion.ignored);
  };

  const onIgnoreSuggestionBtnMouseEnter = () => {
    setIsActiveHover(true);
  };
  const onIgnoreSuggestionBtnMouseLeave = () => {
    setIsActiveHover(false);
  };

  const onSelect = () => {
    if (ignored) return;
    handleSelected(index);
    if (!isSelected && compMap[comp_ids[0]]) pageThroughDupes(compMap[comp_ids[0]]._id);
    else {
      setSuggestionIndex(0);
      pageThroughDupes(null);
    }
  };

  const onCheckClick = (e) => {
    e.stopPropagation();
    handleMultiSelect(suggestion._id);
  };

  const compStatus = compMap.hasOwnProperty(comp_ids[0]) ? compMap[comp_ids[0]].status : "NONE";

  useEffect(() => {
    getDiff();
  }, [suggestionIndex]);

  const isMultiSelected = multiSelectedSuggestions.some((s) => s._id === suggestion._id);

  const anyMultiSelected = multiSelectedSuggestions.length > 0;

  return (
    <div
      className={classnames({
        [style.compSuggestion]: true,
        [style.isSelected]: isSelected,
        [style.isMultiSelected]: isMultiSelected,
        [style.isIgnored]: ignored && !isActiveHover,
        [style.activeIgnored]: ignored && isActiveHover,
      })}
      onClick={onSelect}
    >
      <div
        className={classnames({
          [style.suggestionInfo]: true,
          [style.isSelected]: isSelected,
        })}
      >
        {!isSelected ? (
          <div className={style.instanceInfo}>
            <div className={style.numberBadge}>{comp_ids.length}</div>
            {` instance${comp_ids.length > 1 ? "s" : ""} in project`}
          </div>
        ) : (
          <SuggestionPagination
            index={suggestionIndex}
            isSelected={isSelected}
            numItems={comp_ids.length}
            incrementIndex={incrementIndex}
            decrementIndex={decrementIndex}
            itemName={{ singular: "instance", plural: "instances" }}
          />
        )}

        {!ignored && (
          <PropagationWrapper>
            <input
              type="checkbox"
              checked={isMultiSelected}
              onChange={onCheckClick}
              className={classNames(style.multiSelectCheckbox, {
                [style.checked]: isMultiSelected,
              })}
            />
          </PropagationWrapper>
        )}
      </div>

      <div
        className={classnames({
          [style.suggestionInstance]: true,
          [style.activeSuggestion]: isSelected,
        })}
      >
        <div className={style.compSuggestionHeader} />
        <div
          className={classnames({
            [style.wsCompItem]: true,
            [style.cursor]: false,
          })}
        >
          <div
            className={classnames({
              [style.statusBar]: compStatus !== "NONE",
              [style.statusWip]: compStatus === "WIP",
              [style.statusReview]: compStatus === "REVIEW",
              [style.statusFinal]: compStatus === "FINAL",
            })}
          />
          <div className={style.top}>
            <div className={style.text}>
              {variables?.length > 0 ? (
                <TextItem textItem={{ text, variables }} frameVariant={null} />
              ) : (
                diff.comp.map((section, key) => {
                  return (
                    <span
                      key={key}
                      className={classnames({
                        [style.commonWsText]: !(section.added || section.removed),
                      })}
                    >
                      {(section.added || !section.removed) && section.value}
                    </span>
                  );
                })
              )}
            </div>
          </div>
          {compMap[comp_ids[suggestionIndex]] &&
            (compMap[comp_ids[suggestionIndex]].notes || compMap[comp_ids[suggestionIndex]].tags.length > 0) && (
              <div className={style.bottom}>
                {compMap[comp_ids[suggestionIndex]].notes && (
                  <div
                    className={classnames({
                      [style.marginBottom]: compMap[comp_ids[suggestionIndex]].tags.length > 0,
                    })}
                  >
                    {formattedCompMapNotes[comp_ids[suggestionIndex]]}
                  </div>
                )}
                {compMap[comp_ids[suggestionIndex]].tags.length > 0 && (
                  <div className={style.tags}>
                    {compMap[comp_ids[suggestionIndex]].tags.map((tag, index) => (
                      <div key={index} className={style.tag}>
                        {tag}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
        </div>
        <div className={style.suggestionInfo}>
          <span>Suggested component</span>
          <span>{rating}% text match</span>
        </div>
      </div>
      <div
        className={classnames({
          [style.wsCompItem]: true,
          [style.wsCompDraft]: comp.instances.length === 1 && !comp.instances[0].hasOwnProperty("doc_ID"),
        })}
      >
        <div className={style.wsCompTitle}>{comp.name}</div>
        <div
          className={classnames({
            [style.statusBar]: wsComp.status !== "NONE",
            [style.statusWip]: wsComp.status === "WIP",
            [style.statusReview]: wsComp.status === "REVIEW",
            [style.statusFinal]: wsComp.status === "FINAL",
          })}
        />
        <div className={style.top}>
          <div className={classnames([style.text, { [style.commonWsText]: RICH_TEXT_ENABLED }])}>
            {RICH_TEXT_ENABLED ? (
              <RichTextRender richText={wsComp.rich_text} renderProps={{ className: style.richTextContainer }} />
            ) : wsComp?.variables?.length > 0 ? (
              <TextItem textItem={wsComp} frameVariant={null} />
            ) : (
              diff.ws.map((section, key) => {
                return (
                  <span
                    key={key}
                    className={classnames({
                      [style.commonWsText]: !(section.added || section.removed),
                    })}
                  >
                    {(section.added || !section.removed) && section.value}
                  </span>
                );
              })
            )}
          </div>
        </div>
        {(wsComp.notes || wsComp.tags.length > 0) && (
          <div className={style.bottom}>
            {wsComp.notes && (
              <div
                className={classnames({
                  [style.notes]: true,
                  [style.marginBottom]: wsComp.tags.length > 0,
                })}
              >
                {formattedWsCompNotes}
              </div>
            )}
            {wsComp.tags.length > 0 && (
              <div className={style.tags}>
                {wsComp.tags.map((tag, index) => (
                  <div key={index} className={style.tag}>
                    {tag}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {!anyMultiSelected && (
        <div className={style.attachBtn}>
          <span
            className={classnames({
              [style.ignoreSuggestionBtn]: true,
              [style.disabled]: !isEnabled,
            })}
            onClick={onIgnoreSuggestion}
            onMouseEnter={onIgnoreSuggestionBtnMouseEnter}
            onMouseLeave={onIgnoreSuggestionBtnMouseLeave}
          >
            {ignored ? (
              <>
                <UndoIcon className={style.undoIcon} /> Unignore
              </>
            ) : (
              "Ignore"
            )}
          </span>
          {!ignored && (
            <Button onClick={onAttach} disabled={!isEnabled}>
              {comp_ids.length > 1 ? "Attach to all" : "Attach"}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ComponentAttachSuggestion;
