import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { atom } from "jotai";
import { atomWithLocation } from "jotai-location";

const SEARCH_PARAM = "search";
const DESIGN_PREVIEW_PARAM = "designPreview";

export const locationAtom = atomWithLocation();

/**
 * Atom that represents the search query in the URL.
 */
export const searchAtom = atomWithURLStorage(SEARCH_PARAM, locationAtom, { isString: true });

/**
 * Atom that represents whether the user is currently searching.
 */
export const isSearchingAtom = atom((get) => get(searchAtom)?.length || 0 > 0);

export const designPreviewURLAtom = atomWithURLStorage(DESIGN_PREVIEW_PARAM, locationAtom, { isString: true });

export const designPreviewToggledAtom = atom(
  (get) => {
    const urlValue = get(designPreviewURLAtom);
    return urlValue === "true";
  },
  (_get, set, newValue: boolean) => {
    if (newValue) {
      set(designPreviewURLAtom, "true");
    } else {
      set(designPreviewURLAtom, null);
    }
  }
);
