import { ZABPlatformIntegrationModalItem } from "@shared/types/VariantGroup";
import z from "zod";

export const ZABPlatformSyncData = z.array(
  ZABPlatformIntegrationModalItem.omit({
    flagName: true,
    syncedVariants: true,
    lockedVariants: true,
  }).extend({
    syncedVariants: z.array(z.string()),
  })
);
