import { z } from "zod";

import * as WritingTypes from "../../../shared/types/writing";

export const ZPerformAIRequest = {
  body: z.object({
    action: z.enum(WritingTypes.ACTIONS),
    text: z.string(),
  }),
};
export const ZPerformAIResponse = z.unknown();
