import { IComponent } from "@shared/types/Component";
import { ActualComponentInterface } from "@shared/types/TextItem";
import { Populate } from "@shared/types/lib";
import { useCallback, useMemo, useRef, useState } from "react";

export type ComponentInstance = Populate<
  ActualComponentInterface<string>,
  "doc_ID",
  {
    _id: string;
    doc_name: string;
  }
>;

export type Component = Populate<IComponent, "instances", ComponentInstance[]>;

export interface SingleSelection {
  type: "single";
  selectedId: string;
  selectedComponent: Component;
}

export interface MultiSelection {
  type: "multi";
  selectedIds: string[];
  selectedComponents: Component[];
}

export interface NoSelection {
  type: "none";
}

export type SelectionState = SingleSelection | MultiSelection | NoSelection;

export const useSelectionState = (componentsRef: { current: Component[] }) => {
  const [_state, setState] = useState<SelectionState>({ type: "none" });

  const selectionRangeAnchor = useRef(-1);
  const selectionRangeHead = useRef(-1);

  /**
   * Moves the selection anchor to the specified position. Since the head
   * is defined relative to the anchor, whenever the anchor is moved,
   * the head needs to be reset.
   */
  const moveAnchor = useCallback((newAnchor: number) => {
    selectionRangeAnchor.current = newAnchor;
    selectionRangeHead.current = -1;
  }, []);

  const moveAnchorToComponent = useCallback(
    (componentId: string) => {
      const components = componentsRef.current;

      const selectedComponentIndex = components.findIndex((c) => c._id === componentId);
      if (selectedComponentIndex === -1) {
        return;
      }

      moveAnchor(selectedComponentIndex);
    },
    [moveAnchor]
  );

  /**
   * Resets the selection anchor to an undefined position. Since the head
   * is defined relative to the anchor, whenever the anchor is moved,
   * the head needs to be reset.
   */
  const resetAnchor = useCallback(() => {
    selectionRangeAnchor.current = -1;
    selectionRangeHead.current = -1;
  }, []);

  /**
   * Resets the selection head to an undefined position.
   */
  const resetHead = useCallback(() => {
    selectionRangeHead.current = -1;
  }, []);

  /**
   * Selects a single component and moves the selection anchor
   * to that component's index.
   *
   * Can be called with a `componentId` that is already
   * selected to force a refresh of the component's data from the cache.
   */
  const selectComponent = useCallback(
    (componentId: string) => {
      const components = componentsRef.current;

      let selectedComponent: Component | null = null;
      let selectedComponentIndex: number = -1;
      for (let i = 0; i < components.length; i++) {
        const c = components[i];
        if (c._id === componentId) {
          selectedComponent = c;
          selectedComponentIndex = i;
          break;
        }
      }

      if (!selectedComponent) {
        console.info(`Failed to find component`, { componentId });
        return;
      }

      moveAnchor(selectedComponentIndex);

      setState({
        type: "single",
        selectedId: componentId,
        selectedComponent,
      });
    },
    [moveAnchor]
  );

  /**
   * Selects an array of components. Does not handle changing the selection anchor
   * or the selection head, as the way with which those should be changed depend on
   * the manner in which the selection is performed, and the anchor/head may be on
   * either side of the provided array.
   *
   * Can be called with `componentIds` that are already
   * selected to force a refresh of the components' data from the cache.
   */
  const selectComponents = useCallback(
    (componentIds: string[]) => {
      const componentIdSet = componentIds.reduce((s, id) => s.add(id), new Set<string>());

      const selectedComponents = componentsRef.current.filter((c) => componentIdSet.has(c._id));

      if (selectedComponents.length !== componentIdSet.size) {
        console.warn(`Failed to select some components`, {
          componentIds,
          selectedComponents,
        });
      }

      setState({
        type: "multi",
        selectedIds: componentIds,
        selectedComponents,
      });
    },
    [setState]
  );

  const refreshSelection = useCallback(() => {
    setState((s) => {
      if (s.type === "none") {
        return s;
      }

      if (s.type === "single") {
        const component = componentsRef.current.find((c) => c._id === s.selectedId);
        if (!component) {
          return s;
        }

        return {
          ...s,
          selectedComponent: component,
        };
      }

      if (s.type === "multi") {
        const set = s.selectedIds.reduce((se, id) => se.add(id), new Set<string>());
        const components = componentsRef.current.filter((c) => set.has(c._id));
        if (components.length !== s.selectedIds.length) {
          return s;
        }

        return {
          ...s,
          selectedComponents: components,
        };
      }

      throw new Error("Couldn't refresh invalid selection state");
    });
  }, [setState]);

  /**
   * Deselects all components and resets the selection anchor.
   */
  const deselectAll = useCallback(() => {
    resetAnchor();
    setState({ type: "none" });
  }, [resetAnchor]);

  const recomputeAnchor = useCallback(() => {
    const components = componentsRef.current;
    if (_state.type === "none") return;

    const anchorComponent = _state.type === "single" ? _state.selectedComponent : _state.selectedComponents[0];

    let selectedComponentIndex: number = -1;
    for (let i = 0; i < components.length; i++) {
      const c = components[i];
      if (c._id === anchorComponent._id) {
        selectedComponentIndex = i;
        break;
      }
    }

    selectionRangeAnchor.current = selectedComponentIndex;
  }, [_state]);

  const actions = useMemo(
    () => ({
      /**
       * Selects a single component and moves the selection anchor
       * to that component's index.
       *
       * Can be called with a `componentId` that is already
       * selected to force a refresh of the component's data from the cache.
       */
      selectComponent,
      /**
       * Selects an array of components. Does not handle changing the selection anchor
       * or the selection head, as the way with which those should be changed depend on
       * the manner in which the selection is performed, and the anchor/head may be on
       * either side of the provided array.
       *
       * Can be called with `componentIds` that are already
       * selected to force a refresh of the components' data from the cache.
       */
      selectComponents,
      /**
       * Deselects all components and resets the selection anchor.
       */
      deselectAll,
      /**
       * Refreshes the current selection from the cache, if it exists. Noops otherwise.
       */
      refreshSelection,
      recomputeAnchor,
    }),
    [selectComponent, selectComponents, deselectAll, refreshSelection, recomputeAnchor]
  );

  const state = useMemo(
    () => ({
      ...(_state || undefined),
      hasSelection: () => _state.type !== "none",
      hasSingleSelection: () => _state.type === "single",
      hasMultiSelection: () => _state.type === "multi",
      componentIsSelected: (componentId: string) => {
        if (_state.type === "single") {
          return componentId === _state.selectedId;
        }

        if (_state.type === "multi") {
          return _state.selectedIds.includes(componentId);
        }

        return false;
      },
      range: {
        anchor: selectionRangeAnchor,
        head: selectionRangeHead,
        resetAnchor,
        resetHead,
        moveAnchor,
        moveAnchorToComponent,
        moveHead: (newHead: number) => (selectionRangeHead.current = newHead),
        hasValidAnchor: () => selectionRangeAnchor.current !== -1,
        hasValidHead: () => selectionRangeHead.current !== -1,
      },
    }),
    [_state, moveAnchor, moveAnchorToComponent, resetAnchor, resetHead]
  );

  return [state, actions] as const;
};
