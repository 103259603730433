import classNames from "classnames";
import React, { useCallback, useState } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import Tooltip from "../../molecules/Tooltip";
import LibraryComingSoon, { ILibraryComingSoonProps } from "../LibraryComingSoon";
import style from "./index.module.css";

interface ILibrarySectionProps extends Omit<ILibraryComingSoonProps, "layout"> {
  useComingSoon: boolean;
  className?: string;
  dividerAbove?: boolean;
  dividerBelow?: boolean;
  onClickPublish?: () => void;
  onClickLink?: () => void;
}

export function LibrarySection({
  className,
  dividerAbove,
  dividerBelow,
  onClickPublish,
  onClickLink,
  useComingSoon,
  ...libraryComingSoonProps
}: ILibrarySectionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickPublish = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
    onClickPublish?.();
  }, [onClickPublish]);

  const handleClickLink = useCallback(() => {
    if (useComingSoon) {
      setIsOpen((isOpen) => !isOpen);
    }
    onClickLink?.();
  }, [onClickLink, useComingSoon]);

  return (
    <div
      className={classNames(style.container, className, {
        [style.borderTop]: dividerAbove,
        [style.borderBottom]: dividerBelow,
      })}
      data-testid="library-section"
    >
      <div className={style.header}>
        <div>
          <Text size="small" color="primary" weight="strong">
            Library
          </Text>
        </div>
        <div className={style.buttonsContainer}>
          <Tooltip content="Publish to library..." side="left" type="invert">
            <Button level="subtle" type="icon" onClick={handleClickPublish}>
              <AddToLibraryIcon />
            </Button>
          </Tooltip>
          <Tooltip content="Link to library component..." side="left" type="invert">
            <Button level="subtle" type="icon" onClick={handleClickLink}>
              <LibraryComponentLinkIcon />
            </Button>
          </Tooltip>
        </div>
      </div>
      {isOpen && <LibraryComingSoon layout="horizontal" {...libraryComingSoonProps} />}
    </div>
  );
}

export function AddToLibraryIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.096 14.4076C12.6213 14.5196 12.1493 14.6876 11.68 14.9116V8.60757C12.1173 8.35157 12.5813 8.15957 13.072 8.03157C13.5627 7.90357 14.0587 7.83957 14.56 7.83957C14.944 7.83957 15.3253 7.8769 15.704 7.95157C16.0827 8.02624 16.448 8.13824 16.8 8.28757V10.3996H18.08V7.90357C18.08 7.78624 18.0507 7.67424 17.992 7.56757C17.9333 7.4609 17.8453 7.3809 17.728 7.32757C17.2267 7.08224 16.712 6.8929 16.184 6.75957C15.656 6.62624 15.1147 6.55957 14.56 6.55957C13.9413 6.55957 13.336 6.63957 12.744 6.79957C12.152 6.95957 11.584 7.19957 11.04 7.51957C10.496 7.19957 9.928 6.95957 9.336 6.79957C8.744 6.63957 8.13867 6.55957 7.52 6.55957C6.96533 6.55957 6.424 6.62624 5.896 6.75957C5.368 6.8929 4.85333 7.08224 4.352 7.32757C4.23467 7.3809 4.14667 7.4609 4.088 7.56757C4.02933 7.67424 4 7.78624 4 7.90357V15.6156C4 15.8609 4.104 16.0476 4.312 16.1756C4.52 16.3036 4.736 16.3089 4.96 16.1916C5.35467 15.9782 5.768 15.8129 6.2 15.6956C6.632 15.5782 7.072 15.5196 7.52 15.5196C8.16 15.5196 8.77867 15.6316 9.376 15.8556C9.97333 16.0796 10.528 16.3942 11.04 16.7996C11.552 16.3942 12.1067 16.0796 12.704 15.8556C12.9975 15.7455 13.2962 15.6625 13.6 15.6065V14.3104C13.4304 14.3359 13.2624 14.3683 13.096 14.4076Z"
        fill="#A3A3A3"
      />
      <path
        d="M14.88 15.5196H16.8V17.4396H18.08V15.5196H20V14.2396H18.08V12.3196H16.8V14.2396H14.88V15.5196Z"
        fill="#A3A3A3"
      />
    </svg>
  );
}

export function LibraryComponentLinkIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4051_20593" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
        <rect x="4.5" y="4.5" width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4051_20593)">
        <path
          d="M16.375 17.625C15.9687 17.625 15.6042 17.5078 15.2812 17.2734C14.9583 17.0391 14.7344 16.7396 14.6094 16.375H11.375C10.6875 16.375 10.099 16.1302 9.60938 15.6406C9.11979 15.151 8.875 14.5625 8.875 13.875C8.875 13.1875 9.11979 12.599 9.60938 12.1094C10.099 11.6198 10.6875 11.375 11.375 11.375H12.625C12.9688 11.375 13.263 11.2526 13.5078 11.0078C13.7526 10.763 13.875 10.4688 13.875 10.125C13.875 9.78125 13.7526 9.48698 13.5078 9.24219C13.263 8.9974 12.9688 8.875 12.625 8.875H9.39063C9.25521 9.23958 9.02865 9.53906 8.71094 9.77344C8.39323 10.0078 8.03125 10.125 7.625 10.125C7.10417 10.125 6.66146 9.94271 6.29688 9.57812C5.93229 9.21354 5.75 8.77083 5.75 8.25C5.75 7.72917 5.93229 7.28646 6.29688 6.92188C6.66146 6.55729 7.10417 6.375 7.625 6.375C8.03125 6.375 8.39323 6.49219 8.71094 6.72656C9.02865 6.96094 9.25521 7.26042 9.39063 7.625H12.625C13.3125 7.625 13.901 7.86979 14.3906 8.35937C14.8802 8.84896 15.125 9.4375 15.125 10.125C15.125 10.8125 14.8802 11.401 14.3906 11.8906C13.901 12.3802 13.3125 12.625 12.625 12.625H11.375C11.0312 12.625 10.737 12.7474 10.4922 12.9922C10.2474 13.237 10.125 13.5312 10.125 13.875C10.125 14.2188 10.2474 14.513 10.4922 14.7578C10.737 15.0026 11.0312 15.125 11.375 15.125H14.6094C14.7448 14.7604 14.9714 14.4609 15.2891 14.2266C15.6068 13.9922 15.9687 13.875 16.375 13.875C16.8958 13.875 17.3385 14.0573 17.7031 14.4219C18.0677 14.7865 18.25 15.2292 18.25 15.75C18.25 16.2708 18.0677 16.7135 17.7031 17.0781C17.3385 17.4427 16.8958 17.625 16.375 17.625ZM7.625 8.875C7.80208 8.875 7.95052 8.8151 8.07031 8.69531C8.1901 8.57552 8.25 8.42708 8.25 8.25C8.25 8.07292 8.1901 7.92448 8.07031 7.80469C7.95052 7.6849 7.80208 7.625 7.625 7.625C7.44792 7.625 7.29948 7.6849 7.17969 7.80469C7.0599 7.92448 7 8.07292 7 8.25C7 8.42708 7.0599 8.57552 7.17969 8.69531C7.29948 8.8151 7.44792 8.875 7.625 8.875Z"
          fill="#A3A3A3"
        />
      </g>
    </svg>
  );
}

export default LibrarySection;
