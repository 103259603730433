import useSegment from "@/hooks/useSegment";
import * as SegmentEvents from "@shared/segment-event-names";
import { Ditto } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import ResourceRow from "./resourceRow";
import style from "./style.module.css";

export interface Props {
  resources: { resourceDittoComponentId: string; value: number; limit?: number }[];
  onUpgradePlanClicked?: () => void;
}

export default function ResourcesPanel(props: Props) {
  const segment = useSegment();

  const upgradePlan = () => {
    segment.track({
      event: SegmentEvents.UPGRADE_PLAN_LINK_CLICKED,
      properties: { location: "resources panel", application: "web_app" },
    });
    props.onUpgradePlanClicked?.();
  };

  return (
    <Panel titleDittoComponentId="resources">
      <div className={style.container}>
        <table className={style.table}>
          <tbody>
            <tr className={style.divider}>
              <td>
                <div className={style.header}>
                  <Ditto componentId="resource" />
                </div>
              </td>
              <td>
                <div className={style.headerValue}>
                  <Ditto componentId="quantity" />
                </div>
              </td>
            </tr>
            {props.resources.map((resource, index) => (
              <ResourceRow
                key={resource.resourceDittoComponentId}
                resourceDittoComponentId={resource.resourceDittoComponentId}
                value={resource.value}
                limit={resource.limit}
                divider={index < props.resources.length - 1}
                onUpgradePlanClicked={upgradePlan}
              />
            ))}
          </tbody>
        </table>
      </div>
    </Panel>
  );
}
