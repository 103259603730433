import GroupIcon from "@mui/icons-material/Group";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { IRole } from "@shared/types/User";
import React from "react";
import ReactTooltip from "react-tooltip";
import UserAvatar from "./UserAvatar";
import style from "./style.module.css";

interface InvitedUser {
  type: "invited";
  email: string;
  role: string;
  _id: string;
}

interface ExistingUser {
  type: "existing";
  name: string;
  email: string;
  role: string;
  userId: string;
  _id: string;
}

export type UserWithAccess = InvitedUser | ExistingUser;

interface Props {
  currentUser: {
    _id: string;
    name: string;
    email: string;
    role: IRole;
  };
  usersWithAccess: UserWithAccess[];
  onUserRemoved: (email: string) => void;
  allowSelfRemoval: boolean;
}

const FolderAccessList = (props: Props) => {
  const { currentUser, usersWithAccess, onUserRemoved, allowSelfRemoval } = props;

  const canRemoveUser = (user: UserWithAccess) => {
    if (user._id === currentUser._id) return allowSelfRemoval;
    if (currentUser.role === "admin") return true;
    if (currentUser.role === "editor" && user?.role !== "admin") return true;
    return user?.role === "commenter";
  };

  const getRemoveBtnText = (user: UserWithAccess) => {
    if (user._id === currentUser._id) {
      return "Leave";
    }

    if (user.type === "invited") {
      return "Revoke Invite";
    }

    return "Remove";
  };

  return (
    <div className={style.container}>
      <div className={style.title}>
        <GroupIcon className={style.groupIcon} />
        All admins of this workspace
        <ReactTooltip id="admin-info" place="bottom" effect="solid">
          Admins of a workspace automatically have access to all invite-only folders.
        </ReactTooltip>
        <InfoIcon className={style.infoIcon} data-tip data-for="admin-info" />
      </div>
      <div className={style.userList}>
        {usersWithAccess.map((user, index) => (
          <div key={index} className={style.row}>
            <div className={style.userCol}>
              <UserAvatar name={user.type === "existing" ? user.name : undefined} />
              <div className={style.userInfo}>
                <div className={style.userDetails}>
                  {user.type === "existing" && <div className={style.userName}>{user.name}</div>}
                  {user.type === "invited" && <div className={style.boldText}>{user.email}</div>}
                  <div className={style.userRole}>{user.role}</div>
                </div>
                {user?.type === "existing" && <div>{user.email}</div>}
              </div>
            </div>
            {canRemoveUser(user) && (
              <div className={style.removeBtn} onClick={() => onUserRemoved(user.email)}>
                {getRemoveBtnText(user)}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FolderAccessList;
