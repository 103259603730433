import useUserFlags from "@/hooks/useUserFlags";
import { getProjectGroups } from "@/http/project";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import { VARIANT_STATUSES_ENABLED } from "@/utils/featureFlags";
import ProjectWebsocketsHandler from "@/views/Project/components/ProjectWebsocketsHandler/ProjectWebsocketsHandler";
import * as Sentry from "@sentry/react";
import { RenderIfHasResourcePermission, userHasSomeResourceAccess } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { useDittoComponent } from "ditto-react";
import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import spinner from "../../assets/small-spinner.gif";
import MergeBranchModal from "../../components/MergeBranchModal";
import MergeSuccessModal from "../../components/MergeSuccessModal";
import OverlayToast from "../../components/OverlayToast";
import { useOverlayToast } from "../../components/OverlayToast/useOverlayToast";
import ResyncWarningToast from "../../components/ResyncWarningToast";
import DeleteProjModal from "../../components/deleteprojmodal/deleteprojmodal";
import PermissionRequiredProject from "../../components/permissions/PermissionRequired/PermissionRequiredProject";
import ToastNotification from "../../components/toast-notification/toast-notification";
import { PANELS, TOAST_TYPES, routes } from "../../defs";
import { FlaggedFeaturesContext } from "../../flagged-feature";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import * as httpComp from "../../http/comp";
import { pollingBackgroundJobRequest } from "../../http/lib/clientHelpers";
import { navRoutes } from "../../nav-routes";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import ActivateSampleProjectModal from "./components/ActivateSampleProjectModal";
import GroupNavigation from "./components/GroupNavigation";
import ManageGroupsModal from "./components/ManageGroupsModal";
import { ManageGroupsProvider } from "./components/ManageGroupsModal/ManageGroupsModalContext";
import ProjectDetail from "./components/ProjectDetail";
import ProjectHeader from "./components/ProjectHeader";
import {
  TextItem,
  createMultiSelectComponentIndex,
  multiSelectEnabledAtComponentLevel,
  multiSelectEnabledForComponent,
  setComponentMetadata,
} from "./components/ProjectMultiSelect";
import TextItemList from "./components/TextItemList";
import { GROUP_REDUCER_TYPES } from "./state/groupStateActions";
import { isGroupLinked, isGroupUnlinkable } from "./state/types";
import { UNSELECT_ALL_ATTRIBUTE } from "./state/unselectAll";
import { ALL_PAGE, ALL_PAGE_ID, DRAFTED_GROUPS_PAGE, DRAFTED_GROUPS_PAGE_ID } from "./state/usePageState";
import { PAGE_FRAME_LIMIT, ProjectProvider, useInitializeProjectState } from "./state/useProjectState";
import { getProjectHasFigmaConnection } from "./state/useResyncState";
import style from "./style.module.css";

const INTERCOM_TOUR_IDS = {
  EDITOR: 471350,
  COMMENTER: 471732,
  DEVELOPER: 471979,
};

const ProjectPage = () => {
  let { state } = useLocation();
  const segment = useSegment();
  const { getUserFlag, setUserFlag } = useUserFlags();
  const { users: workspaceUsers } = useWorkspace();
  const { user, loading, getTokenSilently } = useAuthenticatedAuth();
  const { checkIfProjectLocked } = useContext(FlaggedFeaturesContext);
  const {
    canSaveEdits: [canSaveEdits, setCanSaveEdits],
    unsavedDetailChangesExist,
    checkDetailPanelChanges,
    showUnsavedChangesModal,
  } = useContext(UnsavedChangesContext);
  const projectState = useInitializeProjectState();

  const [unauthorizedProjectAccess, setUnauthorizedProjectAccess] = useState(false);

  const [syncSettingsModalVisible, setSyncSettingsModalVisible] = useState(false);

  const [frameInfo, setFrameInfo] = useState({
    isNewFrame: false,
    frameId: null,
    fileID: null,
    appliedVariantId: null,
  });
  const [hidingAvailable, setHidingAvailable] = useState(true);
  const [job, setJob] = useState(user.job ?? "NONE");
  const [panelState, setPanelState] = useState(PANELS.doc.edit);
  const [commentState, setCommentState] = useState({
    isSelected: false,
    thread_id: null,
  });
  const [pollingForPreviewUpdates, setPollingForPreviewUpdates] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMultiSelectToast, setShowMultiSelectToast] = useState(false);
  const [showMultiAttachCompToast, setShowMultiAttachCompToast] = useState(false);
  const [showAutoAttachmentToast, setShowAutoAttachmentToast] = useState(false);

  const onShowAutoAttachmentToast = () => {
    setShowAutoAttachmentToast(true);
    setTimeout(() => setShowAutoAttachmentToast(false), 5000);
  };

  const [toastObj, setToastObj] = useState({});
  const [customToast, setCustomToast] = useState({
    show: false,
    message: null,
    icon: null,
  });

  const [allFrameApiIDs, setAllFrameApiIDs] = useState([]);

  const [goToMainProjectUrl, setGoToMainProjectUrl] = useState(null);

  const [showMergeSucessModal, setShowMergeSuccessModal] = useState(false);
  const [isBranchMerging, setIsBranchMerging] = useState(false);

  /*
   * The index of the last component selected with a non shift-click.
   */
  const multiSelectionStart = useRef(null);
  /*
   * The index of the last component selected with a shift-click.
   */
  const multiSelectionEnd = useRef(null);

  const [groupMeta, setGroupMeta] = useState({});

  const {
    projectId,
    doc: [doc, setDoc],
    folder: [folder, setFolder],
    groupState: [groupState, groupStateDispatch],
    selectedPage: [selectedPage, setSelectedPage],
    framePreviewsMap,
    setFramePreviewsMap,
    previewsJobId: [previewsJobId, setPreviewsJobId],
    selectedComp: [selectedComp, setSelectedComp],
    multiSelectedComps: [multiSelectedComps, setMultiSelectedComps],
    multiSelectedIds: [multiSelectedIds, setMultiSelectedIds],
    multiSelectOn,
    groupRenderState: {
      groups: { filteredGroups: filteredFrames, filteredComps: filteredCompIds, searchFilteredGroups },
    },
    displayApiIds: [displayApiIds, setDisplayApiIds],
    suggestedCompId: [suggestedCompId, setSuggestedCompId],
    updateDocHistory: [updateDocHistory, setUpdateDocHistory],
    selectedGroupId: [, setSelectedGroupId],
    selectedDraftGroupId: [selectedDraftGroupId, setSelectedDraftGroupId],
    quickReplyCommentState: [, setQuickReplyCommentState],
    docComponents: [docComponents, setDocComponents],
    highlightDraftTextItem,
    framePage: [framePage, setFramePage],
    setPageByTextItemId,
    handleScrollToTextItem,
    handleInitialResync,
    resync,
    resyncLoading,
    resyncToast,
    showResyncSucessToast,
    RESYNC_WARNING_KEY,
    showResyncWarning,
    setShowResyncWarning,
    showHighlightNew,
    setShowHighlightNew,
    showResyncDeletionToast,
    updateFramePreviewsMap,
    getDocVariants,
    fetchProjectBranchInfo,
    projectBranchInfo: [projectBranchInfo, setProjectBranchInfo],
    showMergeBranchModal: [showMergeBranchModal, setShowMergeBranchModal],
    variants,
    variantsLoading,
    createTextItemPanelStatus: [createTextItemPanelStatus, setCreateTextItemPanelStatus],
    unselectAll,
    forceMultiSelectOn: [forceMultiSelectOn, setForceMultiSelectOn],
    multiSelectedVariants: [multiSelectedVariants, setMultiSelectedVariants],
    isFigmaAuthenticated,
    showToast: [showToast, setShowToast],
    queuePostResyncExecution,
    setActiveVariantOnGroup,
  } = projectState;

  const { overlayToastProps, showToast: showOverlayToast } = useOverlayToast();
  const assigneeBannerShownOnMount = useRef(false);
  const assignmentToastText = useDittoComponent({
    componentId: "project-page.assigned-text-toast",
  });

  const {
    tagState: [tagState, setTagState],
    assignee: [assigneeQueryParam],
  } = projectState.search;

  /**
   * Reference used to cache the value of the groups in the groupState,
   * used exclusively by the function that is called whenever the selection state
   * changes inside of a Draft group. That function is called by an internal
   * mechanism of our text editing library, TipTap, that creates a closure
   * resulting in stale data unless we use a ref to circumvent it.
   *
   * See https://github.com/ueberdosis/tiptap/issues/2403
   */
  const groupsReference = useRef(groupState.groups);

  /**
   * Referenced used to temporarily cache a selection value - handles the case
   * where a user hits 'Enter' in a Draft group, creates a new text item and then
   * simultaenously:
   * 1. triggers logic for that new text item to be selected
   * 2. adds that new text item to the project state
   *
   * Since the selection logic runs before the text item is added in the state,
   * this ref is used to cache the selection if the text item can't be found, and the
   * cache is read from in a useEffect that is triggered once the state update
   * propagates.
   */
  const queuedDraftTextItemSelection = useRef(null);

  const onDraftTextItemSelectionUpdate = (textItemIds) => {
    const groups = groupsReference.current;
    const textItems = getTextItemsFromTextItemIds(textItemIds, groups);

    // When the user hits 'Enter' on an unlinkable group to create a new
    // text item, the text item will not be created in state prior to this
    // function running. In that case, we store the text item ids to be selected
    // in a local ref, which is consumed by an effect further down in this component
    // that is triggered by changes to the group state.
    const [firstTextItem] = textItems || [];
    if (!firstTextItem) {
      queuedDraftTextItemSelection.current = textItemIds;
      return;
    }

    const isSingleSelection = textItems.length === 1;
    const isMultiSelection = textItems.length > 1;

    if (isSingleSelection) {
      setSelectedComp(firstTextItem);
      setMultiSelectedIds([]);
      setMultiSelectedComps([]);
      setMultiSelectedVariants([]);
    } else if (isMultiSelection) {
      setSelectedComp(null);
      setMultiSelectedIds(textItemIds);
      setMultiSelectedComps(textItems);
    }

    setCanSaveEdits(false);
  };

  // Whenever the group state is updated, check to see if
  // a draft group is selected and if a draft text item selection has been queued up. If so, then clear
  // the queued selection and attempt to select the queued text items.
  useEffect(() => {
    groupsReference.current = groupState.groups;

    const hasQueuedSelection = queuedDraftTextItemSelection.current;

    if (!hasQueuedSelection) {
      return;
    }

    const textItemIds = [...queuedDraftTextItemSelection.current];
    queuedDraftTextItemSelection.current = null;
    onDraftTextItemSelectionUpdate(textItemIds, groupState.groups);
  }, [groupState.groups, selectedDraftGroupId]);

  const currentComp = useMemo(
    () => docComponents.find((doc) => doc._id === selectedComp?._id),
    [docComponents, selectedComp]
  );

  const isMultiSelectOnSameFrame = useMemo(() => {
    const selectedFrameIds = {};
    multiSelectedComps.forEach((comp) => {
      if (comp._meta) {
        selectedFrameIds[comp._meta?.groupId] = true;
      }
    });
    return Object.keys(selectedFrameIds).length === 1;
  }, [multiSelectedComps]);

  const hideMergeBranchModal = () => setShowMergeBranchModal(false);

  useEffect(() => {
    if (selectedComp) {
      setCreateTextItemPanelStatus({ show: false, groupId: null });
    }
  }, [selectedComp]);

  useEffect(() => {
    if (createTextItemPanelStatus.show) {
      setSelectedComp(null);
      setForceMultiSelectOn(false);
      setMultiSelectedIds([]);
      setMultiSelectedComps([]);
      setMultiSelectedVariants([]);
      setCanSaveEdits(false);
      setSelectedDraftGroupId(null);
    }
  }, [createTextItemPanelStatus]);

  // null if not ready, id if ready
  const [previewsReady, setPreviewsReady] = useState(null);

  useEffect(
    function pollForPreviewsJob() {
      const jobId = previewsJobId;

      if (!jobId) {
        return;
      }

      setPreviewsJobId(null);
      setPollingForPreviewUpdates(true);

      pollForPreviewResponse(jobId, POLLING_TIME_IN_SEC, 0).then(async () => {
        setPreviewsReady(true);
      });
    },
    [previewsJobId, setPreviewsJobId, setPollingForPreviewUpdates]
  );

  useEffect(
    function fetchPreviewsJob() {
      if (!previewsReady) {
        return;
      }

      const { url } = API.doc.put.latestFigmaSync;
      http.put(url(projectId)).then(({ data }) => {
        try {
          let previewsMap = {};

          data.groups.forEach((group) => {
            if (group.integrations.figma.previews) {
              previewsMap[group.integrations.figma.frame_id] = {
                previews: group.integrations.figma.previews,
                previewsLastUpdatedAt: data.integrations.figma.previews_updated_at,
                frameDimensions: group.integrations.figma.position,
                textDimensions: framePreviewsMap[group.integrations.figma.frame_id]?.textDimensions || {},
              };
            }
          });

          const updatedGroups = groupState.groups.map((group) => {
            const frameId = group?.integrations?.figma?.frame_id;
            if (!frameId || !previewsMap[frameId]) return group;

            return {
              ...group,
              integrations: {
                ...group.integrations,
                figma: {
                  ...group.integrations.figma,
                  previews: previewsMap[frameId].previews,
                },
              },
            };
          });
          groupStateDispatch({
            type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
            groups: updatedGroups,
          });
          setFramePreviewsMap(previewsMap);

          if (frameInfo.frameId) {
            // if currently selecting a frame, update it
            setFrameInfo((prev) => ({
              isNewFrame: true,
              frameId: prev.frameId,
              frameName: prev.frameName,
              fileID: prev.fileID,
              figma_node_ids: getFigmaNodeIdsOnFrame(prev.frameId),
              appliedVariantId: prev.appliedVariantId,
            }));
          }
          setPollingForPreviewUpdates(false);
        } catch (e) {
          console.error("error fetching latest previews: ", e);
        } finally {
          setPreviewsReady(false);
        }
      });
    },
    [groupState, previewsReady]
  );

  const handleUnlinkGroups = async ({ projectId, deletedGroupIds, unlinkedGroupIds }) => {
    try {
      const { url, body } = API.doc.put.unlinkGroups;
      const { data } = await http.put(
        url,
        body({
          project_id: projectId,
          deleted_group_ids: deletedGroupIds,
          unlinked_group_ids: unlinkedGroupIds,
        })
      );
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.GROUP_FETCHING_FINISHED,
        status: true,
      }); // stop pagination
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
        groups: data.groups,
      });
      setDoc(data); // updates title, time_last_resync, etc. (relied upon by highlighting new changes)

      // later on: only update history if have edits from Figma
      setUpdateDocHistory(true);
    } catch (error) {
      console.error("Error unlinking groups", error);
    }
  };

  const handleDismissResyncWarning = () => {
    localStorage.setItem(`${RESYNC_WARNING_KEY}-${projectId}`, "true");
    segment.track({
      event: SegmentEvents.DISMISSED_RESYNC_WARNING,
      properties: {
        project_id: projectId,
      },
    });
    setShowResyncWarning(false);
  };

  /*
   * When groupMeta is updated, deselect any hidden components
   * in groups that don't have the hidden section open or don't
   * have the base variant selected.
   *
   * Update cases:
   * - A new variant is selected for a group
   */
  useEffect(() => {
    /* Determine which groups might need to have components deselected */
    const groupIdsToDeselect = Object.keys(groupMeta).reduce((acc, groupId) => {
      const { hiddenOpen, activeVariant } = groupMeta[groupId] || {};
      const baseVariantSelected = !activeVariant || activeVariant.id === "__base__";

      /*
       * Potentially de-select components in groups that have don't have their hidden section
       * open and their base variant selected
       */
      if (!(hiddenOpen && baseVariantSelected)) {
        return {
          ...acc,
          [groupId]: true,
        };
      }

      return acc;
    }, {});
    setMultiSelectedComps((components) => {
      /* Explicitly track component ids that we are
       * deselecting to make it easy and performant
       * when it comes time to deselect from the array
       * of ids
       */
      const componentIdsToDeselect = {};
      const componentsUpdated = [];

      components.forEach((component) => {
        if (!component.is_hidden) {
          componentsUpdated.push(component);
          return;
        }

        const groupId = component._meta?.groupId;

        /*
         * If the component is in a de-selection group,
         * queue it up to be de-selected; otherwise, keep it
         * in the array of selections
         */
        if (groupIdsToDeselect[groupId]) componentIdsToDeselect[component._id] = true;
        else componentsUpdated.push(component);
      });

      if (componentsUpdated.length === 1) {
        const [component] = componentsUpdated;
        setSelectedComp(component);
        setMultiSelectedIds([]);
        setForceMultiSelectOn(false);
        return [];
      } else {
        setMultiSelectedIds((ids) => (ids.length ? ids.filter((id) => !componentIdsToDeselect[id]) : ids));

        setSelectedComp((selectedComp) => {
          if (selectedComp && componentIdsToDeselect[selectedComp._id] && selectedComp.is_hidden) {
            return null;
          } else {
            return selectedComp;
          }
        });

        return componentsUpdated;
      }
    });
  }, [groupMeta, setSelectedComp]);

  /*
   * When filteredCompIds change from the search query being updated:
   * - disable multi-select for filtered out components
   * - re-enable multi-select for components not filtered out
   */
  useEffect(
    () =>
      setDocComponents((components) =>
        components.map((component) =>
          setComponentMetadata(component, (meta) => ({
            ...meta,
            disabled: {
              ...meta.disabled,
              bySearch: !filteredCompIds.includes(component._id),
            },
          }))
        )
      ),
    [filteredCompIds, setDocComponents]
  );

  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [showFrameModal, setShowFrameModal] = useState(false);
  const [multiSelectGroupIds, setMultiSelectGroupIds] = useState([]);

  const params = useParams();
  const initialURLStateLoad = useRef(true);
  const initialCompSelection = useRef(true);

  const setURLParams = (page, comp, search, commentThreadId) => {
    let url = `/projects/${params.id}`;

    if (params.branchId) {
      url += `/branch/${params.branchId}`;
    }

    url += `/page/${page.id}`;

    if (comp) {
      url += `/${comp._id}`;
    }

    if (commentThreadId) {
      url += `/${commentThreadId}`;
    }

    let queryString = search;
    if (comp && queryString === undefined) {
      const queryParams = new URLSearchParams(window.location.search);
      const variantId = comp.selectedVariant?.id;
      queryParams.delete("frameVariant");
      if (variantId && variantId !== "__base__") queryParams.set("frameVariant", variantId);
      url += queryParams.toString() ? `?${queryParams.toString()}` : "";
    } else if (search) {
      url += search;
    }

    const currentPath = window.location.pathname + window.location.search;
    if (currentPath !== url) history.replace(url);
  };

  const showActivateModal = doc && doc.isSample && doc.integrations.figma.file_id === "sampleProject";

  useEffect(
    function initialSyncTextItemWithURL() {
      if ((!docComponents.length && !selectedPage === ALL_PAGE) || variantsLoading || !initialURLStateLoad.current)
        return;

      // for navigating to a fully qualified URL
      const textItemIdFromParams = params?.textItemId;
      // for when other pages want to navigate to a selected text item but don't
      // have the fully qualified URL (e.g. from a notifciation)
      const textItemIdFromState = state?.textItemId || state?.compId;
      const textItemId = textItemIdFromParams || textItemIdFromState;

      const component = getCompFromCompId(textItemId);

      if (!textItemId) initialCompSelection.current = false;
      if (textItemId && !component) return;

      if (params.commentThreadId) {
        setCommentState({ thread_id: params.commentThreadId, isSelected: true });
      }

      initialURLStateLoad.current = false;
      if (component) {
        const groupId = Object.keys(filteredFrames)[TextItem.getGroupIndex(component)];

        const queryParams = new URLSearchParams(window.location.search);
        const variantId = queryParams.get("frameVariant");
        let variant = null;
        if (variantId) {
          const frameVariants = variants.frameVariants?.[groupId];
          variant = frameVariants?.find((v) => v.id === variantId) || null;
        }

        selectComp(component, variant, undefined, groupId);

        handleSelectFrameVariant(variant, groupId);

        const groupIndex = TextItem.getGroupIndex(component);
        const newFramePage = Math.floor(groupIndex / PAGE_FRAME_LIMIT);
        if (framePage !== newFramePage) setFramePage(newFramePage);
      }

      setURLParams(selectedPage, component, null, params.commentThreadId);
    },
    [state, docComponents]
  );

  useEffect(
    function syncSelectedPageWithURL() {
      if (initialURLStateLoad.current) return;
      if (selectedPage) {
        setURLParams(selectedPage, selectedComp, "");
      }
    },
    [selectedPage]
  );

  useEffect(
    function syncSelectedTextItemWithURL() {
      if (initialURLStateLoad.current) return;
      setURLParams(selectedPage, selectedComp);

      if (initialCompSelection.current) {
        initialCompSelection.current = false;
        if (selectedComp) {
          handleScrollToTextItem(selectedComp?._id);
        }
      }
    },
    [selectedComp]
  );

  const history = useHistory();

  const GROUP_FETCH_LIMIT = 50;
  const POLLING_TIME_IN_SEC = 6000; // poll for updated image previews every 6 sec after resync
  const storageKey = `ditto-show-api-ids-${projectId}`;

  const pageReducer = (state, action) => {
    switch (action.type) {
      case "ADVANCE_PAGE":
        return { ...state, page: state.page + 1 };
      case "RESET_PAGE":
        return state.page === 0 ? { ...state, page: -1 } : { ...state, page: 0 };
      default:
        return state;
    }
  };
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: -2 });

  /*
   * This effect is responsible for regenerating state pieces
   * critical for selection and multi-selection on the project page.
   *
   * It re-runs anytime any text item, group, or block in the project
   * has a change made to it.
   */
  useEffect(
    function regenerateTextItemMap() {
      if (!selectedPage) {
        return;
      }

      // Returns a flat, in-order array of all selectable text items in the project.
      //
      // Also mutates ALL text items (hacky) by giving them a `_meta` property
      // that contains information such a text item's absolute index
      // position relative to the other text items in the project and the id
      // of the group that it is in.
      const textItemsInProject = createMultiSelectComponentIndex({
        groups: searchFilteredGroups, // This is just groups on page then filtered
        page: selectedPage,
      });

      setDocComponents(textItemsInProject);

      // Track text items that should be unselected.
      //
      // A text item should be unselected if..
      // 1. There is a property in its `_meta` field indicating it is not
      // eligible for multi-selection (note that the `_meta` field was just
      // regenerated further up in this effect)
      // 2. It's not the only text item that is selected
      const textItemsToUnselect = {};
      textItemsInProject.forEach((textItem) => {
        const isMultiSelectDisabled = !multiSelectEnabledAtComponentLevel(textItem);

        const isNotSingleSelected = !selectedComp || selectedComp._id !== textItem._id;

        if (isMultiSelectDisabled && isNotSingleSelected) {
          textItemsToUnselect[textItem._id] = true;
        }
      });

      // Remove the indicated text items from multiselect state
      setMultiSelectedIds((ids) => (ids.length ? ids.filter((id) => !textItemsToUnselect[id]) : ids));
      setMultiSelectedComps((components) =>
        components.length ? components.filter(({ _id }) => !textItemsToUnselect[_id]) : components
      );

      // If a single text item is selected, and the text item that is selected
      // has been indicated for deselection, then de-select it.
      setSelectedComp((selectedComp) => {
        if (selectedComp && textItemsToUnselect[selectedComp._id]) {
          return null;
        } else {
          return selectedComp;
        }
      });
    },
    [
      searchFilteredGroups,
      selectedPage,
      selectedComp,
      setSelectedComp,
      setMultiSelectedIds,
      setMultiSelectedComps,
      setDocComponents,
    ]
  );

  useEffect(
    function fetchProjectBranchInfo() {
      if (doc?._id) getProjectBranchInfo();
    },
    [doc]
  );

  useEffect(() => {
    if (groupState && groupState.finished && state && state.compId) {
      checkDetailPanelChanges(() => {
        setSuggestedCompId(null);
        setSingleSelected(state.compId); // only show if we're not opening a comment
        handleScrollToTextItem(state.compId);
        // this effect triggers on both comment mention and assignment, so
        // commentThreadId is not always relevant.
        if (state.commentThreadId) {
          setCommentState({
            isSelected: true,
            thread_id: state.commentThreadId,
          });
          setPanelState(PANELS.doc.edit);
        }

        // replace state after use. very important to delete state.compId,
        // otherwise this effect runs forever!
        let newState = { ...history.location.state };
        delete newState.compId;
        delete newState.commentThreadId;
        history.replace({ ...history.location, state: newState });
      });
    }
    if (state && state.shouldUnselect) {
      setSingleSelected(null, false);
      let newState = { ...history.location.state };
      delete newState.shouldUnselect;
      history.replace({ ...history.location, state: newState });
    }
  }, [state, groupState]);

  const handleAddNewVariants = async (groupId, variants, folderId, applyToAllGroups, status) => {
    const { url, body } = API.variant.post.addFrameVariants;
    try {
      const response = await http.post(
        url,
        body({
          projectId: projectId,
          groupIds: [groupId],
          variants,
          folderId,
          applyToAllGroups,
          status: VARIANT_STATUSES_ENABLED ? status : undefined,
        })
      );
      getDocVariants();
      setUpdateDocHistory(true);

      return response.data.addedVariants;
    } catch (error) {
      console.error("Error adding frame variants", error);
    }
  };

  const handleDeleteFrameVariant = async (frame_ID, variant_ID) => {
    try {
      const { url, body } = API.variant.delete.frameVariant;
      await http.delete(url, {
        data: body({
          doc_ID: projectId,
          frame_ID,
          variant_ID,
        }),
      });
      setSelectedComp(null);
      initializeProject();
      getDocVariants();
      setUpdateDocHistory(true);
      setActiveVariantOnGroup(frame_ID, null);
    } catch (error) {
      console.error("Error deleting frame variant", error);
    }
  };

  const handleSelectFrameVariant = (frameVariant, groupId) => {
    checkDetailPanelChanges(() => {
      if (selectedComp && selectedComp.is_hidden && frameVariant && frameVariant.id !== "__base__") {
        selectComp(selectedComp);
      } else if (selectedComp && TextItem.getGroupId(selectedComp) === groupId) {
        setSelectedComp((comp) => ({ ...comp, selectedVariant: frameVariant }));
      }

      setMultiSelectedComps((comps) =>
        comps.map((comp) => (TextItem.getGroupId(comp) === groupId ? { ...comp, selectedVariant: frameVariant } : comp))
      );

      setActiveVariantOnGroup(groupId, frameVariant?.id);
    });
  };

  /**
   * Safely retrieves and loads doc data. Does additional state checks to determine if the page needs to be loaded.
   */
  const loadDocPage = async () => {
    if (groupState.finished) {
      return;
    }

    if (!groupState.fetching) {
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.FETCHING_GROUPS,
        fetching: true,
      });
    }

    try {
      const { url } = API.doc.get.pageInfo;
      const numPagesToSkip = (pager.page < 0 ? 0 : pager.page) * GROUP_FETCH_LIMIT;
      const { data: doc } = await http.get(url(projectId, numPagesToSkip, GROUP_FETCH_LIMIT));

      updateFramePreviewsMap(doc);

      // TODO: is this ok to be <= 0 instead of === 0?
      // previously was causing a bug when this function was re-called,
      // causing all of the groups to get duplicated and stacked in weird cases,
      // like when deleting a variant from a group
      if (pager.page <= 0) {
        groupStateDispatch({
          type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
          groups: doc.groups,
        });
      } else {
        groupStateDispatch({
          type: GROUP_REDUCER_TYPES.STACK_GROUPS,
          groups: doc.groups,
        });
      }

      if (doc.groups.length === 0 || doc.groups.length < GROUP_FETCH_LIMIT) {
        // Only set this to false after pagination is complete
        groupStateDispatch({
          type: GROUP_REDUCER_TYPES.FETCHING_GROUPS,
          fetching: false,
        });
        groupStateDispatch({
          type: GROUP_REDUCER_TYPES.GROUP_FETCHING_FINISHED,
          status: true,
        });
        setDoc(doc);
      } else {
        pagerDispatch({ type: "ADVANCE_PAGE" }); // if not finished, keep going
      }
    } catch (e) {
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.FETCHING_GROUPS,
        fetching: false,
      });
      return e;
    }
  };

  useEffect(() => {
    if (doc?.is_locked) fetchProjectBranchInfo();
  }, [doc?.is_locked]);

  useEffect(() => {
    const KEYS = {
      Escape: 27,
    };

    const keyboardHandler = (event) => {
      const { keyCode } = event;

      switch (keyCode) {
        case KEYS.Escape:
          if (panelState !== PANELS.doc.inline_reply) {
            checkDetailPanelChanges(() => {
              unselectAll();
            });
          }
      }
    };

    document.addEventListener("keydown", keyboardHandler, false);

    return () => {
      document.removeEventListener("keydown", keyboardHandler, false);
    };
  }, [unsavedDetailChangesExist]);

  /**
   * Add a document-level event listener that unselects
   * everything when the user clicks directly on an element
   * that contains the specified data attribute.
   */
  useEffect(() => {
    const handleUnselectAll = (e) => {
      if (!e.target) {
        return;
      }

      const target = e.target;
      if (!target.dataset[UNSELECT_ALL_ATTRIBUTE]) {
        return;
      }
      if (panelState !== PANELS.doc.inline_reply) {
        checkDetailPanelChanges(() => {
          unselectAll();
        });
      }
    };

    document.addEventListener("click", handleUnselectAll);
    return () => {
      document.removeEventListener("click", handleUnselectAll);
    };
  }, [unsavedDetailChangesExist]);

  const getDocTags = async () => {
    try {
      const { url } = API.doc.get.allTags;
      const { data: tags } = await http.get(url(projectId, Array.from(tagState.selected)));
      tags.sort((a, b) => a._id.localeCompare(b._id));

      setTagState((s) => {
        return {
          ...s,
          counts: tags.reduce((acc, tag) => {
            acc[tag._id] = tag.total;
            return acc;
          }, {}),
        };
      });
    } catch (error) {
      console.error("Error getting doc tags", error);
    }
  };

  useEffect(() => {
    getDocTags();
  }, [tagState.selected]);

  const getWorkspaceTags = async () => {
    try {
      const { url } = API.workspace.get.tags;
      const { data: tags } = await http.get(url);

      setTagSuggestions(tags.filter((tag) => tag).map((tag, i) => ({ id: i, name: tag })));
    } catch (err) {
      console.error("error fetching workspace tags in doc.jsx: ", err);
    }
  };

  /**
   * This is the useEffect that loads all of the initial document data
   * and initializes the project.
   */
  useEffect(() => {
    if (loading) {
      return;
    }

    const bootstrap = async () => {
      const initializationResult = await initializeProject();
      if (typeof initializationResult === "string" || !initializationResult) {
        switch (initializationResult) {
          case "unauthorized":
            return setUnauthorizedProjectAccess(true);
          default:
            return history.push(navRoutes.projects.path);
        }
      }

      getDocTags();
      getWorkspaceTags();
      getDocVariants();
      setUpdateDocHistory(true);
    };

    bootstrap();
  }, [loading, projectId]);

  useEffect(() => {
    if (!loading && !groupState.finished && pager.page !== -2) {
      loadDocPage().then(() => {
        // show assignee toast after doc has loaded
        if (assigneeQueryParam && !assigneeBannerShownOnMount.current) {
          assigneeBannerShownOnMount.current = true;
          setTimeout(() => showOverlayToast(assignmentToastText, 5000), 250);
        }
      });
    }
  }, [pager.page]);

  const pollForPreviewResponse = async (jobId, interval, count) => {
    try {
      const { url } = API.jobs.get.previewId;
      const { data } = await http.get(url(jobId));
      let result = data;
      let { state } = result;
      if (state === "active" || state === "stuck" || state === "waiting") {
        await new Promise((res) => setTimeout(res, interval));
        result = await pollForPreviewResponse(jobId, interval, count + 1);
      }
      return result;
    } catch (e) {
      console.error("Error while polling for previews response is: ", e.message);
    }
  };

  const initializeSelectedPages = (project) => {
    if (selectedPage) {
      return;
    }

    if (getProjectHasFigmaConnection(project)) {
      const pages = project.integrations.figma.selected_pages.map((page) => ({
        id: page.figma_id,
        name: page.name,
      }));

      let initialPage = pages.find((p) => p.id === params.pageId) || pages[0];

      if (params.pageId === DRAFTED_GROUPS_PAGE_ID) {
        initialPage = DRAFTED_GROUPS_PAGE;
      } else if (params.pageId === ALL_PAGE_ID) {
        initialPage = ALL_PAGE;
      }

      setSelectedPage(initialPage);
      return;
    }

    setSelectedPage(DRAFTED_GROUPS_PAGE);
  };

  const initializeProject = async () => {
    try {
      // don't initialize project if projectId is missing
      if (!projectId || projectId === ":id") return;

      const { url } = API.doc.get.initialInfo;
      const { data: result } = await http.get(url(projectId));

      initializeSelectedPages(result);

      setDoc(result);
      setFolder(result.folder);

      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.GROUP_FETCHING_FINISHED,
        status: false,
      });

      pagerDispatch({ type: "RESET_PAGE" });

      // Handle initial Resync
      handleInitialResync();
      return result;
    } catch (e) {
      if (e.response?.data?.code === "unauthorized") {
        return "unauthorized";
      }
      console.error("error while getting doc/initialInfo: ", e.message);
      return null;
    }
  };

  const selectTag = async (tagName) => {
    const selected = new Set(tagState.selected);
    selected.has(tagName) ? selected.delete(tagName) : selected.add(tagName);
    setTagState((s) => ({ ...s, selected }));
  };

  const clearSelectedTags = () => {
    setTagState((s) => ({ ...s, selected: new Set() }));
  };

  const updateFramesGivenCompIds = (textItemIds) => {
    try {
      const groupIds = Array.from(getGroupIdsFromTextItems(textItemIds));
      if (groupIds) {
        return updateFrames(groupIds);
      } else {
        throw Error("Group IDs are null");
      }
    } catch (err) {
      console.error("error updating frames given compIDs: ", err, " and compIDs are: ", textItemIds);
      forceShowToast({
        title: "⚠️ Issue updating frames",
        body: "Sorry, we had trouble updating the project. ",
        type: TOAST_TYPES.edit_error,
        autoHide: false,
      });
    }
  };

  // pass in array of figma artboard IDs
  const updateFrames = async (frameIDs) => {
    const [request] = getProjectGroups({ projectId, groupIds: frameIDs });
    const { data: res } = await request;

    const returnedGroupsMap = res.groups.reduce((acc, group) => ({ ...acc, [group._id.toString()]: group }), {});

    const newGroups = groupState.groups.map((group) => returnedGroupsMap[group._id.toString()] || group);

    groupStateDispatch({
      type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
      groups: newGroups,
    });

    getDocTags();
    if (doc && doc.developer_mode_enabled) getAllFrameIds();
  };

  const getGroupIdsFromTextItems = (textItemIds) => {
    const groupIdSet = new Set();

    for (const textItemId of textItemIds) {
      const groupFound = groupState.groups.find((group) => {
        for (const textItem of group.comps || []) {
          if (textItem._id === textItemId) return true;
        }

        for (const block of group.blocks || []) {
          if (block?.comps?.some((textItem) => textItem._id == textItemId)) return true;
        }

        return false;
      });

      if (groupFound) {
        groupIdSet.add(groupFound._id.toString());
      }
    }

    return groupIdSet;
  };

  const hideDeleteModal = () => setShowDeleteModal(false);

  const hideMergeSuccessModal = () => setShowMergeSuccessModal(false);

  // On merge success modal button click, redirect to the main project
  const goToMainProject = () => {
    history.push(goToMainProjectUrl);
    setShowMergeSuccessModal(false);
    setGoToMainProjectUrl(null);
  };
  const handleMergeBranch = async (shouldMerge) => {
    try {
      const shouldDeleteBranchProject =
        projectBranchInfo?.mainProjectInfo && projectBranchInfo?.branchProjectInfo && !shouldMerge;
      if (shouldDeleteBranchProject) {
        const { url } = API.doc.delete.delete;
        await http.delete(url(projectBranchInfo?.branchProjectInfo?._id));
        setShowMergeBranchModal(false);

        if (doc._id === projectBranchInfo?.mainProjectInfo?._id) {
          setDoc((prevDoc) => ({ ...prevDoc, is_locked: false }));
          resync();
        } else {
          history.push(routes.nonNavRoutes.project.getPath(projectBranchInfo?.mainProjectInfo?._id));
        }
        return;
      }
      const { url } = API.doc.post[shouldMerge ? "mergeBranch" : "discardBranch"];
      setIsBranchMerging(true);

      let localProjectId = projectId;
      // if project locked, then we are on the main project and should merge or discard the branch project
      if (doc.is_locked && projectBranchInfo?.branchProjectInfo) {
        localProjectId = projectBranchInfo.branchProjectInfo._id;
      }
      const {
        data: { status, state, mainProjectId },
      } = await http.post(url(localProjectId), {});
      if (status) {
        setShowDeleteModal(true);
      } else if (doc._id === projectBranchInfo?.mainProjectInfo?._id) {
        setIsBranchMerging(false);
        setShowMergeBranchModal(false);
        setDoc((prevDoc) => ({ ...prevDoc, is_locked: false }));
        resync();
      } else if (state === "project-locked") {
        setSelectedComp(null);
        setDoc((prevDoc) => ({ ...prevDoc, is_locked: true }));
        setGoToMainProjectUrl(routes.nonNavRoutes.project.getPath(mainProjectId));
        checkIfProjectLocked();
        setIsBranchMerging(false);
        setShowMergeSuccessModal(true);
        setShowMergeBranchModal(false);

        setUpdateDocHistory(true);
      } else {
        setSelectedComp(null);
        await resync();
        setIsBranchMerging(false);
        setShowMergeBranchModal(false);
      }
    } catch (err) {
      console.error(err);
      setIsBranchMerging(false);
    }
  };

  const toggleShowToast = () => setShowToast(!showToast);

  const toggleFrameModal = () => {
    if (!resyncLoading) {
      setShowFrameModal((prev) => !prev);
    }
  };

  const refetchMultiComps = async (comp_ids) => {
    // load new version of text items from the backend
    const [request] = httpComp.getMultiTextItems({ textItemIds: comp_ids });
    const { data: textItems } = await request;
    const textItemsById = textItems.reduce((acc, textItem) => {
      acc[textItem._id] = textItem;
      return acc;
    }, {});

    // TODO: update hidingAvailable here
    let hasCompInBlock = false;
    let new_multi = [];
    let frameIndex = 0;
    for (var group of groupState.groups) {
      for (var comp of group.comps) {
        if (comp_ids.includes(comp._id)) {
          const updatedTextItem = textItemsById[comp._id] || comp;
          new_multi.push({ ...comp, ...updatedTextItem, blockIndex: null, frameIndex: frameIndex });
        }
      }
      if (group.blocks) {
        let blockIndex = 0;
        for (const block of group.blocks) {
          for (const compInBlock of block.comps) {
            if (comp_ids.includes(compInBlock._id)) {
              const updatedTextItem = textItemsById[compInBlock._id] || compInBlock;
              new_multi.push({
                ...compInBlock,
                ...updatedTextItem,
                blockIndex: blockIndex,
                frameIndex: frameIndex,
              });
              if (!hasCompInBlock) {
                hasCompInBlock = true;
              }
            }
          }
          blockIndex += 1;
        }
      }
      frameIndex += 1;
    }

    const newSelectedComps = new_multi;
    const selectedIds = new_multi.map(({ _id }) => _id);

    setMultiSelectedIds(selectedIds);
    // multiSelectedComps holds mutated state data, selectedVariant and _meta
    // we want to make sure they're preserved through the refetch
    setMultiSelectedComps((comps) => {
      const updatedComps = newSelectedComps.map((newComp) => {
        const currentSelectedComp = comps.find((c) => c._id === newComp._id);
        if (currentSelectedComp) {
          return {
            ...currentSelectedComp,
            ...newComp,
          };
        }
        return newComp;
      });
      return updatedComps;
    });
    setMultiSelectedVariants(newSelectedComps.map((comp) => comp.selectedVariant));

    setHidingAvailable(!hasCompInBlock);

    if (!panelState) {
      const newPanelState = selectedDraftGroupId ? PANELS.doc.activity : PANELS.doc.edit;
      setPanelState(newPanelState);
    }

    return new_multi;
  };

  const setMultiSelected = (comp_ids, showToast = true) => {
    if (comp_ids.length === 1) {
      const componentToSelect = docComponents.find(({ _id }) => _id === comp_ids[0]);

      return setSelectedComp(componentToSelect || null);
    }

    setSelectedComp(null);

    if (showToast && !multiSelectOn) {
      setShowMultiSelectToast(true);
      setTimeout(() => setShowMultiSelectToast(false), 3000);
    }
    refetchMultiComps(comp_ids);
    setPageByTextItemId(comp_ids[comp_ids.length - 1]);
  };

  const handleSuggestionSelectComp = (comp_id) => {
    if (!comp_id) {
      setSuggestedCompId(null);
      return;
    }

    setPageByTextItemId(comp_id);
    setTimeout(() => {
      setSuggestedCompId(comp_id);
      handleScrollToTextItem(comp_id);
    }, 100);
  };

  /**
   *
   * @param {*} comp_id
   * @param {*} show
   * @param {boolean} disableQuickReplyComments whether or not to disable quick reply comments
   * while selecting the specified text item; defaults to `true`.
   * @returns
   */
  const setSingleSelected = (comp_id, show = true, disableQuickReplyComments = true) => {
    if (disableQuickReplyComments) {
      setQuickReplyCommentState({ enabled: false });
    }

    if (!show) {
      const comp = getCompFromCompId(comp_id);
      setSelectedComp(comp);
      return;
    }

    const comp = getCompFromCompId(comp_id);
    if (!comp) {
      return;
    }

    const group = groupState.groups[comp.frameIndex];
    if (!group) {
      return;
    }

    const groupId = group._id.toString();

    // Hidden components can be selected by clicking on an activity item;
    // if the user selects a hidden component, make sure the "Hidden" panel
    // for that group is open
    if (comp.is_hidden) {
      setGroupMeta((groupMeta) => ({
        ...groupMeta,
        [groupId]: {
          hiddenOpen: true,
        },
      }));
    }

    setPageByTextItemId(comp_id);
    setTimeout(() => {
      setSelectedGroupId(groupId);
      setSelectedComp(comp);
    }, 0);

    if (!isGroupLinked(group)) {
      highlightDraftTextItem(comp._id.toString());
    }

    const hidingAvailable = !(isGroupUnlinkable(group) || isCompInBlock(comp));
    setHidingAvailable(hidingAvailable);
  };

  const handleOpenFramePage = (groupId) => {
    const group = groupState.groups.find((group) => group._id === groupId);
    if (!group) {
      return;
    }

    const groupIsLinked = isGroupLinked(group);
    let page = DRAFTED_GROUPS_PAGE;
    if (groupIsLinked) {
      const pageObj = doc?.integrations.figma.selected_pages?.find(
        (page) => page.figma_id === group.integrations.figma.page_id
      );
      if (pageObj) {
        page = {
          id: pageObj.figma_id,
          name: pageObj.name,
        };
      }
    }

    setSelectedPage(page);

    const groupsOnPage = groupState.groups
      .filter((currentGroup) =>
        groupIsLinked
          ? currentGroup.integrations.figma.page_id === group.integrations.figma.page_id
          : !isGroupLinked(currentGroup)
      )
      .sort((a, b) => (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1));

    const groupIndex = groupsOnPage.findIndex((currentGroup) => currentGroup._id === group._id);

    const newFramePage = Math.floor(groupIndex / PAGE_FRAME_LIMIT);

    if (framePage !== newFramePage) setFramePage(newFramePage);
  };

  const handleOpenBlockPage = (blockId) => {
    const group = groupState.groups.find((group) => {
      return !!group.blocks.find((block) => block._id === blockId);
    });
    if (!group) return;

    handleOpenFramePage(group._id);
  };

  const isCompInBlock = (comp) => {
    return "blockIndex" in comp && comp.blockIndex !== null;
  };

  /**
   *
   * @param {string} groupId
   * @param {*} frameVariant
   * @param {boolean} hiddenOpen
   * @returns
   */
  const selectAllCompsInGroup = (groupId, frameVariant, hiddenOpen) => {
    const group = groupState.groups.find((group) => group._id === groupId);
    if (!group) return;

    // The quick reply panel shouldn't be shown by default when a comp is selected,
    // and instead should only be shown in particular scenarios that don't use this function.
    setQuickReplyCommentState({ enabled: false });

    // Any time this function is triggered, we're clicking on a regular text item outside
    // of a draft group, so we want to ensure that the state reflects the fact that a draft
    // group is not selected.
    setSelectedDraftGroupId(null);

    if (suggestedCompId) setSuggestedCompId(null);

    checkDetailPanelChanges(() => {
      handleSelectAllCompsInGroup(group, frameVariant, hiddenOpen);
    });

    setSelectedGroupId(groupId);
  };

  /**
   * Actually Selects all the comps in a group
   * @param {Group} group
   * @param {*} frameVariant
   * @param {boolean} hiddenOpen
   */
  const handleSelectAllCompsInGroup = (group, frameVariant, hiddenOpen) => {
    let currSelectedMap = multiSelectedComps.reduce((map, comp) => {
      map[comp._id] = comp;
      return map;
    }, {});

    const numberOfSelectedComponents = () => Object.keys(currSelectedMap).length;

    const selectComponent = (component) => {
      const groupId = component._meta?.groupId;
      const selectedVariant = groupMeta[groupId]?.activeVariant;

      currSelectedMap[component._id] = { ...component, selectedVariant };
    };
    // Add them in a set to avoid duplicates
    // when we move them to array at the end
    const selectedGroupIds = new Set();
    const selectGroupId = (component) => {
      const groupId = component._meta?.groupId;
      selectedGroupIds.add(groupId);
    };
    const deselectAllComponents = () => (currSelectedMap = {});
    // last two args are only used for Sentry error data collection
    const isSelected = (component, low, high) => {
      if (!component) {
        // Collecting more information on Sentry issue
        // https://sentry.io/organizations/ditto-words/issues/3906377103/?project=5934602&referrer=slack
        Sentry.captureException(new Error(`Component is ${component} in group selection`), {
          extra: {
            projectId: projectId,
            group,
            currSelectedMap,
            low,
            high,
          },
        });
        return false;
      }

      return !!currSelectedMap[component._id];
    };

    const updateHidingAvailable = () => {
      const hidingAvailable = Object.keys(currSelectedMap).every((id) => {
        const component = currSelectedMap[id];

        const componentIsInBlock = isCompInBlock(component);
        const componentHasBlockId = component._meta && component._meta.blockId !== null;
        const componentHasVariants = component.variants && component.variants.length > 0;

        return !(componentIsInBlock || componentHasBlockId || componentHasVariants);
      });

      setHidingAvailable(hidingAvailable);
    };

    const low = docComponents.findIndex((comp) => comp._meta?.groupId?.toString() === group._id?.toString());
    const high = docComponents.findLastIndex((comp) => comp._meta?.groupId?.toString() === group._id?.toString());

    const selectNewRange = () => {
      for (let i = low; i <= high; i++) {
        const component = docComponents[i];
        // We shouldn't be getting null components here, we were trying to manually send a sentry error to figure out
        // what was causing it but it happens very rarely
        if (!component) continue;

        if (
          !isSelected(component, low, high) &&
          (!frameVariant || frameVariant.id === "__base__" || !component.is_hidden) &&
          (hiddenOpen || !component.is_hidden)
        )
          selectComponent(component);
      }
    };

    deselectAllComponents();
    selectNewRange();
    updateHidingAvailable();
    multiSelectionEnd.current = high;

    if (numberOfSelectedComponents() === 1) {
      const component = currSelectedMap[Object.keys(currSelectedMap)[0]];
      setSelectedComp(component);
      deselectAllComponents();
    }

    // get selected group ids
    const firstComp = Object.values(currSelectedMap)[0];
    if (firstComp) {
      selectGroupId(firstComp);
      checkIfNewFrame(getCompFromCompId(firstComp._id));
    }
    setMultiSelectGroupIds(Array.from(selectedGroupIds));

    const currSelectedIds = [];
    const currSelectedComps = [];

    Object.keys(currSelectedMap).forEach((id) => {
      currSelectedIds.push(id);
      currSelectedComps.push(currSelectedMap[id]);
    });

    setMultiSelectedIds(currSelectedIds);
    setMultiSelectedComps(currSelectedComps);
    setMultiSelectedVariants(currSelectedComps.map((comp) => comp.selectedVariant));

    if (currSelectedIds.length > 1) {
      setSelectedComp(null);
    }
  };

  const selectComp = (componentClicked, frameVariant = null, e = {}, groupId = null) => {
    // The quick reply panel shouldn't be shown by default when a comp is selected,
    // and instead should only be shown in particular scenarios that don't use this function.
    setQuickReplyCommentState({ enabled: false });

    // Any time this function is triggered, we're clicking on a regular text item outside
    // of a draft group, so we want to ensure that the state reflects the fact that a draft
    // group is not selected.
    setSelectedDraftGroupId(null);

    const componentMeta = componentClicked?._meta
      ? componentClicked._meta
      : docComponents.find((c) => c._id === componentClicked._id)?._meta;
    const component = {
      ...componentClicked,
      _meta: componentMeta,
      selectedVariant: frameVariant,
    };

    if (suggestedCompId) setSuggestedCompId(null);

    checkDetailPanelChanges(() => {
      changeSelectedComp(component, e);
    });

    setSelectedGroupId(groupId);
  };

  const changeSelectedComp = (componentClicked = null, { shiftKey, metaKey, ctrlKey } = {}) => {
    const component = componentClicked;

    let multiSelect = shiftKey || metaKey || ctrlKey;
    /**
     * If the user is holding down the shift or meta keys and clicks
     * a component when nothing is already selected, treat it as a
     * single selection.
     else */
    if (multiSelect && !multiSelectedIds.length && !selectedComp) multiSelect = false;
    /**
     * If the user is holding down the shift or meta keys and clicks
     * the only component that is already selected, de-select everything.
     */ else if (
      multiSelect &&
      multiSelectedIds.length <= 1 &&
      selectedComp &&
      componentClicked._id === selectedComp._id
    )
      return unselectAll();

    if (multiSelect) {
      let currSelectedMap = multiSelectedComps.reduce((map, comp) => ({ ...map, [comp._id]: comp }), {});

      const numberOfSelectedComponents = () => Object.keys(currSelectedMap).length;

      const selectComponent = (component) => {
        const groupId = component._meta?.groupId;
        const selectedVariant = groupMeta[groupId]?.activeVariant;

        currSelectedMap[component._id] = { ...component, selectedVariant };
      };
      // Add them in a set to avoid duplicates
      // when we move them to array at the end
      const selectedGroupIds = new Set();
      const selectGroupId = (component) => {
        const groupId = component._meta?.groupId;
        selectedGroupIds.add(groupId);
      };
      const deselectComponent = (component) => delete currSelectedMap[component._id];
      const deselectAllComponents = () => (currSelectedMap = {});
      const isSelected = (component) => !!currSelectedMap[component._id];

      const componentAlreadySelected = multiSelectedIds.includes(component._id);

      const updateHidingAvailable = () => {
        const hidingAvailable = Object.keys(currSelectedMap).every((id) => {
          const component = currSelectedMap[id];

          const componentIsInBlock = isCompInBlock(component);
          const componentHasBlockId = component._meta && component._meta.blockId !== null;
          const componentHasVariants = component.variants && component.variants.length > 0;

          return !(componentIsInBlock || componentHasBlockId || componentHasVariants);
        });

        setHidingAvailable(hidingAvailable);
      };

      /*
       * Control / Meta Select
       *
       * Toggles whether or not the clicked component is selected;
       * takes precedence over shift select
       */
      if (metaKey || ctrlKey) {
        if (componentAlreadySelected) {
          deselectComponent(component);
          updateHidingAvailable();
        } else {
          /**
           * If we have a single component selected already
           * when we click on another, include the one already
           * selected in the multi-select arrays.
           */
          if (selectedComp && !numberOfSelectedComponents()) {
            const component = docComponents[multiSelectionStart.current];
            selectComponent(component);
            setSelectedComp(null);
          }

          selectComponent(component);
          updateHidingAvailable();
        }
      } else {
        /*
         * Shift Select
         *
         * 1. Deselect everything between selection start (last non-shift click) and selection end (last shift click)
         * 2. Set selection end to current click location
         * 3. Select everything between start and end
         *
         * Algorithm derived from https://github.com/ibash/better-multiselect
         */
        let selectionStart = multiSelectionStart.current;
        let selectionEnd = multiSelectionEnd.current;

        const deselectOldRange = () => {
          /*
           * We set "selectionEnd" to null whenever the start of a selection is moved,
           * which is why this check is needed
           */
          if (typeof selectionEnd === "number") {
            const low = Math.min(multiSelectionEnd.current, selectionStart);
            const high = Math.max(multiSelectionEnd.current, selectionStart);

            for (let i = low; i <= high; i++) {
              const component = docComponents[i];
              if (i === selectionStart) continue;

              deselectComponent(component);
            }
          }
        };

        const selectNewRange = () => {
          const low = Math.min(selectionStart, component._meta.index);
          const high = Math.max(selectionStart, component._meta.index);

          for (let i = low; i <= high; i++) {
            const component = docComponents[i];

            if (
              !isSelected(component) &&
              multiSelectEnabledForComponent(component, groupMeta) &&
              filteredCompIds.includes(component._id)
            )
              selectComponent(component);
          }
        };

        deselectOldRange();
        selectNewRange();
        updateHidingAvailable();
        multiSelectionEnd.current = component._meta.index;
      }

      if (numberOfSelectedComponents() === 1) {
        const component = currSelectedMap[Object.keys(currSelectedMap)[0]];
        setSelectedComp(component);
        deselectAllComponents();
      }

      // get selected group ids
      Object.values(currSelectedMap).forEach((component) => selectGroupId(component));
      setMultiSelectGroupIds(Array.from(selectedGroupIds));

      const currSelectedIds = [];
      const currSelectedComps = [];

      Object.keys(currSelectedMap).forEach((id) => {
        currSelectedIds.push(id);
        currSelectedComps.push(currSelectedMap[id]);
      });

      setMultiSelectedIds(currSelectedIds);
      setMultiSelectedComps(currSelectedComps);
      setMultiSelectedVariants(currSelectedComps.map((comp) => comp.selectedVariant));

      if (currSelectedIds.length > 1) {
        setSelectedComp(null);
      }
    } else {
      if (selectedComp && component && component._id === selectedComp._id) {
        if (!selectedComp.selectedVariant || !component.selectedVariant) setSelectedComp(null);
        else if (selectedComp.selectedVariant.id === component.selectedVariant.id) setSelectedComp(null);
        else setSelectedComp(component);
      } else {
        setHidingAvailable(!isCompInBlock(component));
        checkIfNewFrame(component);
        setSelectedComp(component);
      }

      setMultiSelectedIds([]);
      setMultiSelectedComps([]);
      setMultiSelectedVariants([]);
    }

    if (!(multiSelect && shiftKey)) {
      multiSelectionStart.current = component._meta?.index;
      multiSelectionEnd.current = null;
    }

    /*
     * To avoid a confusing UX for users who are still adjusting
     * to the keyboard-based multi-select functionality, we want
     * to always force the multi-select toggle to show as "on"
     * WHENEVER a component is selected with one of the modifier
     * keys held down.
     */
    setForceMultiSelectOn(shiftKey || metaKey || ctrlKey);
  };

  const fireToast = ({ title, body, autoHide }) => {
    setToastObj({ title: title, body: body, autoHide: autoHide });
    toggleShowToast();
  };

  const forceShowToast = ({ title, body, autoHide, displayAccountLink = false, type = TOAST_TYPES.default }) => {
    setToastObj({ title, body, displayAccountLink, type, autoHide });
    setShowToast(true);
  };

  useEffect(
    function handleResyncToastMessages() {
      if (resyncToast) {
        forceShowToast(resyncToast);
      }
    },
    [resyncToast]
  );

  const onMultiSelectButtonClick = () => {
    setForceMultiSelectOn((previousState) => {
      const state = !previousState;
      if (!state) {
        if (canSaveEdits) {
          showUnsavedChangesModal();
          return previousState;
        }
        unselectAll();
      }

      segment.track({
        event: "Multi-select Toggle Clicked",
        properties: { state },
      });

      return state;
    });
  };

  function checkIfNewFrame(newComp) {
    const currentCompFrame = selectedComp ? getFrameInfoFromIndex(selectedComp.frameIndex) : null;
    const newCompFrame = getFrameInfoFromIndex(newComp.frameIndex);
    if (currentCompFrame === null || (newCompFrame && newCompFrame.id !== currentCompFrame.id)) {
      setFrameInfo({
        isNewFrame: true,
        frameId: newCompFrame.id,
        frameName: newCompFrame.name,
        fileID: doc.integrations.figma.branch_id || doc.integrations.figma.file_id,
        figma_node_ids: getFigmaNodeIdsOnFrame(newCompFrame.id),
        appliedVariantId: newCompFrame.appliedVariantId,
      });
    } else {
      setFrameInfo({ ...frameInfo, isNewFrame: false });
    }
  }

  const getFrameInfoFromIndex = (frameIndex) => {
    const frame = groupState.groups[frameIndex];
    if (frame) {
      return {
        id: frame.integrations.figma.frame_id,
        name: frame.name,
        appliedVariantId: frame.integrations.figma.applied_variant_id,
      };
    }
    return null;
  };

  const getCompFromCompId = (compId) => {
    let frameIndex = 0;
    for (const frame of groupState.groups) {
      // 1. search through items in comps bank in Frame
      for (const comp of frame.comps) {
        if (comp._id === compId) {
          return { ...comp, blockIndex: null, frameIndex: frameIndex };
        }
      }
      // 2. search through comps in Blocks in Frame
      if (frame && frame.blocks) {
        let blockIndex = 0;
        for (const block of frame.blocks) {
          for (const comp of block.comps) {
            if (comp._id === compId) {
              return {
                ...comp,
                blockIndex: blockIndex,
                frameIndex: frameIndex,
              };
            }
          }
          blockIndex += 1;
        }
      }
      frameIndex += 1;
    }
    return null;
  };

  const getTextItemsFromTextItemIds = (textItemIds, groups) => {
    let textItemIdMap = textItemIds.reduce((acc, compId) => ({ ...acc, [compId]: true }), {});

    groups.forEach((group, groupIndex) => {
      if (!group) {
        return;
      }

      group.comps.forEach((textItem) => {
        if (!textItemIdMap[textItem._id]) {
          return;
        }

        textItemIdMap[textItem._id] = {
          ...textItem,
          blockIndex: null,
          frameIndex: groupIndex,
        };
      });

      group.blocks.forEach((block, blockIndex) =>
        block.comps.forEach((textItem) => {
          if (!textItemIdMap[textItem._id]) {
            return;
          }

          textItemIdMap[textItem._id] = {
            ...textItem,
            blockIndex,
            frameIndex: groupIndex,
          };
        })
      );
    });

    const textItems = textItemIds.map((id) => textItemIdMap[id]).filter((textItem) => typeof textItem !== "boolean");

    if (!textItems.length) {
      return null;
    }

    return textItems;
  };

  function getFigmaNodeIdsOnFrame(frameId) {
    const frames = [...groupState.groups];
    const frame = frames.find((artboard) => artboard.integrations.figma.frame_id === frameId);
    let frameComps = frame && frame.comps ? frame.comps : [];
    let compsInBlocks = [];
    if (frame && frame.blocks) {
      for (const block of frame.blocks) {
        Array.prototype.push.apply(compsInBlocks, block.comps);
      }
      Array.prototype.push.apply(compsInBlocks, frameComps);
    }
    const formattedCompNodeIds = compsInBlocks.map((comp) => comp.figma_node_ID);
    return formattedCompNodeIds;
  }

  const getProjectBranchInfo = async () => {
    try {
      const data = await pollingBackgroundJobRequest({
        url: "/jobs/figmaGetBranchInfo",
        requestBody: {
          projectId: doc._id,
          validateFigma: false,
        },
      });
      setProjectBranchInfo(data);
    } catch (error) {
      console.error("Error fetching project branch info", error);
    }
  };

  const getAllFrameIds = async () => {
    try {
      const { url } = API.doc.get.allFrameApiIDs;
      const { data: frameIds } = await http.get(url(projectId));
      setAllFrameApiIDs(frameIds);
    } catch (error) {
      console.error("Error fetching /doc/allFrameApiIDs: ", error.message);
    }
  };

  useEffect(() => {
    getAllFrameIds();
  }, [setAllFrameApiIDs]);

  const reorder = (oldList, startIndex, endIndex) => {
    const result = Array.from(oldList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const reorderGroups = async ({ groupId, startIndex, endIndex }) => {
    const originalOrder = [...groupState.groups];
    try {
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
        groups: reorder(groupState.groups, startIndex, endIndex),
      });

      /**
       * Unsaved draft groups exist on the front-end and affect start/end
       * indexes, but don't exist on the back-end. To account for this,
       * we need to adjust the start and end indexes to what they would be
       * if the unsaved draft groups didn't exist before sending the values to
       * the backend.
       */
      let adjustedStartIndex = startIndex;
      let adjustedEndIndex = endIndex;

      for (let i = 0; i < groupState.groups.length; i++) {
        const g = groupState.groups[i];
        if (g.unsaved) {
          if (startIndex > i) adjustedStartIndex--;
          if (endIndex > i) adjustedEndIndex--;
        }
      }

      const groupToMove = groupState.groups.find((g) => g._id === groupId);
      if (!groupToMove.unsaved) {
        const { url, body } = API.doc.put.reorderGroups;
        await http.put(
          url(projectId),
          body({
            groupId,
            startIndex: adjustedStartIndex,
            endIndex: adjustedEndIndex,
          })
        );
      }
    } catch (error) {
      console.error("Error doing 'reorderGroups'", error);

      forceShowToast({
        title: "⚠️ Error reordering group",
        body: "There was an error reordering your groups. Please try again",
        displayAccountLink: false,
        autoHide: false,
      });
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
        groups: originalOrder,
      });
    }
  };

  const setFramePinned = async (frameId, is_pinned) => {
    let updated_artboards = [...groupState.groups];
    updated_artboards.forEach((group, i) => {
      if (group._id == frameId) {
        updated_artboards[i].pinned = is_pinned;
      }
    });
    groupStateDispatch({
      type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
      groups: updated_artboards,
    }); // update frontend first so there's no lag!

    try {
      const { url, body } = API.doc.post.setFramePinned;
      await http.post(
        url(projectId),
        body({
          frameId: frameId,
          is_pinned: is_pinned,
        })
      );
    } catch (e) {
      console.error("Error updating pinned frames in doc.jsx", e);
    }
  };

  const previewsLastUpdatedAt =
    doc?.integrations?.figma.previews_updated_at > doc?.integrations?.figma.resynced_at
      ? doc?.integrations?.figma.previews_updated_at
      : doc?.integrations?.figma.resynced_at;

  const handleRichTextEnabled = () => {
    const updateRichTextFlagState = !doc?.feature_flags?.rich_text;
    // unselect current comp so rich_text flag can be updated
    setSelectedComp(null);
    setMultiSelectedIds([]);
    setMultiSelectedComps([]);
    setDoc((prev) => ({
      ...prev,
      feature_flags: {
        ...prev.feature_flags,
        rich_text: updateRichTextFlagState,
      },
    }));
    setUpdateDocHistory(true);
    setCustomToast({
      show: true,
      message: `Rich text ${updateRichTextFlagState ? "enabled" : "disabled"}`,
      icon: null,
    });
    setTimeout(() => {
      setCustomToast({ show: false, message: null, icon: null });
    }, 4000);
  };

  const handleDevModeEnabled = () => {
    window.localStorage.setItem(storageKey, true);
    initializeProject();
    getAllFrameIds();
    setUpdateDocHistory(true);
  };

  const handleDisplayApiIds = (show) => {
    window.localStorage.setItem(storageKey, show);
    setDisplayApiIds(show);
  };

  const handleActivateProject = async (fileId) => {
    try {
      // update the integrations.figma.file_id on the backend
      const { url, body } = API.doc.post.setFileId;
      const { data } = await http.post(url(projectId), body({ fileId }));

      // update the file id on the frontend
      setDoc((prev) => ({
        ...prev,
        integrations: {
          ...prev.integrations,
          figma: {
            ...prev.integrations.figma,
            file_id: fileId,
          },
        },
      }));

      await resync(null, null, fileId);
      hideActivateModal();
      handleViewTour();
    } catch (error) {
      console.error("Error activating project", error);
      throw error;
    }
  };

  useEffect(() => {
    if (doc && doc.developer_mode_enabled) {
      let showIds = window.localStorage.getItem(storageKey);
      if (showIds === null) {
        window.localStorage.setItem(storageKey, true);
        setDisplayApiIds(true);
      } else {
        setDisplayApiIds(showIds === "true");
      }
    } else {
      setDisplayApiIds(false);
    }
  }, [doc]);

  const userHasEditAccess = userHasSomeResourceAccess("project_folder", "edit");
  const userHasSeenTour = getUserFlag("hasSeenSampleProjectTour");

  function handleViewTour() {
    let tourId;
    if (job === "ENGINEER") tourId = INTERCOM_TOUR_IDS.DEVELOPER;
    else {
      userHasEditAccess ? (tourId = INTERCOM_TOUR_IDS.EDITOR) : (tourId = INTERCOM_TOUR_IDS.COMMENTER);
    }
    window.Intercom("startTour", tourId);
    setUserFlag("hasSeenSampleProjectTour", true);
  }

  // if we're viewing the sample project, and it's been activated, launch the Intercom tour
  useEffect(
    function launchTourOnPageMount() {
      if (
        !userHasSeenTour &&
        doc &&
        doc.isSample &&
        // if the file_id has been modified from sampleProject, it means the project has been activated
        doc.integrations.figma.file_id !== "sampleProject"
      ) {
        handleViewTour();
      }
    },
    [doc]
  );

  const savedGroups = groupState.groups.filter((group) => !group.unsaved);

  // Fire a segment event when switching/loading a page
  // these events are used for Intercom tours
  useEffect(() => {
    if (doc && !doc.isSample && selectedPage) {
      if (selectedPage.id === DRAFTED_GROUPS_PAGE.id) {
        segment.track({
          event: "Loaded Drafted Groups Page",
        });
      } else {
        segment.track({
          event: "Loaded Figma Page",
        });
      }
    }
  }, [doc, selectedPage]);

  if (!doc?.isSample && unauthorizedProjectAccess) {
    return <PermissionRequiredProject />;
  }

  if (!doc /*fetchDocLoading  groupState.fetching*/ /*|| loading*/) {
    return (
      <ProjectProvider projectState={projectState}>
        <div className={style.docWrap}>
          <ProjectHeader
            title="Loading..."
            slackChannelId={null}
            slackChannelName={null}
            is_loading={true}
            forceShowToast={forceShowToast}
            dev_mode_enabled={displayApiIds || (doc && doc.developer_mode_enabled)}
            isLocked={doc && doc?.is_locked}
            richTextEnabled={doc && doc?.feature_flags?.rich_text}
            folder={folder}
            user={user}
            displayApiIds={displayApiIds}
            variants={variants}
            handleDisplayApiIds={handleDisplayApiIds}
            handleDevModeEnabled={handleDevModeEnabled}
            handleRichTextEnabled={handleRichTextEnabled}
            syncSettingsModalVisible={syncSettingsModalVisible}
            setSyncSettingsModalVisible={setSyncSettingsModalVisible}
            toggleFrameModal={toggleFrameModal}
          />
          <div className={style.docContainer}>
            <div className={style.docLoading}>
              <img className={style.loading} src={spinner} />
            </div>
          </div>
        </div>
      </ProjectProvider>
    );
  }

  return (
    <ProjectProvider projectState={projectState}>
      <Helmet>
        <title>{doc.doc_name}</title>
      </Helmet>
      <RenderIfHasResourcePermission
        permission="project_folder:comment" // this works for both editors and commenters
        NoPermissionComponent={PermissionRequiredProject}
        override={doc?.isSample}
      >
        <div className={style.docWrap}>
          {showResyncWarning && <ResyncWarningToast onDismiss={handleDismissResyncWarning} />}
          {customToast.show && <OverlayToast text={customToast.message} />}
          <OverlayToast
            text="Automatically attached selected text item to matching component"
            hidden={!showAutoAttachmentToast}
          />
          <OverlayToast text="👉 Multi-select turned on" hidden={!showMultiSelectToast} />
          <OverlayToast text="🎉 Components attached!" hidden={!showMultiAttachCompToast} />
          <OverlayToast text="🎉 Resync successful!" hidden={!showResyncSucessToast} />
          <OverlayToast
            text="Heads up! The text you were editing has been deleted from the Figma file, or is on a page that is no longer synced."
            hidden={!showResyncDeletionToast}
          />
          <OverlayToast {...overlayToastProps} />

          <ProjectHeader
            title={
              doc.integrations.figma.branch_id && projectBranchInfo?.mainProjectInfo
                ? projectBranchInfo.mainProjectInfo.name
                : doc.doc_name
            }
            docSlackInfo={doc.integrations.slack}
            setDoc={setDoc}
            figma_file_ID={doc.integrations.figma.file_id}
            figma_branch_id={doc.integrations.figma.branch_id}
            selected_pages={doc.integrations.figma.selected_pages.map(({ figma_id }) => figma_id)}
            isLocked={doc?.is_locked}
            projectBranchInfo={projectBranchInfo}
            folder={folder}
            user={user}
            isFigmaAuthenticated={isFigmaAuthenticated}
            resync={resync}
            resyncLoading={resyncLoading}
            doc_ID={projectId}
            time_last_resync={doc.integrations.figma.resynced_at}
            is_loading={false}
            forceShowToast={forceShowToast}
            multiOn={multiSelectOn || forceMultiSelectOn}
            onMultiSelectButtonClick={onMultiSelectButtonClick}
            artboards={groupState.groups}
            workspaceUsers={workspaceUsers}
            dev_mode_enabled={displayApiIds || (doc && doc.developer_mode_enabled)}
            richTextEnabled={doc && doc?.feature_flags?.rich_text}
            displayApiIds={displayApiIds}
            variants={variants}
            setSuggestedCompId={setSuggestedCompId}
            handleDisplayApiIds={handleDisplayApiIds}
            handleDevModeEnabled={handleDevModeEnabled}
            handleRichTextEnabled={handleRichTextEnabled}
            isSampleProject={doc.isSample}
            syncSettingsModalVisible={syncSettingsModalVisible}
            setSyncSettingsModalVisible={setSyncSettingsModalVisible}
            toggleFrameModal={toggleFrameModal}
          />

          <ToastNotification
            top={18}
            left={5}
            showToast={
              showToast &&
              !(
                // Don't show the toast if the sample project hasn't been activated, errors will appear in activation modal
                (doc.isSample && doc.integrations.figma.file_id === "sampleProject" && !isFigmaAuthenticated)
              )
            }
            toggleShowToast={toggleShowToast}
            toastObj={toastObj}
          />
          <div className={style.docContainer}>
            <GroupNavigation
              groupState={groupState}
              reorderFrames={reorderGroups}
              setFramePinned={setFramePinned}
              setSelectedPage={setSelectedPage}
              selectedPage={selectedPage}
              unselectAll={unselectAll}
              toggleFrameModal={toggleFrameModal}
              framePage={framePage}
              setFramePage={setFramePage}
              handleOpenFramePage={handleOpenFramePage}
              filteredFrames={filteredFrames}
              paginationFrameLimit={PAGE_FRAME_LIMIT}
              setSuggestedCompId={setSuggestedCompId}
              resyncLoading={resyncLoading}
            />

            {typeof doc.then !== "function" && groupState.groups && (
              <TextItemList
                groupMeta={groupMeta}
                setGroupMeta={setGroupMeta}
                multiOn={multiSelectOn}
                setUpdateDocHistory={setUpdateDocHistory}
                selectComp={selectComp}
                selectAllCompsInGroup={selectAllCompsInGroup}
                suggestedCompId={suggestedCompId}
                selectedId={selectedComp ? selectedComp._id : null}
                unselectAll={unselectAll}
                selectTag={selectTag}
                clearSelectedTags={clearSelectedTags}
                multiSelectedIds={multiSelectedIds}
                doc_name={doc.doc_name}
                doc_ID={projectId}
                variants={variants}
                handleSelectFrameVariant={handleSelectFrameVariant}
                handleAddNewVariants={handleAddNewVariants}
                handleDeleteFrameVariant={handleDeleteFrameVariant}
                groupState={groupState}
                pagerDispatch={pagerDispatch}
                resyncLoading={resyncLoading}
                reorderFrames={reorderGroups}
                updateFrames={updateFrames}
                setPanelState={setPanelState}
                setCommentState={setCommentState}
                setHidingAvailable={setHidingAvailable}
                setMultiSelected={setMultiSelected}
                figmaFileId={doc.integrations.figma.branch_id || doc.integrations.figma.file_id}
                figmaAccessToken={isFigmaAuthenticated}
                allFrameApiIDs={allFrameApiIDs}
                displayApiIds={displayApiIds}
                framePage={framePage}
                setFramePage={setFramePage}
                paginationFrameLimit={PAGE_FRAME_LIMIT}
                setSuggestedCompId={setSuggestedCompId}
                groupStateDispatch={groupStateDispatch}
                onDraftTextItemSelectionUpdate={onDraftTextItemSelectionUpdate}
                setCreateTextItemPanelStatus={setCreateTextItemPanelStatus}
                createTextItemPanelStatus={createTextItemPanelStatus}
                pollingForPreviewUpdates={pollingForPreviewUpdates}
                previewsLastUpdatedAt={previewsLastUpdatedAt}
                isSampleProject={doc.isSample}
                job={job}
                setJob={setJob}
                handleViewTour={handleViewTour}
              />
            )}
            <ProjectDetail
              refetchMultiComps={refetchMultiComps}
              groups={groupState.groups}
              currentComp={currentComp}
              doc_ID={projectId}
              doc_name={doc.doc_name}
              folder={folder}
              doc_date_time_created={doc.date_time_created}
              selectedId={selectedComp?._id}
              selectedVariant={selectedComp ? selectedComp.selectedVariant : null}
              handleSelectFrameVariant={handleSelectFrameVariant}
              multiOn={multiSelectOn}
              multiSelectedIds={multiSelectedIds}
              multiSelectedComps={multiSelectedComps}
              multiSelectedVariants={multiSelectedVariants}
              isMultiSelectOnSameFrame={isMultiSelectOnSameFrame}
              setMultiSelected={setMultiSelected}
              setMultiSelectedComps={setMultiSelectedComps}
              setSingleSelected={setSingleSelected}
              prev_resync_time={doc.integrations.figma.resynced_at}
              curr_resync_time={doc.integrations.figma.resynced_at || doc.edited_at}
              handleDocUpdate={updateFramesGivenCompIds}
              fireToast={fireToast}
              frameInfo={frameInfo}
              figmaFileID={doc.integrations.figma.file_id}
              forceShowToast={forceShowToast}
              updateDocHistory={updateDocHistory}
              setUpdateDocHistory={setUpdateDocHistory}
              showHighlightNew={showHighlightNew}
              setShowHighlightNew={setShowHighlightNew}
              hidingAvailable={hidingAvailable}
              panelState={panelState}
              setPanelState={setPanelState}
              commentState={commentState}
              setCommentState={setCommentState}
              changeSelectedComp={changeSelectedComp}
              resyncLoading={resyncLoading}
              tagSuggestions={tagSuggestions}
              getWorkspaceTags={getWorkspaceTags}
              unselectAll={unselectAll}
              setShowMultiAttachCompToast={setShowMultiAttachCompToast}
              workspaceUsers={workspaceUsers}
              framePreviewsMap={framePreviewsMap}
              pollingForPreviewUpdates={pollingForPreviewUpdates}
              handleOpenFramePage={handleOpenFramePage}
              handleOpenBlockPage={handleOpenBlockPage}
              frameVariants={variants.frameVariants}
              workspaceVariants={variants.workspaceVariants}
              handleSuggestionSelectComp={handleSuggestionSelectComp}
              suggestedCompId={suggestedCompId}
              setCustomToast={setCustomToast}
              multiSelectGroupIds={multiSelectGroupIds}
              createTextItemPanelStatus={createTextItemPanelStatus}
              setCreateTextItemPanelStatus={setCreateTextItemPanelStatus}
              setSelectedComp={setSelectedComp}
              projectBranchInfo={projectBranchInfo}
              selectAllCompsInGroup={selectAllCompsInGroup}
              syncSettingsModalVisible={syncSettingsModalVisible}
              setSyncSettingsModalVisible={setSyncSettingsModalVisible}
            />
          </div>
          {showFrameModal && (
            <ManageGroupsProvider groups={savedGroups}>
              <ManageGroupsModal
                docId={projectId}
                pages={doc.integrations.figma.selected_pages}
                isFigmaAuthenticated={isFigmaAuthenticated}
                figmaFileId={doc.integrations.figma.file_id}
                figmaBranchId={doc.integrations.figma.branch_id}
                onHide={toggleFrameModal}
                resync={resync}
                handleUnlinkGroups={handleUnlinkGroups}
                forceShowToast={forceShowToast}
              />
            </ManageGroupsProvider>
          )}
          {showMergeBranchModal && (
            <MergeBranchModal
              isSaving={isBranchMerging}
              onHide={hideMergeBranchModal}
              projectId={projectId}
              projectBranchInfo={projectBranchInfo}
              handleMergeBranch={handleMergeBranch}
            />
          )}
          {showMergeSucessModal && (
            <MergeSuccessModal goToMainProject={goToMainProject} onHide={hideMergeSuccessModal} />
          )}
          {showDeleteModal && (
            <DeleteProjModal
              doc_ID={projectId}
              doc_name={doc.doc_name}
              projectBranchInfo={projectBranchInfo}
              onHide={hideDeleteModal}
              isMissingBranch={true}
            />
          )}
          {showActivateModal && (
            <ActivateSampleProjectModal projectId={doc._id} handleActivateProject={handleActivateProject} />
          )}
        </div>
      </RenderIfHasResourcePermission>
      <ProjectWebsocketsHandler
        updateMultiComps={refetchMultiComps}
        showAutoAttachmentToast={onShowAutoAttachmentToast}
      />
    </ProjectProvider>
  );
};

export default ProjectPage;
