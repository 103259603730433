import { Mark, mergeAttributes } from "@tiptap/core";
import { forEachBracketPair } from "../../../lib/text";
import { ITipTapText } from "../../../types/TextItem";
import { CLASS } from "./TemplateComponentHighlighter";

export const NAME = "template_highlight";

/**
 * A custom mark that is used to highlight bracket pairs in template components.
 * This is only needed when the editor is being exported to JSON, as the editor
 * uses the `TemplateComponentHighlighter` to apply lightweight decorations
 * to brackets while editing text instead.
 *
 * For this node to work, it should be used in conjunction with the function
 * for adding template marks defined below.
 */
export const TemplateHighlightNode = Mark.create({
  name: NAME,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,
  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes({ class: CLASS }, HTMLAttributes), 0];
  },
});

/**
 * Given a ProseMirror text node, returns an array of JSONContent nodes that
 * have been split up to include a mark for template highlights where applicable.
 */
export const addTemplateHighlightMarks = (node: ITipTapText) => {
  const textNodes: ITipTapText[] = [];

  const { text } = node;
  if (!text) return [node];

  let lastIndex = 0;
  forEachBracketPair(text, ({ value, start, end }) => {
    const precedingText = text.substring(lastIndex, start);
    if (precedingText)
      textNodes.push({
        type: "text",
        text: precedingText,
      });

    if (value)
      textNodes.push({
        type: "text",
        text: value,
        marks: [{ type: NAME }],
      });

    lastIndex = end;
  });

  const remainderText = text.substring(lastIndex);
  if (remainderText) {
    textNodes.push({
      type: "text",
      text: remainderText,
    });
  }

  return textNodes;
};
