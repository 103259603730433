import { IWorkspaceSlackAuthenticated } from "../../shared/types/Workspace";

const DAYS_IN_MS = 1000 * 60 * 60 * 24;

export function getRemainingWorkspaceTrialDays(workspaceInfo: IWorkspaceSlackAuthenticated | null) {
  if (workspaceInfo?.ws_trial) {
    const elapsedDays = Math.floor((Date.now() - new Date(workspaceInfo.ws_trial).getTime()) / DAYS_IN_MS);
    return 14 - elapsedDays;
  }

  return -1;
}

export function getRemainingDevToolsTrialDays(workspaceInfo: IWorkspaceSlackAuthenticated | null) {
  if (workspaceInfo?.devToolsTrial) {
    const elapsedDays = Math.floor((Date.now() - new Date(workspaceInfo.devToolsTrial).getTime()) / DAYS_IN_MS);
    return 14 - elapsedDays;
  }

  return -1;
}

export function getRemainingDaysInTrial(workspaceInfo: IWorkspaceSlackAuthenticated | null) {
  let teamTrialDays = getRemainingWorkspaceTrialDays(workspaceInfo);
  let devTrialDays = getRemainingDevToolsTrialDays(workspaceInfo);

  const days = Math.max(teamTrialDays, devTrialDays);
  return days;
}
