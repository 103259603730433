import { z } from "zod";

export const ZAuthorizeRequest = {
  body: z.object({
    code: z.string(),
    redirectUri: z.string(),
    projectId: z.string().optional(),
    initializeConnection: z.boolean().optional(),
  }),
};
export const ZAuthorizeResponse = z.unknown();

export const ZRevokeRequest = {
  body: z.object({
    projectId: z.string().optional(),
    revokeConnection: z.boolean().optional(),
  }),
};
export const ZRevokeResponse = z.unknown();
