/*
 * Comment input and button with dropdown for @mentioning
 * Uses DraftJs with mentions plugin
 * Inputs:
 * * mentions -- list of users able to @mention
 * * saveContentCallback -- function to call when click button
 * * placeholderText
 */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { EditorContent, ReactRenderer, useEditor } from "@tiptap/react";
import classnames from "classnames";
import tippy from "tippy.js";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";

export interface MentionedUser {
  email: string;
  finishedDittoOverview: boolean;
  userId: string;
  job: string;
  name: string;
  role: string;
  _id: string;
  createdAt: Date;
}

// TODO: Do we event want this to be the same?
export interface CommentEditorProps {
  mentions: MentionedUser[];
  placeholderText: string;
  saveContentCallback: (
    contentToSave: string,
    mentionedUsersInfo: MentionedUser[],
    resetEditorState: () => void
  ) => Promise<void>;
  shouldAutofocus?: boolean;
  className?: string;
}

interface MentionListProps {
  items: MentionedUser[];
  command: (item: MentionedUser & { id: string }) => void;
}

const MentionList = forwardRef((props: MentionListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.userId, ...item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    if (props.items.length > 0) {
      selectItem(selectedIndex);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        return enterHandler();
      }

      return false;
    },
  }));

  return props.items.length ? (
    <div className={style.mentionListItems}>
      {props.items.map((item, index) => (
        <button
          className={classnames(style.mentionListItem, {
            [style.mentionListItemIsSelected]: index === selectedIndex,
          })}
          key={index}
          onClick={() => selectItem(index)}
        >
          {item.name}
        </button>
      ))}
    </div>
  ) : (
    <></>
  );
});

const CommentEditor = (props: CommentEditorProps) => {
  const { mentions, saveContentCallback, placeholderText, shouldAutofocus = false, className } = props;

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: placeholderText,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: style.mention,
        },
        renderLabel: ({ options, node }) => {
          const mentionUserName =
            mentions.find((mention) => mention.userId === node.attrs.id)?.name || "[removed user]";
          return `${options.suggestion.char}${mentionUserName}`;
        },
        suggestion: {
          allowSpaces: false,
          items: ({ query, editor }) => {
            const existingMentionIds =
              (editor.getJSON().content ?? [])[0].content?.flatMap((item) =>
                item.type === "mention" ? [item.attrs?.id] : []
              ) ?? [];
            return mentions
              .filter(
                (item) =>
                  !existingMentionIds.includes(item.userId) &&
                  item.name.toLowerCase().replace(/\s/g, "").includes(query.toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let component;
            let popup;

            return {
              onStart: (props) => {
                component = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                });

                if (!props.clientRect) {
                  return;
                }

                popup = tippy("body", {
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                });
              },

              onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                  return;
                }

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return component.ref?.onKeyDown(props);
              },

              onExit() {
                popup[0].destroy();
                component.destroy();
                // update suggestions
              },
            };
          },
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: `commentEditorInnerContent ${style.commentEditorInnerContent}`,
        "data-testid": "comment-input",
      },
    },
    onUpdate: ({ editor }) => {
      setCanSaveComment(editor.getText() !== "");
    },
    content: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [],
        },
      ],
    },
  });

  useEffect(
    function shouldAutoFocus() {
      if (shouldAutofocus) {
        editor?.commands.focus();
      }
    },
    [shouldAutofocus, editor]
  );

  const {
    canSaveComment: [canSaveComment, setCanSaveComment],
    setModalParams,
  } = useContext(UnsavedChangesContext);

  const resetEditorState = () => {
    editor?.commands.clearContent();
    editor?.commands.clearNodes();
    editor?.commands.focus();
    setCanSaveComment(false);
  };

  const getEditorText = function getEditorText() {
    const mentionedUsersInfo: MentionedUser[] = [];

    interface CommentParagraph {
      type: "paragraph";
      content: ({ text: string; type: "text" } | { type: "mention"; attrs: { id: string } })[];
    }

    const json = editor?.getJSON();
    const paragraphs: CommentParagraph[] | undefined = json?.content as CommentParagraph[] | undefined;

    const contentToSave =
      paragraphs
        ?.map((paragraph) => {
          const text = paragraph.content
            ?.map((item) => {
              if (item.type === "text") {
                return item.text;
              } else if (item.type === "mention") {
                const mentionedUser = mentions.find((mention) => mention.userId === item.attrs.id);
                if (!mentionedUser) {
                  return "";
                }
                mentionedUsersInfo.push(mentionedUser);
                return "<@" + mentionedUser.userId + ">";
              }
              return "";
            })
            .join("");
          return text;
        })
        .join("\n") || "";

    return { contentToSave, mentionedUsersInfo };
  };

  async function saveContent() {
    setCanSaveComment(false);
    let { contentToSave, mentionedUsersInfo } = getEditorText();
    await saveContentCallback(contentToSave, mentionedUsersInfo, resetEditorState);
  }

  useEffect(() => {
    if (canSaveComment && getEditorText().contentToSave !== "") {
      setModalParams({
        modalText: {
          title: "You have an unsaved comment!",
          body: "Before navigating away, would you like to post your comment?",
          actionPrimary: "Post",
          actionSecondary: "Discard",
        },
        saveCallback: () => {
          setCanSaveComment(false);
          saveContent();
        },
        discardCallback: () => {
          setCanSaveComment(false);
          resetEditorState();
        },
      });
    }
  }, [editor, setModalParams, canSaveComment, setCanSaveComment, saveContent, setCanSaveComment, resetEditorState]);

  return (
    <div className={className}>
      <div className={style.commentEditor} data-testid="comment-box">
        <EditorContent editor={editor} className={style.commentEditorContent} placeholder={placeholderText} />
        {getEditorText().contentToSave && (
          <ButtonPrimary
            data-testid="post-comment-button"
            text="Post"
            onClick={saveContent}
            disabled={!canSaveComment}
          />
        )}
      </div>
    </div>
  );
};

export default CommentEditor;
